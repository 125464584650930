import { Button, Col, Row } from "antd";
import i18n from "util/base/i18n";
import { FormulaFieldType } from "../../IProjectionModeling";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { IExpression, IFormula } from "../../newProjection/stepTwo/IStepTwo";
import { FormulaArea } from "./FormulaArea";
import { FormulaEspecials } from "./outher/FormulaEspecialsEnum";
import { OperationsSelect } from "./outher/OperationsSelect";
export default function FormulaContent() {
    const { setFormula } = useProjectionModelingContext();

    function buildOperationToAdd(
        selectedOperation: string,
        lastExpression: IExpression | undefined,
        openingParenthesesCount: number,
        closingParenthesesCount: number
    ): IExpression | null {
        if (isLeftParenthesis(selectedOperation, lastExpression)) {
            return createExpression(FormulaFieldType.LEFT_PARENTHESIS, FormulaFieldType.LEFT_PARENTHESIS);
        }
        if (isRightParenthesis(selectedOperation, lastExpression, openingParenthesesCount, closingParenthesesCount)) {
            return createExpression(FormulaFieldType.RIGHT_PARENTHESIS, FormulaFieldType.RIGHT_PARENTHESIS);
        }
        if (isValueOrField(selectedOperation, lastExpression)) {
            const fieldType =
                selectedOperation === FormulaEspecials.VALUE
                    ? FormulaFieldType.ABSOLUTE_VALUE
                    : FormulaFieldType.STATIC_FIELD;

            const operator =
                selectedOperation === FormulaEspecials.VALUE ? FormulaEspecials.VALUE : FormulaEspecials.FIELD;
            return createExpression(fieldType, operator);
        }
        if (isPercentage(selectedOperation, lastExpression)) {
            return createExpression(FormulaFieldType.PERCENTAGE_VALUE, FormulaEspecials.PERCENTAGE);
        }
        if (isOperator(selectedOperation, lastExpression)) {
            return createExpression(FormulaFieldType.OPERATOR, selectedOperation);
        }
        return null;
    }
    function isLeftParenthesis(selectedOperation: string, lastExpression: IExpression | undefined): boolean {
        return (
            selectedOperation === FormulaFieldType.LEFT_PARENTHESIS &&
            (!lastExpression || lastExpression.fieldType === FormulaFieldType.OPERATOR)
        );
    }
    function isRightParenthesis(
        selectedOperation: string,
        lastExpression: IExpression | undefined,
        openingParenthesesCount: number,
        closingParenthesesCount: number
    ): boolean {
        return (
            selectedOperation === FormulaFieldType.RIGHT_PARENTHESIS &&
            closingParenthesesCount < openingParenthesesCount &&
            lastExpression &&
            (lastExpression.fieldType === FormulaFieldType.ABSOLUTE_VALUE ||
                lastExpression.fieldType === FormulaFieldType.STATIC_FIELD ||
                lastExpression.fieldType === FormulaFieldType.PERCENTAGE_VALUE ||
                lastExpression.operator === FormulaFieldType.RIGHT_PARENTHESIS)
        );
    }
    function isValueOrField(selectedOperation: string, lastExpression: IExpression | undefined): boolean {
        return (
            ["VALUE", "FIELD"].includes(selectedOperation) &&
            (!lastExpression ||
                lastExpression.operator === FormulaFieldType.LEFT_PARENTHESIS ||
                lastExpression.fieldType === FormulaFieldType.OPERATOR)
        );
    }
    function isPercentage(selectedOperation: string, lastExpression: IExpression | undefined): boolean {
        return (
            selectedOperation === FormulaEspecials.PERCENTAGE &&
            lastExpression &&
            (lastExpression.fieldType === FormulaFieldType.ABSOLUTE_VALUE ||
                lastExpression.fieldType === FormulaFieldType.STATIC_FIELD)
        );
    }
    function isOperator(selectedOperation: string, lastExpression: IExpression | undefined): boolean {
        return (
            lastExpression &&
            (lastExpression.fieldType === FormulaFieldType.ABSOLUTE_VALUE ||
                lastExpression.fieldType === FormulaFieldType.STATIC_FIELD ||
                lastExpression.fieldType === FormulaFieldType.PERCENTAGE_VALUE ||
                lastExpression.operator === FormulaFieldType.RIGHT_PARENTHESIS)
        );
    }
    function createExpression(fieldType: FormulaFieldType, operator: string): IExpression {
        return {
            position: 0,
            fieldType: fieldType,
            operator: operator,
            staticField: null,
            modularFieldId: 0,
            scenarioId: 0,
            organizationId: 0,
            projectionId: null,
            managementAccountingAccountId: 0,
            managementCostCenterId: 0,
            period: "",
            rate: 100,
            value: 0,
        };
    }
    function addOperationToFormula(selectedOperation: string) {
        setFormula((prevFormula: IFormula) => {
            const expressions = prevFormula?.expressions || [];
            const lastExpression = expressions[expressions.length - 1];
            const openingParenthesesCount = expressions.filter(
                (item) => item.operator === FormulaFieldType.LEFT_PARENTHESIS
            ).length;
            const closingParenthesesCount = expressions.filter(
                (item) => item.operator === FormulaFieldType.RIGHT_PARENTHESIS
            ).length;
            let operationToAdd = buildOperationToAdd(
                selectedOperation,
                lastExpression,
                openingParenthesesCount,
                closingParenthesesCount
            );
            if (!operationToAdd) return prevFormula;
            operationToAdd.position = expressions.length;
            operationToAdd.projectionId = prevFormula?.projectionId || null;
            return {
                ...prevFormula,
                expressions: [...expressions, operationToAdd],
            };
        });
    }
    function removeItem(index) {
        setFormula((state) => ({
            ...state,
            expressions: state.expressions.filter((_, itemIndex) => itemIndex !== index),
        }));
    }
    function handleCleanFormula() {
        setFormula((state) => ({
            ...state,
            expressions: [],
        }));
    }
    return (
        <>
            <Row style={{ marginTop: "16px" }} gutter={[24, 24]}>
                <Col span={24}>
                    <div className="form-field-operations">
                        <OperationsSelect onChooseOperation={addOperationToFormula} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col id="field-modal-formula" span={24}>
                    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <h3 style={{ color: "#0065B3" }}>{i18n.t("additional_field_formula")}</h3>
                        <Button style={{ marginBottom: "8px" }} onClick={handleCleanFormula}>
                            {i18n.t("clear_formula")}
                        </Button>
                    </Row>
					<FormulaArea removeItem={removeItem} />
                </Col>
            </Row>
        </>
    );
}
