import { Row } from "antd";
import { TopButtons } from "components/topButtons/TopButtons";
import i18n from "util/base/i18n";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Notification } from "components/notification/Notification";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { Icon } from "@iconify/react";
import { useState } from "react";
import CalculateRulesModal from "./CalculateRulesModal";
import { IDistributionRuleData } from "../IDistributionRule";

export default function Title() {
	const {
		selectedRowKeys,
		setRuleForEdit,
		tableData,
		getTableData,
		setIsModalOpen,
		setIsTableDataLoading
	} = useDistributionRuleContext();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const customButtons: CustomButtons[] = [
		{
			icon: <Icon icon="ph:calculator-bold" />,
			toolTip: i18n.t("distribution_rules.calculate_rule"),
			onClick: () => {
				setIsPopoverOpen(true);
			}
		}
	]

	const handleNew = () => {
		setIsModalOpen(true);
	}

	const handleEdit = () => {
		const item: IDistributionRuleData = tableData.find(({ id }) => selectedRowKeys[0] === id);
		setRuleForEdit(item);
	}

	const handleDelete = () => {
		setIsTableDataLoading(true);
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `/software-house/distribution-rule?ids=${selectedRowKeys}`,
		}, onDelete, handleErrorRequest)
	}

	const onDelete = () => {
		getTableData();
		Notification({
			message: i18n.t("successfully_deleted"),
			type: "success"
		})
	}

	return (
		<Row>
			<Row className="page-title-content">
				<h1>{i18n.t("distribution_rules.title")}</h1>
			</Row>
			<Row>
				<TopButtons
					mainButtonTitle={i18n.t("distribution_rules.new_rule")}
					handleNew={handleNew}
					handleEdit={handleEdit}
					handleDelete={handleDelete}
					isDeletable={!!selectedRowKeys.length}
					isEditable={selectedRowKeys.length === 1}
					customButtons={customButtons}
				/>
			</Row>
			<CalculateRulesModal isModalOpen={isPopoverOpen} setIsPopoverOpen={setIsPopoverOpen} />
		</Row>
	)
}