import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ProfessionalCategoryTableData } from "module/budget/pages/professionalCategory/IProfessionalCategory";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { createContext, Key, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { IProjectionGrouperData } from "../../groupers/IProjectionGroupers";
import { onLoadCommom } from "../functions/buildData";
import {
    FormValues,
    ICommomList,
    IFieldList,
    IGroupList,
    IProjection,
    IProjectionModelingContext,
    TableData,
} from "../IProjectionModeling";
import { ListConditions } from "../newProjection/stepOne/IStepOne";
import { IFormula } from "../newProjection/stepTwo/IStepTwo";

const ProjectionModelingContext: React.Context<IProjectionModelingContext> = createContext<IProjectionModelingContext>(
    {} as IProjectionModelingContext
);

export function ProjectionModelingProvider({ children }: { children: ReactElement }) {
    const [costCenterList, setCostCenterList] = useState<IModalityFilter[]>([]);
    const [accountingList, setAccountingList] = useState<IModalityFilter[]>([]);
    const [projectionsOptions, setProjectionsOptions] = useState<Options[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<TableData[]>([]);
    const [ledgerList, setLedgerList] = useState<IModalityFilter[]>([]);
    const [isInstallment, setIsInstallment] = useState<boolean>(false);
    const [isWhole, setIsWhole] = useState<boolean>(false);
    const [isEditingProjection, setIsEditingProjection] = useState<boolean>(false);
    const [tableData, setTableData] = useState<IGroupList[]>([]);
    const [filterdTableData, setFilterdTableData] = useState<IGroupList[]>([]);
    const [isGridFetching, setIsGridFetching] = useState<boolean>(true);
    const [grouperData, setGrouperData] = useState<IProjectionGrouperData[]>([]);
    const [isNewProjection, setIsNewProjection] = useState<boolean>(false);
    const [stepOneListConditions, setStepOneListConditions] = useState<ListConditions[]>([]);
    const [fieldList, setFieldList] = useState<IFieldList[]>([]);
    const [eventSelected, setEventSelected] = useState<number | string>();
    const [scheduleDays, setScheduleDays] = useState<number>(0);
    const [form] = Form.useForm<FormValues>();
	const [projectionToSave, setProjectionToSave] = useState<Record<string, any>>();
	const [isFormulaFetching, setIsFormulaFetching] = useState(true);
    const itemForEdit: TableData | null = useMemo(() => {
        function searchItem(data: TableData[]): TableData | null {
            for (const item of data) {
                if (item.id && selectedIds.includes(item.id)) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const foundChild = searchItem(item.children);
                    if (foundChild) {
                        return foundChild;
                    }
                }
            }
            return null;
        }

        const selectedIds: number[] = selectedRows.map((row) => (typeof row === "object" ? row.id : row));
        return searchItem(selectedRows);
    }, [selectedRows]);

    const [formula, setFormula] = useState<IFormula>({
        projectionId: null,
        expressions: [],
    });

    const tableColumns: ColumnsType<TableData> = [
        {
            title: i18n.t<string>("event"),
            dataIndex: "event",
            key: "event",
            align: "left",
            render: (value, { children = [] }) => {
                if (!children.length) return;
                return value;
            },
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
        },
        {
            title: i18n.t("ledger_book"),
            dataIndex: "ledgerId",
            key: "ledgerId",
            render: (id: number) => {
                const label = ledgerList.find(({ value }) => value === id)?.label;
                return label;
            },
        },
        {
            title: i18n.t<string>("calculation_order"),
            dataIndex: "calculationOrder",
            key: "calculationOrder",
            align: "left",
        },
        {
            title: `${i18n.t<string>("origin")} (01)`,
            dataIndex: "origin",
            key: "origin",
            align: "left",
            render: (value: string) => {
                if (!value) return "";
                const label: string = i18n.t(`projection.origin.${value.toLowerCase()}`);
                return label;
            },
        },
        {
            title: "Data de vigência",
            dataIndex: "endDate",
            key: "endDate",
            align: "center",
        },
    ];

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/planning/management-account-plan-hierarchy/get-accounting-accounts",
            },
            (data: ICommomList[]) => onLoadCommom(data, setAccountingList)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/planning/management-cost-center",
            },
            (data: ICommomList[]) => onLoadCommom(data, setCostCenterList)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/monolith/ledger?client={client}",
            },
            onLoadLedger
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/planning/projection/grouper`,
            },
            onLoadGrouper
        );
    }, []);

    useEffect(() => {
        if (!grouperData.length) return;
        getTableData();
    }, [grouperData]);

    useEffect(() => {
        if (!isNewProjection) return;

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url:
                    `/planning/projection/find-projections-excluding-id` +
                    (selectedRowKeys[0] ? `?id=${selectedRowKeys[0]}` : ""),
            },
            onLoadProjections
        );
    }, [isNewProjection]);

    const onLoadProjections = (data: IProjection[]) => {
        if (!data) {
            return;
        }

        const updatedSelect = Object.values<IProjection>(data).map((projection: IProjection) => ({
            value: projection.id,
            label: projection.description,
        }));
        setProjectionsOptions(updatedSelect);
    };

    function onLoadLedger(data: ProfessionalCategoryTableData[]) {
        const newLedgerList: IModalityFilter[] = data.map((item) => {
            const label: string = `${item.externalCode} - ${item.name}`;
            return {
                value: item.id,
                label,
            };
        });

        setLedgerList(newLedgerList);
    }

    function onLoadProjection(data: Record<string, any>) {
        const eventKeys = Object.keys(data);
        if (eventKeys.length === 0 || grouperData.length === 0) {
            setTableData([]);
            setIsGridFetching(false);
            return;
        }

        const updateTableData: IGroupList[] = eventKeys.map((key) => {
            const grouperList: string[] = Object.keys(data[key]);

            const secondLevel: TableData[] = grouperList.map((grouper) => {
                const children: TableData[] = data[key][grouper].map((res: any) => ({
                    id: res.id,
                    key: res.id,
                    description: res.description,
                    event: res.event,
                    origin: res.origin,
                    formula: res.formula,
                    calculationOrder: res.order,
                    startDate: res.effectiveStartDate,
                    endDate: res.effectiveEndDate,
                    currencyId: res.currencyId,
                    percentualBase: res.percentualBase,
                    cashCreditAccountingAccountId: res.cashCreditAccountingAccountId,
                    cashDebitAccountingAccountId: res.cashDebitAccountingAccountId,
                    cashCreditCostCenterId: res.cashCreditCostCenterId,
                    cashDebitCostCenterId: res.cashDebitCostCenterId,
                    accrualCreditAccountingAccountId: res.accrualCreditAccountingAccountId,
                    accrualDebitAccountingAccountId: res.accrualDebitAccountingAccountId,
                    accrualCreditCostCenterId: res.accrualCreditCostCenterId,
                    accrualDebitCostCenterId: res.accrualDebitCostCenterId,
                    scheduleDays: res.scheduleDays,
                    schedulePayment: res.schedulePayment,
                    flexFieldsConditions: res.flexFieldsConditions,
                    ledgerId: res.ledgerId,
                    grouperId: res.grouperId,
                    projectionCopyType: res.projectionCopyType,
                    indexerId: res.indexerId,
                    indexerType: res.indexerType,
                }));

                const grouperValue = Number(grouper);
                const keyGroup =
                    grouperData.find((g: IProjectionGrouperData) => g.id === grouperValue)?.description || "Unknown";

                return {
                    event: keyGroup,
                    key: Math.floor(Math.random() * 10000) + 1,
                    children: children,
                    value: grouperValue,
                };
            });
            secondLevel.sort((a, b) => a.event.localeCompare(b.event));
            const randomKey = Math.floor(Math.random() * 10000) + 1;
            return {
                event: i18n.t("modules." + key.toLowerCase().replace("_", ".")),
                key: randomKey,
                children: secondLevel,
                value: randomKey,
            };
        });

        setFilterdTableData(updateTableData);
        setTableData(updateTableData);
        setIsGridFetching(false);
    }

    function getTableData() {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/planning/projection?organization={organization}`,
            },
            onLoadProjection
        );
    }

    function onLoadGrouper(data: IProjectionGrouperData[]) {
        if (data.length === 0) {
            setGrouperData([]);
            return;
        }
        setGrouperData(data);
    }

    return (
        <ProjectionModelingContext.Provider
            value={{
                form,
                accountingList,
                costCenterList,
                projectionsOptions,
                setProjectionsOptions,
                selectedRowKeys,
                setSelectedRowKeys,
                selectedRows,
                setSelectedRows,
                formula,
                setFormula,
                ledgerList,
                setLedgerList,
                isInstallment,
                setIsInstallment,
                isWhole,
                setIsWhole,
                isEditingProjection,
                setIsEditingProjection,
                filterdTableData,
                setFilterdTableData,
                setTableData,
                tableData,
                itemForEdit,
                isGridFetching,
                setIsGridFetching,
                grouperData,
                setGrouperData,
                getTableData,
                isNewProjection,
                setIsNewProjection,
                tableColumns,
                setStepOneListConditions,
                stepOneListConditions,
                fieldList,
                setFieldList,
                eventSelected,
                setEventSelected,
                projectionToSave,
                setProjectionToSave,
                scheduleDays,
				setScheduleDays,
				isFormulaFetching,
				setIsFormulaFetching
            }}
        >
            {children}
        </ProjectionModelingContext.Provider>
    );
}

export const useProjectionModelingContext = (): IProjectionModelingContext => {
    const projectionContext = useContext(ProjectionModelingContext);
    return projectionContext;
};
