import { ColumnsType } from "antd/lib/table";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { ConsolidationTableData } from "./IConsolidation";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { Form } from "antd";
import ConsolidationModal from "./components/ConsolidationModal";
import ConsolidationTable from "./components/ConsolidationTable";
import { CustomButtons } from "components/topButtons/ITopButtons";
import "./styles.sass";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { sortList } from "util/functions/sortList";
import { Notification } from "components/notification/Notification";
import ConsolidationElimination from "./consolidationElimination/ConsolidationElimination";

export default function Consolidation() {
    const [tableData, setTableData] = useState<ConsolidationTableData[]>([]);
    const [filteredTableData, setFilteredTableData] = useState<ConsolidationTableData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalList, setModalList] = useState<ConsolidationTableData[]>([]);
    const [isNewConsolidation, setIsNewConsolidation] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isRateVisualization, setIsRateVisualization] = useState<boolean>(false);
    const [form] = Form.useForm();

    const tableColumns: ColumnsType<ConsolidationTableData> = [
        {
            title: i18n.t<string>("code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
        },
    ];

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/consolidation/import",
            templateUrl: "/planning/consolidation/template?locale={locale}",
            type: 'excel',
        },
    ];

    const viewValuesButton: CustomButtons[] = [{
        title: "Visualizar Taxas",
        disabled: !(selectedRows.length === 1),
        className: `gs-secondary-button rates-btn-order ${selectedRowKeys.length || "disabled-button"} `,
        onClick: () => {
            handleViewRates();
        }
    }];

    useEffect(() => {
        fetchConsolidation();
    }, []);

    useEffect(() => {
        setFilteredTableData(tableData);
    }, [tableData]);

    function fetchConsolidation() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/consolidation`
        }, onLoadTableData.bind(this));
    }

    function onLoadTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };

        const updateTableData = data.map(res => {
            return {
                key: res.id,
                externalCode: res.externalCode,
                description: res.description,
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));

        setTableData(updateTableData);
        setIsFetching(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: ConsolidationTableData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNew: boolean) {
        if (isNew) {
            setIsNewConsolidation(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                description: selectedRows[0].description,
            });
            setIsNewConsolidation(false);
        }
        setModalList([]);
        setIsModalVisible(true);
    }

    function handleViewRates() {
        setIsRateVisualization(true);
    }

    function handleDelete() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/planning/consolidation?ids=${selectedRowKeys.toString()}`,
        }, onDeleteCostCenter.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteCostCenter(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        }
        fetchConsolidation();
    };

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setModalList([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function handleSaveConsolidation(data) {
        const dataToSave = data.map(
            item => {
                return {
                    id: isNewConsolidation ? null : selectedRowKeys[0],
                    externalCode: isNewConsolidation ? item.externalCode : selectedRows[0].externalCode,
                    description: item.description,
                }
            });

        setSelectedRowKeys([]);
        setSelectedRows([]);

        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: isNewConsolidation ? RequestType.POST : RequestType.PUT,
            url: '/planning/consolidation',
            params: isNewConsolidation ? dataToSave : dataToSave[0],
        }, onSaveConsolidation.bind(this));
    }

    function onSaveConsolidation(response) {
        if (response) {
            Notification({
                type: "success",
                message: isNewConsolidation ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"),
            });
        }
        form.resetFields();
        fetchConsolidation();
    };

    if (isRateVisualization) {
        return (
            <ConsolidationElimination
                consolidationId={selectedRowKeys[0]}
                onCancel={() => {
                    setIsRateVisualization(false);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                }}
                selectedRows={selectedRows}
            />
        )
    }

    return (
        <>
            <div>
                <div className="page-title-content">
                    <h1>{i18n.t("consolidation_registration")}</h1>
                </div>
                <div id="top-buttons-consolidation">
                    <TopButtons
                        mainButtonTitle={i18n.t("new_consolidation")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        mainButtonStyles={{ order: -1 }}
                        customButtons={viewValuesButton}
                        handleDelete={handleDelete}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        hasCaptcha={true}
                        multipleSearch={{
                            options: [
                                { description: "externalCode", i18nString: "code" },
                                { description: "description", i18nString: "description" },
                            ],
                            setTableData: setFilteredTableData,
                            tableData
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, i18n.t("consolidation_model")),
                            importProps: importProps
                        }}
                    />
                </div>
                <ConsolidationModal
                    isModalVisible={isModalVisible}
                    isNewConsolidation={isNewConsolidation}
                    handleCancel={handleCloseModal}
                    form={form}
                    modalList={modalList}
                    setModalList={setModalList}
                    handleSaveConsolidation={handleSaveConsolidation}
                    tableData={tableData}
                    selectedRowKeys={selectedRowKeys}
                />
                <ConsolidationTable
                    isFetching={isFetching}
                    tableData={filteredTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
            </div>
        </>
    )
}