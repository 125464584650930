import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { FilterType, IDistributionRuleFilter, IDistributionRuleResultFilter, IFilterFormValues, IOptionRuleFilter, IDistributionRuleContext, IDistributionRuleData, FilterResultType } from "../IDistributionRule";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { ErrorRequest, FlexFieldFilterResponse } from "util/types/types";
import { useForm } from "antd/lib/form/Form";
import { TableRowSelection } from "antd/lib/table/interface";

const DistributionRuleContext = createContext<IDistributionRuleContext>(null);
export const DistributionRuleProvider = ({ children }: { children: ReactElement }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const [tableData, setTableData] = useState<IDistributionRuleData[]>([]);
	const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [ruleForEdit, setRuleForEdit] = useState<IDistributionRuleData | null>();
	const [ruleFilterOptions, setRuleFilterOptions] = useState<IOptionRuleFilter[]>([]);
	const [selectedTab, setSelectedTab] = useState<"filter" | "result">("filter");
	const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
	const [filtersForm] = useForm<IFilterFormValues>();
	const [ruleConditionFilter, setRuleConditionFilter] = useState<IDistributionRuleFilter[]>([]);
	const [ruleConditionResult, setRuleConditionResult] = useState<IDistributionRuleResultFilter[]>([]);
	const rowSelection: TableRowSelection<IDistributionRuleData> = {
		selectedRowKeys,
		onChange: (key: number[]) => {
			setSelectedRowKeys(key);
		}
	};

	useEffect(() => {
		if (!isModalOpen) {
			setSelectedRowKeys([]);
			return;
		}
		getAsyncData();
	}, [isModalOpen])

	useEffect(() => {
		getTableData();
	}, [])

	const getAsyncData = async () => {
		const flexFieldsResponse = await ServiceCaller.doAsyncRequest<FlexFieldFilterResponse[]>({
			type: RequestType.GET,
			url: `/budget-base/flex-field/find-all-by-module?module=EXPENSES`,
		})

		Promise.resolve(flexFieldsResponse)
			.then(({ data }) => {
				onLoadRuleFilterOptions(data)
			})
	}

	const onLoadRuleFilterOptions = (flexFields: FlexFieldFilterResponse[]) => {
		const flexData: IOptionRuleFilter[] = flexFields.map(({ externalCode, description, id }) => {
			const label: string = `${externalCode} - ${description}`;
			return ({
				label,
				value: `${id}-${FilterType.FLEX_FIELD}`,
				filter: FilterType.FLEX_FIELD,
				result: FilterResultType.FLEX_FIELD
			})
		})
		setRuleFilterOptions(flexData);
		setIsOptionsLoading(false);
	}

	const getTableData = async () => {
		setSelectedRowKeys([]);
		const response = await ServiceCaller.doAsyncRequest({
			type: RequestType.GET,
			url: "/software-house/distribution-rule/get-by-scenario?scenarioId={scenario}"
		})

		if (response.success) {
			setTableData(response.data as IDistributionRuleData[]);
		} else {
			handleErrorRequest({} as ErrorRequest);
		}

		setIsTableDataLoading(false);
	}

	return (
		<DistributionRuleContext.Provider
			value={{
				setTableData,
				tableData,
				isTableDataLoading,
				setIsTableDataLoading,
				isModalOpen,
				setIsModalOpen,
				isSaving,
				setIsSaving,
				ruleForEdit,
				setRuleForEdit,
				ruleFilterOptions,
				selectedTab,
				setRuleFilterOptions,
				setSelectedTab,
				isOptionsLoading,
				setIsOptionsLoading,
				filtersForm,
				ruleConditionFilter,
				ruleConditionResult,
				setRuleConditionFilter,
				setRuleConditionResult,
				getTableData,
				rowSelection,
				selectedRowKeys
			}}
		>
			{children}
		</DistributionRuleContext.Provider>
	)
}

export const useDistributionRuleContext = (): IDistributionRuleContext => {
	return useContext(DistributionRuleContext);
}