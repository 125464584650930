import { Tabs } from "antd";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import DistributionRule from "./DistributionRule";
import i18n from "util/base/i18n";


export function FiltersTab() {
	const { selectedTab, setSelectedTab } = useDistributionRuleContext();
	return (
		<Tabs
			activeKey={selectedTab}
			type="card"
			className="gs-tab"
			onChange={setSelectedTab}
		>
			<Tabs.TabPane tab={i18n.t<string>("filter")} key="filter">
				<DistributionRule />
			</Tabs.TabPane>
			<Tabs.TabPane tab={i18n.t<string>("result")} key="result">
				<DistributionRule />
			</Tabs.TabPane>
		</Tabs>
	)
}