import { DatePicker, Tabs } from "antd";
import i18n from "util/base/i18n";
import Content from "./components/Content";
import "./styles.sass"
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { CostCenterList, LevelItem } from "./IFromTo";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Icon } from "@iconify/react";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { useAccountAccountingOptions } from "hooks/useAccountAccountingOptions";
import { useCostCenterOptions } from "hooks/useCostCenterOptions";

export default function FromTo() {

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/management-cost-center-parameterization/import",
            templateUrl: "/planning/management-cost-center-parameterization/template?locale={locale}",
            type: 'excel',
            title: i18n.t("planning_from_to.importer.cost_center")
        },
        {
            importUrl: "/planning/management-account-parameterization/import",
            templateUrl: "/planning/management-account-parameterization/template?locale={locale}",
            type: 'excel',
            title: i18n.t("planning_from_to.importer.accounting_account"),
            hasImportOption: true,
        }
    ]

    const [treeData, setTreeData] = useState<LevelItem[]>([]);
    const [costCenterList, setCostCenterList] = useState(null);
    const [isFetchingTreeData, setIsFetchingTreeData] = useState(true);
    const [isFetchingCostCenterList, setIsFetchingCostCenterList] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedNodeKeys, setCheckedNodeKeys] = useState<number[]>([]);
	const [checkedCostCenters, setCheckedCostCenters] = useState([]);
	const { data: accountAccountingOptions } = useAccountAccountingOptions();
	const { data: costCenterOptions } = useCostCenterOptions();

    const [referenceDate, setReferenceDate] = useState(moment());

    const handleYearChange = (date) => {
        setReferenceDate(date);
    };

    useEffect(() => {
        setIsFetchingTreeData(true);
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/planning/management-account-plan-hierarchy/tree",
        }, onLoadTreeData.bind(this));

        if (costCenterList !== null) {
            setIsFetchingCostCenterList(true);
            fetchCostCenterList();
        }
    }, [referenceDate]);

    function fetchCostCenterList() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/planning/management-cost-center?clientId={client}&localeId={locale}&organizationId={organization}",
        }, (data: CostCenterList[]) => {
            setCostCenterList(data);
            setIsFetchingCostCenterList(false);
        });
    }

    function onLoadTreeData(data) {
        if (data.length === 0) {
            setTreeData([]);
            setIsFetchingTreeData(false);
            return;
        }
        const treeCopy = cloneDeep(data);
        treeCopy.forEach((node) => {
            addPropertyTitle(node);
        });
        setTreeData(treeCopy);
        setIsFetchingTreeData(false);
    }

    function addPropertyTitle(tree: LevelItem) {
        if (tree && typeof tree === 'object') {
            const externalCodeSuffix = ` (${tree.externalCode})`;
            if (tree.hasOwnProperty('description')) {
                tree.title = tree.dataTree.length === 0 ? `${tree.description}${externalCodeSuffix}` : tree.description;
                tree.children = tree.dataTree;
                tree.key = tree.id;
                delete tree.description;
				delete tree.dataTree;
            }
            for (let key in tree) {
                addPropertyTitle(tree[key]);
            }
        }
    };

    function onChangeTab(activeTab: "ledger_account" | "cost_center") {
        if (activeTab === "cost_center") {
            if (costCenterList === null) {
                setIsFetchingCostCenterList(true);
                fetchCostCenterList();
            }
        }
        setCheckedNodeKeys([]);
        setCheckedCostCenters([]);
    }

    return (
        <div id="plan-from-to">
            <div className="page-title-content">
                <h1>{i18n.t("from_to")}</h1>
                <DatePicker
                    onChange={handleYearChange}
                    picker="year"
                    suffixIcon={<span><Icon icon="solar:calendar-bold" /> {i18n.t("base_year")}</span>}
                    value={referenceDate}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "end", padding: "8px 20px" }}>
                <ImportExportMenu importProps={importProps} buttonType="3dots" />
            </div>
            <div className="page-content">
                <Tabs
                    onChange={onChangeTab}
                    centered
                    className="gs-tab"
                    type="card"
                    moreIcon={null}
                >
                    <Tabs.TabPane tab={i18n.t("ledger_account")} key="ledger_account">
                        <Content
                            treeData={treeData}
                            checkedKeys={checkedNodeKeys}
                            setCheckedKeys={setCheckedNodeKeys}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                            isFetching={isFetchingTreeData}
                            selectedTab="ledger_account"
							referenceDate={referenceDate}
							allOptions={accountAccountingOptions}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("cost_center")} key="cost_center">
                        <Content
                            costCenterList={costCenterList}
                            checkedKeys={checkedCostCenters}
                            setCheckedKeys={setCheckedCostCenters}
                            isFetching={isFetchingCostCenterList}
                            selectedTab="cost_center"
							referenceDate={referenceDate}
							allOptions={costCenterOptions}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}