import Split from "react-split";
import "./SplitComponent.sass";

export function SplitComponent({ children }: { children: React.ReactNode }) {
    return (
        <Split
            className="split"
            style={{ display: "flex", flexDirection: "row" }}
            minSize={300}
            gutterAlign="start"
            sizes={[185, 800]}
        >
            {children}
        </Split>
    );
}
