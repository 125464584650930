import { Row, Col, Input, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { IOptionsListProps, ReportType } from "../../../../IRegistrationSalesReports";
import i18n from "util/base/i18n";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import StringUtil from "util/StringUtil";
import { useNewReportContext } from "../../../NewReportContext";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { OptionSelect } from "module/budget/pages/collaborators/linkAccounts/ILinkAccounts";

export default function OptionsList({
	selectedOptionsList,
	setSelectedOptionsList,
	onChangeCheckboxGroup,
	options = [],
	formulas,
	selectedRowKey
}: IOptionsListProps) {
	const [searchValue, setSearchValue] = useState("");
	const { reportType, isPlanningReport } = useNewReportContext();
	const [defaultOptions, setDefaultOptions] = useState<OptionSelect[]>(options);

	const filteredOptions: CheckboxOptionType[] = defaultOptions
		.map(({ label, value }) => {
			const labelFormatted: string = value.startsWith("LEVEL") ? `${i18n.t("report")}: ${label}` : label;
			const style: React.CSSProperties = isPlanningOption(value.split("-")[0]) ? {
				background: "#F1F2F3",
			} : {};
			return ({
				value,
				label: labelFormatted,
				style,
			})
		})
		.filter(({ label, value }) => filterOption(value) && StringUtil.getWithoutSpecials(label)?.includes(StringUtil.getWithoutSpecials(searchValue)))

	function filterOption(value: string): boolean {
		return value !== `LEVEL-${selectedRowKey}`;
	}

	function isPlanningOption(value: string): boolean {
		return ["MOVEMENT", "OPENING_BALANCE", "FINAL_BALANCE", "CREDIT", "DEBIT"].includes(value);
	}

	function onLoadOptions(planningOptions: any[]) {
		setDefaultOptions(state => {
			const newPlanningOptions = planningOptions.map(({ name }) => {
				const translatedName: string = i18n.t(`new_sales_report.options_formula.${name}`)
				return ({
					value: `${name}-0`,
					label: translatedName
				})
			})
			return ([
				...newPlanningOptions,
				...state
			])
		})
	}

	useEffect(() => {
		let defaultSelectedArr: string[] = [];
		formulas.forEach(({ attributeId, levelId, type }) => {
			if (isPlanningOption(type)) {
				defaultSelectedArr.push(`${type}-0`);
			} else {
				if (attributeId) {
					defaultSelectedArr.push(`ATTRIBUTE-${attributeId}`);
				} else if (levelId) {
					defaultSelectedArr.push(`LEVEL-${levelId}`);
				}
			}
		})
		setSelectedOptionsList(defaultSelectedArr);
	}, [formulas])

	useEffect(() => {
		if (!isPlanningReport()) return;

		ServiceCaller.doRequest(({
			type: RequestType.GET,
			url: "/budget-report/report/get-option-formulas"
		}), onLoadOptions)
	}, [])

	return (
		<div className="options-list">
			<Row>
				{i18n.t<string>("list_of_options")}
			</Row>
			<div className="options-list-content">
				<Row>
					<Col span={24}>
						<Input.Search
							className="search-business-unit"
							placeholder={i18n.t<string>("search_values")}
							value={searchValue}
							onChange={({ target: { value } }) => setSearchValue(value)}
						/>
						<Checkbox.Group
							value={selectedOptionsList}
							onChange={(listChecked) => {
								setSelectedOptionsList(listChecked as string[]);
								onChangeCheckboxGroup(
									listChecked.map(checkedId => {
										return (
											{
												label: filteredOptions.find(({ value }) => value === checkedId).label,
												value: checkedId
											}
										)
									})
								)
							}
							}
							options={filteredOptions}
						/>
						<span className="footer">
							{filteredOptions?.length} {i18n.t<string>("items")}
						</span>
					</Col>
				</Row>
			</div>
		</div>
	)
}