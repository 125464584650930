import { Modal } from "antd";
import { IUpdateAccountsProps } from "./IUpdateAccountsProps";
import { Filter } from "components/filter/Filter";
import i18n from "util/base/i18n";
import { useState } from "react";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export default function UpdateAccounts({ isRecalculateModalOpen, setIsRecalculateModalOpen, budgetPeriodDates, openPeriods }: IUpdateAccountsProps) {
    const { userInfo } = useUserContext();
    const [selectedPeriod, setSelectedPeriod] = useState<[moment.Moment, moment.Moment] | null>(null);

    const onRefreshAccounts = () => {
        if (!selectedPeriod || !selectedPeriod[0] || !selectedPeriod[1]) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.select_a_period_to_continue"),
            });
            return;
        }
        handleRefreshAccountLinked();
        closeRecalculateModal();
        Notification({
            type: "info",
            message: `${i18n.t("accounts_being_updated")}...`,
        })
    }

    const closeRecalculateModal = () => {
        setIsRecalculateModalOpen(false);
        setSelectedPeriod(null);
    };

	async function handleRefreshAccountLinked() {
        const yearList = [];
        const listofYearsWithOpenBudget = budgetPeriodDates.period.map((period) => period.year);
        let currentDate = selectedPeriod[0].clone();

        while (currentDate.isSameOrBefore(selectedPeriod[1])) {
            yearList.push(currentDate.format("YYYY"));
            currentDate.add(1, "year");
        }
        const yearWithoutBudgetOpenPeriod = yearList.filter(
            (year) => !listofYearsWithOpenBudget.includes(Number(year))
        );
        const yearWithBudgetOpenPeriod = yearList.filter((year) => listofYearsWithOpenBudget.includes(Number(year)));

        if (yearWithoutBudgetOpenPeriod.length > 0) {
            Notification({
                type: "warning",
                message: `${i18n.t("no_open_budget")} ${yearWithoutBudgetOpenPeriod.join(", ")}`,
            });
        }

        async function doRefreshAccountLinked() {
            const responses = [] as {
                success: boolean;
                data: any;
                year: number;
            }[];

            for (const year of yearWithBudgetOpenPeriod) {
                const dataToSend = {
                    scenarioId: userInfo.selection.scenarioId,
                    organizationId: userInfo.selection.organizationId,
                    year: Number(year),
                    currencyId: userInfo.currencyId,
                    localeId: userInfo.localeId,
                    clientId: userInfo.clientId,
                };

                try {
                    const response = await ServiceCaller.doAsyncRequest({
                        type: RequestType.POST,
                        url: `/expenses/budget-opening/update-linked-accounts`,
                        params: dataToSend,
                    });
                    responses.push({ ...response, year });
                } catch (error) {
                    responses.push({ ...error, year });
                    console.error(`${i18n.t("error_for_the_year")} ${year}:`, error);
                }
            }

            return responses;
        }

        doRefreshAccountLinked().then((apiResponses) => {
            apiResponses.forEach((res) => {
                if (res.data.messageCode === "empty") {
                    Notification({
                        type: "error",
                        message: `${res.year}: ${i18n.t("fixed_expense_texts.no_linked_accounts")}`,
                        duration: 5,
                    });
                } else if (res.success) {
                    Notification({
                        type: "success",
                        message: `${res.year}: ` + i18n.t("fixed_expense_texts.accounts_updated_successfully"),
                        duration: 5,
                    });
                } else {
                    Notification({
                        type: "error",
                        message: `${res.year}: ${i18n.t("notifications.something_went_wrong")}`,
                        duration: 5,
                    });
                }
            });
        });
    }

    function handleChangeSelectPeriod(period: [moment.Moment, moment.Moment]) {
        setSelectedPeriod(period);
    }

    return (
        <Modal
            title={i18n.t("select_period_to_update_linked_accounts")}
            visible={isRecalculateModalOpen}
            onOk={onRefreshAccounts} 
            onCancel={closeRecalculateModal} 
            okText={i18n.t("refresh")}
            centered
        >
            <Filter
                id="period-filter-modal"
                value={selectedPeriod}
                setValue={handleChangeSelectPeriod}
                title={i18n.t("period")}
                type="datepicker"
                link="/"
                data={[]}
                datepicker={{
                    format: "MM/YYYY", 
                    picker: "month", 
                    period: openPeriods, 
                    rangePicker: true, 
                    allowClear: false, 
                }}
                buttonStyles={{ cursor: "default" }}
            />
        </Modal>
    )
}