import { Layout } from "antd";
import "./DistributionRule.sass";
import Title from "./components/Title";
import DistributionRuleGrid from "./components/DistributionRuleGrid";
import DistributionRuleModal from "./components/DistributionRuleModal";
import { DistributionRuleProvider } from "./context/DistributionRuleContext";

export default function DistributionRule() {
	return (
		<DistributionRuleProvider>
			<Layout className="light distribution-rule-page">
				<Layout.Header>
					<Title />
				</Layout.Header>
				<Layout.Content>
					<DistributionRuleGrid />
				</Layout.Content>
				<DistributionRuleModal />
			</Layout>
		</DistributionRuleProvider>
	)
}