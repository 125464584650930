import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { ICommomList, IOriginDataResponse, OriginData } from "../IProjectionModeling";
import i18n from "util/base/i18n";

const buildCommomList = (data: ICommomList[]): IModalityFilter[] => {
	const newList: IModalityFilter[] = data.map(({ externalCode, id, description, name }) => {
		const formattedDescription: string = description ? description : name;
		const label: string = `${externalCode} - ${formattedDescription}`;
		return {
			value: id,
			label
		};
	});
	return newList;
}

export const onLoadCommom = (data: ICommomList[], commomSet: React.Dispatch<React.SetStateAction<IModalityFilter[]>>) => {
	commomSet(buildCommomList(data));
}

export const buildOriginData = (data: IOriginDataResponse, commomSet: React.Dispatch<React.SetStateAction<OriginData[]>>) => {
	const origin = Object.keys(data).map((key) => {
		const label: string = i18n.t(`projection.${data[key]}`);
		const newOrigin: OriginData = {
			value: key,
			label
		}

		return newOrigin;
	})
	commomSet(origin);
}