import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Key } from "react";
import { PaginationResponse } from "util/types/types";

export interface IHeaderRightContentProps {
	startDate: moment.Moment;
	setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>
	endDate: moment.Moment;
	setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>>
	integrationSelected: IntegrationSelected;
}

export interface IPanelCommonProps {
	panelKey?: string | number;
	title: string;
}

export interface IModulePanelProps extends IPanelCommonProps {
}

export interface IPanelHeaderProps extends IPanelCommonProps {
	runningProcesses: number;
	isLoading: boolean;
}

export interface ICollapseContentProps {
	startDate: moment.Moment;
	endDate: moment.Moment;
	selectedRowKeys: string[];
	setSelectedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
	setIntegrationSelected: React.Dispatch<React.SetStateAction<IntegrationSelected>>;
}

export interface IPanelObject {
	title: string;
	key: string;
}

export interface ModuleDataResponse {
	module: ModuleTypes
	integrationId: number;
	origin: ModuleTypes;
	integration: IntegrationType;
	active: boolean;
	lastExecution: moment.Moment;
	firstErrorDate: moment.Moment;
	error: number;
	processing: number;
	integrated: number;
	discarted: number;
	moduleDescription: string;
	integrationDescription: string;
	integrationStatus: IntegrationStatus;
}

export enum IntegrationStatus {
	NULL,
	PROCESSING,
	INTEGRATED,
	ERROR,
	INCOMPLETE,
	DISCARTED
}

export interface DataResponse {
	EXPENSES: ModuleDataResponse[];
	REVENUE: ModuleDataResponse[];
	HUMAN_RESOURCES: ModuleDataResponse[];
	PLANNING: ModuleDataResponse[];
	GENERAL_REGISTRATIONS: ModuleDataResponse[];
	FLEX_FIELDS: ModuleDataResponse[];
}

export enum ModuleTypes {
	EXPENSES,
	REVENUE,
	HUMAN_RESOURCES,
	PLANNING,
	GENERAL_REGISTRATIONS,
	FLEX_FIELDS
}

export interface IModuleTableProps {
	onChangeSelectedTable: (key: string[]) => void;
	selectedRowKey: string[];
	tableData: ModuleDataResponse[];
	onSelectItemData: (module: ModuleTypes, integration: IntegrationType,
		status: IntegrationStatus) => void;
}

export enum IntegrationType {
	EXPENSES_REALIZED,
	EXPENSES_HISTORY,
	HUMAN_RESOURCES_PLANNED,
	COST_CENTER,
	REVENUE_ACOMPLISHMENT,
	PLANNING_OTHER_EVENTS
}

export interface IntegrationSelected {
	module: ModuleTypes;
	integration: IntegrationType;
	status: IntegrationStatus;
	startDate: moment.Moment;
	endDate: moment.Moment;
}

export interface IDataItemContentProps {
	selected: IntegrationSelected;
	selectedRowKeys: string[];
	setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
}

export interface IDataItemContent {
	id: number;
	creationDate: moment.Moment;
	module: ModuleTypes;
	moduleDescription: string;
	integration: IntegrationType;
	integrationDescription: string;
	status: IntegrationStatus;
	statusDescription: string;
	layout: ILayoutDataItem[];
	data: { flexFields: FlexFieldDataItem[] } & { [key: string]: any, errors: {}[] };
	errors: { [key: string]: string }
}

export interface FlexFieldDataItem {
	fieldExternalCode: string;
	flexField: string;
	valueDescription: string;
	valueExternalCode: string;
}

export interface IDataItemResponse extends PaginationResponse {
	content: IDataItemContent[];
}
export interface ILayoutDataItem {
	caption: string;
	code: string;
	description: string;
}

export interface IReplicatScenarioModalProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IIntegrationsContext {
	fixedExpenseTableData: ModuleDataResponse[]
	humanResourcesTableData: ModuleDataResponse[]
	revenueTableData: ModuleDataResponse[]
	planningTableData: ModuleDataResponse[]
	generalRegistrations: ModuleDataResponse[]
	flexFeldsTableData: ModuleDataResponse[]
	chooseWichTableData: {
		EXPENSES: ModuleDataResponse[]
		HUMAM_RESOURCES: ModuleDataResponse[]
		FLEX_FIELDS: ModuleDataResponse[]
		GENERAL_REGISTRATIONS: ModuleDataResponse[]
		REVENUE: ModuleDataResponse[]
		PLANNING: ModuleDataResponse[]
	}
	isLoading: boolean
	onLoadTableData: (data: DataResponse) => void
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	searchValue: string
	setSearchValue: React.Dispatch<React.SetStateAction<string>>
	tableData: IDataItemContent[]
	setTableData: React.Dispatch<React.SetStateAction<IDataItemContent[]>>
	columns: ColumnsType<any>
	setColumns: React.Dispatch<React.SetStateAction<ColumnsType<any>>>
	refresh: () => void
	startDate: moment.Moment
	setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>
	endDate: moment.Moment
	setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>>
}

export interface IOrganziationPeriod {

	integration: IntegrationType;

	start: moment.Moment;

	end: moment.Moment;

};
