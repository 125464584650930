import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { ICustomSelectOptions } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization"
import StringUtil from "util/StringUtil";

export const tableProps = {
	pagination: { hideOnSinglePage: true },
	className: "gs-table",
	bordered: true
}
export const tabProps = {
	className: "gs-tab",
	type: "card",
	moreIcon: null
} as { type: 'card' }

export const selectProps: SelectProps<any, DefaultOptionType | any> = {
	showSearch: true,
	optionFilterProp: "label",
	filterOption: (input: string, option: ICustomSelectOptions): boolean => {
		try {
			const stringLabel: string = option?.label ?? '';
			const labelWithOutSpecials: string = StringUtil.getWithoutSpecials(stringLabel);
			const inputWithOutSpecials: string = StringUtil.getWithoutSpecials(input);

			return labelWithOutSpecials.includes(inputWithOutSpecials);
		} catch (error: any) {
			console.error(error);
			return false;
		}
	},
	filterSort: (optionA: ICustomSelectOptions, optionB: ICustomSelectOptions): number => {
		try {
			const labelA: string = StringUtil.getWithoutSpecials(optionA?.label ?? '');
			const labelB: string = StringUtil.getWithoutSpecials(optionB?.label ?? '');
			return labelA.localeCompare(labelB);
		} catch (error) {
			console.error(error);
			return 0;
		}
	}
}