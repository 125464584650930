import { Key, useState, useEffect } from 'react';
import i18n from 'util/base/i18n';
import { Icon } from '@iconify/react';
import { Button, Input, Spin, Tooltip, Tree } from 'antd';
import { usePermissionContext } from 'context/PermissionContext';
import { AccountSearch, CostCenterSearch, DataTypeTable, filteredDataAllLinked, LevelItem } from '../IGroupings';
import MultipleSearchGroupings from './MultipleSearchGroupings';

interface Props {
    treeData: any[];
    checkedNodeKeys: number[];
    setCheckedNodeKeys: (key: number[]) => void;
    addNewSubLevel: (parentId: number) => void;
    tableData: DataTypeTable[];
    isTreeDisabled: boolean;
    expandedKeys: number[];
    setExpandedKeys(n: number[]): void;
    setIsSelectPackageLevel: React.Dispatch<React.SetStateAction<boolean>>;
    accountToSearch?: AccountSearch[];
    filteredDataAllLinked: filteredDataAllLinked[];
    currentTree: "account-hierarchy" | "cost-center-hierarchy";
    costCenterToSearch?: CostCenterSearch[];
}

export default function SiderContent(props: Props) {
    const { functionalityPermissions } = usePermissionContext();
    const [search, setSearch] = useState('');
    const [autoExpandedKeys, setAutoExpandedKeys] = useState<Key[]>([]);
    const [highlightedKeys, setHighlightedKeys] = useState<Key[]>([]);
    const [searchType, setSearchType] = useState<'tree' | 'account'>('tree');

    useEffect(() => {
        setSearch('');
        setSearchType('tree');
        setAutoExpandedKeys([]);
        props.setExpandedKeys([]);
        props.setCheckedNodeKeys([]);
    }, [props.currentTree]);

    useEffect(() => {
        if (search) {
            if (searchType === 'account') {
                const linkedAccounts = findLinkedAccounts(search);
                const linkedKeys = linkedAccounts.map(account => account.value);

                if (linkedKeys.length > 0) {
                    setHighlightedKeys(linkedKeys);
                } else {
                    setHighlightedKeys([]);
                }

                const keys = getExpandedKeys(props.treeData, search);
                setAutoExpandedKeys(keys);
            } else {
                const keys = getExpandedKeys(props.treeData, search);
                setAutoExpandedKeys(keys);
                setHighlightedKeys([]);
            }
        } else {
            setAutoExpandedKeys([]);
            setHighlightedKeys([]);
        }
    }, [search, searchType, props.treeData, props.filteredDataAllLinked]);

    const getExpandedKeys = (data: LevelItem[], search: string): Key[] => {
        let expandedKeys: Key[] = [];

        const traverse = (items: LevelItem[], parentKey?: Key): Key[] => {
            let keys: Key[] = [];

            items.forEach((item) => {
                if (item.title.toLowerCase().includes(search.toLowerCase())) {
                    if (parentKey) {
                        keys.push(parentKey);
                    }
                    keys.push(item.key);
                }
                if (item.children) {
                    keys = keys.concat(traverse(item.children, item.key));
                }
            });

            return keys;
        };

        expandedKeys = traverse(data);
        return expandedKeys;
    };

    const findLinkedAccounts = (search: string) => {
        return props.filteredDataAllLinked.filter(account =>
            account.label.toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleSearch = (value: string, type: 'tree' | 'account') => {
        props.setCheckedNodeKeys([]);
        setSearch(value);
        setSearchType(type);
        if (type === 'account') {
            const linkedAccounts = findLinkedAccounts(value);
            const linkedKeys = linkedAccounts.map(account => account.value);

            if (linkedKeys.length > 0) {
                setHighlightedKeys(linkedKeys);
            } else {
                setHighlightedKeys([]);
            }

            const keys = getExpandedKeys(props.treeData, value);
            setAutoExpandedKeys(keys);
        } else {
            const keys = getExpandedKeys(props.treeData, value);
            setAutoExpandedKeys(keys);
            setHighlightedKeys([]);
        }
    };

    const matchedAccounts = searchType === 'account'
        ? findLinkedAccounts(search).map(linkedAccount => {
            if (props.currentTree === "account-hierarchy") {
                return props.accountToSearch?.find(account =>
                    account.accountId === linkedAccount.value
                );
            } else {
                return props.costCenterToSearch?.find(account =>
                    account.costCenterId === linkedAccount.value
                );
            }
        }).filter(account => account !== undefined)
        : [];

    const hierarchyAccountIds = matchedAccounts
        .filter(account => 'hierarchyAccountId' in account)
        .map(account => (account as AccountSearch)?.hierarchyAccountId);

    const hierarchyCostCenterIds = matchedAccounts
        .filter(account => 'hierarchyCostCenterId' in account)
        .map(account => (account as CostCenterSearch)?.hierarchyCostCenterId);

    const findIdsByHierarchy = (treeData: any[], hierarchyIds: (number | undefined)[]): number[] => {
        let ids: number[] = [];

        const traverse = (nodes: any[]) => {
            nodes.forEach(node => {
                if (hierarchyIds.includes(node.id)) {
                    ids.push(node.id);
                }
                if (node.children && node.children.length > 0) {
                    traverse(node.children);
                }
            });
        };

        traverse(treeData);
        return ids;
    };

    const matchingIds = [
        ...findIdsByHierarchy(props.treeData, hierarchyAccountIds),
        ...findIdsByHierarchy(props.treeData, hierarchyCostCenterIds)
    ];

    const buildTreeData = (data: LevelItem[], search: string, ids: number[]): LevelItem[] => {
        const filterData = (items: LevelItem[]): LevelItem[] => {
            return items
                .map((item) => {
                    if (item.children) {
                        const children = filterData(item.children);
                        if (children.length > 0 || item.title.toLowerCase().includes(search.toLowerCase()) || ids.includes(item.key)) {
                            return { ...item, children };
                        }
                    } else if (item.title.toLowerCase().includes(search.toLowerCase()) || ids.includes(item.key)) {
                        return item;
                    }
                    return undefined;
                })
                .filter((item): item is LevelItem => item !== undefined);
        };

        return filterData(data);
    };

    const onCheck = (checked: { checked: Key[]; halfChecked: Key[] }, { node }: any) => {
        const selectedKey = checked.checked.length > 0 ? [checked.checked[checked.checked.length - 1]] : [];
        props.setIsSelectPackageLevel(node.isSpendingPackageLevel);
        props.setCheckedNodeKeys(selectedKey.map(Number) as number[]);
    };

    const chooseIconExpanded = (props: { expanded: boolean }) => {
        return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
    };

    const onClickAddLevel = (id: number) => {
        props.addNewSubLevel(id);
    };

    const addButtonLines = (line) => {
        return (
            <span className='tree-title-content'>
                <p className={line.isSpendingPackageLevel ? 'package-level-title' : ''}>
                    {line.title}
                </p>
                <div>
                    {functionalityPermissions.new && (
                        <Button
                            onClick={() => onClickAddLevel(line.key)}
                            icon={<Icon id={line.key} icon="akar-icons:plus" />}
                        />
                    )}
                    {line.showIcon && (
                        <Tooltip title={i18n.t('already_linked')}>
                            <Icon icon="akar-icons:link-chain" />
                        </Tooltip>
                    )}
                </div>
            </span>
        );
    };

    const onExpand = (expandedKeys: number[], obj: { expanded: boolean }) => {
        props.setExpandedKeys(expandedKeys);
    };

    return (
        <div className='sider-content'>
            <MultipleSearchGroupings
                onSearch={handleSearch}
                currentTree={props.currentTree}
                searchValue={search}
                searchType={searchType}
            />
            <Spin spinning={props.isTreeDisabled}>
                {props.treeData.length > 0 ? (
                    <Tree
                        checkStrictly
                        checkedKeys={props.checkedNodeKeys}
                        switcherIcon={chooseIconExpanded}
                        treeData={buildTreeData(
                            JSON.parse(JSON.stringify(props.treeData)),
                            search.toLowerCase(),
                            searchType === 'account' ? matchingIds : []
                        )}
                        showLine={{ showLeafIcon: false }}
                        checkable
                        onCheck={onCheck}
                        titleRender={addButtonLines}
                        disabled={props.isTreeDisabled}
                        expandedKeys={props.expandedKeys.length > 0 ? props.expandedKeys : autoExpandedKeys.map(Number)}
                        onExpand={onExpand}
                        selectable={false}
                    />
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            margin: '40px 0',
                        }}
                    >
                        <Icon icon='clarity:tree-view-line' />
                        {i18n.t('groupings_texts.no_registered_level')}
                    </div>
                )}
            </Spin>
        </div>
    );
}
