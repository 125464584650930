import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { ROOT_PATH } from "util/rootPath";
import moment from "moment";
import { TopButtons } from "components/topButtons/TopButtons";
import { ColumnsType } from "antd/lib/table";
import { Icon } from "@iconify/react";
import { Button, Form, Tooltip } from "antd";
import InvestmentList from "./components/InvestmentList";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import './style.sass';
import InvestmentModal from "./components/InvestmentModal";
import { ErrorRequest, FlexFieldFilterResponse, FlexFieldValueListBean, FlexFilter, Options, ResponseStatus } from "util/types/types";
import { useErrorBoundary } from "react-error-boundary";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Filters } from "./components/Filters";
import { useUserContext } from "context/UserContext";
import { Notification } from "components/notification/Notification";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import Detail from "./Detail/Detail";
import { DetailData, DetailTableData, ViewModeSelected } from "./Detail/IDetail";
import { SummaryCards } from "./components/SummaryCards";
import '../../../budget/pages/fixedExpense/components/summaryCards/styles.sass';
import { ICurrency } from "module/dashboard/analytics/card/currency/ICurrency";
import { formatNumber } from "util/formatNumber";
import { cloneDeep, set } from "lodash";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { ControlPanelPeriods, GracePeriodConfiguration, InvestmentConfiguration, InvestmentSummaryCards, InvestmentTableData, ModuleConfiguration, ValueForm } from "./IInvestment";

export default function Investment() {
    const [toggle, setToggle] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingCards, setIsFetchingCards] = useState(true);
    const [tableData, setTableData] = useState<InvestmentTableData[]>([]);
    const [filteredTableData, setFilteredTableData] = useState<InvestmentTableData[]>([]);
    const [investmentSelected, setInvestmentSelected] = useState<ValueForm>({} as ValueForm);
    const [detailSelectedId, setDetailSelectedId] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalList, setModalList] = useState([]);
    const [configurations, setConfigurations] = useState<InvestmentConfiguration>({} as InvestmentConfiguration);
    const [isFetchingConfigurations, setFetchingConfigurations] = useState(true);
    const [accountingList, setAccountingList] = useState([]);
    const [isFetchingAccounting, setIsFetchingAccounting] = useState(true);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [isFetchingBusinessUnit, setIsFetchingBusinessUnit] = useState(true);
    const [costCenterList, setCostCenterList] = useState<Options[]>([]);
    const [isFetchingCostCenter, setIsFetchingCostCenter] = useState(true);
    const [templateList, setTemplateList] = useState([]);
    const [isFetchingTemplate, setIsFetchingTemplate] = useState(true);
    const [currenciesList, setCurrenciesList] = useState<Options[]>([]);
    const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
    const { showBoundary } = useErrorBoundary();
    const [showJustification, setShowJustification] = useState(false);
    const [accountingDisabled, setAccountingDisabled] = useState(false);
    const [flexFieldsOptions, setFlexFieldsOptions] = useState<FlexFilter[]>([]);
    const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilter[]>([]);
    const [isEditingDetail, setIsEditingDetail] = useState(false);
    const [periodFilter, setPeriodFilter] = useState([moment().startOf('year'), moment().endOf('year')]);
    const [businessUnitFilter, setBusinessUnitFilter] = useState<Options[]>([]);
    const [costCenterIdFilter, setCostCenterIdFilter] = useState<Options[]>([]);
    const [accountingIdFilter, setAccountingIdFilter] = useState<Options[]>([]);
    const [flexFieldStringFilter, setFlexFieldStringFilter] = useState(Array(30).join(':'));
    const [detailTableData, setDetailTableData] = useState<DetailTableData[]>([]);
    const [detailData, setDetailData] = useState<DetailData>({} as DetailData);
    const [isFetchingDetailData, setIsFetchingDetailData] = useState(true);
    const [currencyImg, setCurrencyImg] = useState<ICurrency>();
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();
    const [viewModeSelected, setViewModeSelected] = useState<ViewModeSelected>(ViewModeSelected.TEMPLATE);
    const [monthsToBlock, setMonthsToBlock] = useState([]);
    const [linkCardTotal, setLinkCardTotal] = useState("");
    const [controlPanelPeriods, setControlPanelPeriods] = useState<ControlPanelPeriods>({} as ControlPanelPeriods);
    const [gracePeriodValue, setGracePeriodValue] = useState<number>(0);
    const [savedValue, setSavedValue] = useState<boolean>(false);
    const [cards, setCards] = useState<InvestmentSummaryCards>({} as InvestmentSummaryCards)
    const [hasFlexOrganization, setHasFlexOrganization] = useState<boolean>(null);

    const currentBusinessUnit = userInfo.selection.businessUnitId;

    const accountingColumn = {
        title: "Contabilização",
        dataIndex: "accountingDescription",
        key: "accountingDescription",
        className: 'cost-category-name-column',
    }

    const tableColumns: ColumnsType = [
        {
            title: "Unidade de Negócio",
            dataIndex: "businessUnitDescription",
            key: "businessUnitDescription",
            align: "left",
        },
        {
            title: "Centro de Custo",
            dataIndex: "costCenterDescription",
            key: "costCenterDescription",
            align: "left",
            className: 'cost-category-name-column'
        },
        {
            ...accountingColumn,
            align: "left",
            render: (value, record: ValueForm) => {
                return (
                    <div>
                        {value}
                        <Tooltip title={record?.currencyDescription}><span className="gs-tag currency" style={{ margin: '0 0 0 5px', fontSize: 10 }}>{record.currencyDescription?.split('-')?.shift()}</span></Tooltip>
                    </div>
                );
            }
        },
        {
            title: "Valor Orçado",
            dataIndex: "plannedValue",
            key: "plannedValue",
            align: "left",
            className: 'cost-category-name-column',
            render: (value) => formattedValue(value)
        },
        {
            title: "Valor Realizado",
            dataIndex: "accomplishedValue",
            key: "accomplishedValue",
            align: "left",
            className: 'cost-category-name-column',
            render: (value) => formattedValue(value)
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            render: () => <Icon style={{ fontSize: 24, marginBottom: -2 }} icon="bxs:chevron-right-circle" />
        },
    ];

    const columnsToExport = cloneDeep(tableColumns).map(column =>
        column.key === "accountingDescription" ? accountingColumn : column
    ).slice(0, -2);

    const onUpdateValues = () => {
        setSavedValue(true);
        Notification({
            type: 'info',
            message: "Atualizando valores...",
        })
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/update-values-and-exchanges?client={client}&user={user}&scenario={scenario}&organization={organization}&businessUnit={businessUnit}`,
        }, (response: ResponseStatus) => {
            Notification({
                type: response.success ? 'success' : 'error',
                message: i18n.t(response.message),
            })
            if (response.success) {
                loadCards();
            }
        }, handleErrorRequest)
        setSavedValue(false);
    };

    const importProps: IImportModal[] = [
        {
            importUrl: "/investment/import/planned",
            templateUrl: "/investment/import/planned/template?locale={locale}&user={user}&client={client}",
            type: 'excel',
            title: i18n.t("import_budget"),
            hasImportOption: true,
        },
        {
            importUrl: "/investment/import/accomplished",
            templateUrl: "/investment/import/accomplished/template?locale={locale}&user={user}&client={client}",
            type: 'excel',
            title: i18n.t("import_accomplished"),
        },
    ];

    const menuItem: ItemType[] = [
        {
            key: 'updateValues',
            label: (
                <Button
                    className="grid-operation-importExport-btn"
                    onClick={() => {
                        onUpdateValues()
                    }}
                    type="text"
                    icon={<Icon icon="mdi:file-document-refresh-outline" width="19" />}
                >
                    Atualizar Valores
                </Button>
            ),
        }];

    useEffect(() => {

        loadFlexField();

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/flex-field-value/find-all-organization-flex-fields",
        }, (data) => {
            setHasFlexOrganization(data?.length > 0 ? true : false);
        });

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/monolith/business-unit/findAllByOrganizationIdWithSecurity?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}",
        }, onLoadBusinessUnit.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/cost-center?client={client}&locale={locale}&user={user}&organization={organization}&bu={businessUnit}",
        }, onLoadCostCenter.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/investment/template?organization={organization}",
        }, onLoadTemplate.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/investment/accounting?organization={organization}",
        }, onLoadAccounting.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/currency?locale={locale}&client={client}&user={user}`,
        }, (data) => {
            setCurrenciesList(data);
            setIsFetchingCurrency(false);
        }, err => showBoundary(err));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/module-configuration/find-all-by-module?module=INVESTMENT",
        }, onLoadConfiguration.bind(this));
    }, []);

    useEffect(() => {
        if (hasFlexOrganization === null || !currentBusinessUnit) return;
        fetchInvestments(periodFilter);
    }, [hasFlexOrganization, currentBusinessUnit]);

    useEffect(() => {
        if (!userInfo.currencyId) return
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/monolith/currency/findById?id=${userInfo.currencyId}&clientId={client}&locale={locale}`
        }, (currency: ICurrency) => {
            setCurrencyImg(currency);
        });
    }, [userInfo]);

    useEffect(() => {
        if (userInfo?.selection?.scenarioId) {
            ServiceCaller.doRequest({
                type: RequestType.GET,
                url: `/budget-base/control-panel/find-periods-by-organizations?scenario=${userInfo.selection.scenarioId}&organization={organization}`
            }, (data) => {
                const newObj = {};
                Object.keys(data).map(key => {

                    const yearObject = data[key];

                    const startDate = yearObject?.expensesAndResourcesOpeningStart ? moment(new Date(yearObject.expensesAndResourcesOpeningStart)).format('YYYY-MM-DD') : null;
                    const endDate = yearObject?.expensesAndResourcesOpeningEnd ? moment(new Date(yearObject.expensesAndResourcesOpeningEnd)).format('YYYY-MM-DD') : null;

                    return (
                        newObj[key] = {
                            entryPeriod: [startDate, endDate],
                            accomplished: yearObject?.expensesAndResourcesRealizedPeriod ? moment(new Date(yearObject.expensesAndResourcesRealizedPeriod)).format('YYYY-MM') : null
                        }
                    )
                });

                setControlPanelPeriods(newObj);
                loadCards();
            });

        }
    }, [userInfo, periodFilter]);

    useEffect(() => {
        if (tableData.length > 0 && !isFetchingBusinessUnit && !isFetchingCostCenter && !isFetchingAccounting && !isFetchingCurrency && !isFetchingConfigurations) {
            setTableData(tableData.map(item => {
                const businessUnitDescription = businessUnitList.find(bu => bu.value === item.businessUnitId);
                const costCenterDescription = costCenterList.find(cc => cc.value === item.costCenterId);
                const accountingDescription = accountingList.find(acc => acc.value === item.accountingId);
                const currencyDescription = currenciesList.find(acc => acc.value === item.currencyId);

                return {
                    ...item,
                    businessUnitDescription: businessUnitDescription?.label,
                    costCenterDescription: costCenterDescription?.label,
                    accountingDescription: accountingDescription?.label,
                    currencyDescription: currencyDescription?.label,
                };
            }));
        }
    }, [isFetchingBusinessUnit, isFetchingCostCenter, isFetchingAccounting, isFetching, isFetchingCurrency, isFetchingConfigurations]);

    useEffect(() => {
        setFilteredTableData(tableData);
    }, [tableData]);

    function loadCards() {
        setIsFetchingCards(true);

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/card-total?scenario={scenario}&organization={organization}&startPeriod=${periodFilter[0].date(1).format("YYYY-MM-DD")}&endPeriod=${periodFilter[1].endOf('month').format("YYYY-MM-DD")}&user={user}`,
        }, onLoadCards, (err) => {
            handleErrorRequest(err)
            setIsFetchingCards(false)
        })
    }

    function onLoadCards(data: InvestmentSummaryCards) {
        setCards(data)
        setIsFetchingCards(false)
        setToggle(false)
    }

    function onLoadBusinessUnit(data: any[]) {
        setBusinessUnitList(data.map(item => {
            return {
                label: item.name,
                value: item.id
            }

        }));
        setIsFetchingBusinessUnit(false)
    }

    function onLoadCostCenter(data: Options[]) {
        setCostCenterList(data);
        setIsFetchingCostCenter(false)
    }

    function onLoadTemplate(data) {

        const templateOption = [
            { value: 0, label: "Nenhum Template", accountingId: 0 },
            ...data.map(item => ({ label: item.description, value: item.id, accountingId: item.investmentAccountId }))
        ];

        setTemplateList(templateOption);
        setIsFetchingTemplate(false);
    }

    function onLoadAccounting(data) {
        setAccountingList(data.map(item => {
            return {
                label: item.description,
                value: item.id,
                costCenterId: item.costCenterId
            }
        }));
        setIsFetchingAccounting(false)
    }

    function onLoadConfiguration(data: ModuleConfiguration[]) {
        setConfigurations(data.reduce((map, object) => {
            map[object.property] = object;
            return map;
        }, {} as InvestmentConfiguration));

        const gracePeriodValue = data.find(
            (item): item is GracePeriodConfiguration => item.property === "GRACE_PERIOD"
        );

        if (gracePeriodValue && gracePeriodValue.value && !isNaN(Number(gracePeriodValue.value))) {
            setGracePeriodValue(Number(gracePeriodValue.value));
        }

        setFetchingConfigurations(false);
    }

    function onLoadInvestment(data) {

        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };
        const updateTableData = data.map(res => {
            return {
                id: res.id,
                key: res.id,
                businessUnitId: res.businessUnitId,
                costCenterId: res.costCenterId,
                accountingId: res.accountingId,
                plannedValue: res.plannedValue,
                currencyId: res.currencyId,
                accomplishedValue: res.accomplishedValue,
                acquisitionDate: res.acquisitionDate,
                totalOpenings: res.totalOpenings,
                businessUnitDescription: "",
                costCenterDescription: "",
                accountingDescription: "",
                ...res.flexFields
            };
        });
        setTableData(updateTableData);
        setIsFetching(false);

        const headersIds = updateTableData.map(item => item.id);

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/get-dates-for-headers?headers=${headersIds.join(',')}` +
                `&startPeriod=${periodFilter[0].date(1).format("YYYY-MM-DD")}&endPeriod=${periodFilter[1].endOf('month').format("YYYY-MM-DD")}`,
        }, (data) => {
            setMonthsToBlock(data);
        }, handleErrorRequest)
    };

    function handleOpenModal(isFromDetail: boolean) {
        const { currentDate, initialPeriod, finalPeriod } = controlPanelValues();
        if ((currentDate < initialPeriod || currentDate > finalPeriod) || !(initialPeriod && finalPeriod)) {
            Notification({
                type: "info",
                message: i18n.t("fixed_expense_texts.outside_typing_period"),
            });
            return;
        }
        setModalList([]);
        form.resetFields();
        if (isFromDetail) {
            setIsEditingDetail(true)

            let flexFields = {};
            Object.keys(investmentSelected).forEach(currentKey => {
                if (!isNaN(Number(currentKey))) {
                    flexFields[`ff-${currentKey}`] = investmentSelected[currentKey] ? investmentSelected[currentKey] : null;
                }
            });
            form.setFieldsValue({
                businessUnitId: investmentSelected.businessUnitId,
                costCenterId: investmentSelected.costCenterId,
                accountingId: investmentSelected.accountingId,
                currencyId: investmentSelected.currencyId,
                templateId: 0,
                quantity: 1,
                unityValue: 0,
                ...flexFields
            });
        } else {

            form.setFieldsValue({
                quantity: 1,
                unityValue: 0,
                currencyId: currencyImg?.id,
                businessUnitId: hasFlexOrganization ? currentBusinessUnit : undefined
            });

        }
        setIsModalVisible(true);
    };

    function handleCloseModal() {
        form.resetFields();
        setIsEditingDetail(false)
        setAccountingDisabled(false);
        setShowJustification(false);
        setIsModalVisible(false);
    };


    function handleIncludeInvestmentModalList(data: ValueForm) {
        let activationDate = data.activationDate;
        if (!activationDate) {
            let date = data.acquisitionDate.clone();
            date.add(Number(configurations["GRACE_PERIOD"].value), "months");
            activationDate = date;
        }

        const modalListItems = [...modalList, {
            ...data,
            justification: data.justification ? data.justification : "",
            groupingKey: generateGroupingKey(data),
            itemKey: generateItemKey(data),
            activationDate: activationDate
        }];

        setModalList(modalListItems);
        form.resetFields(["acquisitionDate", "activationDate", "justification"]);
        form.setFieldsValue({
            quantity: 1,
            unityValue: 0,
        });
    }

    function generateGroupingKey(item: ValueForm) {
        let key = `${item.businessUnitId}-${item.costCenterId}-${item.accountingId}-${item.currencyId}`
        Object.keys(item).forEach(currentKey => {
            if (currentKey.includes('ff-') && item[currentKey]) {
                key += `-${currentKey}:${item[currentKey]}`
            }
        })

        return key;
    }

    function generateItemKey(item) {
        return Object.values(item).join('') + moment(item.acquisitionDate).format('MM/YYYY') + moment(item.activationDate).format('MM/YYYY');
    }

    function handleSaveInvestment(data) {
        setSavedValue(true);

        const headersToSave = data.map(item => {
            const flexFields = {}
            Object.keys(item[0]).forEach(key => {
                if (key.includes('ff-') && item[0][key]) {
                    Object.assign(flexFields, { [key.replace('ff-', '')]: item[0][key] })
                }
            });

            const flexFieldsList = flexFieldsOptions
                .filter(objeto => flexFields[objeto.value] !== undefined)
                .map(objeto => ({ fieldCode: objeto.fieldCode, valueOption: flexFields[objeto.value] }));

            return {
                id: null,
                organizationId: userInfo.selection.organizationId,
                businessUnitId: item[0].businessUnitId,
                costCenterId: item[0].costCenterId,
                currencyId: item[0].currencyId,
                accountingId: item[0].accountingId,
                accomplishedValue: null,
                flexKey: generateFlexFieldSelectedString(flexFieldsList),
                flexFields,
                openings: item.map(sub => {
                    const acquisitionDateFormat = moment(sub.acquisitionDate).date(1).format("YYYY-MM-DD");
                    return ({
                        id: null,
                        headerId: null,
                        templateId: sub.templateId,
                        quantity: sub.quantity,
                        unityValue: sub.unityValue,
                        totalValue: sub.totalValue,
                        accomplishedValue: 0,
                        accomplished: false,
                        acquisitionDate: acquisitionDateFormat,
                        activationDate: sub.activationDate ? moment(sub.activationDate).date(1).format("YYYY-MM-DD") : acquisitionDateFormat,
                        justification: sub?.justification ? sub?.justification : ""
                    })
                })
            };
        });

        const investment = {
            clientId: userInfo.clientId,
            scenarioId: userInfo.selection.scenarioId,
            organizationId: userInfo.selection.organizationId,
            headers: headersToSave,
        }

        handleCloseModal();
        setIsFetching(true);

        detailSelectedId && setIsFetchingDetailData(true);

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: 'investment/',
            params: investment,
        }, onSaveInvestment.bind(this));
    }

    function onSaveInvestment(data) {
        if (data) {
            Notification({
                type: "success",
                message: i18n.t("successfully_saved"),
            });
        }
        form.resetFields();

        fetchInvestments(periodFilter,
            businessUnitFilter[0]?.value ?? 0,
            costCenterIdFilter[0]?.value ?? 0,
            accountingIdFilter[0]?.value ?? 0,
            flexFieldStringFilter);

        detailSelectedId && loadInvestmentDetail();
        loadCards();
        setSavedValue(false);
    }

    function loadInvestmentDetail() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/get-detail?header=${detailSelectedId}&startPeriod=${periodFilter[0].date(1).format("YYYY-MM-DD")}&endPeriod=${periodFilter[1].endOf('month').format("YYYY-MM-DD")}&scenario=${userInfo.selection.scenarioId}`,
        }, (data) => {
            (data?.accounting !== null || data?.templates !== null) ? setDetailData(data) : setDetailData({} as DetailData);
            data?.templates.length === 0 ? setViewModeSelected(ViewModeSelected.ACCOUNTING) : setViewModeSelected(ViewModeSelected.TEMPLATE);
            setIsFetchingDetailData(false);
        }, (err) => {
            handleErrorRequest(err);
        });
    }

    function loadFlexField(fromDetailPage = false) {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/flex-field/find-all-by-module?module=EXPENSES`
        }, onLoadFlexField.bind(this, fromDetailPage), (err: ErrorRequest) => {
            handleErrorRequest(err);
        });
    }

    async function onLoadFlexField(fromDetailPage, data: FlexFieldFilterResponse[]) {
        const flexField: FlexFilter[] = data
            .sort((a, b) => a.ordenation - b.ordenation)
            .map(item => {
                return {
                    label: item.description,
                    value: item.id,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.ordenation,
                    fieldCode: Number(item.fieldCode.split('_').pop()),
                    selectedOption: [],
                    children: []
                }
            });
        try {
            const promises = flexField.map(ff => {
                if (!ff.linkedFilters) {
                    return new Promise<void>((resolve, reject) => {
                        ServiceCaller.doRequest({
                            type: RequestType.GET,
                            url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`
                        }, (data: FlexFieldValueListBean[]) => {
                            ff.children = data.map(item => ({
                                value: item.id,
                                label: item.externalCode + ' - ' + item.description,
                                flexFieldId: item.flexFieldId
                            }))
                            return resolve();
                        }, (err) => {
                            handleErrorRequest(err)
                            return reject()
                        })
                    })
                }
                return Promise.resolve()
            })
            await Promise.all(promises)
        } catch (error) {
            handleErrorRequest(error)
        }
        setFlexFieldsOptions(flexField);
        !fromDetailPage && setFlexFieldsFilters(flexField);
    }

    async function fetchInvestments(
        period = [moment(), moment()],
        buId = 0,
        costCenterId = 0,
        accountingId = 0,
        flexFieldStringKey = Array(30).join('%3A'),
    ) {
        setPeriodFilter(period);
        setIsFetching(true);

        const flexFieldKey = flexFieldStringKey !== ":::::::::::::::::::::::::::::" && flexFieldStringKey.replaceAll(':', '%3A');

        const businessUnitParam = hasFlexOrganization ? `&businessUnitId=${currentBusinessUnit}` : (buId > 0 ? `&businessUnitId=${buId}` : '');

        const response: any = await ServiceCaller.doAsyncRequest({
            type: RequestType.GET,
            url: `/investment/?organization={organization}&` +
                `startPeriod=${period[0].date(1).format("YYYY-MM-DD")}&` +
                `endPeriod=${period[1].endOf('month').format("YYYY-MM-DD")}` +
                businessUnitParam +
                (costCenterId > 0 ? `&costCenterId=${costCenterId}` : '') +
                (accountingId > 0 ? `&accountingId=${accountingId}` : '') +
                (flexFieldKey ? `&flexFields=${flexFieldKey}` : '') +
                `&scenario={scenario}` +
                `&client={client}` +
                `&user={user}`
        })

        if (response.success) {
            onLoadInvestment(response.data);
        } else {
            handleErrorRequest(response.data as any)
        }
    }

    function formattedValue(value) {
        return formatNumber(value, {
            style: "currency",
            currency: "BRL",
        })
    }

    function generateFlexFieldSelectedString(flexField) {
        let flexFieldSelectedString = ':::::::::::::::::::::::::::::';
        flexField?.forEach(ff => {
            const updatedData = flexFieldSelectedString.split(':');
            const position = ff.fieldCode - 1;
            const flexFilterValue = ff.valueOption === 0 ? '' : ff.valueOption;
            updatedData[position] = flexFilterValue.toString();
            const flexFieldString = updatedData.join(':');
            flexFieldSelectedString = flexFieldString;
        });
        return flexFieldSelectedString;
    };

    function handleClickBackButton() {
        setDetailSelectedId(0);
        setDetailData({} as DetailData);
        setDetailTableData([]);
        loadFlexField(true);
        setViewModeSelected(ViewModeSelected.TEMPLATE);
        loadCards();
        fetchInvestments(periodFilter,
            businessUnitFilter[0]?.value ?? 0,
            costCenterIdFilter[0]?.value ?? 0,
            accountingIdFilter[0]?.value ?? 0,
            flexFieldStringFilter);
    }

    function controlPanelValues(accomplishedYear = null) {
        const currentDate = moment().format('YYYY-MM-DD');
        const initialPeriod = controlPanelPeriods[moment(currentDate).format('YYYY')]?.entryPeriod[0] || null;
        const finalPeriod = controlPanelPeriods[moment(currentDate).format('YYYY')]?.entryPeriod[1] || null;

        const accomplished = controlPanelPeriods[accomplishedYear]?.accomplished;

        return {
            currentDate,
            initialPeriod,
            finalPeriod,
            accomplished,
        }
    }

    function onLoadImportSuccess() {
        loadCards();
        fetchInvestments(periodFilter,
            businessUnitFilter[0]?.value ?? 0,
            costCenterIdFilter[0]?.value ?? 0,
            accountingIdFilter[0]?.value ?? 0,
            flexFieldStringFilter);
    }

    return (
        <>
            <div style={{ justifyContent: 'space-between', height: 80, margin: "0px auto 16px auto", padding: "0 16px" }} className="page-title-content">
                <h1>Controle de Investimentos</h1>
                <SummaryCards currencyImg={currencyImg} refresh={toggle} period={periodFilter} savedValue={savedValue} isFetching={isFetchingCards} cards={cards} />
                <div style={{ marginRight: "30px" }}>
                    <img src={`${ROOT_PATH}/renderer/image/${currencyImg?.image}`} alt={currencyImg?.isoCode} style={{ marginRight: "5px", borderRadius: "50%", objectFit: "cover", width: 15, height: 15 }} />
                    <label style={{ fontSize: "13px" }} >{currencyImg?.isoCode}</label>
                </div>
            </div>
            {!detailSelectedId ?
                <>
                    <Filters
                        businessUnitOptions={businessUnitList}
                        costCenterOptions={costCenterList}
                        accountingOptions={accountingList}
                        flexFieldsOptions={flexFieldsFilters}
                        setFlexFieldsOptions={setFlexFieldsFilters}
                        fetchApportionments={fetchInvestments}
                        periodFilter={periodFilter}
                        setPeriodFilter={setPeriodFilter}
                        costCenter={costCenterIdFilter}
                        setCostCenter={setCostCenterIdFilter}
                        accounting={accountingIdFilter}
                        setAccounting={setAccountingIdFilter}
                        flexFieldStringKey={flexFieldStringFilter}
                        setFlexFieldStringKey={setFlexFieldStringFilter}
                        businessUnit={businessUnitFilter}
                        setBusinessUnit={setBusinessUnitFilter}
                        hasFlexOrganization={hasFlexOrganization}
                    />
                    <section className="investment-section" style={{ paddingTop: 10 }}>
                        <div className="hired-table-container">
                            <div id="top-buttons-cost-category">
                                <div>
                                    <TopButtons
                                        mainButtonTitle={"Novo Investimento"}
                                        handleNew={() => handleOpenModal(false)}
                                        searchPlaceholder={i18n.t("costCategory.search_cost_category")}
                                        handleSuccessImport={onLoadImportSuccess}
                                        multipleSearch={{
                                            tableData: tableData,
                                            setTableData: setFilteredTableData,
                                            options: [
                                                { i18nString: 'business_unit', description: 'businessUnitDescription' },
                                                { i18nString: 'cost_center', description: 'costCenterDescription' },
                                                { i18nString: 'investment.accounting', description: 'accountingDescription' }
                                            ]
                                        }}
                                        importExportOptions={{
                                            exportGridData: () => handleExportGridData(tableData, columnsToExport, 'Investimentos'),
                                            importProps: !(moment(new Date()).format('YYYY-MM-DD') < (controlPanelPeriods[moment().format('YYYY')]?.entryPeriod?.[0] || '') || moment(new Date()).format('YYYY-MM-DD') > (controlPanelPeriods[moment().format('YYYY')]?.entryPeriod?.[1] || '')) ? importProps : undefined,
                                            otherFeatures: menuItem
                                        }}
                                    />
                                </div>
                            </div>
                            <InvestmentList
                                isFetching={isFetching}
                                tableData={filteredTableData}
                                tableColumns={tableColumns}
                                setDetailId={setDetailSelectedId}
                                setInvestmentSelected={setInvestmentSelected}
                            />
                        </div>
                    </section>
                </>
                :
                <Detail
                    detailId={detailSelectedId}
                    investmentSelected={investmentSelected}
                    handleOpenModal={handleOpenModal}
                    detailTableData={detailTableData}
                    setDetailTableData={setDetailTableData}
                    isModalVisible={isModalVisible}
                    form={form}
                    flexFieldsOptions={flexFieldsOptions}
                    setFlexFieldsOptions={setFlexFieldsOptions}
                    currenciesList={currenciesList}
                    loadInvestmentDetail={loadInvestmentDetail}
                    detailData={detailData}
                    isFetching={isFetchingDetailData}
                    setIsFetching={setIsFetchingDetailData}
                    handleClickBackButton={handleClickBackButton}
                    viewModeSelected={viewModeSelected}
                    setViewModeSelected={setViewModeSelected}
                    periodFilter={periodFilter}
                    configurations={configurations}
                    controlPanelPeriods={controlPanelPeriods}
                    controlPanelValues={controlPanelValues}
                    setToggle={setToggle}
                />
            }
            <InvestmentModal
                isEditingDetail={isEditingDetail}
                handleSave={handleSaveInvestment}
                handleSubmit={handleIncludeInvestmentModalList}
                handleCancel={handleCloseModal}
                modalList={modalList}
                setModalList={setModalList}
                isModalVisible={isModalVisible}
                form={form}
                templateList={isEditingDetail ? [{ value: 0, label: "Nenhum template" }, ...templateList.filter(item => item.accountingId === investmentSelected.accountingId)] : templateList}
                isFetchingTemplate={isFetchingTemplate}
                accountingList={accountingList}
                isFetchingAccounting={isFetchingAccounting}
                accountingDisabled={accountingDisabled}
                setAccountingDisabled={setAccountingDisabled}
                businessUnitList={businessUnitList}
                isFetchingBusinessUnit={isFetchingBusinessUnit}
                costCenterList={costCenterList}
                isFetchingCostCenter={isFetchingCostCenter}
                showJustification={showJustification}
                setShowJustification={setShowJustification}
                currenciesList={currenciesList}
                isFetchingCurrency={isFetchingCurrency}
                flexFieldsOptions={flexFieldsOptions}
                setFlexFieldsOptions={setFlexFieldsOptions}
                formattedValue={formattedValue}
                detailSelectedId={detailSelectedId}
                detailData={detailData}
                generateFlexFieldSelectedString={generateFlexFieldSelectedString}
                monthsToBlock={monthsToBlock}
                controlPanelPeriods={controlPanelPeriods}
                gracePeriod={gracePeriodValue}
                currentBusinessUnit={currentBusinessUnit}
                hasFlexOrganization={hasFlexOrganization}
            />
        </>
    )
}