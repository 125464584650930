import { Col, Form, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Hired } from "../IHumanResoucers";
import TableCollaborators from "./TableCollaborators";

interface Props {
	hiredList: Hired[];
}
export default function FinalStepModal({ hiredList }: Props) {
	const [valueOfContracts, setValueOfContracts] = useState(0);

	useEffect(() => {
		let newValueOfContracts = 0;
		const december = 11;
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth();
		const monthsUntilDecember = Math.abs(currentMonth - december);
		hiredList.map(hired => {
			if (hired.variableSalary) {
				newValueOfContracts += hired.hourPrice * hired.hourAmount * hired.collaboratorAmount * monthsUntilDecember;
			} else {
				newValueOfContracts += hired.collaboratorAmount * hired.fixedSalary * monthsUntilDecember;
			}
		})
		setValueOfContracts(newValueOfContracts);
	}, [hiredList])

	return (
		<>
			<Row gutter={10}>
				<Col>
					<span className="gs-tag blue">{i18n.t("human_resources.all_hires")}</span>
				</Col>
				{/* <Col>
					<span className="gs-tag green">
						{new Intl.NumberFormat(i18n.language.replace("_", "-"), {
							compactDisplay: "long",
							maximumFractionDigits: 2,
							minimumFractionDigits: 2
						}).format(valueOfContracts)}
					</span>
				</Col> */}
			</Row>
			<TableCollaborators
				hiredList={hiredList}
				isOnFinalStep={true}
			/>
		</>
	)
}