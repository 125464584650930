import { Icon } from "@iconify/react";
import { Checkbox, Input, Spin, Tree } from "antd";
import { Key, useEffect, useMemo, useState } from "react";
import i18n from "util/base/i18n";
import { AccountList, CostCenterList, ISidebarContent, LevelItem } from "../IFromTo";
import MultipleSearchGroupings from "module/budget/pages/groupings/components/MultipleSearchGroupings";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import MultipleSearchFromTo from "./MultipleSearchFromTo";
import StringUtil from "util/StringUtil";
import { cloneDeep } from "lodash";
import { commomFilterTableData } from "util/TableUtils";
import { Options } from "util/types/types";
const { Search } = Input;

export default function SidebarContent({
	treeData,
	costCenterList,
	checkedKeys,
	setCheckedKeys,
	expandedKeys,
	setExpandedKeys,
	isFetching,
	selectedTab,
	tableDataLinked
}: ISidebarContent) {
	const [search, setSearch] = useState('');
	const [searchType, setSearchType] = useState<'tree' | 'account'>('tree')
	const [formattedTreeData, setFormattedTreeData] = useState<LevelItem[]>([]);
	const [costCenterOptions, setCostCenterOptions] = useState<Options[]>([]);

	useEffect(() => {
		cleanSearch();
		setFormattedTreeData(buildTreeData(treeData));
	}, [treeData])

	useEffect(() => {
		if (!costCenterList) return;
		cleanSearch();
		setCostCenterOptions(buildCostCenterOptions(costCenterList));
	}, [costCenterList])

	function cleanSearch() {
		setSearch("");
		setSearchType("tree");
		if (selectedTab === "ledger_account") {
			onExpand([]);
			onCheck({ checked: [], halfChecked: [] });
		} else {
			onChangeCostCenter([]);
		}
	}

	function buildCostCenterOptions(data: CostCenterList[], search: string = ""): Options[] {
		if (!search.length) return data.map(({ externalCode, description, id }) => {
			return ({
				label: `${externalCode} - ${description}`,
				value: id
			})
		})

		return commomFilterTableData(costCenterList, search, ["description", "externalCode"])
			.map(({ externalCode, description, id }) => {
				return ({
					label: `${externalCode} - ${description}`,
					value: id
				})
			})
	}

	function buildCostCenterByLinkedAccount(data: CostCenterList[], search: string = ""): Options[] {
		if (!search) return buildCostCenterOptions(data);
		const allCostCenterLinkedFiltered: AccountList[] = commomFilterTableData(tableDataLinked, search, ["name", "externalCode"]);
		const filteredData: CostCenterList[] = data
			.filter(({ id }) => allCostCenterLinkedFiltered.some((costCenter) => costCenter.managementCostCenterId === id));
		return buildCostCenterOptions(filteredData);
	}

	function buildTreeData(data: LevelItem[] = [], search: string = ""): LevelItem[] {
		if (!search) return data;

		const filterData = (items: LevelItem[]): LevelItem[] => {
			return items
				.map((item) => {
					const matchesParent: boolean = StringUtil.getWithoutSpecials(item.title).includes(StringUtil.getWithoutSpecials(search));

					if (item.children) {
						const filteredChildren: LevelItem[] = filterData(item.children);
						if (matchesParent || filteredChildren.length > 0) {
							return { ...item, children: matchesParent ? item.children : filteredChildren };
						}
					} else if (matchesParent) {
						return item;
					}
					return null;
				})
				.filter((item) => item !== null); 
		};

		return filterData(data);
	}


	function buildTreeDataByLinkedAccount(data: LevelItem[], search: string = ""): LevelItem[] {
		if (search.length === 0) return data;
		const allAccountLinkedFiltered: AccountList[] = commomFilterTableData(tableDataLinked, search, ["name", "externalCode"]);
		const filterData = (items: LevelItem[]): LevelItem[] => {
			return items
				.map((item) => {
					if (item.children?.length) {
						const children: LevelItem[] = filterData(item.children);
						if (children.length > 0) {
							return { ...item, children };
						}
					} else {
						const isAccountOnThree: boolean = allAccountLinkedFiltered.some((account) => account.managementAccountPlanHierarchyId === item.key);
						if (isAccountOnThree) return item;
					}
					return undefined;
				})
				.filter((item) => item);
		};

		return filterData(data);
	};

	function onCheck(checked: { checked: Key[]; halfChecked: Key[] }) {
		const selectedKey = checked.checked.length > 0 ? [checked.checked[checked.checked.length - 1]] : [];
		setCheckedKeys(selectedKey.map(Number));
	}

	function onExpand(expandedKeys: number[]) {
		setExpandedKeys(expandedKeys)
	}

	function chooseIconExpanded(props: { expanded: boolean }) {
		return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
	}

	function addButtonLines(line) {
		return (
			<span className='tree-title-content'>
				<p>
					{line.title}
				</p>
			</span >
		)
	}

	function onChangeCostCenter(checkedValues: CheckboxValueType[]) {
		if (checkedValues.length === 1) {
			setCheckedKeys(checkedValues as number[]);
			return;
		}
		const newlySelectedValue: CheckboxValueType = checkedValues.find(value => !checkedKeys.some(key => key === value));
		const selectedKey: CheckboxValueType[] = newlySelectedValue ? [newlySelectedValue] : [];

		setCheckedKeys(selectedKey as number[]);
	}

	const handleSearch = (value: string, type: 'tree' | 'account') => {
		setCheckedKeys([]);
		setSearch(value);
		setSearchType(type);
		if (selectedTab === "ledger_account") {
			let newData: LevelItem[] = [];
			if (type === 'account') {
				newData = buildTreeDataByLinkedAccount(treeData, value);
			} else {
				newData = buildTreeData(treeData, value);
			}

			setFormattedTreeData(newData);
		} else {
			let newData: Options[] = [];
			if (type === 'account') {
				newData = buildCostCenterByLinkedAccount(costCenterList, value);
			} else {
				newData = buildCostCenterOptions(costCenterList, value);
			}

			setCostCenterOptions(newData);
		}
	};

	return (
		<div className='sider-content'>
			<MultipleSearchFromTo
				onSearch={handleSearch}
				currentTree={selectedTab}
				searchValue={search}
				searchType={searchType}
			/>
			<Spin spinning={isFetching}>
				{selectedTab === "ledger_account" ?
					(treeData.length > 0 ?
						<Tree
							checkStrictly
							checkedKeys={checkedKeys}
							switcherIcon={chooseIconExpanded}
							treeData={formattedTreeData}
							showLine={{ showLeafIcon: false }}
							checkable
							onCheck={onCheck}
							titleRender={addButtonLines}
							disabled={isFetching}
							expandedKeys={expandedKeys}
							onExpand={onExpand}
							selectable={false}
						/>
						:
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 40 }}>
							<Icon icon="clarity:tree-view-line" />
							{i18n.t("groupings_texts.no_registered_level")}
						</div>
					) :
					<div>
						<Checkbox.Group
							options={costCenterOptions}
							value={checkedKeys}
							onChange={onChangeCostCenter}
						/>
					</div>
				}
			</Spin>
		</div>
	)
}