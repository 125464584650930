import { Icon } from "@iconify/react";
import { Button, Col, Modal, Row } from "antd";
import i18n from "util/base/i18n";
import { INewReportModalProps, ReportType } from "../IRegistrationSalesReports";
import { useEffect, useState } from "react";
import { useNewReportContext } from "./NewReportContext";


export default function NewReportModal({
	isNewReportModalOpen,
	setIsNewReportModalOpen,
}: INewReportModalProps) {
	const [reportSelected, setReportSelected] = useState<ReportType>();
	const { setReportType } = useNewReportContext();

	useEffect(() => {
		if (!isNewReportModalOpen) {
			setReportSelected(null);
		}
	}, [isNewReportModalOpen]);
	
	const updateReportType = (reportType: ReportType) => {
		setReportSelected(reportType);
	}
	const classButtonSelected = (type: ReportType): string => {
		return type === reportSelected ? "type-selected" : "";
	}

	const handleClose = () => {
		setIsNewReportModalOpen(false);
		setReportType(null);
	}

	const onOk = () => {
		setReportType(reportSelected);
		setIsNewReportModalOpen(false);
	}

	return (
		<Modal
			className="new-report-modal"
			open={isNewReportModalOpen}
			cancelButtonProps={{
				type: "link"
			}}
			okText={i18n.t<string>("continue")}
			cancelText={i18n.t<string>("cancel")}
			okButtonProps={{ className: "gs-main-button", disabled: ReportType[reportSelected] ? false : true }}
			title={i18n.t<string>("new_report")}
			onOk={onOk}
			onCancel={handleClose}
			destroyOnClose
		>
			<Row className="new-report-header">
				<Col>
					<p>
						{i18n.t<string>("new_sales_report.select_the_type_of_report_you_want_to_register")}
					</p>
				</Col>
			</Row>
			<Row className="new-report-content">
				<Col span={12}>
					<Button
						type="text"
						className={classButtonSelected(ReportType.PLANNING)}
						onClick={() => updateReportType(ReportType.PLANNING)}
					>
						<Row>
							<Icon icon="fluent:arrow-growth-24-filled" />
						</Row>
						<Row>
							{i18n.t<string>("financial_statements")}
						</Row>
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="text"
						className={classButtonSelected(ReportType.REVENUE)}
						onClick={() => updateReportType(ReportType.REVENUE)}
					>
						<Row>
							<Icon icon="tabler:report-money" />
						</Row>
						<Row>
							{i18n.t<string>("profitability")}
						</Row>
					</Button>
				</Col>
			</Row>
		</Modal>
	)
}