import { Table, Tooltip } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Key, useCallback, useRef, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ITableData } from "../IAdditionalFields";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableBodyRowProps } from "module/budget/pages/flexField/IFlexField";
import { Notification } from "components/notification/Notification";
import { Icon } from "@iconify/react";
import { expandIcon } from "module/budget/pages/revenue/mainFlow/grid/row/ExpandIcon";

interface Props {
	data: ITableData[];
	isFetching: boolean;
	selectedRowKeys: Key[];
	onChange: (selectRowKeys: Key[], selectedRows: ITableData[]) => void;
	onLoadFields:  (data) => void
}
export default function AdditionalFieldsTable({
	data,
	isFetching,
	selectedRowKeys,
	onChange,
	onLoadFields
}: Props) {

	const tableColumns: ColumnsType = [
		{
			title: i18n.t("additional_field_name"),
			align: "left",
			dataIndex: "fieldName",
			key: "fieldName",
			width: 200,
			render: (text) => {
				const value = data.find(item => item.fieldName === text);
				return (
					<div className='field-column'>
						<p>
							{value?.fieldName}
						</p>
						<Icon icon="ph:arrows-out-line-vertical" />
					</div>

				)
			},
		},
		{
			title: i18n.t("type"),
			align: "left",
			dataIndex: "type",
			key: "center",
			width: 100,
			render: (text) => i18n.t(`collaborators.additional_fields.${text}`)
		},
		{
			title: i18n.t("incidence"),
			align: "left",
			dataIndex: "incidence",
			key: "incidence",
			width: 200,
			render: (text) => text ? i18n.t(`collaborators.additional_fields.${text}`) : ""
		},
		{
			title: i18n.t("basis_for"),
			align: "left",
			dataIndex: "baseFor",
			key: "baseFor",
			width: 200,
			render: (text: string[]) => {
				return (
					<>
						{
							text?.length < 1 ? "" :
							i18n.t(`collaborators.additional_fields.base.${text[0]}`)
						}
						{
							text?.length > 1 &&
							<Tooltip
								color={'rgba(0,0,0,0.9'}
								placement="right"
								title={text.slice(1).map((base) => <p key={base} style={{ margin: 0 }}>{i18n.t(`collaborators.additional_fields.base.${base}`)}</p>)}
							>
								<span className="additional-items">
									{`+${text?.length - 1}`}
								</span>
							</Tooltip>
						}
					</>
				)

			}
		},
		{
			title: i18n.t("setting"),
			align: "left",
			dataIndex: "configuration",
			key: "configuration",
			width: 300,
			render: (text) => {
				return (
					<>
						{
							text?.length < 1 ? "" :
							i18n.t(`${text[0]}`)
						}
						{
							text?.length > 1 &&
							<Tooltip
								color={'rgba(0,0,0,0.9'}
								placement="right"
								title={text.slice(1).map((base) => <p style={{ margin: 0 }}>{i18n.t(`collaborators.additional_fields.configuration.${base}`)}</p>)}
							>
								<span className="additional-items">
									{`+${text?.length - 1}`}
								</span>
							</Tooltip>
						}
					</>
				)
			}
		},
	]

	function onUpdateOrder(data: ITableData[]) {
		onLoadFields(data);
		Notification({
			type: 'success',
			message: i18n.t<string>("successfully_saved"),
		})
	}

	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragRow = data[dragIndex];
			let newData: ITableData[] = JSON.parse(JSON.stringify(data));
			newData.splice(dragIndex, 1);
			newData.splice(hoverIndex, 0, dragRow);

			ServiceCaller.doRequest({
				type: RequestType.PUT,
				params: newData.map((item, index) => {
					return ({
						id: item.id,
						ordenation: index + 1
					})
				}),
				url: "/human-resources/fields/saveOrdenation",
			}, onUpdateOrder.bind(this));
			return newData;
		}, [data]);

	const DraggableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: DraggableBodyRowProps) => {
		const type = 'DraggableBodyRow';
		const ref = useRef<HTMLTableRowElement>(null);

		const [{ isOver, dropClassName }, drop] = useDrop({
			accept: type,
			collect: monitor => {
				const { index: dragIndex } = monitor.getItem() || {};
				if (dragIndex === index) {
					return {};
				}
				return {
					isOver: monitor.isOver(),
					dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
				};
			},
			drop: (item: { index: number }) => {
				moveRow(item.index, index);
			},
		});

		const [, drag] = useDrag({
			type,
			item: { index },
			collect: monitor => ({
				isDragging: monitor.isDragging(),
			}),
		});
		drop(drag(ref));

		return (
			<tr
				ref={ref}
				className={`${className}${isOver ? dropClassName : ''}`}
				style={{ cursor: 'move', ...style }}
				{...restProps}
			/>
		);
	};

	const rowSelection = { selectedRowKeys, onChange };

	return (
		<DndProvider backend={HTML5Backend}>
			<Table
				columns={tableColumns}
				className="gs-table additional-fields-table"
				dataSource={data}
				rowKey={"id"}
				rowSelection={{
					...rowSelection,
				}}
				components={{
					body: {
						row: DraggableBodyRow,
					}
				}}
				pagination={false}
				scroll={{ y: 'calc(100vh - 250px)' }}
				expandIcon={expandIcon}
				bordered
				loading={{
					spinning: isFetching,
					tip: `${i18n.t<string>("loading")}...`
				}}
				onRow={(_, index) => {
					const attr = {
						index,
						moveRow,
					};
					return attr as React.HTMLAttributes<any>;
				}}
			/>
		</DndProvider>
	)
}