import { FlexFilter, ModuleType, Options } from "util/types/types";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { BudgetOpeningProviderProps, BudgetOpeningContextProps } from "./IContext";
import {
    BudgetGridData,
    BudgetOpening,
    BudgetOpeningCachedData,
    Column,
    CurrenciesRate,
    PlannedMonth,
    ViewModeSelected,
} from "../IFixedExpense";
import { useUserContext } from "context/UserContext";
import { Notification } from "components/notification/Notification";
import { useLocalStorage } from "hooks/useLocalStorage";
import { createGridData } from "../components/gridContainer/utils/createGridData";
import { useErrorBoundary } from "react-error-boundary";
import { createPlannedMonthsMock } from "../components/gridContainer/functions/createPlannedMonthsMock";
import i18n from "util/base/i18n";
import { ErrorFallbackProps } from "components/error/ErrorFallback";
import { useBudgetDates } from "hooks/useBudgetDates";
import useCachedQuery from "hooks/useCachedQuery";
import { queryClient } from "util/queryClient";
import { useCurrencyOptions } from "hooks/useCurrencyOptions";
import { useUserCurrency } from "hooks/useUserCurrency";
import { useSelectedCostCenterOptions } from "./useSelectedCostCenterOptions";
import { useSelectedAccountAccountingOptions } from "./useSelectedAccountAccountingOptions";
import { usePermissionContext } from "context/PermissionContext";
import { exportGrid } from "../components/gridContainer/functions/exportGrid";

const BudgetOpeningContext = createContext<BudgetOpeningContextProps>({} as BudgetOpeningContextProps);

export function BudgetOpeningProvider({ children }: BudgetOpeningProviderProps) {
    const { userInfo } = useUserContext();
    const [budgetOpeningCached, setBudgetOpeningCached] = useLocalStorage(
        `budget-opening-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`,
        {}
    );
    const [budgetGridData, setBudgetGridData] = useState<BudgetGridData[]>([]);
    const [accountAccounting, setAccountAccounting] = useState([]);
    const [costCenter, setCostCenter] = useState([]);
    const [spendingPackage, setSpendingPackage] = useState([]);
    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>([null, null]);
    const [currenciesOptions, setCurrenciesOptions] = useState<Options[]>(null);
    const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilter[]>([]);
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(false);
    const [error500, setError500] = useState(false);
    const [isQueryByPackage, setIsQueryByPackage] = useState(false);
    const [isLoadingCacheData, setIsLoadingCacheData] = useState(false);
    const [isFetchingGridData, setIsFetchingGridData] = useState(false);
    const [selectedRows, setSelectedRows] = useState<BudgetGridData[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [isOnlyChangingPeriod, setIsOnlyChangingPeriod] = useState(false);
    const [currenciesSelected, setCurrenciesSelected] = useState<Options[]>([{ value: userInfo.currencyId, label: '' }]);
    const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([
        [moment().startOf("year"), moment().month(11).endOf("month")],
    ]);
    const [plannedMonthsMock, setPlannedMonthsMock] = useState<PlannedMonth[]>([]);
    const [openBudgetYears, setOpenBudgetYears] = useState<number[]>([]);
    const [viewModeSelected, setViewModeSelected] = useState<ViewModeSelected[]>([]);
    const [columns, setColumns] = useState<Column[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<[moment.Moment, moment.Moment] | null>(null);
    const { showBoundary } = useErrorBoundary();
    const { data: costCenterOptions } = useSelectedCostCenterOptions();
    const { data: currencyOptions } = useCurrencyOptions();
    const { data: accountAccountingOptions, isLoading: isFetchingAccountOptions } = useSelectedAccountAccountingOptions(
        costCenter[0]?.value,
        {
			enabled: !!costCenter[0]?.value,
        }
    );
    const { data: currency } = useUserCurrency(userInfo.currencyId, { enabled: !!userInfo.id });
    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);
    const costCenterId: number = costCenter[0]?.value;
    const { data: spendingPackageOptions } = useCachedQuery<Options[]>(
        ["spending-package-options", costCenter[0]?.value.toString()],
        {
            type: RequestType.GET,
            url: `/budget-base/account-hierarchy/get-spending-packages?costCenterId=${costCenterId}`,
        },
        {
            enabled: !!costCenter[0]?.value,
        },
        (data: { spendingPackages: { id: number; description: string }[] }) => {
            return data.spendingPackages.map((item) => ({ label: item.description, value: item.id }));
        }
    );

    const { data: currenciesConversions } = useCachedQuery(
        ["currenciesConversions"],
        {
            type: RequestType.GET,
            url: `/budget-base/currency-conversion/find-conversion-by-origin?origin=${userInfo.currencyId}`,
        },
        { enabled: !!userInfo.id }
    );

    const { data: indexerOptions } = useCachedQuery<Options[]>(
        ["indexer-options"],
        {
            type: RequestType.GET,
            url: "/budget-base/indexer",
        },
        { enabled: !!userInfo.id },
        (data) => {
            return data.map((option) => ({ label: option.description, value: option.id }));
        }
    );

    const [accountsWithValue, setAccountsWithValue] = useState<boolean>(false);
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { functionalityPermissions, isFetchingFuncPermissions } = usePermissionContext();

    useEffect(() => {
        if (userInfo && !isFetchingFlexFields) {
            getFlexFieldFilters();
        }
    }, [userInfo])

    useEffect(() => {
        (functionalityPermissions.package && !functionalityPermissions.accounts) && setIsQueryByPackage(true);
    }, [functionalityPermissions]);

    useEffect(() => {
        if (userInfo && currenciesOptions) {
            const userCurrency = currenciesOptions.find(item => item.value === userInfo.currencyId);
            if (userCurrency) {
                setCurrenciesSelected([{ value: userInfo.currencyId, label: userCurrency.label }]);
            }
        }
    }, [userInfo, currenciesOptions]);

    useEffect(() => {
        if (!budgetPeriodDates) {
            return;
        }
        if (budgetPeriodDates.period?.length === 0) {
            showBoundary({
                code: 400,
                title: "Não há exercício aberto",
                message: "Adicione um exercício na tela de painel de controle, ou peça permissão para o exercício",
                image: "empty_list",
            } as ErrorFallbackProps["error"]);
        }
        const openedPeriods = budgetPeriodDates.period?.reduce((acc, val) => {
            const period = [moment(val.expensesResourcesBudgetStartDate), moment(val.expensesResourcesBudgetEndDate)];
            acc.push(period);
            return acc;
        }, []);
        setOpenBudgetYears(budgetPeriodDates.period?.map((period) => period.year));
        setOpenedCalendarPeriod(openedPeriods);
    }, [budgetPeriodDates]);

    useEffect(() => {
        if (!userInfo.id || !costCenterOptions || !accountAccountingOptions) return;

        if (!costCenter.length && costCenterOptions?.length) {
            setIsLoadingCacheData(false);
            const costCenterSelected: Options[] = costCenterOptions.filter(costCenter => costCenter.lastSelected);
            setCostCenter(costCenterSelected);
        }

        if (!accountAccounting.length && accountAccountingOptions?.length) {
            setIsLoadingCacheData(false);
            const accountAccountingSelected: Options[] = accountAccountingOptions.filter(({ lastSelected }) => lastSelected);
            setAccountAccounting(accountAccountingSelected);
        }

		
	}, [userInfo, costCenterOptions]);

    useEffect(() => {
        if (!currencyOptions || !currenciesConversions) return;
        handleAddCurrenciesOptFilter(currencyOptions, currenciesConversions);
    }, [currencyOptions, currenciesConversions]);

    useEffect(() => {
        if (
            !userInfo.id ||
            !userInfo.currencyId ||
            !currenciesOptions ||
            !costCenterOptions ||
            isFetchingAccountOptions ||
            !budgetPeriodDates ||
            !accountAccountingOptions ||
            !spendingPackageOptions ||
            budgetGridData.length > 0 ||
            !budgetOpeningCached.costCenterId
        ) {
            return;
        }

        if (
            budgetOpeningCached.spendingPackage?.length === 0
        ) {
            localStorage.removeItem(
                `budget-opening-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`
            );
            setIsLoadingCacheData(false);
            setIsFetchingGridData(false);
            return;
        }

        if (spendingPackageOptions?.length > 0 && budgetOpeningCached.spendingPackage?.length > 0) {
            let isCachedDifferent: boolean = false;
            const cachedSpendingPackage = spendingPackageOptions.filter((item) => {
                const hasOnOptions: boolean = budgetOpeningCached.spendingPackage.includes(item.value);
                if (!hasOnOptions) {
                    isCachedDifferent = true;
                }
                return hasOnOptions;
            }
            );
            if (cachedSpendingPackage.length === 0) {
                setIsQueryByPackage(false);
                return;
            }
            if (isCachedDifferent) setSpendingPackage(cachedSpendingPackage);
        }
    }, [
        accountAccountingOptions,
        spendingPackageOptions,
        currenciesOptions,
        costCenterOptions,
        isFetchingAccountOptions,
        userInfo,
        budgetOpeningCached,
    ]);

    useEffect(() => {
        if (period[0] !== null && userInfo.currencyId) {
            const plannedMonths = createPlannedMonthsMock(period, userInfo.currencyId);

            setPlannedMonthsMock(plannedMonths);
        }
    }, [period, userInfo.currencyId]);

    function handleExportGrid() {
        exportGrid(columns, budgetGridData, viewModeSelected, selectedRows)
    }

    function onChangeQueryType() {
        if (isQueryByPackage) {
            setSpendingPackage([]);
            setBudgetGridData([]);
        } else {
            setAccountAccounting([]);
            setBudgetGridData([]);

        }
        setIsQueryByPackage((state) => !state);
    }

    function refreshCard() {
        setIsLoading(true);
        ServiceCaller.doRequest(
            {
                url: `/expenses/budget-calculation/total-values?scenario={scenario}&organization={organization}&businessUnit={businessUnit}&client={client}&locale={locale}&user={user}&startDate=${
                    period[0] ? period[0].valueOf() : moment().year().valueOf()
                }&endDate=${period[0] ? period[1].valueOf() : moment().year().valueOf()}`,
                type: RequestType.GET,
            },
            handleGetTotalValues.bind(this)
        );
    }

    function handleGetTotalValues(data) {
        setCards(data);
        setIsLoading(false);
    }

    async function onLoadFlexField(data: any[], budgetOpeningCachedData?: BudgetOpeningCachedData) {
        const flexField: FlexFilter[] = data
            .sort((a, b) => Number(a.order) - Number(b.order))
            .map((item) => {
                return {
                    label: item.name,
                    value: item.flexFieldId,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.order,
                    fieldCode: Number(item.fildCode.split("_").pop()),
                    selectedOption: [],
                    children: [],
                    allValues: item.allValues
                };
            });
        const selectedCachedFields = budgetOpeningCachedData?.flexFieldInfoData;

        const isCachedSameAsCurrent =
            selectedCachedFields &&
            selectedCachedFields.every((field) => flexField.find((f) => f.value === field.value)) &&
            selectedCachedFields.length === flexField.length;

        try {
            for (const ff of flexField) {
                if ((selectedCachedFields && isCachedSameAsCurrent) || !ff.linkedFilters) {
                    let childFilterValues = ff.allValues;

                    ff.children = childFilterValues.map((item) => ({
                        value: item.fieldValueId,
                        label: item.externalCode + " - " + item.description,
                        linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                    }));
                }

                if (ff.linkedFilters && ff.linkedFilters.includes("BUSINESS_UNIT")) {
                    const bu = userInfo.selection.businessUnitId.toString();

                    let childFilterValues = ff.allValues;

                    ff.children = childFilterValues
                        ?.filter((item) => item.fitler.businessUnitFilters.includes(Number(bu)))
                        .map((item) => ({
                            value: item.fieldValueId,
                            label: item.externalCode + " - " + item.description,
                            linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                        }));
                }
                if (ff.linkedFilters && ff.linkedFilters.includes("ORGANIZATION")) {
                    const org = userInfo.selection.organizationId.toString();

                    let childFilterValues = ff.allValues;

                    ff.children = childFilterValues
                        ?.filter((item) => item.fitler.organizationFilters.includes(Number(org)))
                        .map((item) => ({
                            value: item.fieldValueId,
                            label: item.externalCode + " - " + item.description,
                            linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                        }));
                }
            }

            if (selectedCachedFields && isCachedSameAsCurrent) {
                flexField.forEach((flex) => {
                    const cachedField = selectedCachedFields.find((flexField) => flexField.value === flex.value);
                    if (flex.linkedFilters) {
                        flex.children = flex.children.filter((fValue) =>
                            cachedField.children.find((f) => f.value === fValue.value)
                        );
                        const hasCachedOption =
                            cachedField.selectedOption?.length > 0 &&
                            flex.children.find((opt) => opt.value === cachedField.selectedOption[0].value);
                        if (hasCachedOption) {
                            flex.selectedOption = cachedField.selectedOption;
                        }
                    } else {
                        const hasCachedOption =
                            cachedField.selectedOption?.length > 0 &&
                            flex.children.find((opt) => opt.value === cachedField.selectedOption[0].value);
                        if (hasCachedOption) {
                            flex.selectedOption = cachedField.selectedOption;
                        }
                    }
                });
            } else {
                flexField.forEach((flex) => {
                    if (flex.allValues.filter(item => item.theLastSelected).length > 0) {
                        const selectedFlexField = flex.allValues.filter(item => item.theLastSelected)[0];
                        flex.selectedOption = [{
                            value: selectedFlexField.fieldValueId,
                            label: selectedFlexField.externalCode + " - " + selectedFlexField.description
                        }];
                    }
                })
            }
            if (budgetOpeningCachedData && !budgetOpeningCached.organizationId) {
                setBudgetOpeningCached(budgetOpeningCachedData);
            }
        } catch (err) {
            localStorage.removeItem(
                `budget-opening-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`
            );
        }
		setFlexFieldsFilters(flexField);
        setIsFetchingFlexFields(false);
    }

    async function onLoadBudgetOpening(data: BudgetOpening[]) {
        const currentPeriod = period[0] ? period : budgetOpeningCached.period.map((date) => moment(date));
        const plannedMonths = createPlannedMonthsMock(currentPeriod, userInfo.currencyId);

        setPlannedMonthsMock(plannedMonths);
        const foreignCurrencies = [];

        const budgetOpeningData: BudgetOpening[] = data.map((item, index) => {
            if (item.plannedMonths?.length > 0) {
                let detailIdList = [];
                item.plannedMonths.forEach((plannedMonth) => {
                    plannedMonth.rate = 1;
                    if (
                        plannedMonth.currencyId !== undefined && plannedMonth.currencyId !== userInfo.currencyId &&
                        !foreignCurrencies.includes(plannedMonth.currencyId)
                    ) {
                        foreignCurrencies.push(plannedMonth.currencyId);
                    }
                    if (plannedMonth.detailId && !detailIdList.includes(plannedMonth.detailId)) {
                        detailIdList.push(plannedMonth.detailId);
                    }
                });

                const updatedPlannedMonthsItem = plannedMonths.reduce((acc, val) => {
                    if (detailIdList.length === 0) {
                        const index = item.plannedMonths.findIndex((p) => p.month === val.month);
                        if (index > -1) {
                            return acc;
                        }
                        acc.push({
                            ...val,
                        });
                    } else {
                        detailIdList.forEach((detailId) => {
                            const index = item.plannedMonths.findIndex(
                                (p) => p.month === val.month && p.detailId === detailId
                            );
                            if (index > -1) {
                                return acc;
                            }
                            const data = item.plannedMonths.find((p) => p.detailId === detailId);
                            acc.push({
                                ...val,
                                detailId,
                                detailName: data.detailName,
                                currencyId: data.currencyId,
                                unitValue: data.unitValue !== null ? 0 : null,
                                quantity: data.quantity !== null ? 0 : null,
                                plannedValue: 0,
                                realizedTotal: 0,
                                apportionmentTotal: 0,
                                historicTotal: 0,
                                projectedTotal: 0,
                            });
                        });
                    }

                    return acc;
                }, []);
                item.plannedMonths = [...item.plannedMonths, ...updatedPlannedMonthsItem];
                item.plannedMonths.sort((a, b) => {
                    return moment(a.month).isBefore(b.month) ? -1 : 1;
                });
                return { ...item };
            }
            return {
                ...item,
                plannedMonths,
            };
        });
        await ServiceCaller.doAsyncRequest<CurrenciesRate[]>({
            type: RequestType.GET,
            url: `/budget-base/currency-conversion-tax/currency-exchange-rate?origin=${currenciesSelected[0].value
                }&destination=${userInfo.currencyId}&scenario={scenario}&startDate=${currentPeriod[0].format(
                    "YYYY-MM"
                )}&endDate=${currentPeriod[1].format("YYYY-MM")}`,
        }).then((res) => {
            budgetOpeningData.forEach((budget) => {
                budget.plannedMonths.forEach((plannedMonth) => {
                    const rateOfMonth = res.data.find((rate) => rate.month === plannedMonth.month);
                    if (rateOfMonth) {
                        plannedMonth.rate = rateOfMonth.rate;
                    } else {
                        plannedMonth.rate = 1;
                    }
                });
            });
        });

        let updatedGridData = createGridData(
            budgetOpeningData,
            accountAccountingOptions,
            plannedMonths,
            userInfo.currencyId,
            currenciesOptions,
            spendingPackageOptions
        );
        updatedGridData = OrderSpendingPackage(updatedGridData);
        setBudgetGridData(updatedGridData);
        setIsFetchingGridData(false);
        setIsLoadingCacheData(false);
        refreshCard();
    }
    function OrderSpendingPackage(updatedGridData: BudgetGridData[]) {
        if (isQueryByPackage && spendingPackageOptions?.length > 0) {
            const itensByPackage: { [key: string]: BudgetGridData[] } = {}
            updatedGridData.sort((a, b) => {
                return a.spendingPackage.localeCompare(b.spendingPackage)
            }).forEach(item => {
                if (itensByPackage.hasOwnProperty(item.spendingPackage)) {
                    itensByPackage[item.spendingPackage].push(item);
                    itensByPackage[item.spendingPackage].sort((contA, contB) => {
                        const isAStantard: boolean = contA.standardAccount !== null && contA.standardAccount === contA.budgetOpening.accountId;
                        const isBStantard: boolean = contB.standardAccount !== null && contB.standardAccount === contB.budgetOpening.accountId;
                        if (isAStantard === isBStantard) {
                            return contA.accounting.localeCompare(contB.accounting);
                        }
                        return isAStantard ? -1 : 1;
                    });
                } else {
                    itensByPackage[item.spendingPackage] = [item];
                }
            });

            let listOrder: BudgetGridData[] = [];

            Object.values(itensByPackage).forEach(items => {
                listOrder = listOrder.concat(items);
            });
            return listOrder;
        }
        return updatedGridData
    }
    async function getFlexFieldFilters(budgetOpeningCachedData?: BudgetOpeningCachedData) {
        setIsFetchingFlexFields(true);
        const flexfields: any = await ServiceCaller.doAsyncRequest({
            type: RequestType.GET,
            url: "/expenses/flex-field-opening?user={user}",
        });

        onLoadFlexField(flexfields.data, budgetOpeningCachedData);
    }

    function handleAddCurrenciesOptFilter(currencyOptions, currenciesConversions) {
        setCurrenciesOptions(
            currencyOptions.filter(
                (cur) => currenciesConversions.includes(cur.value) || cur.value === userInfo.currencyId
            )
        );
    }

    function handleChangePeriod(period) {
        setPeriod(period);
        setIsOnlyChangingPeriod(true);
    }

    async function handleRefreshAccountLinked() {
        const yearList = [];
        const listofYearsWithOpenBudget = budgetPeriodDates.period.map((period) => period.year);
        let currentDate = selectedPeriod[0].clone();

        while (currentDate.isSameOrBefore(selectedPeriod[1])) {
            yearList.push(currentDate.format("YYYY"));
            currentDate.add(1, "year");
        }
        const yearWithoutBudgetOpenPeriod = yearList.filter(
            (year) => !listofYearsWithOpenBudget.includes(Number(year))
        );
        const yearWithBudgetOpenPeriod = yearList.filter((year) => listofYearsWithOpenBudget.includes(Number(year)));

        if (yearWithoutBudgetOpenPeriod.length > 0) {
            Notification({
                type: "warning",
                message: `${i18n.t("no_open_budget")} ${yearWithoutBudgetOpenPeriod.join(", ")}`,
            });
        }

        async function doRefreshAccountLinked() {
            const responses = [] as {
                success: boolean;
                data: any;
                year: number;
            }[];

            for (const year of yearWithBudgetOpenPeriod) {
                const dataToSend = {
                    scenarioId: userInfo.selection.scenarioId,
                    organizationId: userInfo.selection.organizationId,
                    year: Number(year),
                    currencyId: userInfo.currencyId,
                    localeId: userInfo.localeId,
                    clientId: userInfo.clientId,
                };

                try {
                    const response = await ServiceCaller.doAsyncRequest({
                        type: RequestType.POST,
                        url: `/expenses/budget-opening/update-linked-accounts`,
                        params: dataToSend,
                    });
                    responses.push({ ...response, year });
                } catch (error) {
                    responses.push({ ...error, year });
                    console.error(`${i18n.t("error_for_the_year")} ${year}:`, error);
                }
            }

            return responses;
        }

        doRefreshAccountLinked().then((apiResponses) => {
            apiResponses.forEach((res) => {
                if (res.data.messageCode === "empty") {
                    Notification({
                        type: "error",
                        message: `${res.year}: ${i18n.t("fixed_expense_texts.no_linked_accounts")}`,
                        duration: 5,
                    });
                } else if (res.success) {
                    Notification({
                        type: "success",
                        message: `${res.year}: ` + i18n.t("fixed_expense_texts.accounts_updated_successfully"),
                        duration: 5,
                    });
                } else {
                    Notification({
                        type: "error",
                        message: `${res.year}: ${i18n.t("notifications.something_went_wrong")}`,
                        duration: 5,
                    });
                }
            });
        });
    }

    function handleChangeTableData(data) {
        setTableData(data);
    }

    return (
        <BudgetOpeningContext.Provider
            value={{
                accountAccounting,
                setAccountAccounting,
                accountAccountingOptions,
                costCenter,
                setCostCenter,
                costCenterOptions,
                period,
                setPeriod,
                currenciesOptions,
                flexFieldsFilters,
                setFlexFieldsFilters,
                isFetchingFlexFields,
                error500,
                setError500,
                openedCalendarPeriod,
                selectedRows,
                setSelectedRows,
                budgetPeriodDates,
                userInfo,
                handleRefreshAccountLinked,
                isFetchingAccountOptions,
                budgetOpeningCached,
                setBudgetOpeningCached,
                plannedMonthsMock,
                budgetGridData,
                setBudgetGridData,
                onLoadBudgetOpening,
                isFetchingGridData,
                setIsFetchingGridData,
                isLoadingCacheData,
                currency,
                setIsLoadingCacheData,
                indexerOptions,
                openBudgetYears,
                isQueryByPackage,
                setIsQueryByPackage,
                spendingPackageOptions,
                spendingPackage,
                setSpendingPackage,
                onChangeQueryType,
                currenciesSelected,
                setCurrenciesSelected,
                tableData,
                handleChangeTableData,
                isOnlyChangingPeriod,
                setIsOnlyChangingPeriod,
                handleChangePeriod,
                setAccountsWithValue,
                accountsWithValue,
                functionalityPermissions,
                cards,
                setCards,
                isLoading,
                setIsLoading,
                refreshCard,
                setIsFetchingFlexFields,
                isFetchingFuncPermissions,
                viewModeSelected,
                setViewModeSelected,
                columns,
                setColumns,
                handleExportGrid,
                selectedPeriod,
                setSelectedPeriod
            }}
        >
            {children}
        </BudgetOpeningContext.Provider>
    );
}

export function useBudgetOpeningContext() {
    const context = useContext(BudgetOpeningContext);

    return context;
}
