import { Input, Select } from 'antd';
import i18n from 'util/base/i18n';

const { Search } = Input;

interface Props {
	onSearch: (value: string, type: 'tree' | 'account') => void;
	currentTree: string | 'ledger_account' | 'cost_center';
	searchValue: string;
	searchType: 'tree' | 'account';
}
export default function MultipleSearchFromTo({ onSearch, currentTree, searchValue, searchType }: Props) {

	const handleSearchTypeChange = (value: 'tree' | 'account') => {
		onSearch('', value);
	};

	const handleSearch = (value: string) => {
		onSearch(value, searchType);
	};

	return (
		<Input.Group compact className='multiple-search-groupings'>
			<Select
				className="select-search-multiple-search"
				value={searchType}
				onChange={handleSearchTypeChange}
			>
				<Select.Option value="tree">{
					`${i18n.t('search_by')} ${i18n.t(currentTree === "ledger_account" ? "management_account" : "management_cost_center")}`
				}
				</Select.Option>
				<Select.Option value="account">
					{`${i18n.t('search_by')} 
					${currentTree === "ledger_account" ?
							i18n.t("search_account_linked") :
							i18n.t("search_cost_center_linked")}`}
				</Select.Option>
			</Select>
			<Search
				placeholder={i18n.t('search')}
				value={searchValue}
				onChange={(e) => handleSearch(e.target.value)}
				onSearch={handleSearch}
			/>
		</Input.Group>
	);
}