import { Button, Cascader, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import i18n from "util/base/i18n";
import { Icon } from '@iconify/react';
import './DistributionModal.sass'
import moment from "moment";
import { DistributionFormData, DistributionFormProps, OptionCascader } from "../../IHiredDashboard";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useUserContext } from "context/UserContext";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FlexFilter, Options } from "util/types/types";
import { selectProps } from "util/props/props";
import { useHumanResourcesContext } from "../../../context/HumanResourcesContext";


export function DistributionForm({ form,
	handleSubmit,
	setDistributionList,
	distributionList,
	isFormSection,
	fieldOptions,
	actionsOptions,
	monthOptions,
	flexFieldValues,
	setFlexFieldValues
}: DistributionFormProps) {
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAllMonth, setCheckAllMonth] = useState(false);
	const monthsSelected = Form.useWatch('distributionPeriods', form)

	const { costCategoryOptions, businessUnitOptions, professionalCategoryOptions, costCenterOptions } = useHumanResourcesContext();

	useEffect(() => {
		if (!monthsSelected) {
			setIndeterminate(false);
			setCheckAllMonth(false);
			return;
		}

		const isAllSelected = monthsSelected.length === monthOptions.length;

		setIndeterminate(!isAllSelected && monthsSelected.length > 0 ? true : false);
		setCheckAllMonth(isAllSelected);
	}, [monthsSelected]);

	function getLabelOfOptionsById(options: OptionCascader[], id: string): string {
		return options.find(value => value.value.toString().includes(id.toString()))?.label;
	}
	function handleCollabDistribution(data) {
		let newFlexField: FlexFilter[] = flexFieldValues;
		const flexValues = Object.keys(data)
			.filter(key => key.includes("flexField"))
			.map(key => {
				const arrString = key.split("-");
				const id = arrString[1];

				return {
					idValue: data[key] ? data[key] : 0,
					idFlexField: Number(id),
				};
			});
		newFlexField.map(flexField => {
			flexField.selectedOption[0] = {
				value: flexValues.find(value => value.idFlexField === flexField.value).idValue,
				label: ""
			}
		})
		setFlexFieldValues(flexFieldValues.map(flexField => {
			return ({
				...flexField,
				selectedOption: []
			})
		}))
		const newData: DistributionFormData = {
			key: Number(moment().milliseconds()),
			distributionValue: data.distributionValue,
			field: { value: data.field, label: getLabelOfOptionsById(fieldOptions, data.field) },
			action: { value: data.action, label: getLabelOfOptionsById(actionsOptions, data.action) },
			businessUnitIds: data.businessUnitIds?.map(id => {
				return { value: id, label: getLabelOfOptionsById(businessUnitOptions, id) }
			}),
			costCategoryIds: data.costCategoryIds?.map(id => {
				return { value: id, label: getLabelOfOptionsById(costCategoryOptions, id) }
			}),
			costCenterIds: data.costCenterIds?.map(id => {
				return { value: id, label: getLabelOfOptionsById(costCenterOptions, id) }
			}),
			distributionPeriods: data.distributionPeriods?.map(id => {
				return { value: id[0], label: getLabelOfOptionsById(monthOptions, id[0]) }
			}),
			professionalCategoryIds: data.professionalCategoryIds?.map(id => {
				return { value: id, label: getLabelOfOptionsById(professionalCategoryOptions, id) }
			}),
			flexField: newFlexField
		}
		setDistributionList([...distributionList, newData]);
		form.resetFields();
	}

	const onCheckAllMonthChange = (e: CheckboxChangeEvent) => {
		form.setFieldsValue({ distributionPeriods: e.target.checked ? monthOptions.map(month => [month.value]) : [] })
	};

	const dropdownRender = (menus: React.ReactNode) => (
		<div>
			<div id="dist-cascader-form" style={{ background: '#F1F2F3' }}>
				<Checkbox indeterminate={indeterminate} onChange={onCheckAllMonthChange} checked={checkAllMonth}>
					{i18n.t("select_all")}
				</Checkbox>
			</div>
			{menus}
		</div>
	);

	return (
		<div className="distribution-form-container">
			<Form
				requiredMark={false}
				style={{
					display: "flex",
					flexDirection: "column",
				}}
				layout="vertical"
				form={form}
				name="distribution-values-form"
				onFinish={handleCollabDistribution}
			>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item
							name="field"
							label={i18n.t("human_resources.indexing_fields")}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Select
								options={fieldOptions}
								placeholder={i18n.t("select")}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="businessUnitIds"
							label={i18n.t("business_unit")}>
							<Select
								placeholder={i18n.t("select")}
								options={businessUnitOptions}
								mode="multiple"
								{...selectProps}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="costCenterIds"
							label={i18n.t("cost_center")}>
							<Select
								placeholder={i18n.t("select")}
								options={costCenterOptions}
								mode="multiple"
								{...selectProps}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="professionalCategoryIds"
							label={i18n.t("professional_category.professional_category_name")}>
							<Select
								placeholder={i18n.t("select")}
								options={professionalCategoryOptions}
								mode="multiple"
								{...selectProps}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="costCategoryIds"
							label={i18n.t("cost_category")}>
							<Select
								placeholder={i18n.t("select")}
								options={costCategoryOptions}
								mode="multiple"
								{...selectProps}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="distributionPeriods"
							label={i18n.t("human_resources.distribution_months")}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Cascader
								dropdownClassName="dist-form-cascader-dropdown"
								placeholder={i18n.t("select")}
								options={monthOptions}
								multiple
								dropdownRender={dropdownRender}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="distributionValue"
							label={i18n.t("human_resources.distribution_value")}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<InputNumber
								onChange={value => {
									return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
										notation: "compact",
										currency: "BRL"
									}).format(Number(value)))
								}}
								controls={false}
								decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
								width={"100%"}
								precision={2}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							label={i18n.t("actions")}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="action"
						>
							<Select
								placeholder={i18n.t("select")}
								options={actionsOptions}
							/>
						</Form.Item>
					</Col>
					{
						flexFieldValues.map((flexField) => {
							return (
								<Col key={flexField.fieldCode} span={6}>
									<Form.Item
										label={flexField.label}
										name={`flexField-${flexField.value}-${flexField.fieldCode}`}
									>
										{
											flexField.selectedOption[0]?.value ?
												< Select
													placeholder={i18n.t("select")}
													options={flexField.children}
													mode="multiple"
													defaultValue={flexField.selectedOption[0]?.value ? flexField.selectedOption[0].value : ""}
													{...selectProps}
												/>
												:
												< Select
													placeholder={i18n.t("select")}
													options={flexField.children}
													mode="multiple"
													{...selectProps}
												/>
										}
									</Form.Item>
								</Col>
							)
						})

					}
					<Col span={6} className="gs-flex align-center">
						<Button
							htmlType="submit"
							type="default"
							className="gs-secondary-button"
							icon={<Icon icon="akar-icons:circle-plus-fill" />}
						>
							{i18n.t('add')}
						</Button>
					</Col>
				</Row>

			</Form>
		</div >
	)
}