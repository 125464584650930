import { Icon } from "@iconify/react";
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Tooltip } from "antd";
import { IndexerResponse } from "module/budget/pages/budgetProjection/IBudgetProjection";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { IndexerType } from "module/plan/pages/groupers/IProjectionGroupers";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import i18n, { languages } from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { selectProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFieldFilterResponse, FlexFieldValueListBean, ICurrency, Options } from "util/types/types";
import ConfirmationModal from "../../components/ConfirmationModal";
import { EventData, ICopyType, IFlexFieldValues, IPeriodColumn, IPeriodValue, PlanningEvent } from "../../IProjectionModeling";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { IConditionOperator, IConditionsForm, ListConditions } from "./IStepOne";
import ConditionLine from "./components/ConditionLine";
import { IStepOneProps } from "../stepTwo/IStepTwo";
import { Loading } from "components/loading/Loading";
import { useUserContext } from "context/UserContext";

export default function StepOne({ setPeriodColumns, setPeriodValues }: IStepOneProps) {
	const {
		form,
		ledgerList,
		setStepOneListConditions,
		stepOneListConditions,
		grouperData,
		setEventSelected,
		formula,
		setFormula,
		isNewProjection
	} = useProjectionModelingContext();
	const { userInfo: { currencyId } } = useUserContext();
	const [isFlexFieldValuesLoading, setIsFlexFieldValuesLoading] = useState(true);
	const grouperDataFomatted: IModalityFilter[] = grouperData?.map(({ id, description, externalCode }) => {
		return {
			label: `${externalCode} - ${description}`,
			value: id,
		};
	});
	const copyTypeOptions: GenericSelection[] = [
		{
			label: i18n.t("simple_copy"),
			value: ICopyType.LAST_YEAR_ENTIRE_COPY,
		},
		{
			label: i18n.t("final_balance_copy"),
			value: ICopyType.LAST_MONTH_COPY,
		},
	];
	const isIndexerGrouper: boolean = Form.useWatch("isIndexerGrouper", form);
	const indexerTypeList: GenericSelection[] = [
		{
			label: i18n.t("projection_grouper.none"),
			value: IndexerType.NONE,
		},
		{
			label: i18n.t("projection_grouper.simple"),
			value: IndexerType.SIMPLE,
		},
		{
			label: i18n.t("projection_grouper.accumulated"),
			value: IndexerType.ACCUMULATED,
		},
	];
	const [flexFieldValues, setFlexFieldValues] = useState<IFlexFieldValues[]>([]);
	const [filteredFlexFieldValues, setFilteredFlexFieldValues] = useState<IFlexFieldValues[]>([]);
	const [eventData, setEventData] = useState<EventData[]>([]);
	const [conditionsForm] = Form.useForm();
	const [flexFieldList, setFlexFieldList] = useState<IModalityFilter[]>([]);
	const levelSelected = Form.useWatch("levels", conditionsForm);
	const event = Form.useWatch("event", form);
	const initialDate = Form.useWatch("initialDate", form);
	const endDate: moment.Moment = Form.useWatch("endDate", form);
	const [allCurrencies, setAllCurrencies] = useState<Options[]>([]);
	const [indexerList, setIndexerList] = useState<IModalityFilter[]>([]);
	const [conditionOperationSelected, setConditionOperationSelected] = useState<IConditionOperator>();
	const [lastIndexer, setLastIndexer] = useState<{ indexerId: number; indexerType: IndexerType }>();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [previousValue, setPreviousValue] = useState<string>();

	useEffect(() => {
		ServiceCaller.doRequest(
			{
				url: `/budget-base/currency-conversion/find-all-with-description?locale={locale}`,
				type: RequestType.GET,
			},
			(data: ICurrency[]) => {
				setAllCurrencies(
					data.map((currency) => {
						return {
							label: currency.name,
							value: currency.id,
						};
					})
				);
			}, handleErrorRequest
		);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/planning/projection/get-events`,
		}, onLoadEvent);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flex-field-value/find-all-flexfield-without-security?flexFieldId=0`,
		}, getFlexFieldValues.bind(this));

		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/budget-base/indexer`,
			},
			(data: IndexerResponse[]) => {
				const newIndexer: IModalityFilter[] = data.map(({ description, externalCode, id }) => {
					return {
						label: `${externalCode} - ${description}`,
						value: id,
					};
				});

				setIndexerList(newIndexer);
			}
		);
	}, [])

	useEffect(() => {
		if (allCurrencies.length > 0) {
			const currencyId: number = form.getFieldValue("currency");
			const value: number = allCurrencies.filter((item) => item.value == currencyId)[0]?.value;
			if (value) {
				form.setFieldValue("currency", value);
			}
		}
	}, [allCurrencies]);

	useEffect(() => {
		if (!event || event === previousValue) return;
		if ((formula.expressions.length || stepOneListConditions.length) && previousValue) {
			setShowModal(true);
		} else {
			handleSelectEvent();
			loadFlexFields(event);
		}

	}, [event])

	useEffect(() => {
		if (levelSelected) {
			const filteredValues = flexFieldValues.filter((item) => item.flexFieldId === levelSelected);
			setFilteredFlexFieldValues(filteredValues);
		}
	}, [levelSelected, flexFieldValues]);

	useEffect(() => {
		if (endDate && initialDate) {
			const difference = endDate.month() - initialDate.month();
			const values: IPeriodValue = {};
			const columns: IPeriodColumn[] = [];

			const extraPeriods: number = 6;
			for (let i = 0; i <= difference + extraPeriods; i++) {
				columns.push({
					title: `Periodo ${i + 1}`,
					dataIndex: `period_${i + 1}_value`,
					key: i,
				});
				values[`period_${i + 1}_value`] = "0,00";
			}

			setPeriodValues([values]);
			setPeriodColumns(columns);
		}
	}, [endDate, initialDate]);

	useEffect(() => {
		if (indexerList.length === 0) return;

		const indexerId = form.getFieldValue("indexerId");
		const indexerType = form.getFieldValue("indexerType");
		setLastIndexer({
			indexerId: indexerId,
            indexerType: indexerType,
		});
		
		if (!indexerId && !indexerType) {
			const grouperId = form.getFieldValue("grouper");
			handleCheckboxGrouper(grouperId);
		} else {
			form.setFieldsValue({
                isIndexerGrouper: false,
            });
		}
	}, [indexerList])

	function getFlexFieldValues(data: FlexFieldValueListBean[] = []) {
		const flexFieldValues: IFlexFieldValues[] = data.map((item) => {
			const label: string = `${item.externalCode} - ${item.description}`;
			return {
				value: item.id,
				flexFieldId: item.flexFieldId,
				label,
			};
		});

		setFlexFieldValues(flexFieldValues);
		setIsFlexFieldValuesLoading(false);
	}

	function validateAndFilterConditions(
		currentConditions: ListConditions[],
		newConditions: ListConditions[]
	): ListConditions[] {
		const isEqualCondition = (currentCond: ListConditions, newCond: ListConditions): boolean => {
			return (
				currentCond.levels === newCond.levels &&
				currentCond.levelsValue === newCond.levelsValue &&
				currentCond.operation === newCond.operation
			);
		};

		const filteredNewConditions: ListConditions[] = newConditions.filter(
			(newCond) => !currentConditions.some((currentCond) => isEqualCondition(currentCond, newCond))
		);

		return [...currentConditions, ...filteredNewConditions];
	}

	function onLoadEvent(data: PlanningEvent) {
		const event: EventData[] = Object.keys(data).map((key) => {
			const label: string = i18n.t(`modules.${data[key].toLowerCase().replace("_", ".")}`);
			return { value: key, label };
		});

		setEventData(event);
	}

	function handleIncludeConditions({ levels, levelsValue }: IConditionsForm) {
		setStepOneListConditions((prevConditions) => {
			const newConditions: ListConditions[] = levelsValue.map((value) => {
				return {
					levelsValue: value,
					levels,
					operation: conditionOperationSelected
				};
			});

			return validateAndFilterConditions(prevConditions, newConditions);
		});
		setFilteredFlexFieldValues([]);
		conditionsForm.resetFields();
	}

	function handleRemoveCondition(index) {
		setStepOneListConditions(stepOneListConditions.filter((_, itemIndex) => itemIndex !== index));
	}

	function handleDragEnd() { }

	function handleSelectEvent() {
		conditionsForm.resetFields(["levels", "levelsValue"]);
		setConditionOperationSelected(null);

		setEventSelected(event);
		setPreviousValue(event);
	}

	function loadFlexFields(module: string) {
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/budget-base/flex-field/find-all-by-module?module=${module}`,
			},
			getFlexFields.bind(this)
		);
	}

	function getFlexFields(data: FlexFieldFilterResponse[]) {
		if (data.length === 0) {
			setFlexFieldList([]);
			return;
		}

		const flexFields: IModalityFilter[] = data.map((item) => {
			const label: string = `${item.externalCode} - ${item.description}`;
			return {
				value: item.id,
				label,
			};
		});

		setFlexFieldList(flexFields);
	}

	const onClickConditionOperator = (operator: IConditionOperator) => {
		setConditionOperationSelected(operator);
	}

	const getConditionOperatorClassName = (operator: IConditionOperator): string => {
		return operator === conditionOperationSelected ? "button-active" : "";
	}

	function handleConfirmOk() {
		setShowModal(false);
		setFormula((state) => ({
			...state,
			expressions: [],
		}));

		setStepOneListConditions([]);

		loadFlexFields(event);
		setEventSelected(event);
		setPreviousValue(event);
	}

	function handleCancel() {
		setShowModal(false);
		if (previousValue !== null) {
			form.setFieldsValue({ event: previousValue });
		}
	}

	const handleCheckboxGrouper = (grouperId) => {
		if (!grouperId) {
			form.setFieldsValue({
                indexerId: lastIndexer?.indexerId || null,
                indexerType: lastIndexer?.indexerType || null,
                isIndexerGrouper: false,
            });
		}
		const grouperSelected = grouperData.find((item) => item.id === grouperId);
        const indexerForGrouper = indexerList.find(({ value }) => value === grouperSelected?.indexerId);

        if (indexerForGrouper) {
            form.setFieldsValue({
                indexerId: indexerForGrouper.value,
                indexerType: grouperSelected.indexerType,
				isIndexerGrouper: true,
            });
		} else {
			form.setFieldsValue({
                indexerId: lastIndexer?.indexerId || null,
                indexerType: lastIndexer?.indexerType || null,
                isIndexerGrouper: false,
            });
		}
	};
	const handleCheckboxIndexer = (e) => {
		const isChecked = e.target.checked;

		if (isChecked) {
            setLastIndexer({
                indexerId: form.getFieldValue("indexerId"),
                indexerType: form.getFieldValue("indexerType"),
            });
		}

		handleCheckboxGrouper(isChecked ? form.getFieldValue("grouper") : null);
	}

	return (
		<Row className="step-one-content">
			<Col span={12} className="fields-container-form1">
				<Form form={form} name="new-account-form" layout="vertical" requiredMark={false}>
					<Row gutter={10}>
						<Col span={12}>
							<Form.Item
								name="description"
								label={i18n.t<string>("description")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								required={true}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="grouper"
								label={i18n.t<string>("grouper")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							>
								<Select
									key="input-grouper"
									id="input-grouper"
									options={grouperDataFomatted}
									placeholder={i18n.t<string>("select")}
									onChange={handleCheckboxGrouper}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col span={8}>
							<Form.Item
								name="event"
								label={i18n.t<string>("event")}
								rules={[
									{ required: true, message: i18n.t<string>("required_field") },
								]}
							>
								<Select
									id="input-event"
									options={eventData}
									placeholder={i18n.t<string>("select")}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="ledgerId"
								label={i18n.t<string>("ledger_book")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								required={true}
								{...(isNewProjection && ledgerList?.length === 1) && { initialValue: ledgerList[0].value }}
							>
								<Select
									options={ledgerList}
									placeholder={i18n.t<string>("select")}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="calculationOrder"
								label={i18n.t<string>("calculation_order")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							>
								<InputNumber className="input-order" controls={false} />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<h4 className="gs-tag blue">{i18n.t<string>("validity")}</h4>
					</Row>
					<Row gutter={10}>
						<Col span={12}>
							<Form.Item
								name="initialDate"
								label={i18n.t<string>("from")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							>
								<DatePicker
									className="gs-date-picker"
									locale={languages[i18n.language]}
									format={"DD/MM/YYYY"}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="endDate"
								label={i18n.t<string>("until")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							>
								<DatePicker
									className="gs-date-picker"
									locale={languages[i18n.language]}
									format={"DD/MM/YYYY"}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col span={12}>
							<Form.Item
								name="currency"
								label={i18n.t<string>("conversion_currency")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								{...(isNewProjection && currencyId) && { initialValue: currencyId }}
							>
								<Select
									id="input-currency"
									options={allCurrencies}
									placeholder={i18n.t<string>("select")}
									{...selectProps}
								></Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="projectionCopyType"
								label={i18n.t<string>("copy_type")}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								initialValue={ICopyType.LAST_YEAR_ENTIRE_COPY}
							>
								<Select
									id="projectionCopyType"
									options={copyTypeOptions}
									placeholder={i18n.t<string>("select")}
									{...selectProps}
								></Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col>
							<h4 className="gs-tag blue">{i18n.t("indexer")}</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Item name="isIndexerGrouper" initialValue={isIndexerGrouper} valuePropName="checked">
								<Checkbox onChange={handleCheckboxIndexer}>{i18n.t("indexer_agrouper")}</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col span={12}>
							<Form.Item label={i18n.t("projection_grouper.indexer_type")} name="indexerType">
								<Select disabled={isIndexerGrouper} options={indexerTypeList} allowClear {...selectProps} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={i18n.t("indexer")} name="indexerId">
								<Select disabled={isIndexerGrouper} options={indexerList} allowClear {...selectProps} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
			<Col span={12} className="conditions-form-content">
				<Form form={conditionsForm} onFinish={handleIncludeConditions} layout="vertical" requiredMark={false}>
					<h4 className="conditions-title">{i18n.t<string>("conditions")}</h4>
					<div className="fields-container">
						<Row gutter={10}>
							<Col span={9}>
								<Form.Item
									name="levels"
									label={i18n.t<string>("levels")}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select
										id="input-levels-condition"
										options={flexFieldList}
										placeholder={i18n.t<string>("select")}
										{...selectProps}
									></Select>
								</Form.Item>
							</Col>
							<Col className="condition-operator" span={1}>
								<Tooltip title={i18n.t("revenue.operations.EQUALS")}>
									<Button
										onClick={() => onClickConditionOperator(IConditionOperator.EQUALS)}
										icon={<Icon icon="tabler:equal" />}
										className={getConditionOperatorClassName(IConditionOperator.EQUALS)}
									/>
								</Tooltip>
							</Col>
							<Col className="condition-operator" span={1}>
								<Tooltip title={i18n.t("revenue.operations.DIFFERENT")}>
									<Button
										onClick={() => onClickConditionOperator(IConditionOperator.NOT_EQUALS)}
										icon={<Icon icon="fluent:equal-off-12-regular" />}
										className={getConditionOperatorClassName(IConditionOperator.NOT_EQUALS)}
									/>
								</Tooltip>
							</Col>
							<Col span={9}>
								<Form.Item
									name="levelsValue"
									label={i18n.t<string>("levels_value")}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select
										id="input-levels-value-condition"
										options={filteredFlexFieldValues}
										placeholder={i18n.t<string>("select")}
										mode="multiple"
										loading={isFlexFieldValuesLoading}
										{...selectProps}
									></Select>
								</Form.Item>
							</Col>
							<Col span={4} className="plus-button-container">
								<Button
									htmlType="submit"
									className="plus-button gs-secondary-button"
									icon={<Icon icon="akar-icons:circle-plus-fill" />}
									disabled={!conditionOperationSelected}
								>
									{i18n.t<string>("add")}
								</Button>
							</Col>
						</Row>
					</div>
				</Form>
				<div className="step-one-list-container">
					<h3 className="gs-tag">{i18n.t<string>("detail_filters")}</h3>
					{isFlexFieldValuesLoading ?
						<Loading
							caption=" "
							styles={{ marginLeft: "50px", height: "70px", alignItems: "start" }} />
						:
						stepOneListConditions.length > 0 ? (
							<DragDropContext onDragEnd={handleDragEnd}>
								<Droppable
									droppableId="attribute_parameterization-new-rule-modal-conditions-workarea"
									direction="vertical"
								>
									{(provided) => (
										<div
											className="attribute_parameterization-new-attribute-modal-rules-workarea"
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{stepOneListConditions.map((condition, index) => {
												return (
													<ConditionLine
														key={index}
														index={index}
														selectedLevel={
															flexFieldList.find((item) => item.value === condition.levels)
																?.label
														}
														operation={condition.operation}
														selectedLevelValue={
															flexFieldValues.find(
																(item) => item.value === condition.levelsValue
															)?.label
														}
														handleRemove={(index) => handleRemoveCondition(index)}
													/>
												);
											})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						) : (
							<span>{i18n.t<string>("no_conditions_filters")}</span>
						)}
				</div>
			</Col>
			<ConfirmationModal
				isModalVisible={showModal}
				setIsModalVisible={setShowModal}
				message={"Ao alterar o evento, a fórmula e o Filtro de flexíveis serão excluidos, tem certeza que deseja continuar?"}
				handleConfirmOk={handleConfirmOk}
				handleConfirmCancel={handleCancel}
			/>
		</Row>
	);
}
