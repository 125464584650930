import { Input, Select } from 'antd';
import i18n from 'util/base/i18n';

const { Search } = Input;

interface Props {
    onSearch: (value: string, type: 'tree' | 'account') => void;
    currentTree: 'account-hierarchy' | 'cost-center-hierarchy';
    searchValue: string; 
    searchType: 'tree' | 'account'; 
}

export default function MultipleSearchGroupings({ onSearch, currentTree, searchValue, searchType }: Props) {
    
    const handleSearchTypeChange = (value: 'tree' | 'account') => {
        onSearch('', value);
    };

    const handleSearch = (value: string) => {
        onSearch(value, searchType);
    };

    return (
        <Input.Group
         compact 
         className='multiple-search-groupings'
         >
            <Select
                className="select-search-multiple-search"
                value={searchType}
                onChange={handleSearchTypeChange}
            >
                <Select.Option value="tree">{`${i18n.t('search_by')} ${i18n.t('search_grouper')}`}</Select.Option>
                <Select.Option value="account">{`${i18n.t('search_by')} ${currentTree === "account-hierarchy" ? i18n.t("search_account_linked") : i18n.t("search_cost_center_linked")}`}</Select.Option>
            </Select>
            <Search
                placeholder={i18n.t('search')}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                onSearch={handleSearch}
            />
        </Input.Group>
    );
}