import { Button, Form, Input, Layout, Select, Switch } from "antd";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { ManagementAccountTableProps } from "../IManagementAccount";

export default function ManagementAccountTable({
    form,
    tableData,
    handleSubmit,
    handleClickClear,
    setIsFetchingTableData,
    setIsFetchingTree,
    validateExternalCode
}: ManagementAccountTableProps) {
    const rulesToInputText = [
        { required: true, message: i18n.t<string>("required_field") },
        () => ({
            validator(_, value: string) {
                return validateExternalCode(value,
                    i18n.t<string>("the_external_code_field_cannot_repeat"));
            },
        })
    ];

    function elemenType(type, obj) {
        if (type === "text") {
            return <Input placeholder={i18n.t("type_here")} />;
        } else if (type === "switch") {
            return <Switch checked={obj.value} />;
        } else {
            return (
                <Select
                    loading={obj.isFetching}
                    options={[{
                            label: i18n.t("select"),
                            value: 0
                        }, ...obj.options]}
                    {...selectProps}
                />
            );
        }
    }

    return (
        <Layout.Content>
            <div>
                <Form
                    form={form}
                    name="new-account-form"
                    onFinish={(data) => {
                        setIsFetchingTree(true);
                        setIsFetchingTableData(true);
                        handleSubmit(data);
                    }}
                    requiredMark={false}
                >
                    <table>
                        <tbody>
                            {Object.keys(tableData).map((keyObj) => {
                                if (typeof tableData[keyObj] === "object") {
                                    const { type, className } = tableData[keyObj];

                                    return (
                                        <tr key={keyObj}>
                                            <td className={className ? className : ""}>{i18n.t(keyObj)}</td>
                                            <td>
                                                {type !== "none" ? (
                                                    <Form.Item
                                                        name={keyObj}
                                                        valuePropName={type === "switch" ? "checked" : undefined}
                                                        rules={
                                                            type === "text"
                                                                ? keyObj === "code"
                                                                    ? rulesToInputText
                                                                    : [rulesToInputText[0]]
                                                                : []
                                                        }
                                                    >
                                                        {elemenType(type, tableData[keyObj])}
                                                    </Form.Item>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                    <footer className="gs-flex align-center jc-end">
                        <Button onClick={handleClickClear} type="text">
                            {i18n.t("undo_changes")}
                        </Button>
                        <Button className="gs-main-button" htmlType="submit">
                            {i18n.t("save")}
                        </Button>
                    </footer>
                </Form>
            </div>
        </Layout.Content>
    );
}
