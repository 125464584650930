import { Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import i18n from "util/base/i18n";
import { TableData } from "../IProjectionModeling";
import { useProjectionModelingContext } from "../context/ProjectionModelingContext";
import { Key } from "react";

export default function ProjectionModelingTable() {
	const {
		isGridFetching,
		setSelectedRowKeys,
		setSelectedRows,
		selectedRowKeys,
		filterdTableData,
		tableColumns,
		isNewProjection
	} = useProjectionModelingContext();
	const rowSelection: TableRowSelection<any> = {
		selectedRowKeys,
		onChange,
	};

	function onChange(keys: Key[], rows: TableData[]) {
		setSelectedRows(rows);
		setSelectedRowKeys(keys);
	}

	return (
		<>
			{
				!isNewProjection &&
				<Table
					loading={{
						spinning: isGridFetching,
						tip: `${i18n.t<string>("loading")}...`,
					}}
					className="gs-table"
					dataSource={filterdTableData}
					columns={tableColumns}
					rowSelection={rowSelection}
					pagination={{
						showSizeChanger: true,
						hideOnSinglePage: filterdTableData?.length < 10 ? true : false,
						defaultPageSize: 20,
					}}
					bordered
				/>
			}
		</>
	);
}
