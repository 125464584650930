import { Modal, Form, Select, Input, Checkbox } from "antd";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { INewLevelModal } from "../IGroupings";
import { useWatch } from "antd/es/form/Form";

export function NewLevelModal({
    groupSelected, 
    open, 
    handleCancel, 
    form, 
    handleNewLevel, 
    isSelectDisabled, 
    lookupLevels, 
    checkedNodeKeys,
    currentIsSpendingPackage
}: INewLevelModal) {
    const [isParentSelectedValid, setIsParentSelectedValid] = useState(false);

    const selectedOriginLevel = useWatch("originLevel", form);

    function validateExternalCode(value: string, tableList: any[], message: string, idOfTableList?: Key) {
		if (!value) return Promise.resolve();

		function getAllExternalCodes(objects) {
			let externalCodeList = [];
			objects.forEach(obj => {
				if (obj.key !== idOfTableList) {
					externalCodeList.push(obj.externalCode);
					if(obj.children) {
						getAllExternalCodes(obj.children);
					}
				}
			});
			return externalCodeList;
		}

		const externalCodeArray = getAllExternalCodes(tableList);

		if(externalCodeArray.includes(value)) {
			return Promise.reject(new Error(message));
		}

		return Promise.resolve();
	}

    useEffect(() => {
        if(selectedOriginLevel) {
            setIsParentSelectedValid(verifyIfHasPackageLevel(selectedOriginLevel));
        } else {
            setIsParentSelectedValid(false);
        }

    }, [selectedOriginLevel])

    function verifyIfHasPackageLevel(selectedOriginLevel: number): boolean {
        let hasPackageLevel = false;
        const selectedItem = lookupLevels.find(item => item.key === selectedOriginLevel);

        if (selectedItem && selectedItem.children) {
            for (let child of selectedItem.children) {
                if (child.isSpendingPackageLevel === true) {
                    hasPackageLevel = true;
                    break;
                }
            }
        }

        return hasPackageLevel;
    }

    return (
        <Modal
            width={550}
            centered={true}
            title={`${i18n.t("register_level")} (${groupSelected})`}
            open={open}
            onCancel={handleCancel}
            afterClose={() => form.resetFields()}
            className="gs-modal"
            cancelText={i18n.t<string>("close")}
            okText={i18n.t<string>("save")}
            okButtonProps={{ htmlType: "submit", form: "new-level" }}
            destroyOnClose={true}
            wrapClassName="budget-groupings"
            style={{ marginBottom: '120px' }}
        >
            <Form
                form={form}
                name="new-level"
                onFinish={handleNewLevel}
                initialValues={{originLevel: 0}}
            >
                <div className="new-level-origin">
                    <label htmlFor="origin-level">
                        {i18n.t("origin_level")}
                    </label>
                    <Form.Item
                        name={"originLevel"}
                        rules={[{
                            required: true,
                            message: i18n.t<string>("required_field")
                        }]}
                    >
                        <Select disabled={isSelectDisabled} style={{ width: 485 }} placeholder={i18n.t("select")} id="origin-level" options={[{
                            title: i18n.t("root_level"),
                            children: null,
                            parent: 0,
                            key: 0
                        }, ...lookupLevels]} fieldNames={{
                            value: "key",
                            label: "title"
                        }}
                            {...selectProps}
                        />
                    </Form.Item>
                </div>
                <div className="new-level-inputs">
                    <div>
                        <label htmlFor="external-code">
                            {i18n.t("external_code")}
                        </label>
                        <Form.Item
                            name={"externalCode"}
                            rules={[
                                {
                                    required: true,
                                    message: i18n.t<string>("required_field")
                                },
                                () => ({
                                    validator(_, value: string) {
                                        return validateExternalCode(value, lookupLevels,
                                            i18n.t<string>("the_external_code_field_cannot_repeat"),
                                            checkedNodeKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input
                                id="external-code"
                                placeholder={i18n.t("type_here")}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="description">
                            {i18n.t("description")}
                        </label>
                        <Form.Item
                            name={"description"}
                            rules={[{
                                required: true,
                                message: i18n.t<string>("required_field")
                            }]}
                        >
                            <Input
                                id="description"
                                placeholder={i18n.t("type_here")}
                            />
                        </Form.Item>
                    </div>
                </div>
                <Form.Item name={"showInExpenses"} valuePropName="checked">
                    <Checkbox disabled={(isSelectDisabled ? !currentIsSpendingPackage : !isParentSelectedValid)}>{i18n.t("show_in_expenses")}</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}            