import { IMainColumn, RevenueItemTable, SelectedViewType } from "../../IRevenue";
import { BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";

import { Key } from "react";
import moment from "moment";

import ColumnGrid from "../column/ColumnGrid";
import i18n from "util/base/i18n";
import MainColumnGrid from "../column/MainColumnGrid";
import { FunctionalityPermissions, ModuleType } from "util/types/types";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";

export function buildColumnsByViewType(
    selectedViewType: SelectedViewType,
    monthsColumns: string[],
    periodAccomplished: moment.Moment,
    calculateCellClassName: (record: RevenueItemTable, col, selectedRow) => string,
    selectedRow: Key,
    updateTreeGrid: (closeFetching: boolean) => void,
    setHasSavedValue: (value: boolean) => void,
    obj: IMainColumn,
    budgetPeriodDates: BudgetPeriodDates,
    functionalityPermissions: FunctionalityPermissions
) {
    const columnsList: any = [];
    const columnsListAccomplished: any = [];

    monthsColumns.forEach((month) => {
        const momentMonth = moment(month, 'YYYY-MM')
        const currentBudgetPeriod = budgetPeriodDates.period.find(budDate => budDate.year === moment(month, 'YYYY-MM').year())

        const isEditable = functionalityPermissions.edit && verifyBudgetPeriod(currentBudgetPeriod, momentMonth, budgetPeriodDates.localDate, ModuleType.REVENUE).isEditable;

        if (selectedViewType.value === "FORECAST") {
            let monthNow = moment(month);
            if (monthNow.isAfter(periodAccomplished) || !moment.isMoment(periodAccomplished)) {
                columnsList.push(ColumnGrid({ month: month, monthColumns: monthsColumns }, null, selectedViewType, periodAccomplished, isEditable));
            } else {
                columnsListAccomplished.push(ColumnGrid({ month: month, monthColumns: monthsColumns }, null, selectedViewType, periodAccomplished, isEditable));
            }
        } else {
            columnsList.push(ColumnGrid({ month: month, monthColumns: monthsColumns }, null, selectedViewType, periodAccomplished, isEditable));
        }
    });

    let columnTitle = {
        title: selectedViewType.value === "FORECAST" ? i18n.t<string>("planned") : selectedViewType.label,
        width: 90,
        onHeaderCell: () => {
            return {
                style: {
                    textAlign: "center"
                }
            }
        },
        children: columnsList.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: RevenueItemTable) => ({
                    record,
                    className: col.className,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    width: 'max-content',
                    periodAccomplished,
                    monthsColumns,
                    updateTreeGrid,
                    setHasSavedValue
                }),
            };
        })
    };

    let columns: any = [MainColumnGrid(obj)];
    if (selectedViewType.value === "FORECAST") {
        let columnTitlesAccomplished = {
            title: i18n.t<string>("accomplished"),
            width: 90,
            onHeaderCell: () => {
                return {
                    style: {
                        backgroundColor: "#004D7B",
                        textAlign: "center"
                    }
                }
            },
            children: columnsListAccomplished.map(col => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,
                    onCell: (record: RevenueItemTable) => ({
                        record,
                        className: calculateCellClassName(record, col, selectedRow),
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        width: 'max-content',
                        periodAccomplished,
                        monthsColumns,
                        updateTreeGrid,
                        setHasSavedValue
                    }),
                };
            })
        }

        if (columnsListAccomplished.length !== 0 && moment.isMoment(periodAccomplished)) {
            columns.push(columnTitlesAccomplished);
        }

        if (columnsList.length !== 0) {
            columns.push(columnTitle);
        }
    } else {
        columns.push(columnTitle);
    }

    columns.push(ColumnGrid(null, { selectedRow: selectedRow, monthsColumns: monthsColumns }, null, periodAccomplished, true));

    return columns;
}

