import { Icon } from "@iconify/react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Modal,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
    Tooltip,
    notification,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import { ImageBox } from "components/imageBox/ImageBox";
import { evaluate } from "mathjs";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { validateFormField } from "util/functions/validateFormField";
import { selectProps } from "util/props/props";
import { IAdditionalFieldsForm, ITableData, fieldItem } from "../IAdditionalFields";
import { FormulaAreaFields } from "./FormulaAreaFields";
import { OperationsSelectFields } from "./operators/OperationsSelectFields";

interface Props {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    isNewAdditionalFields: boolean;
    tableData: ITableData[];
    handleSave: (data: IAdditionalFieldsForm[]) => void;
    form: any;
    lineChecked: Key | number;
    selectedRowsKeys: Key[];
    fieldsFormula: {label: string, value: string, options?: any}[];
    setFieldsFormula: (value: any) => void;
    formula: any[];
    setFormula: (value: any) => void;
    isFormula: boolean;
    setIsFormula: (value: boolean) => void;
}
export default function AdditionalFieldsModal({
    isModalVisible,
    setIsModalVisible,
    isNewAdditionalFields,
    tableData,
    handleSave,
    form,
    lineChecked,
    selectedRowsKeys,
    fieldsFormula,
    setFieldsFormula,
    formula,
    setFormula,
    isFormula,
    setIsFormula,
}: Props) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [additionalFieldsList, setAdditionalFieldsList] = useState<IAdditionalFieldsForm[]>([]);
    const [isIncidenceDisabled, setIsIncidenceDisabled] = useState(true);
    const [isBaseDisabled, setIsBaseDisabled] = useState(false);
    const [isConfigurationDisabled, setIsConfigurationDisabled] = useState(true);
    const [lastIndex, setLastIndex] = useState<number>(0);
    const [selectedOption, setSelectedOption] = useState<fieldItem>(null);

    const isIncidenceSelectedOvertime = Form.useWatch("incidence", form)?.includes("OVERTIME");
    const isTypeSelectedPercentage = Form.useWatch("type", form)?.includes("PERCENTAGE");
    const isTypeSelectedValue = Form.useWatch("type", form)?.includes("NUMERIC");
    const isCalculable = Form.useWatch("configuration", form)?.includes("calculate_value");

    useEffect(() => {
        if (form.getFieldsValue("type")?.toString().includes("PERCENTAGE")) {
            setIsIncidenceDisabled(false);
        } else {
            setIsIncidenceDisabled(true);
        }
    }, [isNewAdditionalFields]);

    useEffect(() => {
        const lastItem = tableData[tableData.length - 1];
        if (lastItem && lastItem.order) {
            setLastIndex(lastItem.order);
        }
    }, [isModalVisible]);

    useEffect(() => {
        if (isIncidenceSelectedOvertime) {
            form.setFieldsValue({ base: form.getFieldValue("base")?.filter((value) => !value.includes("isOvertime")) });
        }
    }, [isIncidenceSelectedOvertime]);

    useEffect(() => {
        if (isTypeSelectedPercentage) {
            form.setFieldsValue({
                configuration: form.getFieldValue("configuration")?.filter((value) => !value.includes("phisical")),
            });
        }
    }, [isTypeSelectedPercentage]);

    useEffect(() => {
        if(isTypeSelectedValue){
            setIsIncidenceDisabled(true);
            setIsBaseDisabled(false);
            setIsConfigurationDisabled(false);
        }
        if(isNewAdditionalFields){
            form.setFieldsValue({configuration: ["calculate_value"]});
        }
    }, [isTypeSelectedValue])

    useEffect(() => {
        if(isFormula) {
            form.setFieldsValue({configuration: ["calculate_value", "formula"]});
        } 
    }, [isFormula])

    function handleEditAdditionalFields(additionalFields: IAdditionalFieldsForm) {
        setIsDisabled(true);
        form.setFieldsValue({
            active: additionalFields.active,
            externalCode: additionalFields.externalCode,
            fieldName: additionalFields.fieldName,
            type: additionalFields.type,
            incidence: additionalFields.incidence,
            base: additionalFields.base,
            configuration: additionalFields.configuration,
            formulaConfiguratuion: additionalFields.formulaConfiguration
        });
        const index = additionalFieldsList.indexOf(additionalFields);
        const updatedListToSave = [...additionalFieldsList];
        updatedListToSave.splice(index, 1);
        setAdditionalFieldsList(updatedListToSave);
        if (additionalFields.configuration.includes("formula")) {
            setIsFormula(true);
            setFormula(additionalFields.formulaUnprepared);
        }
    }

    function handleDeleteAdditionalFields(externalCode: string) {
        const index = additionalFieldsList.findIndex((c) => c.externalCode === externalCode);
        const updatedAdditionalFieldsList = [...additionalFieldsList];
        updatedAdditionalFieldsList.splice(index, 1);
        setAdditionalFieldsList(updatedAdditionalFieldsList);
    }

    function onFinishForm(data: IAdditionalFieldsForm) {
        const orderFinded = tableData.find((item) => item.id === selectedRowsKeys[0]);

        data.formulaUnprepared = formula;
        const preparedFormula = formula.map((item, index) => {
            if (item.type === "field") {
                return {
                    formulaOrder: index + 1,
                    fieldValue: item.content["id"],
                };
            }

            if(item.type === "value"){
                return {
                    formulaOrder: index + 1,
                    informedValue: item.informedValue,
                };
            };

            return {
                formulaOrder: index + 1,
                operationCalcType: item.content,
            };
        });

        if (isFormulaValid(preparedFormula)) {
            if (isFormula) {
                data.formulaItems = preparedFormula;
            }

            if (orderFinded && orderFinded.order) {
                data.order = orderFinded.order;
                setLastIndex(data.order);
            } else {
                data.order = lastIndex + 1;
                setLastIndex(data.order);
            }

            setIsDisabled(false);
            setIsFormula(false);
            setFormula([]);
            form.resetFields();
            setAdditionalFieldsList(isNewAdditionalFields ? [...additionalFieldsList, data] : []);
        } else {
            openInvalidFormulaNotification("topRight");
        }
    }

    function prepareFormula(data: IAdditionalFieldsForm) {
        const orderFinded = tableData.find((item) => item.id === selectedRowsKeys[0]);

        data.formulaUnprepared = formula;
        const preparedFormula = formula.map((item, index) => {
            if (item.type === "field") {
                return {
                    formulaOrder: index + 1,
                    fieldValue: item.content["id"],
                };
            }

            if(item.type === "value"){
                return {
                    formulaOrder: index + 1,
                    informedValue: item.informedValue,
                };
            };

            return {
                formulaOrder: index + 1,
                operationCalcType: item.content,
            };
        });

        if (isFormulaValid(preparedFormula)) {
            if (isFormula) {
                data.formulaItems = preparedFormula;
            }

            if (orderFinded && orderFinded.order) {
                data.order = orderFinded.order;
                setLastIndex(data.order);
            } else {
                data.order = lastIndex + 1;
                setLastIndex(data.order);
            }

            if (!isNewAdditionalFields) {
                handleSave([data]);
                return;
            }

            setIsDisabled(false);
            setIsFormula(false);
            setFormula([]);
            form.resetFields();
            setIsModalVisible(false);
            setAdditionalFieldsList([...additionalFieldsList, data]);
        } else {
            openInvalidFormulaNotification("topRight");
        }
    }

    function onCloseModal() {
        setIsModalVisible(false);
        setIsFormula(false);
        form.resetFields();
        setFormula([]);
        setAdditionalFieldsList([]);
    }

    function onChangeType({ target: { value } }: RadioChangeEvent) {
        if (value.includes("NUMERIC")) {
            setIsFormula(false);
            setIsIncidenceDisabled(true);
            setIsBaseDisabled(false);
            setIsConfigurationDisabled(false);
            form.resetFields(["incidence"]);
        } else {
            setIsFormula(false);
            setIsIncidenceDisabled(false);
            setIsBaseDisabled(false);
            setIsConfigurationDisabled(false);
        }
    }

    function addOperationToFormula(selectedOperation: string) {
        if (selectedOperation === "LEFT_PARENTHESIS" || selectedOperation === "RIGHT_PARENTHESIS") {
            setFormula([...formula, { type: "operator", content: selectedOperation }]);
        }

        if (selectedOperation === "VALUE") {
            setFormula([
                ...formula,
                { type: "value", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` },
            ]);
        }

        if (formula.length !== 0) {
            if (formula[formula.length - 1].type !== "operator") {
                setFormula([...formula, { type: "operator", content: selectedOperation }]);
            } else if (
                formula[formula.length - 1].content === "LEFT_PARENTHESIS" ||
                formula[formula.length - 1].content === "RIGHT_PARENTHESIS"
            ) {
                setFormula([...formula, { type: "operator", content: selectedOperation }]);
            }
        }
        
        setSelectedOption(null);
    }

    function removeItem(index) {
        if (formula.find(item => item.content.name === form.getFieldValue("fieldName"))) {
            if (!fieldsFormula.find(field => field.label === i18n.t("collaborators.additional_fields.current_field"))) {
                const fields: {label: string, value: string}[] = [{
                    label: i18n.t("collaborators.additional_fields.current_field"),
                    value: JSON.stringify({
                        id: 0,
                        type: "field",
                        name: form.getFieldValue("fieldName")
                    })
                }];

                fields.push(...fieldsFormula);
                setFieldsFormula(fields);
            }
        }

        setFormula((state) => state.filter((item, itemIndex) => itemIndex !== index));
        setSelectedOption(null);
    }

    function addFieldToFormula(field) {
        if (field.grouperId === 0) {
            setFormula([
                ...formula,
                {
                    key: field.id + "-" + Math.random(),
                    type: "field",
                    content: {
                        ...field,
                        name: i18n.t<string>(field.name),
                    },
                },
            ]);
            return;
        }

        if (formula.length === 0) {
            setFormula([
                ...formula,
                {
                    key: field.id + "-" + Math.random(),
                    type: "field",
                    content: field,
                },
            ]);
            return;
        }

        if (formula[formula.length - 1].type !== "field") {
            setFormula([
                ...formula,
                {
                    key: field.id + "-" + Math.random(),
                    type: "field",
                    content: field,
                },
            ]);
        }
    }

    function isFormulaValid(formula) {
        if (!isFormula) return true;

        const parsedFormula = formula.reduce((acc, current) => {
            if (current.fieldValue || current.fieldValue === 0) {
                return `${acc}1`;
            }

            if (current.operationCalcType) {
                switch (current.operationCalcType) {
                    case "LESS":
                        return `${acc} - `;
                    case "PLUS":
                        return `${acc} + `;
                    case "MULTIPLY":
                        return `${acc} * `;
                    case "DIVIDER":
                        return `${acc} / `;
                    case "LEFT_PARENTHESIS":
                        return `${acc} ( `;
                    case "RIGHT_PARENTHESIS":
                        return `${acc} ) `;
                }
            }

            if(current.informedValue) {
                return `${acc} ${current.informedValue}`;
            }

            return "";
        }, "");

        try {
            const result = Math.abs(evaluate(parsedFormula));

            if (result >= 0) {
                return true;
            }
        } catch {
            return false;
        }
    }

    function openInvalidFormulaNotification(placement: NotificationPlacement) {
        notification.error({
            message: `Erro: Fórmula inválida!`,
            description: "Verifique se a mesma está correta e tente novamente!",
            placement,
            duration: 3,
        });
    }

    function handleIsFormula(){
        setIsFormula(!isFormula);
    }

    function handleClickInfo({ target: { checked } }) {
        if(checked) {
            const fields: any = [{
                    label: i18n.t("collaborators.additional_fields.current_field"),
                    value: JSON.stringify({
                            id: 0,
                            type: "field",
                            name: i18n.t("collaborators.additional_fields.current_field")
                    })
                }];

            fields.push(...fieldsFormula);
            setFieldsFormula(fields);
        }else{
            const fields = fieldsFormula.filter(item => {
                if (item.options) {
                    return item.options.filter(option => JSON.parse(option.value).id);
                } else {
                    return JSON.parse(item.value).id !== 0;
                }
            });
            setFieldsFormula(fields);
        }
    }

    function handleCleanFormula(){
        setFormula([]);
        setSelectedOption(null);
    }

    return (
        <Modal
            title={
                isNewAdditionalFields
                    ? i18n.t("collaborators.additional_fields.new_additional_field")
                    : i18n.t("collaborators.additional_fields.edit_additional_field")
            }
            visible={isModalVisible}
            width="60%"
            onCancel={onCloseModal}
            cancelText={i18n.t<string>("cancel")}
            okText={isNewAdditionalFields ? i18n.t<string>("save") : i18n.t<string>("edit")}
            centered
            wrapClassName="budget-additional-fields-modal"
            okButtonProps={{
                htmlType: isNewAdditionalFields ? "button" : "submit",
                form: "additionalFields-form",
            }}
            className="additional-fields-modal"
            onOk={() => {
                if (isNewAdditionalFields) {
                    handleSave(additionalFieldsList);
                    setAdditionalFieldsList([]);
                } else {
                    form.validateFields().then(() => {
                        setIsDisabled(false);
                        prepareFormula(form.getFieldsValue());
                    });
                }
            }}
            destroyOnClose
        >
            <Form
                name="additionalFields-form"
                className="form-content"
                layout="vertical"
                form={form}
                requiredMark={false}
                onFinish={onFinishForm}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            label={i18n.t("additional_field_name")}
                            name="fieldName"
                            rules={[{ required: true, message: i18n.t("required_field") }]}
                        >
                            <Input placeholder={i18n.t("type_here")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={i18n.t("external_code")}
                            name="externalCode"
                            rules={[
                                { required: true, message: i18n.t("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(
                                            value,
                                            "externalCode",
                                            tableData,
                                            additionalFieldsList,
                                            i18n.t<string>("the_external_code_field_cannot_repeat"),
                                            lineChecked
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder={i18n.t<string>("type_here")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={i18n.t("active")} name="active" initialValue={true}>
                            <Radio.Group className="form-radio-active">
                                <Radio value={true}>{i18n.t("active")}</Radio>
                                <Radio value={false}>{i18n.t("inactive")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="form-input-fields">
                        <Row className="row-header">
                            <Col span={4}>
                                <p>{i18n.t("type")}</p>
                            </Col>
                            <Col span={6}>
                                <p>{i18n.t("setting")}</p>
                            </Col>
                            <Col span={6}>
                                <p>{i18n.t("basis_for")}</p>
                            </Col>
                            <Col span={6}>
                                <p>{i18n.t("incidence")}</p>
                            </Col>
                        </Row>
                        <Row className="form-checkable">
                            <Col span={4}>
                                <Form.Item name="type" rules={[{ required: true, message: i18n.t("required_field") }]}>
                                    <Radio.Group onChange={onChangeType}>
                                        <Radio value="NUMERIC">{i18n.t("value")}</Radio>
                                        <Radio value="PERCENTAGE">{i18n.t("percentage")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="configuration">
                                    <Checkbox.Group>
                                        <Checkbox disabled={isTypeSelectedPercentage || isFormula} value={"phisical"}>
                                            {i18n.t("collaborators.additional_fields.configuration.phisical")}
                                        </Checkbox>
                                        <Checkbox disabled={isFormula} value={"indexing"}>
                                            {i18n.t("collaborators.additional_fields.configuration.indexing")}
                                        </Checkbox>
                                        <Checkbox disabled={isFormula} value={"premisse"}>
                                            {i18n.t("collaborators.additional_fields.configuration.premisses")}
                                        </Checkbox>
                                        {isTypeSelectedValue &&
                                            <Checkbox disabled={isFormula || isTypeSelectedPercentage} value={"calculate_value"}>
                                                {i18n.t("calculate_value")}
                                            </Checkbox>
                                        }
                                        <Checkbox disabled={isTypeSelectedPercentage} value={"formula"} onChange={handleIsFormula}>
                                            {i18n.t("formula")}
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="incidence">
                                    <Radio.Group disabled={isIncidenceDisabled}>
                                        <Radio value={"SALARY"}>
                                            {i18n.t("collaborators.additional_fields.SALARY")}
                                        </Radio>
                                        <Radio value={"OVERTIME"}>
                                            {i18n.t("collaborators.additional_fields.OVERTIME")}
                                        </Radio>
                                        <Radio value={"SALARY_PLUS_OVERTIME"}>
                                            {i18n.t("collaborators.additional_fields.SALARY_PLUS_OVERTIME")}
                                        </Radio>
                                        <Radio value={"CALCULATION_BASE"}>
                                            {i18n.t("collaborators.additional_fields.CALCULATION_BASE")}
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="base">
                                    <Checkbox.Group disabled={isBaseDisabled || !isCalculable && !isTypeSelectedPercentage}>
                                        <Checkbox value={"isCalculation"}>
                                            {i18n.t("collaborators.additional_fields.base.isCalculation")}
                                        </Checkbox>
                                        <Checkbox disabled={isIncidenceSelectedOvertime} value={"isOvertime"}>
                                            {i18n.t("collaborators.additional_fields.base.isOvertime")}
                                        </Checkbox>
                                        <Checkbox value={"isVacation"}>
                                            {i18n.t("collaborators.additional_fields.base.isVacation")}
                                        </Checkbox>
                                        <Checkbox value={"isThirteenth"}>
                                            {i18n.t("collaborators.additional_fields.base.isThirteenth")}
                                        </Checkbox>
                                        <Checkbox value={"isFgts"}>
                                            {i18n.t("collaborators.additional_fields.base.isFgts")}
                                        </Checkbox>
                                        <Checkbox value={"isInss"}>
                                            {i18n.t("collaborators.additional_fields.base.isInss")}
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {isFormula && (
                    <>
                        <Row style={{ marginTop: "16px" }}>
                            <Col span={6}>
                                <div style={{ display: "flex", flexDirection: "column", marginRight: 25}}>
                                    <label className="gs-input-label" htmlFor={"fields-formula-select"} style={{ marginBottom: 8 }}>
                                        {i18n.t("fields")}
                                    </label>

                                    <Select
                                        defaultValue={i18n.t("collaborators.additional_fields.select") as unknown as fieldItem}
                                        id={"fields-formula-select"}
                                        value={selectedOption}
                                        onChange={(selectedField) => {
                                            setSelectedOption(selectedField);
                                            selectedField = JSON.parse(selectedField.toString());
                                            addFieldToFormula(selectedField);
                                        }}
                                        style={{ marginTop: 5 }}
                                        options={fieldsFormula}
                                        {...selectProps}
                                        placeholder={i18n.t("collaborators.additional_fields.select")}
                                    />
                                </div>
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <div className="form-field-operations">
                                            <OperationsSelectFields onChooseOperation={addOperationToFormula} hasValueOperator/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item name="formulaConfiguration">
                                            <Checkbox.Group disabled={isConfigurationDisabled} style={{display: "flex", flexDirection: "row", marginTop: "16px", flexFlow: "row-reverse"}}>
                                                <Col span={10}>
                                                    <Checkbox disabled={!isFormula || !isNewAdditionalFields} value={"isBalance"}>
                                                        {i18n.t("apply_balance")}
                                                    </Checkbox>
                                                </Col>
                                                <Col span={14}>
                                                    <Checkbox disabled={!isFormula} value={"isExternalInfo"} onChange={handleClickInfo}>
                                                        {i18n.t("consider_field")}
                                                    </Checkbox>
                                                </Col>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col id="field-modal-formula" span={24}>
                                <Row style={{justifyContent: "space-between", alignItems: "center"}}>
                                    <h3>{i18n.t("additional_field_formula")}</h3>
                                    <Button style={{marginBottom: "8px"}}
                                        onClick={handleCleanFormula}
                                    >
                                        Limpar Fórmula
                                    </Button>
                                </Row>
                                <FormulaAreaFields formula={formula} removeItem={removeItem} setFormula={setFormula} />
                            </Col>
                        </Row>
                    </>
                )}
                {isNewAdditionalFields && (
                    <Row className="form-footer">
                        <Col offset={18} span={6}>
                            <Button
                                style={{ marginTop: -10 }}
                                type="default"
                                htmlType="submit"
                                className="gs-secondary-button"
                                icon={<Icon icon="akar-icons:circle-plus-fill" />}
                            >
                                {i18n.t<string>("addToList")}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
            {isNewAdditionalFields && (
                <div className="list-of-added-items">
                    {additionalFieldsList.length > 0 ? (
                        <>
                            <div className="cost-category-list-header">
                                <Row align="middle" gutter={2}>
                                    <Col span={6} style={{ paddingLeft: 6 }}>
                                        {i18n.t("additional_field_name")}
                                    </Col>
                                    <Col span={4} style={{ paddingLeft: 6 }}>
                                        {i18n.t("type")}
                                    </Col>
                                    <Col span={6} style={{ paddingLeft: 6 }}>
                                        {i18n.t("incidence")}
                                    </Col>
                                    <Col span={4} style={{ paddingLeft: 6 }}>
                                        {i18n.t("basis_for")}
                                    </Col>
                                    <Col span={2} style={{ paddingLeft: 6 }}>
                                        {i18n.t("active")}
                                    </Col>
                                    <Col span={1} />
                                    <Col span={1} />
                                </Row>
                            </div>
                            <div className="cost-category-list-content">
                                {additionalFieldsList.map((additionalFields) => {
                                    return (
                                        <Row key={additionalFields.externalCode} align="middle" gutter={2}>
                                            <Col span={6} style={{ justifyContent: "start" }}>
                                                {additionalFields.fieldName}
                                            </Col>
                                            <Col span={4} style={{ justifyContent: "start" }}>
                                                {i18n.t(`collaborators.additional_fields.${additionalFields.type}`)}
                                            </Col>
                                            <Col span={6} style={{ justifyContent: "start" }}>
                                                {additionalFields.incidence
                                                    ? i18n.t(
                                                          `collaborators.additional_fields.${additionalFields.incidence}`
                                                      )
                                                    : ""}
                                            </Col>
                                            <Col span={4} style={{ justifyContent: "start" }}>
                                                {additionalFields.base?.length > 0 &&
                                                    i18n.t(
                                                        `collaborators.additional_fields.base.${additionalFields.base[0]}`
                                                    )}
                                                {additionalFields.base?.length > 1 && (
                                                    <Tooltip
                                                        color={"rgba(0,0,0,0.9"}
                                                        placement="right"
                                                        title={additionalFields.base.map((base) => (
                                                            <p style={{ margin: 0 }}>
                                                                {i18n.t(`collaborators.additional_fields.base.${base}`)}
                                                            </p>
                                                        ))}
                                                    >
                                                        <span className="additional-items">
                                                            {`+${additionalFields.base?.length - 1}`}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </Col>
                                            <Col span={2} style={{ justifyContent: "start" }}>
                                                {additionalFields.active ? i18n.t("active") : i18n.t("inactive")}
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    disabled={isDisabled}
                                                    onClick={() => handleEditAdditionalFields(additionalFields)}
                                                    icon={
                                                        <Icon
                                                            className="edit-button"
                                                            icon="material-symbols:edit-sharp"
                                                        />
                                                    }
                                                />
                                            </Col>
                                            <Col span={1}>
                                                <Icon
                                                    className="delete-icon"
                                                    onClick={() =>
                                                        handleDeleteAdditionalFields(additionalFields.externalCode)
                                                    }
                                                    icon="fa6-solid:trash"
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <ImageBox styles={{ paddingTop: 25 }} />
                    )}
                </div>
            )}
        </Modal>
    );
}
