import { Button, Col, DatePicker, Form, Input, InputNumber, Layout, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { GenericSelection } from "module/leaseProjection/pages/contractSimulation/IContractSimulation";
import i18n from "util/base/i18n";
import { IAllocationData, IAllocationModalProps } from "../../IHumanResoucers";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { IAllocationTableData } from "module/budget/pages/collaborators/allocationParameterization/IAllocationParameterization";
import { Icon } from "@iconify/react";
import ResourcesList from "./ResourcesList";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import AllocationsTable from "./AllocationsTable";
import { cloneDeep } from "lodash";
import { useWatch } from "antd/es/form/Form";
import moment from "moment";
import { OptionCascader } from "../../hiredDashboard/IHiredDashboard";
import { Notification } from "components/notification/Notification";
import { FlexFilter } from "util/types/types";
import { onChangeFlexFieldValue } from "util/functions/onChangeFlexFieldValue";
import { updateFlexFieldFiltersValue } from "util/functions/updateFlexFieldFiltersValue";
import { useHumanResourcesContext } from "../../context/HumanResourcesContext";

export default function AllocationModal({
	getTableData
}: IAllocationModalProps) {
	const defaultRules: Rule[] = [{ required: true, message: i18n.t<string>("required_field") }];
	const {
		isOpen,
		setIsOpen,
		resourcesList,
		setResourceSelected,
		resourceSelected,
		form,
		allocationList,
		setAllocationList,
		closeModal,
		allocationsToSave,
		isSaving,
		setIsSaving,
		isEditing,
		flexFieldValues,
		setFlexFieldValues
	} = useHumanResourcesContext();

	const selectProps = {
		showSearch: true,
		optionFilterProp: "children",
		filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
		filterSort: (optionA, optionB) =>
			(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
	}
	const allocationTypeOptions: OptionCascader[] = [
		{
			label: "Part-Time",
			value: 0
		},
		{
			label: "Full-Time",
			value: 1,
		},
	];
	const startDate: moment.Moment = useWatch("startDate", form);
	const endDate: moment.Moment = useWatch("endDate", form);
	const type: number = useWatch("type", form);
	const totalAllowed: number = allocationList.reduce((acc, { value }) => acc - value, 100);
	const [allocationOptions, setAllocationOptions] = useState<GenericSelection[]>([]);
	const [costCenterOptions, setCostCenterOptions] = useState<GenericSelection[]>([]);

	function onSave(formValue: IAllocationData) {
		setAllocationList(state => {
			const newList = cloneDeep(state);
			let newFlexField: FlexFilter[] = flexFieldValues;
			const flexValues = Object.keys(formValue)
				.filter(key => key.includes("ff"))
				.map(key => {
					const arrString = key.split("-");
					const id = arrString[1];

					return {
						idValue: formValue[key] ? Number(formValue[key]) : 0,
						idFlexField: Number(id),
					};
				});
			newFlexField.map(flexField => {
				flexField.selectedOption[0] = {
					value: flexValues.find(value => value.idFlexField === flexField.value).idValue,
					label: ""
				}
			})

			formValue.employedResourceId = resourceSelected.id ? resourceSelected.id : resourceSelected.key;
			formValue.id = moment().millisecond();
			formValue.flexField = newFlexField;
			newList.push(formValue);
			return newList;
		});
		form.resetFields();
	}

	function disableStartDate(date: moment.Moment) {
		return endDate && date.isSameOrAfter(endDate);
	}

	function disabledEndDate(date: moment.Moment) {
		return startDate && date.isSameOrBefore(startDate);
	}

	function generateFlexFieldSelectedString(flexField: FlexFilter[]) {
		let flexFieldSelectedString = ':::::::::::::::::::::::::::::';

		flexField?.forEach(ff => {
			if (ff.selectedOption.length === 0) return
			const updatedData = flexFieldSelectedString.split(':');
			const position = ff.fieldCode - 1;
			const flexFilterValue = ff.selectedOption[0].value === 0 ? '' : ff.selectedOption[0].value;
			updatedData[position] = flexFilterValue.toString();
			const flexFieldString = updatedData.join(':');
			flexFieldSelectedString = flexFieldString;
		})
		return flexFieldSelectedString;
	}

	function generateFlexFieldFromString(flexFieldSelectedString: string): { [key: string]: string } {
		const fields: { [key: string]: string } = {};
		const data = flexFieldSelectedString.split(':');

		data.forEach((value) => {
			if (value !== '') {
				const childId: number = Number(value);
				const flexFieldId: number = flexFieldValues.find((ff) => ff.children.some(child => child.value === childId)).value;
				let flexFilter: {} = { [`ff-${flexFieldId}`]: childId };

				Object.assign(fields, flexFilter);
			}
		});

		return fields;
	}

	function onOk() {
		const allAllocations = allocationsToSave
			.filter(({ employedResourceId }) => employedResourceId !== resourceSelected.id)
			.concat(allocationList);
		const isValidSave: boolean = resourcesList.every(({ id }) => allAllocations.some(({ employedResourceId }) => employedResourceId === id));
		if (!isValidSave) {
			Notification({
				message: "Todos os colaboradores tem que ter locação",
				type: "warning"
			})
			return;
		}
		setIsSaving(true);

		const formatDataToSave: any[] = allAllocations.map((item) => {
			return ({
				...item,
				flexKey: generateFlexFieldSelectedString(item.flexField),
				endDate: item.endDate.format("YYYY-MM"),
				startDate: item.startDate.format("YYYY-MM")
			})
		})
		ServiceCaller.doRequest({
			type: isEditing ? RequestType.PUT : RequestType.POST,
			url: `/human-resources/allocation-employed-resource-link/${isEditing ? "update-all" : "save-all"}`,
			params: formatDataToSave
		}, handleSaveAllocations)
	}

	function handleSaveAllocations() {
		Notification({
			message: "Alocações salva com sucesso",
			type: "success"
		})
		closeModal();
		getTableData();
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/human-resources/allocation"
		}, (data: IAllocationTableData[]) => {
			setAllocationOptions(data.map(({ id, description }) => {
				return ({
					label: description,
					value: id
				})
			}))
		})

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/cost-center?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}`,
		}, setCostCenterOptions);
	}, [])

	useEffect(() => {
		if (!resourcesList.length) return;
		setResourceSelected(resourcesList[0]);
	}, [resourcesList])

	useEffect(() => {
		if (!type) return;
		form.resetFields(["value"]);
	}, [type])

	useEffect(() => {
		if (isEditing && !isOpen && allocationList.length) {
			const newAllocationList: IAllocationData[] = cloneDeep(allocationList).map((item: IAllocationData) => {
				const flexFields: string = item.flexKey ? item.flexKey : generateFlexFieldSelectedString(item.flexField);
				const newFlexField: { [key: string]: string } = generateFlexFieldFromString(flexFields);

				return ({
					...item,
					...newFlexField
				})
			});

			setIsOpen(true);
			setAllocationList(newAllocationList);
		}
	}, [isEditing, isOpen, resourceSelected, allocationList])

	return (
		<Modal
			destroyOnClose
			title={i18n.t("human_resources.resource_allocation")}
			open={isOpen}
			onCancel={closeModal}
			onOk={onOk}
			width={"80%"}
			className="gs-modal allocation-modal-hr"
			cancelText={i18n.t<string>("cancel")}
			centered
			okText={i18n.t<string>("save")}
			okButtonProps={{ loading: isSaving }}
		>
			<Layout >
				{resourcesList?.length > 1 &&
					<Sider theme="light">
						<ResourcesList />
					</Sider>
				}
				<Content style={{maxWidth:"100%"}}>
					<Row>
						<Form
							layout="vertical"
							onFinish={onSave}
							form={form}
						>
							<Row gutter={7}>
								<Col span={6}>
									<Form.Item
										label={i18n.t("name")}
									>
										<Input value={resourceSelected?.resourceName ? resourceSelected.resourceName : resourceSelected?.collaborator} disabled />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label={i18n.t("from")}
										name={"startDate"}
										rules={defaultRules}
									>
										<DatePicker
											format={"MM/YYYY"}
											picker="month"
											placeholder="MM/YYYY"
											disabledDate={disableStartDate}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label={i18n.t("until")}
										name={"endDate"}
										rules={defaultRules}
									>
										<DatePicker
											format={"MM/YYYY"}
											picker="month"
											placeholder="MM/YYYY"
											disabledDate={disabledEndDate}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label={i18n.t("type")}
										name={"type"}
										rules={defaultRules}
										{...allocationList.length && { initialValue: 0 }}
									>
										<Select
											{...selectProps}
											disabled={!!allocationList.length}
											options={allocationTypeOptions}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row className="row-title">
								<div className="gs-tag blue">
									{i18n.t("destination")}
								</div>
							</Row>
							<Row gutter={7} wrap>
								<Col span={6}>
									<Form.Item
										label={i18n.t("cost_center")}
										name={"costCenterId"}
										rules={defaultRules}
									>
										<Select
											{...selectProps}
											placeholder={i18n.t("select")}
											options={costCenterOptions}
											onChange={() => updateFlexFieldFiltersValue('COST_CENTER', form, flexFieldValues, setFlexFieldValues)}
										/>
									</Form.Item>
								</Col>
								{
									flexFieldValues.length > 0 &&
									flexFieldValues.map(ff =>
										<Col span={6} key={ff.value}>
											<Form.Item
												label={ff.label}
												name={`ff-${ff.value}`}
											>
												<Select
													{...selectProps}
													placeholder={i18n.t("select")}
													options={ff.children}
													onChange={(value) => onChangeFlexFieldValue(value, ff.value, setFlexFieldValues, form,
														{
															flexFieldOptions: flexFieldValues,
														})}
													{...selectProps}
												/>
											</Form.Item>
										</Col>
									)
								}
							</Row>
							<Row>
								<div className="gs-tag blue">
									{i18n.t("allocate_to")}
								</div>
							</Row>
							<Row gutter={7}>
								<Col span={6}>
									<Form.Item
										name={"allocationId"}
										label={i18n.t("allocation")}
										rules={defaultRules}
									>
										<Select
											{...selectProps}
											options={allocationOptions}
										/>
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item
										name={"value"}
										label={i18n.t("allocation_percentage")}
										rules={defaultRules}
										{...type === 1 && { initialValue: 100 }}
									>
										<InputNumber
											disabled={type === 1}
											max={totalAllowed}
											min={1}
											controls={false}
										/>
									</Form.Item>
								</Col>
								<Col className="add-button-col">
									<Button
										type="default"
										htmlType="submit"
										className="gs-secondary-button"
										icon={<Icon icon="akar-icons:circle-plus-fill" />}
										disabled={totalAllowed === 0}
									>
										{i18n.t<string>("add")}
									</Button>
								</Col>
							</Row>
						</Form>
					</Row>
					<Row className="allocation-table-row">
						<Col span={24}>
							<AllocationsTable
								allocationsOptions={allocationOptions}
								costCenterList={costCenterOptions}
								flexFieldValues={flexFieldValues}
							/>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Modal >
	)
}