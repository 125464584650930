import locale_ptBR from "antd/es/date-picker/locale/pt_BR";
import locale_enUS from "antd/es/date-picker/locale/en_US";
import locale_esES from "antd/es/date-picker/locale/es_ES";
import { FunctionalityPermissions, Options } from "util/types/types";
import { FlexFieldValueRequest } from "../revenue/mainFlow/IRevenue";
import { ContractType } from "../revenue/recurringContract/IRecurringContract";

export const languages = {
	pt_BR: locale_ptBR,
	en_US: locale_enUS,
	es_ES: locale_esES
}

export interface User {
	value: number;
	label: string;
}

export interface PermissionSecurityData extends OrganizationFiscalYear {
	id: number;
	description?: string;
	clientId: number;
	userId: number;
	groupId: number;
	itemId?: number;
	businessUnitId?: number;
	permissionSecurityLevel: PermissionSecurityLevel;
	costCenterId?: number;
}

export interface FlexFieldMenuItem {
	description: string;
	flexFieldId: number;
}

export interface TableData extends PermissionSecurityData {
	key: string | number;
}

export interface TableRow {
	key: number;
	description: string;
}
export interface DefaultPermissions {
	account: PermissionOption;
	costCenter: PermissionOption;
	fiscalYear: PermissionOption;
}

export interface PermissionOption {
	key: string;
	permissions: PermissionSecurityData[];
	setPermissions(p: PermissionSecurityData[]): void;
	isFetching: boolean;
	setIsFetching(b: boolean): void;
}

export enum PermissionSecurityLevel {
	Blocked = 'BLOCKED',
	Edit = 'EDIT',
	View = 'VIEW'
}

export interface UserPermissionsTabContainerProps {
	selectedUser: User;
}
export interface IPermissionSecurityArea {
	selectedUser: User;
	isSelectingAll: boolean;
	setIsSelectingAll(b: boolean): void;
	selectedTab: 'group' | 'user'
}

export interface UserPermissionsUserListProps {
	setSelectedUser: (u: User) => void;
	selectedTab: 'group' | 'user'
	setSelectedTab(selected: 'group' | 'user'): void;
}

export interface IPermissionSecurityModal {
	selectedUser: User;
	isModalOpen: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	checkedPermissionItem: TableRow[];
	isBlockedSection: boolean;
	handleDelete: (n: number) => void;
	titleTab: string;
}

export interface UserPermissionsTabContentProps {
	selectedUser: User;
	userPermissions: PermissionSecurityData[];
	setUserPermissions: (userPermissions: PermissionSecurityData[]) => void;
	selectedOptionTab: string;
	subtitle: string;
	searchText: string;
	loadPermissions: (a: []) => void;
	urlLoad: string;
	businessUnitSelectedId: number
}

export interface PermissionItem {
	key: string;
	url: string;
	label: string;
	selectedUser: User;
	setPermissions: (permissionsData: PermissionSecurityData[]) => void;
	subtitle: string;
	searchText: string;
}

export interface IPermissionSecurityContent {
	selectedPermissionItem: PermissionItem;
	permissionData: PermissionSecurityData[];
	selectedUser: User;
	isFetchingFlexFields: boolean;
	isFlexFields: boolean;
	isFetching: boolean;
	tableData: TableData[];
	setTableData(data: TableData[]): void;
	permissionsCostCenter?: PermissionSecurityData[];
	setCostCenterSelectedValue?: (cc: number) => void;
	selectedMenuKey: string;
	functionalityPermissions: FunctionalityPermissions;
	onChangeCostCenterSelectValue?: (cc: number) => void;
	selectedTab?: string;
	onChangeSelect: (value: any) => void;
	organizationOptions: Options[];
	isCostCenterLinked: boolean;
}

export interface OnClickMenu {
	key: string;
	keyPath: string[];
	item: React.ReactInstance;
	domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export interface IDataSalesHierarchyRequest extends IDataSalesHierarchyCommon {
	flexFieldValue: FlexFieldValueRequest;
	flexFieldValueIds: number[];
	childrens: IDataSalesHierarchyRequest[];
	accessTypeUser: AccessTypeUser;
	userSecurityAccess: AccessTypeUser[];
	accessTypeGroup: AccessTypeGroup;
	groupSecurityAccess: AccessTypeGroup[];
}

export interface IAllAccessType {
	accessTypeUser: AccessTypeUser[];
	accessTypeGroup: AccessTypeGroup[];
}

export interface AccessTypeUserRequest {
	id: number;
	userId: number;
	accessType: AccessType;
}

export interface AccessTypeGroupRequest {
	[key: string]: AccessType;
}

export interface IDataSalesHierarchyCommon {
	id: number;
}

export interface AccessTypeCommon {
	accessType?: AccessType;
	notInclude?: boolean;
	idsTree?: number[];
	id?: number;
	flexFieldValueIds?: number[];
}

export interface AccessTypeUser extends AccessTypeCommon {
	userId?: number;
}

export interface AccessTypeGroup extends AccessTypeCommon {
	groupId?: number;
}

export interface AccessTypeGeneric extends AccessTypeCommon {
	groupId?: number;
	userId?: number;
}

export interface IDataSalesHierarchy extends IDataSalesHierarchyCommon {
	key: string;
	title: string;
	children: IDataSalesHierarchy[];
	flexFieldValueId: number;
	flexFieldValueIds: number[];
	accessTypeGroup: AccessTypeGroup;
	accessTypeUser: AccessTypeUser;
}

export enum AccessType {
	ALLOWED = "ALLOWED",
	BLOCKED = "BLOCKED",
}

export interface ISalesHierarchyTableProps {
	selectedId: number;
	searchPermission: string;
	selectedMenuKey: string;
}

export type OrganizationFiscalYear = {
	id: number;
	clientId: number;
	userId: number;
	groupId: number;
	organizationId?: number;
	organizationName?: 'string';
	permissionSecurityLevel: PermissionSecurityLevel;
	year?: number;
}

export enum MenuKey {
	COSTCENTER = 'costCenter',
	ACCOUNT = 'account',
	FISCALYEAR = 'fiscalYear',
	SALESHIERARCHY = 'salesHierarchy',
	CONTRACT = 'contractType'
}

export interface ContractTypeData {
	groupId: number;
	userId: number;
	contractType: ContractType;
	accessType: AccessType;
	id?: number;
}

export interface IFlexFieldValuesResponse extends FlexFieldMenuItem {
	flexFieldValues: IFlexFieldValues[];
}

export interface IFlexFieldValues {
	businessUnitId: number;
	clientId: number;
	description: string;
	externalCode: string;
	flexFieldId: number;
	groupId: number;
	id?: number;
	permissionSecurityLevel?: PermissionSecurityLevel;
	userId: number;
}