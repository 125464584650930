import { Icon } from "@iconify/react";
import { Checkbox, Layout, Table } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { Loading } from "components/loading/Loading";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { SplitComponent } from "module/budget/components/SplitComponents/SplitComponent";
import { Key, useEffect, useMemo, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { tableProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { commomFilterTableData } from "util/TableUtils";
import { ErrorRequest, Options } from "util/types/types";
import { AccountAccountingOptions, AccountList, IContent, LevelItem } from "../IFromTo";
import SidebarContent from "./SidebarContent";
import TableContainer from "./TableContainer";

export default function Content({
	treeData,
	costCenterList,
	checkedKeys,
	setCheckedKeys,
	expandedKeys,
	setExpandedKeys,
	isFetching,
	selectedTab,
	referenceDate,
	allOptions
}: IContent) {
	const [isLastTreeNode, setIsLastTreeNode] = useState(false);
	const [isLoadingTable, setIsLoadingTable] = useState(true);
	const [isSelectingAllLinked, setIsSelectingAllLinked] = useState(false);
	const [isSelectingAllUnlinked, setIsSelectingAllUnlinked] = useState(false);
	const [selectedLinkedRowKeys, setSelectedLinkedRowKeys] = useState<React.Key[]>([]);
	const [selectedUnlinkedRowKeys, setSelectedUnlinkedRowKeys] = useState<React.Key[]>([]);
	const [classification, setClassification] = useState<number>(0);
	const [searchValueUnlinked, setSearchValueUnlinked] = useState<string>("");
	const [searchValueLinked, setSearchValueLinked] = useState<string>("");
	const [tableDataLinked, setTableDataLinked] = useState<AccountList[]>([]);
	const [istableDataLinkedLoading, setIstableDataLinkedLoading] = useState<boolean>(true);
	const [isViewLinkedAccounts, setIsViewLinkedAccounts] = useState<boolean>(false);
	const [selectedTableDataLinked, setSelectedTableDataLinked] = useState<AccountList[]>([]);
	const [tableDataUnlinked, setTableDataUnlinked] = useState<AccountAccountingOptions[]>([]);
	const { userInfo } = useUserContext();

	useEffect(() => {
		getTableDataLinked();
	}, [referenceDate, selectedTab]);

	useEffect(() => {
		if (checkedKeys.length === 0) return;

		setIsLoadingTable(true);
		if (selectedTab === 'ledger_account') {
			const nodeSelected: LevelItem = findNodeTree(treeData, checkedKeys[0]);
			const isLastNode = nodeSelected.classificationId !== null && nodeSelected?.children.length === 0;
			setClassification(nodeSelected.classificationId);
			setIsLastTreeNode(isLastNode);
			if (isLastNode) getAccountTable(nodeSelected.classificationId);
		} else {
			setIsLastTreeNode(true);
			getCostCenterTable();
		}
	}, [checkedKeys, referenceDate]);

	useEffect(() => {
		setTableDataUnlinked(buildTableDataUnlinked());
		setIstableDataLinkedLoading(false);
	}, [allOptions, tableDataLinked, classification])

	const tableDataLinkedFiltered: AccountList[] = useMemo(() => {
		return commomFilterTableData(tableDataLinked, searchValueLinked, ["externalCode", "name"]);
	}, [tableDataLinked, searchValueLinked])

	const tableDataUnlinkedFiltered: Options[] = useMemo(() => {
		return commomFilterTableData(tableDataUnlinked, searchValueUnlinked, ["label"]);
	}, [tableDataUnlinked, searchValueUnlinked])

	const tableColumnsUnlinked: ColumnsType<Options> = [
		{
			title:
				selectedTab === "ledger_account"
					? `${i18n.t<string>("accounting_account")} ${i18n.t<string>('available')}`
					: `${i18n.t<string>("cost_center")} ${i18n.t<string>('available')}`,
			dataIndex: "label",
		},
	];

	const tableColumnsLinked: ColumnsType<AccountList> = [
		{
			title: i18n.t<string>(selectedTab === "ledger_account"
				? i18n.t<string>("accounts_already_linked")
				: i18n.t<string>("cost_center_already_linked"),),
			dataIndex: "name",
			key: "name",
			render: (name, { externalCode }) => {
				const text: string = `${name} - ${externalCode}`;
				return (
					<span className="unlinked">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							{text}
							<Icon
								icon="akar-icons:link-chain"
								style={{ marginLeft: 8 }}
							/>
						</div>
					</span>
				);
			}
		},
	];

	function buildTableDataUnlinked(): AccountAccountingOptions[] {
		if (!allOptions?.length) return [];
		if (!tableDataLinked?.length) return allOptions;

		if (selectedTab === "ledger_account") {
			return allOptions
				.filter(option => !tableDataLinked.some(linked => linked.accountAccountId === option.value));
		} else {
			return allOptions.filter(option =>
				!tableDataLinked.some(linked => linked.costCenterId === option.value)
			);
		}
	}

	function getTableDataLinked() {
		setIstableDataLinkedLoading(true);
		const formattedYear: number = referenceDate.year();
		const url: string = selectedTab === "ledger_account" ?
			"/planning/management-account-parameterization?classificationId=0&hierarchyId=0" :
			"/planning/management-cost-center-parameterization?managementCostCenterId=0"
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `${url}&clientId={client}&localeId={locale}&organizationId={organization}` +
					`&year=` + formattedYear,
			}, setTableDataLinked, handleErrorRequest);
	}

	function getAccountTable(classificationId) {
		const formattedYear = referenceDate.year();
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/planning/management-account-parameterization?clientId={client}&localeId={locale}` +
					`&organizationId={organization}&classificationId=${classificationId}&hierarchyId=${checkedKeys[0]}` +
					`&year=` + formattedYear,
				useProxy: true,
			},
			onLoadAccountOrCostCenter.bind(this),
			(err: ErrorRequest) => {
				handleErrorRequest(err);
			}
		);
	}

	function getCostCenterTable() {
		const formattedYear = referenceDate.year();
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/planning/management-cost-center-parameterization?clientId={client}&localeId={locale}` +
					`&organizationId={organization}&managementCostCenterId=${checkedKeys[0]}` +
					`&year=` + formattedYear,
			},
			onLoadAccountOrCostCenter, handleErrorRequest
		);
	}

	function onLoadAccountOrCostCenter(data: AccountList[]) {
		const updateLinkedAccounts = data.map((item) => ({ ...item, key: item.id, name: item.externalCode + ' - ' + item.name }));
		setSelectedTableDataLinked(updateLinkedAccounts);
		setIsLoadingTable(false);
	}

	function findNodeTree(lines: LevelItem[], key: Key) {
		let obj = null;

		for (let l = lines.length; l--;) {
			const level = lines[l];

			if (level.key === key) {
				obj = level;
				break;
			} else if (level.children.length > 0) {
				obj = findNodeTree(level.children, key);

				if (obj) {
					break;
				}
			}
		}

		return obj;
	}

	function saveLink(isLinked: boolean) {
		isLinked ? setIsSelectingAllUnlinked(false) : setIsSelectingAllLinked(false);
		setIsLoadingTable(true);
		setIstableDataLinkedLoading(true);

		const dataToSave = selectedLinkedRowKeys.map(id => {
			if (selectedTab === 'ledger_account') {
				return {
					organizationId: userInfo.selection.organizationId,
					year: referenceDate.year(),
					managementAccountPlanHierarchyId: checkedKeys[0],
					accountAccountId: id
				};
			} else {
				return {
					organizationId: userInfo.selection.organizationId,
					year: referenceDate.year(),
					managementCostCenterId: checkedKeys[0],
					costCenterId: id
				};
			}
		});

		let url: string;

		selectedTab === 'ledger_account'
			? url = "/planning/management-account-parameterization"
			: url = "/planning/management-cost-center-parameterization";

		ServiceCaller.doRequest({
			type: isLinked ? RequestType.DELETE : RequestType.POST,
			url: isLinked
				? `${url}?ids=${selectedUnlinkedRowKeys.toString()}`
				: url,
			params: dataToSave
		}, () => onSaveLink(isLinked), handleErrorRequest);
	}

	function onSaveLink(isLinked: boolean) {
		Notification({
			type: "success",
			message: isLinked
				? i18n.t("groupings_texts.unlinked_successfully")
				: i18n.t("groupings_texts.linked_successfully"),
		});
		selectedTab === "ledger_account" ? getAccountTable(classification) : getCostCenterTable();
		getTableDataLinked();
	}

	const onChangeSearchUnlinked = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValueUnlinked(e.target.value);
	};

	const onChangeSearchLinked = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValueLinked(e.target.value);
	};

	return (
        <SplitComponent>
            <div className="sidebar-content">
                <SidebarContent
                    treeData={treeData}
                    costCenterList={costCenterList}
                    checkedKeys={checkedKeys}
                    setCheckedKeys={setCheckedKeys}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    isFetching={isFetching}
                    selectedTab={selectedTab}
                    tableDataLinked={tableDataLinked}
                />
            </div>
            <div className="content-container">
                {isFetching ? (
                    <Loading />
                ) : isLastTreeNode && checkedKeys.length === 1 ? (
                    <TableContainer
                        selectedTab={selectedTab}
                        isLoadingTable={isLoadingTable}
                        isSelectingAllLinked={isSelectingAllLinked}
                        setIsSelectingAllLinked={setIsSelectingAllLinked}
                        isSelectingAllUnlinked={isSelectingAllUnlinked}
                        setIsSelectingAllUnlinked={setIsSelectingAllUnlinked}
                        selectedLinkedRowKeys={selectedLinkedRowKeys}
                        selectedUnlinkedRowKeys={selectedUnlinkedRowKeys}
                        setSelectedLinkedRowKeys={setSelectedLinkedRowKeys}
                        setSelectedUnlinkedRowKeys={setSelectedUnlinkedRowKeys}
                        saveLink={saveLink}
                        linkedData={selectedTableDataLinked}
                        unlinkedData={tableDataUnlinked}
                        classificationId={classification}
                    />
				) : (
					<Layout.Content>
                        <div className="tree-level-container-new">
                            <div className="div-content">
                                <div className="search-div">
                                    <Search
                                        className="search-input-accounts"
                                        placeholder={i18n.t("search")}
                                        onChange={onChangeSearchUnlinked}
                                        value={searchValueUnlinked}
                                    />
                                    <Checkbox
                                        checked={isViewLinkedAccounts}
                                        onChange={(e) => setIsViewLinkedAccounts(e.target.checked)}
									>{i18n.t('display_linked')}</Checkbox>
                                </div>
                                {isViewLinkedAccounts && (
                                    <div className="search-div-linked">
                                        <Search
                                            className="search-input-accounts"
                                            placeholder={i18n.t("search")}
                                            onChange={onChangeSearchLinked}
                                            value={searchValueLinked}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="div-content-table">
                                <Table
                                    {...tableProps}
                                    className="gs-table"
                                    style={{ width: "50%" }}
                                    columns={tableColumnsUnlinked}
                                    dataSource={tableDataUnlinkedFiltered}
                                    scroll={{ x: true, y: 500 }}
                                />
                                {isViewLinkedAccounts && (
                                    <Table
                                        {...tableProps}
                                        className="gs-table"
                                        style={{ width: "50%" }}
                                        columns={tableColumnsLinked}
                                        dataSource={tableDataLinkedFiltered}
                                        scroll={{ x: true, y: 500 }}
                                    />
                                )}
                            </div>
                        </div>
                    </Layout.Content>
                )}
            </div>
        </SplitComponent>
    );
}
