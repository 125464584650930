import { Button, Col, Form, Row } from "antd";
import i18n from "util/base/i18n";
import { CustomSelect } from "../../revenue/attributeParameterization/components/custom/CustomSelect";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import { ICustomSelectOptions } from "../../revenue/attributeParameterization/IAttributeParameterization";
import { FilterResultType, FilterType, IDistributionRuleFilter, IDistributionRuleResultFilter, IFilterFormValues, IOptionRuleFilter, IOptionRuleResult, OperationType } from "../IDistributionRule";
import { PlusCircleFilled } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useUserContext } from "context/UserContext";
import { RequestType } from "util/service/IServiceCaller";
import { MeasuringUnit } from "../../revenue/mainFlow/IRevenue";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { EqualOp, NotEqualOp } from "../../revenue/attributeParameterization/components/operators/MathOperators";
import { LessThanEqualOp } from "module/budget/pages/collaborators/additionalFields/components/operators/MathOperators";
import { Condition } from "../../revenue/attributeParameterization/components/rules/Condition";
import { IndexerResponse } from "../../budgetProjection/IBudgetProjection";

export default function DistributionRule() {
	const {
		selectedTab,
		ruleFilterOptions,
		isOptionsLoading,
		filtersForm,
		ruleConditionFilter,
		ruleConditionResult,
		setRuleConditionFilter,
		setRuleConditionResult,
		isModalOpen,
		ruleForEdit
	} = useDistributionRuleContext();
	const { userInfo: { groupIds } } = useUserContext();
	const fieldId: string = useWatch("fieldId", filtersForm);
	const [isValueLoading, setIsValueLoading] = useState(false);
	const [valueOptions, setValueOptions] = useState<ICustomSelectOptions[]>([]);
	const fieldOptions: (IOptionRuleFilter | IOptionRuleResult)[] = useMemo(() => {
		if (selectedTab === "filter") {
			const filterAddOptions: IOptionRuleFilter[] = [
				{
					value: `0-${FilterType.ACCOUNT_LEVEL}`,
					filter: FilterType.ACCOUNT_LEVEL,
					label: i18n.t("new_sales_report.management_accounting_account")
				},
				{
					value: `0-${FilterType.COST_CENTER_LEVEL}`,
					filter: FilterType.COST_CENTER_LEVEL,
					label: i18n.t("new_sales_report.management_cost_center")
				},
				{
					value: `0-${FilterType.ACCOUNTING_ACCOUNT}`,
					filter: FilterType.ACCOUNTING_ACCOUNT,
					label: i18n.t("accounting_account")
				},
				{
					value: `0-${FilterType.COST_CENTER}`,
					filter: FilterType.COST_CENTER,
					label: i18n.t("cost_center")
				},
			]
			return ([
				...filterAddOptions,
				...ruleFilterOptions
			])
		} else {
			const filterAddOptions: IOptionRuleResult[] = [
				{
					value: `0-${FilterResultType.ACCOUNTING_ACCOUNT}`,
					filter: FilterResultType.ACCOUNTING_ACCOUNT,
					label: i18n.t("accounting_account")
				},
				{
					value: `0-${FilterResultType.COST_CENTER}`,
					filter: FilterResultType.COST_CENTER,
					label: i18n.t("cost_center")
				},
			].filter(({ filter }) => !ruleConditionResult.some((item) => item.result === filter))

			return ([
				...filterAddOptions,
				...ruleFilterOptions
			])
		}
	}, [selectedTab, ruleFilterOptions, ruleConditionResult])

	useEffect(() => {
		if (!ruleForEdit) return;
		setRuleConditionFilter(ruleForEdit.filters.map((item) => {
			let fieldName: string = item.fieldName;
			const { type } = item;
			if (type !== FilterType.FLEX_FIELD) {
				fieldName = getLabelByFilter[type];
			}

			return ({
				...item,
				fieldName,
				filter: type as FilterType
			})
		}));

		setRuleConditionResult(ruleForEdit.results.map((item) => {
			let fieldName: string = item.fieldName;
			const { type } = item;
			if (type !== FilterResultType.FLEX_FIELD) {
				fieldName = getLabelByFilterResult[type];
			}
			return ({
				...item,
				fieldName,
				result: type as FilterResultType
			})
		}));
	}, [ruleForEdit])

	useEffect(() => {
		resetFormFields();
	}, [selectedTab, isModalOpen])

	const operatorsOptions: ICustomSelectOptions[] = [
		{
			label: "Igual",
			value: OperationType.EQUALS
		},
		{
			label: "Diferente",
			value: OperationType.NOT_EQUALS
		}
	]

	const getLabelByFilter: Record<FilterType, string> = {
		FLEX_FIELD: "",
		COST_CENTER: i18n.t("cost_center"),
		ACCOUNTING_ACCOUNT: i18n.t("accounting_account"),
		ACCOUNT_LEVEL: i18n.t("new_sales_report.management_accounting_account"),
		COST_CENTER_LEVEL: i18n.t("new_sales_report.management_cost_center")
	}

	const getLabelByFilterResult: Record<FilterResultType, string> = {
		FLEX_FIELD: "",
		COST_CENTER: i18n.t("cost_center"),
		ACCOUNTING_ACCOUNT: i18n.t("accounting_account"),
	}

	const resetFormFields = () => {
		filtersForm.resetFields(["fieldId", "fieldValueId"]);
		filtersForm.setFieldValue("operation", OperationType.EQUALS);
	}

	const handleFinishForm = ({ fieldId, fieldValueId, operation }: IFilterFormValues) => {
		const [id, type] = fieldId.split("-");
		const fieldValueName: string = valueOptions.find((valueItem) => valueItem.value === fieldValueId)?.label;
		const fieldName = fieldOptions.find(({ value }) => value === fieldId)?.label;
		if (selectedTab === "filter") {
			setRuleConditionFilter(state => {
				const newCondition: IDistributionRuleFilter = {
					fieldId: Number(id),
					fieldValueId,
					operation,
					filterOrder: state.length,
					filter: FilterType[type],
					fieldValueName,
					fieldName
				}

				return state.concat(newCondition);
			})
		} else {
			setRuleConditionResult(state => {
				const newCondition: IDistributionRuleResultFilter = {
					fieldId: Number(id),
					fieldValueId,
					operation,
					filterOrder: state.length,
					result: FilterResultType[type],
					fieldValueName,
					fieldName
				}

				return state.concat(newCondition);
			})
		}

		resetFormFields();
	}

	const getFilterRequest: Record<FilterType, string> = {
		FLEX_FIELD: "/budget-base/flex-field-value?user={user}",
		COST_CENTER: "/monolith/cost-center?client={client}&locale={locale}&organization={organization}",
		ACCOUNTING_ACCOUNT: "/monolith/accountingaccount?locale={locale}&client={client}&organization={organization}",
		ACCOUNT_LEVEL: "/budget-base/account-hierarchy?locale={locale}&client={client}&user={user}&organization={organization}&businessUnit={businessUnit}",
		COST_CENTER_LEVEL: "/budget-base/cost-center-hierarchy?locale={locale}&client={client}&user={user}&organization={organization}&businessUnit={businessUnit}"
	}

	const onChangeField = (value: string) => {
		setIsValueLoading(true);
		const [id, type] = value.split("-");
		let url: string = getFilterRequest[type];
		if (type === FilterType.FLEX_FIELD) url += `&flexFieldId=${Number(id)}&group=${groupIds}`;

		ServiceCaller.doRequest({
			url,
			type: RequestType.GET,
		}, (data: (MeasuringUnit | IndexerResponse)[]) => {
			setValueOptions(data.map(item => {
				if ("name" in item) {
					return {
						label: item.name,
						value: item.id,
					};
				} else {
					return {
						label: `${item.externalCode} - ${item.description}`,
						value: item.id,
					};
				}
			}));
			setIsValueLoading(false);
		}, handleErrorRequest);

		filtersForm.resetFields(["fieldValueId"]);
	}

	const getMathOperator: Record<OperationType, ReactElement> = {
		PLUS: <PlusCircleFilled />,
		LESS: <LessThanEqualOp />,
		EQUALS: <EqualOp />,
		NOT_EQUALS: <NotEqualOp />
	}

	const removeCondition = (index: number) => {
		if (selectedTab === "filter") {
			setRuleConditionFilter((prevConditions) => {
				const newConditions = [...prevConditions];
				newConditions.splice(index, 1);
				return newConditions;
			});
		} else {
			setRuleConditionResult((prevConditions) => {
				const newConditions = [...prevConditions];
				newConditions.splice(index, 1);
				return newConditions;
			});
		}
	}

	const buildFilterWorkArea = (): JSX.Element => {
		return (
			<>
				{
					selectedTab === "filter" ? ruleConditionFilter.map((condition, index) => {
						return (
							<Condition
								key={index}
								index={index}
								handleRemove={removeCondition}
							>
								<div className="attribute_parameterization-new-attribute-modal-rule-label">
									{condition.fieldName}
								</div>

								{getMathOperator[condition.operation]}

								<div className="attribute_parameterization-new-attribute-modal-rule-label">
									{condition.fieldValueName}
								</div>
							</Condition>
						);
					})
						:
						ruleConditionResult.map((condition, index) => {
							return (
								<Condition
									key={index}
									index={index}
									handleRemove={removeCondition}
								>
									<div className="attribute_parameterization-new-attribute-modal-rule-label">
										{condition.fieldName}
									</div>

									{getMathOperator[condition.operation]}

									<div className="attribute_parameterization-new-attribute-modal-rule-label">
										{condition.fieldValueName}
									</div>
								</Condition>
							);
						})
				}
			</>
		)
	}

	return (
		<>
			<Form
				name="distribution-rule-form"
				form={filtersForm}
				onFinish={handleFinishForm}
				id="attribute_parameterization-new-rules-modal-rules-section"
				className="distribution-rule-form"
			>
				<Row gutter={15}>
					<Col span={10}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="fieldId"
						>
							<CustomSelect
								id="fieldId"
								labelName={i18n.t<string>("field")}
								options={fieldOptions}
								loading={isOptionsLoading}
								onChange={onChangeField}
								hasSearch
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="operation"
							initialValue={OperationType.EQUALS}
						>
							<CustomSelect
								id="operation"
								labelName={i18n.t<string>("operations")}
								options={operatorsOptions}
								disabled={selectedTab === "result"}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="fieldValueId"
						>
							<CustomSelect
								id="fieldValueId"
								hasSearch
								labelName={i18n.t<string>("value")}
								disabled={!fieldId || isValueLoading}
								options={valueOptions}
								loading={isValueLoading}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Button
						type="text"
						htmlType="submit"
						icon={<PlusCircleFilled />}
						className={`gs-secondary-button`}
					>
						{i18n.t<string>("add")}
					</Button>
				</Row>
			</Form>
			<div className="attribute_parameterization-new-attribute-modal-rules-workarea">
				{buildFilterWorkArea()}
			</div>
		</>
	)
}