import { Icon } from "@iconify/react";
import { Collapse } from "antd";
import i18n from "util/base/i18n";
import { ICollapseContentProps, IPanelObject, IntegrationStatus, IntegrationType, ModuleDataResponse, ModuleTypes } from "../IBudgetIntegrations";
import PanelHeader from "./PanelHeader";
import ModuleTable from "./ModuleTable";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useIntegrationsContext } from "../context/IntegrationsContext";
const { Panel } = Collapse;

export default function CollapseContent({
	selectedRowKeys,
	setSelectedRowKeys,
	setIntegrationSelected
}: ICollapseContentProps) {
	const [selectedPanel, setSelecetedPanel] = useState<string>("");
	const { setIsLoading, onLoadTableData, isLoading, chooseWichTableData,startDate,endDate } = useIntegrationsContext()
	const panelsValue: IPanelObject[] = [
		{
			title: i18n.t("fixed_expense"),
			key: "EXPENSES",
		},
		{
			title: i18n.t("human_resources.human_resources"),
			key: "HUMAM_RESOURCES"
		},
		{
			title: i18n.t("flexField.modules.revenue"),
			key: "REVENUE"
		},
		{
			title: i18n.t("modules.planning"),
			key: "PLANNING"
		},
		{
			title: i18n.t("registration_integrations"),
			key: "GENERAL_REGISTRATIONS"
		},
		{
			title: i18n.t("flexible_fields"),
			key: "FLEX_FIELDS",
		},
	]

	const onChange = (key: string) => {
		setSelecetedPanel(key);
		setSelectedRowKeys([]);
	};

	const renderIcon = (isActive: boolean): JSX.Element => {
		return <Icon icon={isActive ? "ion:chevron-up" : "ion:chevron-down"} />
	}

	const onChangeSelectTable = (keys: string[]) => {
		setSelectedRowKeys(keys);
	}

	const countRunningProcesses = (data: ModuleDataResponse[] = []): number => {
		return data.reduce((count, module) => count + (module.active ? 1 : 0), 0);
	}

	const onSelectItemData = (module: ModuleTypes,
		integration: IntegrationType,
		status: IntegrationStatus) => {
		setIntegrationSelected({
			startDate,
			endDate,
			module,
			integration,
			status
		})
	}

	useEffect(() => {
		setIsLoading(true);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/budget-integration/find-all?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}&locale={locale}`
		}, onLoadTableData)
	}, [startDate, endDate])

	return (
		<Collapse expandIconPosition="right" accordion ghost activeKey={selectedPanel} onChange={onChange} expandIcon={({ isActive }) => renderIcon(isActive)}>
			{panelsValue.map(({ key, title }) => {
				return <Panel
					{...isLoading && { collapsible: "disabled" }}
					header={
						<PanelHeader
							isLoading={isLoading}
							runningProcesses={countRunningProcesses(chooseWichTableData[key])}
							title={title} />} key={key
							}
				>
					<ModuleTable
						onChangeSelectedTable={onChangeSelectTable}
						selectedRowKey={selectedRowKeys}
						tableData={chooseWichTableData[key]}
						onSelectItemData={onSelectItemData}
					/>
				</Panel>
			})}
		</Collapse >
	)
}