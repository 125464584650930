import { Icon } from "@iconify/react";
import { Button, Layout, Table } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SelectAllCard } from "components/selectAllCard/SelectAllCard";
import { useMemo, useState } from "react";
import i18n from "util/base/i18n";
import { commomFilterTableData } from "util/TableUtils";
import { Options } from "util/types/types";
import { AccountAccountingOptions, AccountList, ITableContainer } from "../IFromTo";

export default function TableContainer({
    selectedTab,
	isLoadingTable,
    isSelectingAllLinked,
    setIsSelectingAllLinked,
    isSelectingAllUnlinked,
    setIsSelectingAllUnlinked,
    selectedLinkedRowKeys,
    selectedUnlinkedRowKeys,
    setSelectedLinkedRowKeys,
    setSelectedUnlinkedRowKeys,
	saveLink,
	linkedData,
	unlinkedData,
	classificationId
}: ITableContainer) {
    const [searchValueUnlinked, setSearchValueUnlinked] = useState("");
    const [searchValueLinked, setSearchValueLinked] = useState("");
	const tableColumnsLinked: ColumnsType = [
        {
            title:
                selectedTab === "ledger_account"
                    ? i18n.t<string>("accounts_already_linked")
                    : i18n.t<string>("cost_center_already_linked"),
            dataIndex: "name",
            sorter: (a: AccountList, b: AccountList) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            },
			render(value) {
                return (
                    <div className="title-unlinked-table">
                        <p>{value}</p>
                        <Icon icon="akar-icons:link-chain" />
                    </div>
                );
            },
        },
	];

	const tableColumnsUnlinked: ColumnsType<Options> = [
        {
            title: i18n.t<string>("available"),
			dataIndex: "label",
        },
    ];

	const tableDataFiltered: AccountAccountingOptions[] = useMemo(() => {
		if (!classificationId) return commomFilterTableData(unlinkedData, searchValueUnlinked, ["label"]);

		const classificationData: AccountAccountingOptions[] = unlinkedData
			.filter((item) => Number(item.classificationId) === classificationId);

		return commomFilterTableData(classificationData, searchValueUnlinked, ["label"]);
	}, [unlinkedData, searchValueUnlinked, classificationId])

	const tableDataLinkedFiltered: AccountList[] = useMemo(() => {
		return commomFilterTableData(linkedData, searchValueLinked, ["externalCode", "name"]);
	}, [linkedData, searchValueLinked])

    const onChangeLinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedLinkedRowKeys(selectedRowKeys);
        },
    };

    const onChangeUnlinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUnlinkedRowKeys(selectedRowKeys);
        },
    };

    return (
        <>
            <div className="content-header">
                <div>
                    <p>{i18n.t(selectedTab)}</p>
                </div>
                <Search
                    placeholder={i18n.t("search")}
                    style={{ marginBottom: 5 }}
                    className={"linked-search"}
                    onChange={({ target }) => setSearchValueUnlinked(target.value)}
					value={searchValueUnlinked}
                />
                <Search
                    placeholder={i18n.t("search")}
                    style={{ marginBottom: 5 }}
					onChange={({ target }) => setSearchValueLinked(target.value)}
					value={searchValueLinked}
                />
            </div>
            <Layout.Content>
                <div className="content-tables">
                    <div>
                        {isSelectingAllLinked && (
                            <SelectAllCard
                                selectedRowKeys={selectedLinkedRowKeys}
                                setSelectedRowKeys={setSelectedLinkedRowKeys}
                                tableData={tableDataFiltered}
                                setIsSelectingAll={setIsSelectingAllLinked}
                            />
                        )}
						<Table
							pagination={{ hideOnSinglePage: true }}
							bordered
                            className={
								isSelectingAllLinked ? "gs-table selected-all-table" : "gs-table"
                            }
                            rowSelection={{
                                type: "checkbox",
                                ...onChangeLinked,
                                onSelectAll: (selected) => setIsSelectingAllLinked(selected),
                                selectedRowKeys: selectedLinkedRowKeys,
							}}
                            columns={tableColumnsUnlinked}
                            dataSource={tableDataFiltered}
							rowKey={"value"}
							scroll={{ y: "500px" }}
                        />
                        <Button
                            onClick={() => {
                                saveLink(false);
                                setSelectedLinkedRowKeys([]);
                            }}
                            disabled={selectedLinkedRowKeys?.length === 0}
                            className="gs-main-button"
                            icon={<Icon icon="akar-icons:link-chain" />}
                            style={{ marginTop: tableDataFiltered?.length <= 10 ? 12 : 0 }}
                        >
                            {i18n.t("link")}
                        </Button>
                    </div>
                    <div>
                        {isSelectingAllUnlinked && (
                            <SelectAllCard
                                selectedRowKeys={selectedUnlinkedRowKeys}
                                setSelectedRowKeys={setSelectedUnlinkedRowKeys}
                                tableData={tableDataLinkedFiltered}
                                setIsSelectingAll={setIsSelectingAllUnlinked}
                            />
                        )}
                        <Table
                            className={
                                isSelectingAllUnlinked ? "gs-table selected-all-table" : "gs-table"
                            }
                            rowSelection={{
                                ...onChangeUnlinked,
                                onSelectAll: (selected) => setIsSelectingAllUnlinked(selected),
                                selectedRowKeys: selectedUnlinkedRowKeys,
                            }}
                            columns={tableColumnsLinked}
                            bordered
                            dataSource={tableDataLinkedFiltered}
							pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
							rowKey={"id"}
							scroll={{ y: "500px" }}
                        />
                        {tableDataLinkedFiltered?.length > 0 && (
                            <Button
                                onClick={() => {
                                    saveLink(true);
                                    setSelectedUnlinkedRowKeys([]);
                                }}
                                disabled={selectedUnlinkedRowKeys.length === 0}
                                className="gs-secondary-button"
                                icon={<Icon icon="pajamas:unlink" />}
                                style={{ marginTop: tableDataLinkedFiltered?.length <= 10 ? 12 : 0 }}
                            >
                                {i18n.t("unlink")}
                            </Button>
                        )}
                    </div>
                </div>
            </Layout.Content>
        </>
    )
}