import { Icon } from "@iconify/react";
import { Button, Col, Row, Tooltip } from "antd";
import { DistributionFormData, DistributionListProps } from "../../IHiredDashboard";
import TooltipBox from "./TooltipBox";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import i18n from "util/base/i18n";
import { ImageBox } from "components/imageBox/ImageBox";

export function DistributionList({
	distributionList,
	setDistributionList,
	form,
	isFormSection,
	deleteFunction,
	editFunction,
	isEditButtonDisabled }: DistributionListProps) {
	const [isLoading, setIsloading] = useState(false);

	function handleDelete(data: DistributionFormData) {
		const index = distributionList.indexOf(data);
		const updatedDisList = [...distributionList];
		updatedDisList.splice(index, 1);
		setDistributionList(updatedDisList);
	}

	function handleEdit(data: DistributionFormData) {
		form.setFieldsValue(data)
		const index = distributionList.indexOf(data)
		const updatedDisList = [...distributionList]
		updatedDisList.splice(index, 1)
		setDistributionList(updatedDisList)
	}

	return (
		<div className="distribution-list-container">
			{distributionList?.length === 0 ?
				<ImageBox styles={{borderTop: '1px solid #ccc', paddingTop: 28}}/>
				:
				<div className="list-of-added-items">
					<div className="cost-category-list-header">
						<Row align="middle" gutter={3}>
							<Col span={3}>
								{i18n.t("human_resources.indexing_fields")}
							</Col >
							<Col span={3}>
								{i18n.t("business_unit")}
							</Col>
							<Col span={3}>
								{i18n.t("cost_center")}
							</Col >
							<Col span={4}>
								{i18n.t("professional_category.professional_category_name")}
							</Col>
							<Col span={3}>
								{i18n.t("cost_category")}
							</Col>
							<Col span={2}>
								{i18n.t("months")}
							</Col>
							<Col span={2}>
								{i18n.t("value")}
							</Col>
							<Col span={2}>
								{i18n.t("action")}
							</Col>
							<Col span={1}>
							</Col>
							{/* <Col span={1}>
							</Col> */}
						</Row>
					</div>
					<div className="cost-category-list-content">
						{distributionList.map(line => {
							return (
								<Row align="middle" gutter={3}>
									<Col span={3}>
										{line.field.label}
									</Col >
									<Col span={3}>
										<TooltipBox
											data={line.businessUnitIds}
										/>
									</Col>
									<Col span={3}>
										<TooltipBox
											data={line.costCenterIds}
										/>
									</Col >
									<Col span={4}>
										<TooltipBox
											data={line.professionalCategoryIds}
										/>
									</Col>
									<Col span={3}>
										<TooltipBox
											data={line.costCategoryIds}
										/>
									</Col>
									<Col span={2}>
										<TooltipBox
											data={line.distributionPeriods}
										/>
									</Col>
									<Col span={2}>
										{line.distributionValue}
									</Col>
									<Col span={2}>
										{line.action.label}
									</Col>
									{isFormSection &&
										<>
											<Col span={1} >
												<Button
													disabled={isEditButtonDisabled}
													onClick={() => editFunction(line)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} >
												<Icon
													className="delete-icon"
													onClick={() => deleteFunction(line.key)}
													icon="fa6-solid:trash"
												/>
											</Col>
										</>
										//:
										// <Col className="result-column" span={2} >
										// 	<span className={`gs-tag ${line.resultValue >= 0 ? "green" : "red"}`}>
										// 		{
										// 			new Intl.NumberFormat(i18n.language.replace("_", "-"), {
										// 				maximumFractionDigits: 2,
										// 				minimumFractionDigits: 2,
										// 			}).format(line.resultValue ? line.resultValue : 0)
										// 		}
										// 	</span>
										// </Col>
									}
								</Row>
							)
						})}
					</div>
				</div>
			}
		</div>
	)
}