import { Icon } from "@iconify/react";
import { Button } from "antd";
import i18n from "util/base/i18n";
import { FormulaEspecials } from "./FormulaEspecialsEnum";
import { FormulaOperator } from "./FormulaOperatorEnum";

interface IOperationsSelectProps {
    onChooseOperation: (operation: string) => void;
}

export function OperationsSelect({ onChooseOperation }: IOperationsSelectProps) {
    return (
        <div className="operations-select-container" style={{ display: "flex", flexDirection: "column" }}>
            <label className="gs-input-label" style={{ color: "#0065B3" }}>
                {i18n.t<string>("operations")}
            </label>
            <div className="operations-select-buttons">
                <Button
                    icon={<Icon icon="akar-icons:plus" />}
                    onClick={() => onChooseOperation(FormulaOperator.PLUS)}
                />
                <Button
                    icon={<Icon icon="akar-icons:minus" />}
                    onClick={() => onChooseOperation(FormulaOperator.LESS)}
                />
                <Button
                    icon={<Icon icon="akar-icons:cross" />}
                    onClick={() => onChooseOperation(FormulaOperator.MULTIPLY)}
                />
                <Button
                    icon={<Icon icon="ic:baseline-divide" />}
                    onClick={() => onChooseOperation(FormulaOperator.DIVIDER)}
                />
                <Button
                    icon={<p>(</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation(FormulaOperator.LEFT_PARENTHESIS)}
                />
                <Button
                    icon={<p>)</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation(FormulaOperator.RIGHT_PARENTHESIS)}
                />

                <div style={{ display: "flex" }}>
                    <Button style={{ textAlign: "center" }} onClick={() => onChooseOperation(FormulaEspecials.VALUE)}>
                        {i18n.t("value")}
                    </Button>
                    <Button
                        style={{ textAlign: "center" }}
                        onClick={() => onChooseOperation(FormulaEspecials.PERCENTAGE)}
                    >
                        {i18n.t("percentage")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
