import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import i18n from "util/base/i18n";
import { IFixedExpensePermissions } from "../../../IFixedExpense";

export const importPlanned: IImportModal = {
	importUrl: "/expenses/budget-opening/import/planned",
	templateUrl: "/expenses/budget-opening/template/planned?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
	type: 'excel',
	title: i18n.t("fixed_expense_texts.imports.import_budget"),
	hasImportOption: true,
};

export const importRealized: IImportModal = {
	importUrl: "/expenses/budget-opening/import/realized",
	templateUrl: "/expenses/budget-opening/template/realized?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
	type: 'excel',
	title: i18n.t("fixed_expense_texts.imports.import_realized"),
	hasImportOption: true,
};

export const importHistory: IImportModal = {
	importUrl: "/expenses/budget-opening/import/history",
	templateUrl: "/expenses/budget-opening/template/history?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
	type: 'excel',
	title: i18n.t("fixed_expense_texts.imports.import_history"),
	hasImportOption: true,
};

export const importPlannedYearly: IImportModal = {
	importUrl: "/expenses/budget-opening/import/planned/yearly",
	templateUrl: "/expenses/budget-opening/template/planned/yearly?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
	type: 'excel',
	title: i18n.t("fixed_expense_texts.imports.import_budget_12_months"),
	hasImportOption: true,
};

export const importHistoryYearly: IImportModal = {
	importUrl: "/expenses/budget-opening/import/history/yearly",
	templateUrl: "/expenses/budget-opening/template/history/yearly?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
	type: 'excel',
	title: i18n.t("fixed_expense_texts.imports.import_12_month_history"),
	hasImportOption: true,
};

export const importProps: IImportModal[] = [
	importPlanned,
	importRealized,
	importHistory,
	importPlannedYearly,
	importHistoryYearly,
];

export const getImportPropsByPermission: Record<string, IImportModal> = {
	importplannedmonthly: importPlanned,
	importrealized: importRealized,
	importhistorymonthly: importHistory,
	importplannedyearly: importPlannedYearly,
	importhistoryyearly: importHistoryYearly
}
