import { Button, Col, Input, Popover, Row } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { ICaptchaProps, ICaptchType } from "./ICaptcha";
import { LoadCanvasTemplate, loadCaptchaEngine, validateCaptcha } from "./components/CaptchaSimple";
import i18n from "util/base/i18n";
import './Captcha.sass';
import { Icon } from "@iconify/react";


export default function Captcha({
	onSuccessfulSubmit,
	charactersLength = 6,
	tooltipTitleI18n = "Confirme",
	hasReloadButton = true,
	disabledButton = false,
	buttonTitleI18n,
	icon,
	hasIcon = false,
	captchaType = ICaptchType.upper
}: ICaptchaProps) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>("");

	useEffect(() => {
		if (!isOpen) return;
		loadCaptchaEngine({ numberOfCharacters: charactersLength, captchaType });
	}, [isOpen, charactersLength, captchaType])

	function onSubmit() {
		if (validateCaptcha(inputValue, charactersLength, captchaType)) {
			onSuccessfulSubmit();
			handleClosePopover();
		}

		setInputValue("");
	}

	function handleOpenChange(open: boolean) {
		if (disabledButton) return;
		setIsOpen(open);
		setInputValue("")
	}

	function onChangeInput({ target: { value } }: ChangeEvent<HTMLInputElement>) {
		setInputValue(value);
	}

	function onClickReload() {
		loadCaptchaEngine({ numberOfCharacters: charactersLength, captchaType });
		setInputValue("");
	}

	function handleClosePopover() {
		setIsOpen(false);
		setInputValue("");
	}

	const content: JSX.Element = (
		<Row className="row-content">
			<Row>
				<Col span={24}>
					<LoadCanvasTemplate />
				</Col>
			</Row>
			<Row>
				<Col className="input-content">
					<Input
						placeholder={i18n.t("type_here")}
						maxLength={charactersLength}
						onChange={onChangeInput}
						suffix={hasReloadButton &&
							<div>
								<a id="reload_href" >

								</a>
							</div >
						}
						value={inputValue}
					/>
					<Button
						icon={<Icon icon="tabler:reload" />}
						onClick={onClickReload}
						type="text"
						className="gs-main-button"
					/>
				</Col>
				<Col>

				</Col>
			</Row>
			<Row className="footer-content">
				<Col >
					<Button className="gs-main-button" onClick={onSubmit}>
						{i18n.t("confirm")}
					</Button>
				</Col>
			</Row>
		</Row>
	)

	return (
        <Popover
            content={content}
            trigger={"click"}
            open={isOpen}
            onOpenChange={handleOpenChange}
            overlayClassName="captcha-component-content"
            title={i18n.t(tooltipTitleI18n)}
        >
            <Button
                className={!hasIcon ? "gs-main-button captcha-button" : "top-tool-buttons trash"}
                disabled={disabledButton}
                icon={hasIcon ? <Icon {...icon.props} /> : undefined}
            >
                {i18n.t(buttonTitleI18n)}
            </Button>
        </Popover>
    );
}