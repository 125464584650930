import { Button, Form, Modal } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { TransferForm } from "./TransferForm";
import { ITransferModal, TableDataTransfer, TransferObject, TransfersCancel } from "../../IHiredDashboard";
import { FlexFilter, Options } from "util/types/types";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Notification } from "components/notification/Notification";
import { RequestType } from "util/service/IServiceCaller";
import TransferList from "./TransferList";
import TransferFinalStep from "./TransferFinalStep";
import moment from "moment";
import { HiredTable, TableData } from "../../../IHumanResoucers";
import TransferTable from "./TransferTable";
import { Icon } from "@iconify/react";
import { useHumanResourcesContext } from "../../../context/HumanResourcesContext";

export default function TransferModal({
    isModalVisible,
    setIsModalVisible,
    hiredList,
    year,
    getTableData,
    realizedPeriod,
}: ITransferModal) {
    const [isOnFinalStep, setIsOnFinalStep] = useState(false);
    const [form] = Form.useForm();
    const [transferListOrigin, setTransferListOrigin] = useState([]);
    const [transferList, setTransferList] = useState([]);
    const [resourceSelected, setResourceSelected] = useState<HiredTable>();
    const [flexFieldValues, setFlexFieldValues] = useState<FlexFilter[]>([]);
    const [isTransfersList, setIsTransfersList] = useState(true);
    const [tableData, setTableData] = useState<TableDataTransfer[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [transfersCancel, setTransfersCancel] = useState<TransfersCancel[]>([]);

    const { costCategoryOptions, businessUnitOptions, professionalCategoryOptions } = useHumanResourcesContext();

    function onCancel() {
        if (isOnFinalStep) {
            setIsOnFinalStep(false);
            setIsTransfersList(false);
        } else {
            setIsModalVisible(false);
            form.resetFields();
            setTransferList([]);
            setIsTransfersList(true);
        }
    }
    function formatedOptions(options: any[]) {
        return options.map((option) => {
            if (option.value) return option;
            return {
                value: option.id,
                resourceType: option.resourceType,
                label: option.name ? option.name : option.description,
            };
        });
    }

    function handleAddFlexOptFilter(data: any[]) {
        setFlexFieldValues(
            data
                .map((flexField) => {
                    return {
                        value: flexField.value,
                        label: flexField.label,
                        fieldCode: flexField.fieldCode,
                        selectedOption: [],
                        children: flexField.children.map((ffValues) => {
                            return {
                                value: ffValues.value,
                                label: ffValues.label,
                            };
                        }),
                    };
                })
                .sort((a, b) => {
                    if (a.fieldCode < b.fieldCode) {
                        return -1;
                    } else if (a.fieldCode > b.fieldCode) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
        );
    }

    function handleOk() {
        if (isOnFinalStep) {
            setIsFetching(true);
            setIsTransfersList(true);
            const transferToSave = transferList.map((value) => {
                const referencePeriod = moment(value.period, "MM/YYYY");
                let flexFieldFiltered = [];
                value.flexField.map(({ flexField, flexFieldValue }) => {
                    const flexFind = flexFieldValues.find((field) => field.value === Number(flexField));
                    if (flexFind) {
                        flexFind.selectedOption = [
                            {
                                value: Number(flexFieldValue),
                                label: "",
                            },
                        ];
                        flexFieldFiltered.push(flexFind);
                    }
                });
                return {
                    quantity: value.amount,
                    type: value.type == "transfer" ? 1 : 0,
                    originId: value.resource.value,
                    referencePeriod: referencePeriod.format("YYYY-MM"),
                    justification: "teste",
                    transferReadjustment: value.type == "transfer" ? null : Number(value.readjustment) / 100,
                    destiny: {
                        businessUnitId: value.business.value,
                        costCenterId: value.costCenter.value,
                        costCategoryId: value.costCategory.value,
                        professionalCategoryId: value.professionalCategory.value,
                        flexKey: generateFlexFieldSelectedString(flexFieldFiltered),
                    },
                };
            });

            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: `/human-resources/employedTrasfer/save?scenarioId={scenario}&organizationId={organization}&referenceYear=${year}`,
                    params: transferToSave,
                },
                (data) => handleTransferSuccess(data),
                (err) => handleTransferError(err)
            );
        } else {
            setIsOnFinalStep(true);
            form.resetFields();
        }
    }

    function generateFlexFieldSelectedString(flexField: FlexFilter[]) {
        let flexFieldSelectedString = ":::::::::::::::::::::::::::::";

        flexField?.forEach((ff) => {
            if (ff.selectedOption.length === 0) return;
            const updatedData = flexFieldSelectedString.split(":");
            const position = ff.fieldCode - 1;
            const flexFilterValue = ff.selectedOption[0].value === 0 ? "" : ff.selectedOption[0].value;
            updatedData[position] = flexFilterValue.toString();
            const flexFieldString = updatedData.join(":");
            flexFieldSelectedString = flexFieldString;
        });
        return flexFieldSelectedString;
    }

    function handleTransferSuccess(data) {
        getTableData();

        if(data.success) {
            Notification({
                type: "success",
                message: i18n.t("human_resources.transfer_performed_successfully"),
            });
        } else {
            Notification({
                type: "error",
                message: i18n.t(data.message),
            });
        }

        form.resetFields();
        setResourceSelected(null);
        setTransferList([]);
        setIsFetching(false);
        setIsOnFinalStep(false);
        setIsModalVisible(false);
        setIsTransfersList(true);
    }

    function handleTransferError(err) {
        setIsFetching(false);
        Notification({
            type: "error",
            message: i18n.t("something_went_wrong"),
        });

        form.resetFields();
        setResourceSelected(null);
        setTransferList([]);
        setIsFetching(false);
        setIsOnFinalStep(false);
        setIsModalVisible(false);
        setIsTransfersList(true);
    }

    useEffect(() => {
        if (!isModalVisible) return;
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/flexfilter?module=1&userId={user}&localeId={locale}&clientId={client}`,
            },
            handleAddFlexOptFilter.bind(this)
        );
        getTransferList();
    }, [isModalVisible]);

    useEffect(() => {
        setResourceSelected(null);
        getTransferList();
        form.resetFields();
    }, [year]);

    function deleteFunction(id: number) {
        const index = transferList.findIndex((c) => c.key === id);
        const updatedTransferList = [...transferList];
        updatedTransferList.splice(index, 1);
        setTransferList(updatedTransferList);
    }

    function editFunction(data: TransferObject) {
        form.setFieldsValue({
            ...data,
            tranferAmount: data.amount,
            transferType: data.type,
            initialBudgetPeriod: moment(data.period, "MM/YYYY"),
        });

        setResourceSelected(hiredList.find((item) => item.id.toString() == data.resource.value));
        const index = transferList.indexOf(data);
        const updatedTransferList = [...transferList];
        updatedTransferList.splice(index, 1);
        setTransferList(updatedTransferList);
    }

    function handleTransferShow() {
        setIsTransfersList(!transferList);
    }

    function handleCancelTransfers() {
        setIsFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.PUT,
                url: `/human-resources/employedTrasfer/cancelTransfer?scenarioId={scenario}&organizationId={organization}&year=${year}&clientId={client}&userId={user}&locale={locale}`,
                params: transfersCancel,
            },
            () => {
                Notification({
                    type: "success",
                    message: "Transferências canceladas com sucesso",
                });
                getTransferList();
                getTableData();
                setIsFetching(false);
            }
        );
    }

    function getTransferList() {
        setIsFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/human-resources/employedTrasfer/findAll?scenarioId={scenario}&referenceYear=${year}&organizationId={organization}&locale={locale}&clientId={client}&userId={user}`,
            },
            handleFindTransfers
        );
    }

    function handleFindTransfers(data: any) {
        const transfers = data.map((transfer) => {
            return {
                id: transfer.id,
                key: transfer.id,
                originId: transfer.originResource.employedResourceId,
                originDescription: transfer.originResource.resourceDescription,
                destinationId: transfer.destinationResource.employedResourceId,
                destinationDescription: transfer.destinationResource.resourceDescription,
                transferType: transfer.transferType,
                trasnferTypeDescription: transfer.transferType == "PROMOTION" ? "Promoção" : "Transferência",
                period: moment(transfer.referencePeriod, "YYYY-MM").format("MM/YYYY"),
            };
        });
        setTableData(transfers);
        setIsFetching(false);
    }

    return (
        <Modal
            width={"70%"}
            title={i18n.t("human_resources.transfer_promotion")}
            visible={isModalVisible}
            okButtonProps={{ htmlType: "submit", form: "transfer-collab-form", disabled: !transferList.length }}
            onCancel={onCancel}
            className="gs-modal"
            cancelText={isOnFinalStep ? i18n.t<string>("back") : i18n.t<string>("cancel")}
            okText={isOnFinalStep ? i18n.t<string>("save") : i18n.t<string>("next")}
            onOk={handleOk}
        >
            {isTransfersList ? (
                <>
                    <div className="actions-content-transfer">
                        <Button
                            onClick={handleTransferShow}
                            icon={<Icon icon="octicon:arrow-switch-24" />}
                            className="collaborator-header-right-buttons"
                        >
                            Nova Transferência
                        </Button>
                        <Button
                            onClick={handleCancelTransfers}
                            icon={<Icon icon="material-symbols:cancel-outline" />}
                            className="collaborator-header-right-buttons"
                            disabled={transfersCancel.length > 0 ? false : true}
                        >
                            Cancelar Transferência
                        </Button>
                    </div>

                    <TransferTable
                        year={year}
                        isFetching={isFetching}
                        tableData={tableData}
                        setTransfersCancel={setTransfersCancel}
                    />
                </>
            ) : isOnFinalStep ? (
                <TransferFinalStep
                    transferList={transferList}
                    deleteFunction={deleteFunction}
                    editFunction={editFunction}
                />
            ) : (
                <>
                    <TransferForm
                        form={form}
                        hiredList={hiredList}
                        businessUnitOptions={businessUnitOptions}
                        costCategoryOptions={costCategoryOptions}
                        flexFieldValuesOptions={flexFieldValues}
                        professionalCategoryOptions={professionalCategoryOptions}
                        isModalVisible={isModalVisible}
                        transferList={transferList}
                        setTransferList={setTransferList}
                        year={year}
                        setResourceSelected={setResourceSelected}
                        resourceSelected={resourceSelected}
                        realizedPeriod={realizedPeriod}
                        setIsTransfersList={setIsTransfersList}
                        getTransferList={getTransferList}
                    />
                    <TransferList
                        transferList={transferList}
                        isOnFinalStep={isOnFinalStep}
                        deleteFunction={deleteFunction}
                        editFunction={editFunction}
                        isOrigin={false}
                    />
                </>
            )}
        </Modal>
    );
}
