import Table, { ColumnsType } from "antd/lib/table"
import { AllocationTable, IAllocationData, IAllocationTypeSave, IAllocationsTableProps } from "../../IHumanResoucers"
import { Button } from "antd";
import { Icon } from "@iconify/react";
import { cloneDeep } from "lodash";
import i18n from "util/base/i18n";
import { useEffect, useState } from "react";
import { useHumanResourcesContext } from "../../context/HumanResourcesContext";

export default function AllocationsTable({
	allocationsOptions,
	costCenterList,
	flexFieldValues
}: IAllocationsTableProps) {
	const { allocationList, form, setAllocationList } = useHumanResourcesContext();
	const [columns, setColumns] = useState<ColumnsType<IAllocationData>>([]);
	
	const firstPartDefaultColumns : ColumnsType<IAllocationData> =  [ 
		{
			key: "allocation",
			dataIndex: "allocation",
			title: "Alocação",
			render: (_, { allocationId }) => {
				return allocationsOptions.find(({ value }) => value === allocationId)?.label;
			},
			width: 50
		},
		{
			key: "costCenter",
			dataIndex: "costCenterId",
			title: i18n.t("cost_center"),
			render: ( costCenterId ) => {
				return costCenterList.find(({ value }) => value === costCenterId)?.label;
			},
			width: 50
		},
		{
			key: "type",
			dataIndex: "type",
			title: "Tipo de alocação",
			render: (type: IAllocationTypeSave) => {
				return chooseAllocationType[type]
			},
			width: 50
		}
	];

	const secondPartdefaultColumns : ColumnsType<IAllocationData> = [
		{
			key: "value",
			dataIndex: "value",
			title: "Percentual %",
			render: (value: number) => {
				return `${value}%`;
			},
			width: 30
		},
		{
			key: "startDate",
			dataIndex: "startDate",
			title: "De",
			render: (date: moment.Moment) => {
				return date.format("MM/YYYY");
			},
			width: 20
		},
		{
			key: "endDate",
			dataIndex: "endDate",
			title: "Até",
			render: (date: moment.Moment) => {
				return date.format("MM/YYYY");
			},
			width: 20
		},
		{
			key: "edit",
			dataIndex: "edit",
			title: "",
			render: (_, { id }) => {
				return <Button type="text" icon={<Icon icon={'material-symbols:edit-sharp'} />} onClick={() => onEdit(id)} />
			},
			width: 20
		},
		{
			key: "delete",
			dataIndex: "delete",
			title: "",
			render: (_, { id }) => {
				return <Button type="text" icon={<Icon icon={'fa6-solid:trash'} />} onClick={() => onDelete(id)} />
			},
			width: 20
		}
	]	

	useEffect(() => {
		const flexCols: ColumnsType<IAllocationData> = [];
		flexFieldValues.forEach(({value, children, label}) => {
			cloneDeep(allocationList).forEach((line) => {
				const formattedFlexField: string = `ff-${value}`;
				const flexFieldValueId = line[formattedFlexField];
				const isFlexFieldNotOnCols: boolean = !flexCols.find(({ key }) => key === formattedFlexField);

				if (isFlexFieldNotOnCols && flexFieldValueId) {
					const newFlexCol: ColumnsType<IAllocationData>= [{
						dataIndex: formattedFlexField,
						key: formattedFlexField,
						title: label,
						render: (flexFieldId:number) => {
							const flexFieldValueLabel = children.find(({value}) => value === flexFieldId)?.label;

							return flexFieldValueLabel;
						},
						width: 50
					}]

					flexCols.push(...newFlexCol);
				}
			})
		})
		setColumns([...firstPartDefaultColumns, ...flexCols, ...secondPartdefaultColumns]);
	}, [allocationList])

	function onEdit(rowId: number) {
		const formValues = allocationList.find(({ id }) => rowId === id);
		form.setFieldsValue(formValues);
		setAllocationList(state => {
			let newList = cloneDeep(state);
			newList = newList.filter(({ id }) => id !== rowId);
			return newList;
		})
	}

	function onDelete(rowId: number) {
		setAllocationList(state => {
			let newList = cloneDeep(state);
			newList = newList.filter(({ id }) => id !== rowId);
			return newList;
		})
	}

	const chooseAllocationType = {
		0: "Part-Time",
		1: "Full-Time",
	}

	return (
		<Table
			columns={columns}
			dataSource={allocationList}
			rowKey={"id"}
			rowClassName={"row-allocation-table"}
			locale={{
				emptyText: "Nenhuma alocação adicionada..."
			}}
			pagination={false}
			scroll={{ y: "300px", x: "400px" }}
		/>
	)
}