import { Row, Col, Button, Tooltip } from "antd";
import { IMenuTitleContentProps } from "../../IHumanResoucers";
import { Icon } from "@iconify/react";

export default function MenuTitleContent({
	hasValue,
	title,
	resourceId
}: IMenuTitleContentProps) {
	return (
		<Row className="resources-list-allocation">
			<Col >
				{title}
			</Col>
			{
				hasValue &&
				<Tooltip title={"Replicar alocaçoes para baixo"}>
					<Icon
						className="button-replicate-allocations"
						icon="material-symbols:content-copy"
						style={{
							marginLeft: "auto",
							boxShadow: "none",
							background: "transparent",
							alignSelf: "center",
						}}
					/>
				</Tooltip>
			}
		</Row>
	)
}