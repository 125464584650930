import { TopButtons } from 'components/topButtons/TopButtons';
import './BudgetIntegrations.sass';
import { Icon } from '@iconify/react';
import { CustomButtons } from 'components/topButtons/ITopButtons';
import { Row, Col } from 'antd';
import HeaderRightContent from './components/HeaderRightContent';
import { useEffect, useState } from 'react';
import CollapseContent from './components/CollapseContent';
import { IntegrationSelected, IntegrationStatus } from './IBudgetIntegrations';
import i18n from 'util/base/i18n';
import DataItemContent from './components/DataItemContent';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';
import { Notification } from 'components/notification/Notification';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import ReplicatScenarioModal from './components/ReplicatScenarioModal';
import SceneSetup from './components/SceneSetup';
import { useIntegrationsContext } from './context/IntegrationsContext';

export default function BudgetIntegrations() {
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const [integrationSelected, setIntegrationSelected] = useState<IntegrationSelected>();
	const [customButtons, setCustomButtons] = useState<CustomButtons[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { setSearchValue, refresh, setStartDate, setEndDate, startDate, endDate } = useIntegrationsContext();

	const chooseTitleDataItem = {
		EXPENSES: i18n.t("fixed_expense"),
		HUMAM_RESOURCES: i18n.t("human_resources.human_resources"),
		FLEX_FIELDS: i18n.t("flexible_fields"),
		GENERAL_REGISTRATIONS: i18n.t("registration_integrations"),
		REVENUE: i18n.t("flexField.modules.revenue"),
		PLANNING: i18n.t("modules.planning"),
	}

	const getMessageNotification = {
		DISCARTED:i18n.t("send_to_discard"),
		PROCESS: i18n.t("send_to_process")
	}

	const onLoadProcess = () => {
		Notification({
			message: getMessageNotification["PROCESS"],
			type: "success",
		})
		setSelectedRowKeys([]);
	}

	const onLoadDiscarted = () => {
		Notification({
			message: getMessageNotification["DISCARTED"],
			type: "success",
		})
		setSelectedRowKeys([]);
	}

	const processRequest = (keys: any[]) => {
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "budget-base/budget-integration/process",
			params: keys
		}, onLoadProcess, handleErrorRequest)
	}

	const discartedRequest = (keys: any[]) => {
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "budget-base/budget-integration/discard",
			params: keys
		}, onLoadDiscarted, handleErrorRequest)
	}

	const defaultIntegrationButtons: CustomButtons[] = [
		{
			title: i18n.t("process"),
			className: "gs-secondary-button",
			onClick: () => processRequest(selectedRowKeys),
			disabled: !selectedRowKeys.length
		},
		{
			title: i18n.t("discard"),
			className: "gs-secondary-button",
			onClick: () => discartedRequest(selectedRowKeys),
			disabled: !selectedRowKeys.length
		},
		{
			title: i18n.t("collaborators.additional_fields.configuration.replicable"),
			className: `gs-secondary-button ${selectedRowKeys.length || "disabled-button"}`,
			onClick: () => {
				setIsModalOpen(true);
			},
		}
	]

	const chooseTitleStatus = {
		PROCESSING: i18n.t("processing"),
		INTEGRATED: i18n.t("effective"),
		ERROR: i18n.t("with_error"),
		DISCARTED: i18n.t("discarded"),
		DEFAULT: ""
	}

	const onClickMainButton = () => {
		if (integrationSelected) {
			setSearchValue("");
			setIntegrationSelected(null);
			setSelectedRowKeys([]);
		} else {
			const startDateFormat = startDate.format("YYYY-MM-DD");
			const endDateFormat = endDate.format("YYYY-MM-DD");
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `budget-base/budget-integration/process-all-filter?integration=${selectedRowKeys}&startDate=${startDateFormat}&endDate=${endDateFormat}`,
			}, );	
			onLoadProcessAll()
		}	
	}

	const onLoadProcessAll = () => {
		Notification({
			message: i18n.t("send_to_process"),
			type: "success",
		})
		setSelectedRowKeys([]);
		refresh();
	}
	const getTitle = () => {
		const title = !!integrationSelected ? ` ${chooseTitleDataItem[integrationSelected.module]} - ${chooseTitleStatus[IntegrationStatus[integrationSelected.status]]}` : ""
		return `${i18n.t("budget_integrations")} ${title}`;
	}

	useEffect(() => {
		let newCustomButtons = [];
		if (!!integrationSelected && integrationSelected?.status !== IntegrationStatus.INTEGRATED) {
			newCustomButtons = defaultIntegrationButtons.slice(0, -1);
			if (integrationSelected.status === IntegrationStatus.PROCESSING) {
				newCustomButtons = [defaultIntegrationButtons[1]];
			}
		} else {
			if (!!!integrationSelected) newCustomButtons = [defaultIntegrationButtons[2]];
		}
		setCustomButtons(newCustomButtons);
	}, [integrationSelected, selectedRowKeys])

	return (
		<div className="budget-integrations-page">
			<Row className='header-content'>
				<Col span={8}>
					<div className="page-title-content">
						<h1>
							{getTitle()}
						</h1>

						<div className='top-buttons'>
							<TopButtons
								mainButtonTitle={integrationSelected ? i18n.t("back") : "Processar"}
								mainButtonIcon={<Icon icon={integrationSelected ? "akar-icons:arrow-back-thick-fill" : "fa6-solid:share"} />}
								disabledMainButton={!selectedRowKeys.length && !integrationSelected}
								handleNew={onClickMainButton}
								customButtons={customButtons}
							/>
							<ReplicatScenarioModal
								isOpen={isModalOpen}
								setIsOpen={setIsModalOpen}
							/>

							<SceneSetup
								integrationSelected={undefined}
							/>

						</div>
					</div>
				</Col>
				<Col span={16}>
					<HeaderRightContent
						endDate={endDate}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						startDate={startDate}
						integrationSelected={integrationSelected}
					/>
				</Col>
			</Row>
			<Row className='collapse-content'>
				{
					integrationSelected ?
						<DataItemContent
							selected={integrationSelected}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
						/>
						:
						<CollapseContent
							endDate={endDate}
							startDate={startDate}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
							setIntegrationSelected={setIntegrationSelected}
						/>
				}
			</Row>
		</div >
	)
}