import { FormattedNumber } from "react-intl";
import { findValueByRow } from "../../functions/findValueByRow";
import { IMonthColumn, ITotalColumn, SelectedViewType } from "../../IRevenue";

import i18n from "util/base/i18n";

import moment from "moment";

export default function ColumnGrid(monthColumnGrid: IMonthColumn, totalColumnGrid: ITotalColumn, selectedViewType: SelectedViewType, periodRealized: moment.Moment, isEditable: boolean) {

    if (monthColumnGrid) {
        let monthNow: moment.Moment = moment(monthColumnGrid.month);
        let monthFormat: string = monthNow.format("MMM YYYY");
        let monthFormatted: string = monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);

        const isRealized = periodRealized && monthNow.isSameOrBefore(periodRealized, 'month');

        return {
            title: monthFormatted,
            dataIndex: monthColumnGrid.month,
            className: isRealized ? "realized-column" : "",
            align: 'center',
            key: "value",
            editable: isEditable,
            width: 90,
			onHeaderCell: () => {
				return {
					style: {
						textAlign: "center",
                        backgroundColor: selectedViewType?.value === "FORECAST" 
                        && monthNow.isSameOrBefore(periodRealized) && moment.isMoment(periodRealized) ? "#004D7B" : null,
					}
				}
			},
            render: (text, record) => {
                if (!record.key.includes('lineBeforeCoin')) {
                    return (
                        <div style={{ minWidth: '100%' }}>
                            {findValueByRow(record, monthColumnGrid.month)}
                        </div>
                    )
                }
            }
        }
    } else if (totalColumnGrid) {
        return {
            title: i18n.t<string>("total"),
            dataIndex: 'total',
            fixed: 'right',
            width: 'max-content',
            align: 'right',
			onHeaderCell: () => {
				return {
					style: {
						textAlign: "center"
					}
				}
			},
            onCell: ((record) => ({
                className: record.key.includes('lineBeforeCoin') ? 'revenue-hidden-row-coin' : null
            })),
            render: (value, record) => (
                <>
                    {!record.key.includes('volume') && !record.key.includes('price') ?
                        (!record.key.includes('lineBeforeCoin') && (
                            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                {record.formatType && record.formatType === "DECIMAL" ? (
                                    <span style={{
                                        fontSize: 14
                                    }}>
                                        {new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                            compactDisplay: "long",
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        }).format(record.total)}
                                    </span>
                                ) : (
                                    <span style={{
                                        fontSize: 14
                                    }}>
                                        <FormattedNumber
                                            value={record.total}
                                            style={`currency`}
                                            currencyDisplay="symbol"
                                            currency={record.isoCode ? record.isoCode : null}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                        />
                                    </span>
                                )}
                            </div>
                        )) : "-"
                    }
                </>
            )
        }
    }

    return null;
}