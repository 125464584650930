import { useUserContext } from "context/UserContext";
import { ContainerComponents } from "module/budget/components/containerComponents/ContainerComponents";
import AccountRegistration from "module/budget/pages/accountRegistration/AccountRegistration";
import { BudgetProjection } from "module/budget/pages/budgetProjection/BudgetProjection";
import AdditionalFields from "module/budget/pages/collaborators/additionalFields/AdditionalFields";
import Collaborators from "module/budget/pages/collaborators/collaboratos/Collaborators";
import ItemSecurity from "module/budget/pages/collaborators/itemSecurity/ItemSecurity";
import LinkAccounts from "module/budget/pages/collaborators/linkAccounts/LinkAccounts";
import { Premisses } from "module/budget/pages/collaborators/premisses/Premisses";
import { PremissesFiltersProvider } from "module/budget/pages/collaborators/premisses/context/usePremissesFilters";
import { ControlPanel } from "module/budget/pages/controlPanel/ControlPanel";
import CostCategory from "module/budget/pages/costCategory/CostCategory";
import { DetailLayout } from "module/budget/pages/detailLayout/DetailLayout";
import { FixedExpense } from "module/budget/pages/fixedExpense/FixedExpense";
import { BudgetOpeningProvider } from "module/budget/pages/fixedExpense/context/useBudgetOpeningContext";
import FlexField from "module/budget/pages/flexField/FlexField";
import Groupings from "module/budget/pages/groupings/Groupings";
import { HumanResources } from "module/budget/pages/humanResources/HumanResources";
import { PermissionSecurity } from "module/budget/pages/permissionSecurity/PermissionSecurity";
import ProfessionalCategory from "module/budget/pages/professionalCategory/ProfessionalCategory";
import Revenue from "module/budget/pages/revenue/mainFlow/Revenue";
import AttributeParameterization from "module/budget/pages/revenue/attributeParameterization";
import ReportView from "module/budget/pages/revenue/reportView";
import DashboardAnalytics from "module/dashboard/analytics/DashboardAnalytics";
import ContractSimulation from "module/leaseProjection/pages/contractSimulation/ContractSimulation";
import ContractValues from "module/leaseProjection/pages/contractValues/ContractValues";
import ScenarioProjection from "module/leaseProjection/pages/scenarioProjection/ScenarioProjection";
import UndoProjection from "module/leaseProjection/pages/undoProjection/UndoProjection";
import { Route, Routes } from "react-router-dom";
import { FloatMenu, Header, MainMenu, ModalSceneryFilter, Module, SubHeader, UserMenu } from "structure";
import { StepModal } from 'components/stepModal/StepModal'
import { MenuModule } from "structure/module/IModule";
import { ProjectionGuard } from "util/functions/projectionGuard";
import RegistrationSalesReports from "module/budget/pages/revenue/registrationSalesReports/RegistrationSalesReports";
import RecurringContract from "module/budget/pages/revenue/recurringContract/RecurringContract";
import { EnvironmentModal } from "module/leaseProjection/components/planningEnvironmentTag/components/EnvironmentModal";
import BudgetIntegrations from "module/budget/pages/budgetIntegrations/BudgetIntegrations";
import { Apportionment } from "module/budget/pages/apportionment/Apportionment";
import { RevenueProvider } from "module/budget/pages/revenue/mainFlow/RevenueContext";
import Investment from "module/investment/pages/investment/Investment";
import InvestmentAccounting from "module/investment/pages/investmentAccounting/InvestmentAccounting";
import InvestmentTemplate from "module/investment/pages/investmentTemplate/InvestmentTemplate";
import ProjectionModeling from "module/plan/pages/projectionModeling/ProjectionModeling";
import ManagementAccount from "module/plan/pages/managementAccount/ManagementAccount";
import ManagementCostCenter from "module/plan/pages/managementCostCenter/ManagementCostCenter";
import ParameterizationFromTo from "module/budget/pages/revenue/parameterizationFromTo/ParameterizationFromTo";
import { ParameterizationProvider } from "module/budget/pages/revenue/parameterizationFromTo/context/ParameterizationContext";
import AllocationParameterization from "module/budget/pages/collaborators/allocationParameterization/AllocationParameterization";
import { HumanResourcesProvider } from "module/budget/pages/humanResources/context/HumanResourcesContext";
import FromTo from "module/plan/pages/fromTo/FromTo";
import { IntegrationsProvider } from "module/budget/pages/budgetIntegrations/context/IntegrationsContext";
import { NewReportProvider } from "module/budget/pages/revenue/registrationSalesReports/components/NewReportContext";
import { OtherEventProvider } from "module/budget/pages/planning/otherEvents/context/OtherEventContext";
import { OtherEvents } from "module/budget/pages/planning/otherEvents/OtherEvents";
import AccountingBalances from "module/plan/pages/accountingBalances/AccountingBalances";
import ReversalPeriodPerformed from "module/budget/pages/reversalPeriodPerformed/ReversalPeriodPerformed";
import { NewContractProvider } from "module/budget/pages/revenue/recurringContract/components/newContractContext";
import Consolidation from "module/plan/pages/consolidation/Consolidation";
import AccountingLedger from "module/budget/pages/accountingLedger/AccountingLedger";
import ProjectionGroupers from "module/plan/pages/groupers/ProjectionGroupers";
import DistributionRule from "module/budget/pages/distributionRule/DistributionRule";

export function Router() {
    const { isBudgetModule } = useUserContext();

    return <>
        {isBudgetModule && <ContainerComponents key="containerComponentes" />}
        <Routes key="routesContainer">
            <Route path="/budget/fixed-expense" element={<BudgetOpeningProvider> <FixedExpense /> </BudgetOpeningProvider>} />
            <Route path="/budget/control-panel" element={<ControlPanel />} />
            <Route path="/budget/apportionment" element={<Apportionment />} />
            <Route path="/budget/flex-field" element={<FlexField />} />
            <Route path="/budget/detail-layout" element={<DetailLayout />} />
            <Route path="/budget/budget-integration" element={<IntegrationsProvider><BudgetIntegrations /></IntegrationsProvider>} />
            <Route path="/budget/collaborators/professional-category" element={<ProfessionalCategory />} />
            <Route path="/budget/collaborators/collaborators" element={<Collaborators />} />
            <Route path="/budget/user-permissions" element={<PermissionSecurity />} />
            <Route path="/budget/groupings" element={<Groupings />} />
            <Route path="/budget/budget-projection" element={<BudgetProjection />} />
            <Route path="/budget/reversal-period-performed" element={<ReversalPeriodPerformed />} />
			<Route path="/budget/accounting-ledger" element={<AccountingLedger />} />
			<Route path="/budget/distribution-rules" element={<DistributionRule />} />

            <Route path="/budget/collaborators/human-resources" element={<HumanResourcesProvider> <HumanResources /> </HumanResourcesProvider>} />
            <Route path="/budget/collaborators/cost-category" element={<CostCategory />} />
            <Route path="/budget/collaborators/professional-category" element={<ProfessionalCategory />} />
            <Route path="/budget/collaborators/collaborators" element={<Collaborators />} />
            <Route path="/budget/collaborators/premisses" element={<PremissesFiltersProvider> <Premisses /> </PremissesFiltersProvider>} />
            <Route path="/budget/collaborators/additionalFields" element={<AdditionalFields />} />
            <Route path="/budget/collaborators/link-account" element={<LinkAccounts />} />
            <Route path="/budget/collaborators/account-registration" element={<AccountRegistration />} />
            <Route path="/budget/collaborators/item-security" element={<ItemSecurity />} />
            <Route path="/budget/collaborators/allocation-parameterization" element={<AllocationParameterization />} />

            <Route path="/budget/investment" element={<Investment />} />
            <Route path="/budget/investment/accounting" element={<InvestmentAccounting />} />
            <Route path="/budget/investment/template" element={<InvestmentTemplate />} />

            <Route path="/budget/revenue" element={<RevenueProvider> <Revenue /> </RevenueProvider>} />
            <Route path="/budget/revenue/attribute-parameterization" element={<AttributeParameterization />} />
            <Route path="/budget/revenue/report-view" element={<ReportView />} />
            <Route path="/budget/revenue/contract-recurring" element={<NewContractProvider> <RecurringContract /> </NewContractProvider>} />
            <Route path="/budget/revenue/parameterization-from-to" element={<ParameterizationProvider><ParameterizationFromTo /></ParameterizationProvider>} />
			<Route path="/budget/revenue/report-registration" element={<NewReportProvider> <RegistrationSalesReports /> </NewReportProvider>} />

            <Route path="/budget/planning/management-account" element={<ManagementAccount />} />
            <Route path="/budget/planning/management-cost-center" element={<ManagementCostCenter />} />
            <Route path="/budget/planning/management-parametrization" element={<FromTo />} />
            <Route path="budget/planning/other-events" element={<OtherEventProvider> <OtherEvents /> </OtherEventProvider>} />
            <Route path="/budget/planning/accounting-balances" element={<AccountingBalances />} />
			<Route path="/budget/planning/projection-grouper" element={<ProjectionGroupers />} />

            <Route path="/dashboard/analytics" element={<DashboardAnalytics />} />

            <Route path="/base/top-bar" element={<Header />} />
            <Route path="/base/breadcrumb" element={<SubHeader />} />
            <Route path="/base/menu" element={<MainMenu />} />
            <Route path="/base/user/menu" element={<UserMenu />} />
            <Route path="/base/screenFilter" element={<ModalSceneryFilter />} />
            <Route path="/base/environmentModal" element={<EnvironmentModal />} />
            <Route path="/base/stepModal" element={<StepModal />} />
            <Route path="/base/float-menu" element={<FloatMenu />} />

            <Route path="/module/budget" element={<Module code={MenuModule.BUDGET} title="menu.module.budget" icon="ps:piggy-bank-coins" />} />
            <Route path="/module/base" element={<Module code={MenuModule.BASE} title="menu.module.base" icon="dashicons:networking" />} />
            <Route path="/module/currency" element={<Module code={MenuModule.CURRENCY} title="menu.module.currency" icon="bxs:coin-stack" />} />
            <Route path="/module/cash" element={<Module code={MenuModule.CASH} title="menu.module.cash" icon="bxs:dollar-circle" />} />
            <Route path="/module/financing" element={<Module code={MenuModule.FINANCING} title="menu.module.financing" icon="fa6-solid:arrow-trend-up" />} />
            <Route path="/module/lease" element={<Module code={MenuModule.LEASE} title="menu.module.lease" icon="bx:file" />} />
            <Route path="/module/covenant" element={<Module code={MenuModule.COVENANT} title="menu.module.covenant" icon="fa6-solid:table-list" />} />
            <Route path="/module/dashboard" element={<Module code={MenuModule.DASHBOARD} title="menu.module.dashboard" icon="bxs:dashboard" />} />
            <Route path="/module/integration" element={<Module code={MenuModule.INTEGRATION} title="menu.module.integration" icon="akar-icons:link-chain" />} />
            <Route path="/module/workflow" element={<Module code={MenuModule.WORKFLOW} title="menu.module.workflow" icon="fa6-solid:arrows-spin" />} />
            <Route path="/module/management" element={<Module code={MenuModule.MANAGEMENT} title="menu.module.management" icon="fa6-solid:gear" />} />
            <Route path="/module/security" element={<Module code={MenuModule.SECURITY} title="menu.module.security" icon="ion:shield-half-outline" />} />

            <Route path="/projectionlease/scenario" element={<ProjectionGuard><ScenarioProjection /></ProjectionGuard>} />
            <Route path="/projectionlease/undo" element={<ProjectionGuard><UndoProjection /></ProjectionGuard>} />
            <Route path="/projectionlease/contract" element={<ProjectionGuard><ContractSimulation /></ProjectionGuard>} />
            <Route path='/projectionlease/contract/values/:id' element={<ProjectionGuard><ContractValues /></ProjectionGuard>} />

			<Route path="/budget/planning/projection-modeling" element={<ProjectionModeling />} />
            <Route path="/budget/planning/consolidation" element={<Consolidation />} />
        </Routes>
    </>
}
