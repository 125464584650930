import i18n from "util/base/i18n";
import { CSSProperties } from "react";
import './ImageBox.sass'

interface IImageBox {
    styles?: CSSProperties;
    imgStyles?: CSSProperties;
    title?: string
    message?: React.ReactNode;
    imgName?: string
}

export function ImageBox({styles, title, message, imgName, imgStyles}: IImageBox) {
    return (
        <div className="empty-list-container" style={styles}>
            <img src={`/assets/images/${imgName ?? 'empty_list'}.png`} alt="empty list" style={imgStyles}/>
            {title && <h3>{title}</h3>}
            <p>{(title || message) ? message : i18n.t('No_data_added_yet')}</p>
        </div>
    )
}