import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { cloneDeep } from "lodash";
import { IAllocationData } from "../../IHumanResoucers";
import MenuTitleContent from "./MenuTitleContent";
import { useHumanResourcesContext } from "../../context/HumanResourcesContext";

export default function ResourcesList() {
	const {
		resourcesList,
		resourceSelected,
		setResourceSelected,
		setAllocationsToSave,
		allocationList,
		setAllocationList,
		allocationsToSave
	} = useHumanResourcesContext();

	const menuItems: ItemType[] = resourcesList.map(({ id, resourceName }) => {
		const hasValue: boolean = menuItemHasValue(id);
		return ({ key: id.toString(), label: <MenuTitleContent hasValue={hasValue} resourceId={id} title={resourceName} /> });
	});

	function menuItemHasValue(itemId: number): boolean {
		if (resourceSelected?.id === itemId) {
			return !!allocationList.length;
		} else {
			return !!allocationsToSave.filter(({ employedResourceId }) => employedResourceId === itemId).length;
		}
	}

	function replicateValues(currentResourceId: number): IAllocationData[] {
		let newAllocationToSave: IAllocationData[] = cloneDeep(allocationsToSave);
		const allocationToOverwrite: IAllocationData[] = currentResourceId === resourceSelected.id ?
			allocationList :
			newAllocationToSave.filter(({ employedResourceId }) => employedResourceId === currentResourceId);

		let isAllocationToChange: boolean = false;
		resourcesList.forEach(({ id }) => {
			if (id === currentResourceId) {
				isAllocationToChange = true;
				return;
			}

			const allocationFormatted: IAllocationData[] = cloneDeep(allocationToOverwrite).map((item) => {
				return ({ ...item, employedResourceId: id, id: Math.random() });
			})

			if (isAllocationToChange) {
				if (id === resourceSelected.id) {
					setAllocationList(allocationFormatted);
					return;
				}
				const firstPosition: number = newAllocationToSave.findIndex(({ employedResourceId }) => id === employedResourceId);
				const lastIndexReversed: number = [...newAllocationToSave].reverse().findIndex(({ employedResourceId }) => id === employedResourceId);
				const lastPosition: number = newAllocationToSave.length - lastIndexReversed;

				const numToRemove = lastPosition - firstPosition;

				if (firstPosition < 0) {
					newAllocationToSave.push(...allocationFormatted);
				} else {
					newAllocationToSave.splice(firstPosition, numToRemove, ...allocationFormatted);
				}
			}
		})

		return newAllocationToSave;
	}

	function onChangeMenu(key: string, isClickReplicate: boolean) {
		const resourceId: number = Number(key);
		if (resourceId === resourceSelected.id && !isClickReplicate) return;
		let newAllocationsToSave: IAllocationData[] = [];
		let newAllocationList = [];

		if (isClickReplicate) {
			newAllocationsToSave = replicateValues(resourceId);
		} else {
			newAllocationsToSave = allocationsToSave.filter(({ employedResourceId }) => employedResourceId !== resourceSelected.id).concat(allocationList);
			newAllocationList = cloneDeep(allocationsToSave).filter(({ employedResourceId }) => employedResourceId === resourceId);
			setResourceSelected(resourcesList.find(({ id }) => resourceId === id));
			setAllocationList(newAllocationList);
		}

		setAllocationsToSave(newAllocationsToSave);
	}

	function verifyIsClickReplicate({ target }: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): boolean {
		const stringTarget: string = target.toString().toLowerCase();
		return stringTarget.includes("path") || stringTarget.includes("svg");
	}

	return (
		<Menu
			items={menuItems}
			selectable
			onClick={({ domEvent, key }) => {
				const isClickReplicate: boolean = verifyIsClickReplicate(domEvent);
				onChangeMenu(key, isClickReplicate);
			}}
			selectedKeys={[resourceSelected?.id?.toString()]}
		/>
	)
}