import { createContext, useContext, useState } from "react";
import { DataResponse, IIntegrationsContext, ModuleDataResponse } from "../IBudgetIntegrations";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import moment from "moment";

const IntegrationsContext = createContext<IIntegrationsContext>({} as IIntegrationsContext);

export const IntegrationsProvider = ({ children }) => {
	const [fixedExpenseTableData, setFixedExpenseTableData] = useState<ModuleDataResponse[]>([]);
	const [humanResourcesTableData, setHumanResourcesTableData] = useState<ModuleDataResponse[]>([]);
	const [revenueTableData, setRevenueTableData] = useState<ModuleDataResponse[]>([]);
	const [planningTableData, setPlanningTableData] = useState<ModuleDataResponse[]>([]);
	const [generalRegistrations, setGeneralRegistrations] = useState<ModuleDataResponse[]>([]);
	const [flexFeldsTableData, setFlexFeldsTableData] = useState<ModuleDataResponse[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchValue, setSearchValue] = useState<string>("");
	const [tableData, setTableData] = useState<any[]>([]);
	const [startDate, setStartDate] = useState<moment.Moment>(moment(new Date()));
	const [endDate, setEndDate] = useState<moment.Moment>(moment(new Date()));
	const [columns, setColumns] = useState<ColumnsType<any>>([{
		title: i18n.t("error"),
		dataIndex: "errors",
		width: 150,
		ellipsis: true,
		render: (value: any) => {
			const allErrors: string[] = Object.values(value);
			return (
				<div className="error-column">
					{allErrors.map(item => <p>{item}, </p>)}
				</div>
			)
		}
	}]);

	const onLoadTableData = (data: DataResponse) => {
		setFixedExpenseTableData(data.EXPENSES);
		setFlexFeldsTableData(data.FLEX_FIELDS);
		setGeneralRegistrations(data.GENERAL_REGISTRATIONS);
		setHumanResourcesTableData(data.HUMAN_RESOURCES);
		setRevenueTableData(data.REVENUE);
		setPlanningTableData(data.PLANNING);
		setIsLoading(false);
	}

	const chooseWichTableData = {
		EXPENSES: fixedExpenseTableData,
		HUMAM_RESOURCES: humanResourcesTableData,
		FLEX_FIELDS: flexFeldsTableData,
		GENERAL_REGISTRATIONS: generalRegistrations,
		REVENUE: revenueTableData,
		PLANNING: planningTableData
	}
	function refresh() {
        setIsLoading(true)
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/budget-integration/find-all?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}&locale={locale}`
        }, onLoadTableData)
    }   

	return (
		<IntegrationsContext.Provider
			value={{
				fixedExpenseTableData,
				humanResourcesTableData,
				revenueTableData,
				planningTableData,
				generalRegistrations,
				flexFeldsTableData,
				chooseWichTableData,
				isLoading,
				onLoadTableData,
				setIsLoading,
				searchValue,
				setSearchValue,
				tableData,
				setTableData,
				columns,
				setColumns,
				refresh,
				startDate,
				setStartDate,
				endDate,
				setEndDate
			}}>
			{children}
		</IntegrationsContext.Provider>
	)
}

export const useIntegrationsContext = () => {

	return useContext(IntegrationsContext);
}