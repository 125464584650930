import { Icon } from "@iconify/react";
import { Button } from "antd";
import { Draggable } from "react-beautiful-dnd";
import { EqualOp, NotEqualOp } from "../../../components/operators/MathOperators";

export default function ConditionLine({ index, selectedLevel, operation, selectedLevelValue, handleRemove }) {
    function handleOperation(operator) {
        switch (operation) {
            case "EQUALS":
                return <EqualOp />;
            case "NOT_EQUALS":
                return <NotEqualOp />;
        }
    }
    return (
        <Draggable draggableId={index + ""} key={index} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className="step-one-list-line">
                        <div className="step-one-list-label">{selectedLevel}</div>

                        {handleOperation(operation)}

                        <div className="step-one-list-label">{selectedLevelValue}</div>
                        <div>
                            <Button
                                className="top-tool-buttons edit"
                                icon={<Icon icon="mdi:trash" />}
                                style={{
                                    border: 0,
                                    background: "transparent",
                                    outline: "none",
                                    boxShadow: "none",
                                }}
                                onClick={() => handleRemove(index)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
