import { Button, Carousel, DatePicker, Row } from "antd";
import { IFilters } from "./IFilters";
import { Filter } from "components/filter/Filter";
import "./Filters.sass"
import i18n from "util/base/i18n";
import moment from "moment";
import { useHumanResourcesContext } from "../../context/HumanResourcesContext";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { FlexFilterOptions, Options } from "util/types/types";
import { ProfessionalCategoryOptions } from "hooks/useProfessionalCategoryOptions";
import { CostCategoryOptions } from "hooks/useCostCategoryOptions";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { useEffect, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";

export function Filters(props: IFilters) {
    const windowSize = useWindowSize();
    const [filtersPerRow, setFiltersPerRow] = useState<number>(6);
    const { professionalCategoryOptions, setProfessionalCategoryOptions, businessUnitOptions, setBusinessUnitOptions, isFetchingOptions, flexFieldValues,
        costCenterOptions, setCostCenterOptions, costCategoryOptions, setCostCategoryOptions, isFetchingFlexFields, setFlexFieldValues } = useHumanResourcesContext();

	function disableDate(current: moment.Moment) {
		if (!props.openedCalendarPeriod.includes(current.year())) {
			return true
		}
		return false;
	}

    function handleCheckItems(data: Options[] | ProfessionalCategoryOptions[] | CostCategoryOptions[] | FlexFilterOptions[], type: string, currentFlexField?: number) {
        switch (type) {
            case "professional-category":
                const updatedProfessionalCategories = professionalCategoryOptions.map(item => ({...item, checked: data.some(selectedItem => selectedItem.value === item.value)}));
                setProfessionalCategoryOptions(updatedProfessionalCategories);
                break;
            case "business-unit":
                const updatedBusinessUnits = businessUnitOptions.map(item => ({...item, checked: data.some(selectedItem => selectedItem.value === item.value)}));
                setBusinessUnitOptions(updatedBusinessUnits);
                break;
            case "cost-center":
                const updatedCostCenters = costCenterOptions.map(item => ({...item, checked: data.some(selectedItem => selectedItem.value === item.value)}));
                setCostCenterOptions(updatedCostCenters);
                break;
            case "cost-category":
                const updatedCostCategories = costCategoryOptions.map(item => ({...item, checked: data.some(selectedItem => selectedItem.value === item.value)}));
                setCostCategoryOptions(updatedCostCategories);
                break;
            case "flex-field":
                const updatedFlexFieldValues = flexFieldValues.map(item => {
                    const shouldUpdateChildren = item.value === currentFlexField;

                    if (shouldUpdateChildren) {
                        return {
                            ...item,
                            children: item.children.map(child => ({
                                ...child,
                                checked: data.some(selectedItem => selectedItem.value === child.value)
                            }))
                        };
                    }

                    return item;
                });

                setFlexFieldValues(updatedFlexFieldValues);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 258) / 186);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    function handleApplyFilters() {
        props.getTableData();
    }

    return (
        <Row className="filters-container-style">
            {isFetchingOptions || isFetchingFlexFields && 
                <FilterSkeleton direction="row" active amount={4} gap={10} cardClassName="filter-card-skeleton" />
            }
            {!isFetchingOptions && !isFetchingFlexFields && (
                <div style={{ width: "calc(100% - 330px)", display: "flex" }}>
                    <div className="filter-year-container">
                        <Row> <p className="filter-name">{i18n.t("period")}</p></Row>
                        <Row>
                            <DatePicker
                                onChange={props.onChangeDatePicker}
                                format="YYYY"
                                picker="year"
                                value={moment(props.year)}
                                allowClear={false}
                                disabledDate={disableDate}
                            />
                        </Row>
                    </div>
                    <Carousel
                        className={"gs-carousel"}
                        {...carouselDefaultProps}
                        slidesPerRow={filtersPerRow}
                    >
                        <Filter
                            key={"business-category-filter"}
                            id={"business-category-filter-container"}
                            data={businessUnitOptions}
                            title={i18n.t("business_unit")}
                            type={"checkbox"}
                            searchPlaceholder={i18n.t<string>("search_by") + " " + i18n.t("business_unit")}
                            value={businessUnitOptions.filter(businessUnit => businessUnit.checked)}
                            setValue={(value) => handleCheckItems(value, "business-unit")}
                            isFetching={isFetchingOptions}
                            hasSearch={true}
                        />
                        <Filter
                            key={"cost-category-category-filter"}
                            id={"cost-category-filter-container"}
                            data={costCategoryOptions}
                            title={i18n.t("cost_category")}
                            type={"checkbox"}
                            searchPlaceholder={i18n.t<string>("search_by") + " " + i18n.t("cost_category")}
                            value={costCategoryOptions.filter(costCategory => costCategory.checked)}
                            setValue={(value) => handleCheckItems(value, "cost-category")}
                            isFetching={isFetchingOptions}
                            hasSearch={true}
                        />
                        <Filter
                            key={"cost-center-category-filter"}
                            id={"cost-center-filter-container"}
                            data={costCenterOptions}
                            title={i18n.t("cost_center")}
                            type={"checkbox"}
                            searchPlaceholder={i18n.t<string>("search_by") + " " + i18n.t("cost_center")}
                            value={costCenterOptions.filter(costCenter => costCenter.checked)}
                            setValue={(value) => handleCheckItems(value, "cost-center")}
                            isFetching={isFetchingOptions}
                            hasSearch={true}
                        />
                        <Filter
                            key={"professional-category-filter"}
                            id={"professional-category-filter-container"}
                            data={professionalCategoryOptions}
                            title={i18n.t("professional_category.professional_category_name")}
                            type={"checkbox"}
                            searchPlaceholder={i18n.t<string>("search_by") + " " + i18n.t("professional_category.professional_category_name")}
                            value={professionalCategoryOptions.filter(professionalCategory => professionalCategory.checked)}
                            setValue={(value) => handleCheckItems(value, "professional-category")}
                            isFetching={isFetchingOptions}
                            hasSearch={true}
                        />
                        {flexFieldValues.map(flexField => (
                            <Filter
                                key={flexField.label}
                                id={"filter-container"}
                                data={flexField.children}
                                title={flexField.label}
                                type={"checkbox"}
                                searchPlaceholder={i18n.t<string>("search_by") + " " + flexField.label}
                                value={flexField.children.filter(ff => ff.checked)}
                                setValue={(value) => handleCheckItems(value, "flex-field", flexField.value)}
                                isFetching={isFetchingOptions}
                                hasSearch={true}
                            />
                        ))}
                    </Carousel>
                </div>
            )}
            <Button
                onClick={() => handleApplyFilters()}
                style={{ padding: '0 35px' }}
                className="gs-main-button"
                disabled={isFetchingOptions || isFetchingFlexFields}
            >
                {i18n.t("apply")}
            </Button>
        </Row>
    )
}
