import { Icon } from "@iconify/react";
import { Col, Form, Row, Select, Table } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { selectProps, tableProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import {
	FormulaFieldType,
	IOriginDataResponse,
	OriginData,
} from "../../IProjectionModeling";
import ConfirmationModal from "../../components/ConfirmationModal";
import FormulaContent from "../../components/formula/FormulaContent";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { buildOriginData } from "../../functions/buildData";

export default function StepTwo() {
	const [searchContent, setSearchContent] = useState("");

	const { form, formula, setFormula, fieldList, setFieldList, eventSelected, setIsFormulaFetching } = useProjectionModelingContext();

	const filteredData = fieldList.filter((item) => item.label.toLowerCase().includes(searchContent));

	const origin = Form.useWatch("origin", form);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [pendingValue, setPendingValue] = useState<boolean>(false);
	const [previousValue, setPreviousValue] = useState<IOriginDataResponse>(origin);
	const [originData, setOriginData] = useState<OriginData[]>([]);

	const columns: ColumnsType = [
		{
			title: i18n.t<string>("origin"),
			dataIndex: "label",
			className: "button-origin",
			align: "left",
			render: (text, record) => {
				return (
					<div style={{ cursor: "pointer", width: "100%" }} onClick={() => addFieldToFormula(record)}>
						<div className="column-wrapper">{text}</div>
					</div>
				);
			},
		},
		{
			title: i18n.t<string>("button"),
			width: 100,
			className: "button-origin",
			render: (item) => {
				return (
					<div style={{ cursor: "pointer", width: "100%" }} onClick={() => addFieldToFormula(item)}>
						<div className="column-wrapper">
							<Icon icon="ep:arrow-right-bold" style={{ color: "#d7d7d7" }} />
						</div>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		if (!eventSelected) return;
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/planning/projection/get-origin?event=${eventSelected}`,
			},
			(data: IOriginDataResponse) => buildOriginData(data, setOriginData)
		);
	}, [eventSelected]);

	useEffect(() => {
		if (pendingValue || !origin) {
			return;
		};
		setIsFormulaFetching(true);
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/planning/projection/get-fields?origin=${origin}`,
			},
			onLoadFields
		);
	}, [origin, pendingValue, originData]);

	const onLoadFields = (data) => {
		const fields = Object.keys(data).map((key) => {
			const label = i18n.t(`${data[key].toLowerCase().replace(".", "_")}`);
			return {
				label: label,
				value: {
					id: key,
					type: "field",
					name: i18n.t(`${data[key].replace(".", "_")}`),
				},
			};
		});

		setPreviousValue(origin);
		setFieldList(fields);
		setIsFormulaFetching(false);
	};

	function addFieldToFormula(field: any) {
		setFormula((state) => {
			const expressions = state?.expressions || [];
			const newExpression = {
				position: expressions.length,
				fieldType: FormulaFieldType.STATIC_FIELD,
				operator: "",
				staticField: field.value.id,
				modularFieldId: field.modularFieldId || 0,
				scenarioId: 0,
				organizationId: 0,
				projectionId: state?.projectionId || null,
				managementAccountingAccountId: 0,
				managementCostCenterId: 0,
				period: "",
				rate: 100,
				value: 0,
				formulaExpression: field.value,
			};

			return {
				...state,
				expressions: [...expressions, newExpression],
			};
		});
	}

	function handleConfirmOk() {
		setFormula((state) => {
			return {
				...state,
				expressions: [],
			};
		});

		setShowModal(false);
		setPendingValue(false);
		setPreviousValue(origin);
	}

	function handleConfirmCancel() {
		setShowModal(false);
		setPendingValue(false);
		if (previousValue !== null) {
			form.setFieldsValue({ origin: previousValue });
		}
		setPreviousValue(null);
	}

	function onSearch(e) {
		setSearchContent(e.target.value.toLowerCase());
	}

	return (
		<div id="step-two-new-projection">
			<Form className="step-two-form-content" form={form} layout="vertical" requiredMark={false}>
				<div className="form-container">
					<Col style={{ display: "flex", height: "100%" }}>
						<Col className="origin-container" span={8}>
							<Form.Item
								className="fields-container-form-item"
								name="origin"
								label={`${i18n.t<string>("origin")}`}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							>
								<Select
									id="input-origin"
									options={originData}
									placeholder={i18n.t<string>("select")}
									onChange={(value) => {
										if (formula.expressions.length !== 0) {
											setPendingValue(true);
											setShowModal(true);
										} else {
											setPreviousValue(value);
										}
									}}
									{...selectProps}
								/>
							</Form.Item>
							<Table
								className={"table-projection"}
								columns={columns}
								dataSource={filteredData}
								pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
								scroll={{ y: 240 }}
								showHeader={false}
								title={() => {
									return (
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<Row>
												<p>{"Selecione o item da fórmula"}</p>
											</Row>
											<Search
												style={{ width: "60%" }}
												placeholder="Pesquisar"
												onChange={onSearch}
											/>
										</div>
									);
								}}
							/>
						</Col>
						<Row className="formula-container" style={{}}>
							<FormulaContent />
						</Row>
					</Col>
				</div>
			</Form>

			<ConfirmationModal
				isModalVisible={showModal}
				setIsModalVisible={setShowModal}
				message={"Ao alterar a origem, a fórmula será excluída, tem certeza que deseja continuar?"}
				handleConfirmOk={handleConfirmOk}
				handleConfirmCancel={handleConfirmCancel}
			/>
		</div>
	);
}
