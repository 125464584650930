import { Icon } from "@iconify/react";
import { Button, Col, Popover, Radio, RadioChangeEvent, Row, Space, notification } from "antd";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import StringUtil from "util/StringUtil";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { ReportViewContext } from "../../context/ReportViewContext";
import { exportGridExcel } from '../../exportToExcel';
import { FiltersModal } from "../FiltersModal/";
import { Filter } from "../custom/Filter";
import { ConfigurationsPopOver } from "./ConfigurationsPopOver";
import FilterRadioPopover from "./FilterRadioPopover";
import {
    ICurrency, ICurrencyFilter,
    IPeriodicity, IPeriodicityFilter, IReport, IReportConfig, IReportFilter,
    ReportType
} from "./IFilters";
import { RowFiltersComparative } from "./RowFiltersComparative/RowFiltersComparative";
import { Notification } from "components/notification/Notification";
import { IReportConfigParams } from "../../IReportView";

export function Filters() {
    const {
        period,
        handleChangePeriod,
        selectedReportId,
        reportConfig,
        handleSelectReportId,
        handleReportConfig,
        fetchReport,
        openFilterModal,
        isFilterModalOpen,
        isPopOverAvailable,
        defaultColumns,
        report,
        popOverConfigs,
        showRowFilterComparative,
        handleShowRowFilterComparative,
        periodBase,
        handleIsDetailingLevel,
        handleSelectedCurrency,
        selectedCurrency,
        selectedModalityType,
        handleSelectedModalityType,
        setIsPlanningReportSelected,
        handleSelectedPeriodicityType,
        selectedPeriodicityType,
        allReports,
        consolidationSelected
    } = useContext(ReportViewContext);

    const { userInfo } = useUserContext();

    const [isConfigurationsPopOverOpen, setIsConfigurationsPopOverOpen] = useState(false);
    const [allPeriodicities, setAllPeriodicities] = useState<IPeriodicityFilter[]>([]);
    const [allCurrencies, setAllCurrencies] = useState<ICurrencyFilter[]>([]);
    const [selectedReport, setSelectedReport] = useState<IReportFilter[]>([]);
    const [scenarios, setScenarios] = useState<{ value: string, label: string }[]>([]);

    const modalityTitle: string = StringUtil.capitalizeFirstLetter(i18n.t<string>("modality"));
    const currencyTitle: string = StringUtil.capitalizeFirstLetter(i18n.t<string>("currency"));
    const periodicityTitle: string = StringUtil.capitalizeFirstLetter(i18n.t<string>("periodicity"));
    const currencyLabel: string = selectedCurrency ? allCurrencies.find((currency) => currency.value === selectedCurrency)?.label : null;
    const periodicityLabel: string = selectedPeriodicityType ? allPeriodicities.find((periodicity) => periodicity.value === selectedPeriodicityType)?.label : null;

    function openConfigurationsPopOver() {
        setIsConfigurationsPopOverOpen(prevValue => !prevValue);
    }

    function closeConfigurationsPopOver() {
        setIsConfigurationsPopOverOpen(false);
    }

    function loadSelectedReportConfigurations(id: number) {
        ServiceCaller.doRequest(
            {
                url: `/budget-report/report/configuration/find-by-report/${id}`,
                type: RequestType.GET,
            },
            (data: IReportConfig) => {
                handleChangePeriod([moment(data.endPeriod), moment(data.startPeriod)]);
                handleReportConfig(data);
            },
            (err: ErrorRequest) => {
                notification.error({
                    message: i18n.t<string>("report_configuration.not_exist_configuration_for_this_report"),
                    placement: "bottomRight",
                    duration: 5,
                });

                handleErrorRequest(err);
            }
        );
    }

    function loadAllPeriodicities(): void {
        ServiceCaller.doRequest(
            {
                url: `/budget-report/report/configuration/period`,
                type: RequestType.GET,
            },
            (data: IPeriodicity[]) => {
                const translationPrefix = "revenue.report_viewer.";

                setAllPeriodicities(data.map(modality => ({
                    label: i18n.t<string>(translationPrefix + modality.name.toLowerCase()),
                    type: modality.name,
                    value: modality.id
                })));
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function loadAllCurrencies(): void {
        ServiceCaller.doRequest(
            {
                url: `/budget-base/currency-conversion/find-all-with-description?locale={locale}`,
                type: RequestType.GET,
            },
            (data: ICurrency[]) => {
                setAllCurrencies(data.map(currency => {
                    return {
                        label: currency.name,
                        value: currency.id
                    };
                }));
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function handleSelectReport(selectedReport: IReportFilter[]): void {
        if (selectedReport.length === 0) return;
        const [report] = selectedReport;

        const { value } = report;
        handleSelectReportId(value);
        setSelectedReport(selectedReport);
        loadSelectedReportConfigurations(value);
        setIsPlanningReportSelected(report.reportType === ReportType.PLANNING)
    }

    function handleSelectModality(selectedModality: RadioChangeEvent): void {
        handleSelectedModalityType(selectedModality.target.value);
    }

    function handlePeriodFilter(newPeriod: [moment.Moment, moment.Moment]): void {
        handleChangePeriod([newPeriod[1], newPeriod[0]]);
    }

    function handleCurrency(currency: RadioChangeEvent): void {
        handleSelectedCurrency(currency.target.value);
    }

    function handlePeriodicity(periodicity: RadioChangeEvent): void {
        handleSelectedPeriodicityType(getPeriodicityByInt(Number(periodicity.target.value)));
    }

    const formatDate = (date: moment.Moment): string => date.format("YYYY-MM");

    function getStartDate(): string {
        if (selectedModalityType === "COMPARATIVE_SCENARIO_YEARS") {
            return periodBase[1].isBefore(periodBase[0]) ? formatDate(periodBase[1]) : formatDate(periodBase[0]);
        }
        return formatDate(period[1]);
    };

    function getEndDate(): string {
        if (selectedModalityType === "COMPARATIVE_SCENARIO_YEARS") {
            return periodBase[0].isAfter(periodBase[1]) ? formatDate(periodBase[0]) : formatDate(periodBase[1]);
        }
        return formatDate(period[0]);
    };

    function handleExportToExcel(): void {
        exportGridExcel({
            defaultColumns: defaultColumns,
            handleExportGridData: handleExportGridData,
            report: report,
            selectedModality: selectedModalityType,
            popOverConfigs,
            selectedPeriodicityType: getPeriodicityByInt(selectedPeriodicityType)
        });
    }

    function saveNewReportConfig(): void {
        const validations: { condition: boolean; message: string }[] = [
            { condition: !!selectedReportId, message: "report_configuration.select_report" },
            { condition: !!selectedModalityType, message: "report_configuration.select_modality" },
            { condition: !!selectedCurrency, message: "report_configuration.select_currency" },
            { condition: !!selectedPeriodicityType, message: "report_configuration.select_periodicity" },
        ];

        for (const { condition, message } of validations) {
            if (!condition) {
                Notification({
                    type: "warning",
                    message: i18n.t<string>(message),
                    duration: 5,
                });
                return;
            }
        }

        const newReportConfig: IReportConfigParams = {
            id: reportConfig.id,
            startPeriod: getStartDate(),
            endPeriod: getEndDate(),
            periodType: getPeriodicityByInt(selectedPeriodicityType),
            currencyId: selectedCurrency,
            modalityType: selectedModalityType,
        };

        ServiceCaller.doRequest(
            {
                url: `/budget-report/report/configuration`,
                type: RequestType.POST,
                params: newReportConfig,
            },
            (data: IReportConfig) => {
                handleReportConfig(data);
                fetchReport(data);
                handleIsDetailingLevel(false);
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }
    function getPeriodicityByInt(int: number): string {
        switch (int) {
            case 1:
                return "MONTHLY";
            case 2:
                return "BIMONTHLY";
            case 3:
                return "QUARTERLY";
            case 6:
                return "SEMESTERLY";
            case 12:
                return "YEARLY";
            default:
                return "MONTHLY";
        }
    }

    const modalitys = (
        <>
            {consolidationSelected ? (
                <Radio.Group onChange={handleSelectModality} value={selectedModalityType} style={{ paddingTop: 10, paddingBottom: 15 }}>
                    <Space direction="vertical">
                        <Radio value={"PLANNED"}>{i18n.t<string>("budgeted")}</Radio>
                        <Radio value={"ACCOMPLISHED"}>{i18n.t<string>("accomplished")}</Radio>
                        <Radio value={"COMPARATIVE_ACCOMPLISHED_PLANNED"}>{i18n.t<string>("revenue.report_viewer.comparative_accomplished_planned")}</Radio>
                    </Space>
                </Radio.Group>
            ) : (
                <Radio.Group onChange={handleSelectModality} value={selectedModalityType} style={{ paddingTop: 10, paddingBottom: 15 }}>
                    <Space direction="vertical">
                        <Radio value={"PLANNED"}>{i18n.t<string>("budgeted")}</Radio>
                        <Radio value={"ACCOMPLISHED"}>{i18n.t<string>("accomplished")}</Radio>
                        <Radio value={""} disabled={true}>
                            {i18n.t<string>("revenue.report_viewer.comparative")}
                            <div style={{ display: 'grid', marginTop: 10, borderLeft: '1px solid #DBDBDB', }}>
                                <Radio value={"COMPARATIVE_ACCOMPLISHED_PLANNED"} style={{ marginLeft: 10 }}>{i18n.t<string>("revenue.report_viewer.comparative_accomplished_planned")}</Radio>
                                <Radio value={"COMPARATIVE_SCENARIO_YEARS"} style={{ marginLeft: 10 }}>{i18n.t<string>("revenue.report_viewer.comparative_scenario_years")}</Radio>
                            </div>
                        </Radio>
                        <Radio value={"FORECAST"}>Forecast</Radio>
                    </Space>
                </Radio.Group>
            )}
        </>
    )

    const currencys = (
        <Radio.Group onChange={handleCurrency} value={selectedCurrency} style={{ paddingTop: 10, paddingBottom: 15 }}>
            {allCurrencies.map((currency) => (
                <Radio
                    value={currency.value}
                    key={currency.value}
                >
                    {currency.label}
                </Radio>
            ))}
        </Radio.Group>
    )

    const periodicitys = (
        <Radio.Group onChange={handlePeriodicity} value={selectedPeriodicityType} style={{ paddingTop: 10, paddingBottom: 15 }}>
            {allPeriodicities.map((periodicity) => (
                <Radio
                    value={periodicity.value}
                    key={periodicity.value}
                >
                    {periodicity.label}
                </Radio>
            ))}
        </Radio.Group>
    )

    useEffect(() => {
        loadAllPeriodicities();
        loadAllCurrencies();

        ServiceCaller.doRequest({
            url: `/monolith/scenario/find-all-scenario-with-default?client={client}&locale={locale}`,
            type: RequestType.GET,
        }, (data: any[]) => {
            setScenarios(data.map(scenario => ({
                label: scenario.name,
                value: scenario.id
            })));
        }, (err: ErrorRequest) => {
            handleErrorRequest(err);
        });
    }, []);

    useEffect(() => {
        if (selectedModalityType) {
            if (selectedModalityType.toLowerCase() === "comparative_scenario_years") {
                handleShowRowFilterComparative(true);
                return;
            }

            handleShowRowFilterComparative(false);
        }
    }, [selectedModalityType]);

    return (
        <>
            {isFilterModalOpen && <FiltersModal />}

            <div id="filters-container">
                <div id="filters-options">
                    <div>
                        <Row
                            wrap={false}
                            align="middle"
                            gutter={[0, 30]}
                        >
                            <Col className="revenue-period-filter-card">
                                <Filter
                                    id="period-filter"
                                    title={i18n.t<string>("report")}
                                    type="radio"
                                    value={selectedReport}
                                    data={allReports}
                                    setValue={handleSelectReport}
                                />
                            </Col>
                            <FilterRadioPopover
                                title={modalityTitle}
                                content={modalitys}
                                selectedValue={selectedModalityType ? i18n.t<string>("revenue.report_viewer." + selectedModalityType.toLowerCase()) : null}
                            />
                            <FilterRadioPopover
                                title={currencyTitle}
                                content={currencys}
                                selectedValue={selectedCurrency ? currencyLabel : null}
                            />
                            {!showRowFilterComparative && (
                                <Col className="revenue-period-filter-card">
                                    <Filter
                                        id="period-filter"
                                        title={i18n.t<string>("period")}
                                        type="datepicker"
                                        value={period}
                                        setValue={handlePeriodFilter}
                                        data={[]}
                                        link='/'
                                        datepicker={{
                                            format: "MM/YYYY",
                                            picker: "month",
                                            rangePicker: true,
                                        }}
                                        buttonStyles={{ cursor: 'default' }}
                                    />
                                </Col>
                            )}
                            {!consolidationSelected && (
                                <FilterRadioPopover
                                    title={periodicityTitle}
                                    content={periodicitys}
                                    selectedValue={selectedPeriodicityType ? periodicityLabel : null}
                                />
                            )}
                        </Row>
                    </div>
                    <div>
                        {showRowFilterComparative && (
                            <RowFiltersComparative userInfo={userInfo} scenarios={scenarios} />
                        )}
                    </div>
                </div >

                <div id="filters-buttons">
                    <Col style={{ marginRight: 20 }} aria-disabled={true}>
                        {(isPopOverAvailable) ? (
                            <Popover
                                destroyTooltipOnHide
                                overlayClassName="premisses-filters-settings-popover-container"
                                placement="bottomRight"
                                trigger={["click"]}
                                visible={isConfigurationsPopOverOpen}
                                onVisibleChange={openConfigurationsPopOver}
                                content={(
                                    <ConfigurationsPopOver handleClosePopOver={closeConfigurationsPopOver} />
                                )}
                            >
                                <Button
                                    className="filter-config-buttons"
                                    icon={(<Icon icon="material-symbols:settings" aria-disabled />)}
                                />
                            </Popover>
                        ) : (
                            <Button
                                className="filter-config-buttons"
                                icon={(<Icon icon="material-symbols:settings" aria-disabled />)}
                                style={{
                                    background: '#fff',
                                    opacity: 0.4
                                }}
                                disabled={true}
                            />
                        )}
                        <Button
                            className="filter-config-buttons"
                            icon={(
                                <Icon
                                    icon="clarity:filter-solid"
                                />
                            )}
                            onClick={openFilterModal}
                        />
                    </Col>

                    <Col style={{
                        display: 'flex'
                    }}>
                        <Button
                            className="load_report_button"
                            onClick={saveNewReportConfig}
                        >
                            {i18n.t<string>("revenue.load_report")}
                        </Button>
                        <div style={{
                            marginTop: 5
                        }}>
                            <ImportExportMenu
                                exportGridData={handleExportToExcel}
                                importProps={[]}
                                buttonType="3dots"
                            />
                        </div>
                    </Col>
                </div>
            </div>
        </>
    )
}
