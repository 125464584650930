import { IGroupList } from "../../IProjectionModeling";

export type ListConditions = {
    id?: number | string;
    levels: number;
    levelsValue: number;
	operation: IConditionOperator;
};

export interface IConditionsForm {
	levelsValue: number[];
    levels: number;
}
export interface IGrouperModalProps {
    modalVisible: boolean;
    handleVisibilityModal: (open: boolean) => void;
    selectedGrouper: any;
    setGrouperList: React.Dispatch<React.SetStateAction<any[]>>;
    grouperList: IGroupList[];
    fetchTableData: () => void;
    setSelectedGrouper: React.Dispatch<React.SetStateAction<any>>;
}
export interface ISaveGrouperParams {
    id: string;
    externalCode: string;
    description: string;
}
export interface IFormValues {
    grouperCode: string;
    grouperDescription: string;
}

export enum IConditionOperator {
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS"
}