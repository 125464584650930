import { Icon } from "@iconify/react";
import { Tag as AntdTag } from "antd";
import * as React from "react";
import { useRef } from "react";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { IFieldList } from "../../IProjectionModeling";
import ProjectionModelingModalProps from "./ProjectionModelingModal";

interface ITagProps {
    children: any;
    isMathOperation?: boolean;
    itemIndex: number;
    isParenthesis?: boolean;
    removeItem: (index: any) => void;
    fieldList?: IFieldList[];
    hasProjectionConf?: boolean;
}

export function Tag({
    children,
    itemIndex,
    removeItem,
    isMathOperation = false,
    isParenthesis = false,
    fieldList,
    hasProjectionConf,
}: ITagProps) {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const tagRef = useRef(null);

    const { setFormula } = useProjectionModelingContext();

    const handleMouseOver = () => {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "initial";
    };

    const handleMouseOut = () => {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "hidden";
    };

    const formattedChildren = (() => {
        if (typeof children === "string") {
            return children.replace("_", ".");
        }

        if (React.isValidElement(children)) {
            return children;
        }

        return String(children);
    })();

    const openModal = (): void => setModalOpen(true);

    const handleCancelModal = (): void => {
        setFormula((prevFormula) => {
            const newState = [...prevFormula.expressions];
            return {
                ...prevFormula,
                expressions: newState,
            };
        });
        setModalOpen(false);
    };

    return (
        <>
            <AntdTag
                ref={tagRef}
                className="attribute_parameterization-new-attribute-modal-formula-workarea-tag"
                closable
                onClose={() => removeItem(itemIndex)}
                style={{
                    color: "#0065B3",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: !isMathOperation && !hasProjectionConf ? 0 : "",
                    background: isMathOperation ? "transparent" : "",
                    border: isMathOperation ? "none" : "",
                    transform: isParenthesis && "translateY(15%)",
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {hasProjectionConf ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <>
                            {formattedChildren}
                            <Icon
                                icon="fa6-solid:gear"
                                style={{ marginTop: 1, marginLeft: 6, cursor: "pointer" }}
                                onClick={openModal}
                            />
                        </>
                    </div>
                ) : (
                    <>{formattedChildren}</>
                )}
            </AntdTag>
            <ProjectionModelingModalProps
                itemIndex={itemIndex}
                isModalVisible={modalOpen}
                handleCancel={handleCancelModal}
            />
        </>
    );
}
