import { Layout } from "antd";
import { ProjectionModelingProvider } from "./context/ProjectionModelingContext";
import ProjectionTitle from "./components/ProjectionTitle";
import ProjectionModelingTable from "./components/ProjectionModelingTable";
import NewProjection from "./newProjection/NewProjection";
import './style.sass'

const { Header, Content } = Layout;
export default function Projection() {
	return (
		<ProjectionModelingProvider>
			<Layout className="light projection-groupres-page projection-modeling-page">
				<Header>
					<ProjectionTitle />
				</Header>
				<Content>
					<ProjectionModelingTable />
					<NewProjection />
				</Content>
			</Layout>
		</ProjectionModelingProvider>
	)
}