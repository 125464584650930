import { Modal } from "antd";
import i18n from "util/base/i18n";
import { ConfirmationModalProps } from "../IProjectionModeling";

export default function ConfirmationModal({
    isModalVisible,
    setIsModalVisible,
    message,
    handleConfirmOk,
    handleConfirmCancel,
}: ConfirmationModalProps) {
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal
            width="30%"
            title={i18n.t("attention")}
            visible={isModalVisible}
            okButtonProps={{ style: { backgroundColor: "#FF4D4F" } }}
            onOk={handleConfirmOk ? handleConfirmOk : handleOk}
            className="gs-modal confirm-last-level-modal-container"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("yes")}
            cancelButtonProps={{ onClick: handleConfirmCancel ? handleConfirmCancel : handleCancel }}
            closable={false}
            centered
        >
            <div className="modal-confirmations-last-level">
                <p>{message}</p>
            </div>
        </Modal>
    );
}
