import { Form, FormInstance, Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { Key, useContext, useEffect, useState } from "react";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest, ModuleType } from "util/types/types";
import { EditableRowProps } from "../../../humanResources/IHumanResoucers";
import { CurrencyAndMeasuringChoice, CurrencyElement, IMainColumn, IRevenueTable, RevenueGridRequest, RevenueHeaderInformations, RevenueItemTable, RevenueMeasuringUnit } from "../IRevenue";
import { buildChildrenGrid } from "../functions/buildChildrenGrid";
import { EditableCell } from "./row/EditableCell";
import { verifyIfIsAccomplished } from "../functions/verifyIfIsAccomplished";

import { useUserContext } from "context/UserContext";

import { usePermissionContext } from "context/PermissionContext";
import { RevenueContext } from "../RevenueContext";

import i18n from "util/base/i18n";
import HeaderBarGrid from "./header/HeaderBarGrid";

import * as React from "react";
import '../Revenue.sass';
import { expandIcon } from "./row/ExpandIcon";
import { buildColumnsByViewType } from "./functions/buildColumnsByViewType";
import { useBudgetDates } from "hooks/useBudgetDates";

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const components = {
	body: {
		row: EditableRow,
		cell: EditableCell,
	},
};

export default function RevenueTable({
	itensOrdened, setItensOrdened,
	hasSavedValue, setHasSavedValue,
	setCardHeaderInformation,
	isFilterChanged, setIsFilterChanged,
}: IRevenueTable) {

	const [selectedRow, setSelectedRow] = useState<Key>('');
	const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
	const [flexFieldTree, setFlexFieldTree] = useState<RevenueItemTable[]>([]);
	const [columnsGrid, setColumnsGrid] = useState<any[]>();
	const [listRowsSelected, setListRowsSelected] = useState<RevenueItemTable[]>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [measuringList, setMeasuringList] = useState<RevenueMeasuringUnit[]>([]);
	const [currencyList, setCurrencyList] = useState<CurrencyElement[]>([]);
	const [currencyAndMeasuringChoice, setCurrencyAndMeasuringChoice] = useState<CurrencyAndMeasuringChoice>({ currencyId: 0, measuringId: 0 });
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [keysSelected, setKeysSelected] = useState<any>([]);
	const { data: budgetPeriodDates } = useBudgetDates(ModuleType.REVENUE)
	const { userInfo } = useUserContext();
	const { functionalityPermissions } = usePermissionContext();
	const { selectedViewType, isLoading, handleIsLoading, isFetching, handleIsFetching,
		hasChangeOnGrid, period, updateFiltersFlexField, handleUpdateFiltersFlexField, flexFieldsSelected,
		isOrdenationChanged, handleIsOrdenationChanged, handleIsViewTypeChanged,
		isViewTypeChanged, monthColumns, periodAccomplished, lastMonthAccomplished
	} = useContext(RevenueContext);

	useEffect(() => {
		if (!budgetPeriodDates || monthColumns.length === 0) {
			return
		}

		const obj: IMainColumn = {
			monthsColumns: monthColumns,
			selectedRow: selectedRow, setSelectedRow: setSelectedRow,
			currencyList: currencyList, measuringList: measuringList,
			currencyAndMeasuringChoice: currencyAndMeasuringChoice, setCurrencyAndMeasuringChoice: setCurrencyAndMeasuringChoice,
			handleIsLoading: handleIsLoading,
			updateTreeGrid: updateTreeGrid,
			isEdit: isEdit, setIsEdit: setIsEdit,
			expandedRowKeys: expandedRowKeys, setExpandedRowKeys: setExpandedRowKeys,
			setHasSavedValue: setHasSavedValue,
			functionalityPermissions: functionalityPermissions
		}

		let columns = buildColumnsByViewType(
			selectedViewType,
			monthColumns,
			periodAccomplished,
			calculateCellClassName,
			selectedRow,
			updateTreeGrid,
			setHasSavedValue,
			obj,
			budgetPeriodDates,
			functionalityPermissions
		);

		setColumnsGrid(columns);
		setIsFilterChanged(true);
	}, [selectedRow, monthColumns, selectedViewType, functionalityPermissions, budgetPeriodDates,periodAccomplished]);

	useEffect(() => {
		if (isFirstLoad) {
			setIsFirstLoad(false);
		} else {
			doLoadTree(false);
			doLoadCards();
		}
	}, [hasChangeOnGrid]);

	useEffect(() => {
		if (userInfo.clientId != null) {
			doLoadTree(true);
			doLoadCards();

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/currency-conversion/find-all-with-description?locale={locale}`,
			}, handleCurrencyFlexField.bind(this), handleErrorRequest.bind(this));

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/unit-conversion`
			}, setMeasuringList.bind(this), handleErrorRequest.bind(this));
		}
	}, [userInfo.clientId, flexFieldsSelected]);

	useEffect(() => {
		if (!isFirstLoad) {
			doLoadTree(false);
			doLoadCards();
		}
	}, [period]);

	function doLoadTree(isFirstLoad: boolean) {
		let fields = flexFieldsSelected.filter(({ flexFieldValuesId }) => flexFieldValuesId?.length).map(({ flexFieldId, order, originalOrder, flexFieldValuesId }) => {
			return {
				flexFieldId,
				order,
				originalOrder,
				active: itensOrdened.some(item => item.id === flexFieldId && item.checked),
				flexFieldValueIds: flexFieldValuesId
			};
		});

		if (!userInfo.clientId || !fields.length) {
			handleIsFetching(false);
			return;
		}

		if (!isFetching && (isFirstLoad || updateFiltersFlexField || isFilterChanged || isOrdenationChanged || isViewTypeChanged)) {
			handleIsFetching(true);
		}

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: `/revenue/load-tree`,
			params: {
				userId: userInfo.id,
				groupIds: userInfo.groupIds,
				clientId: userInfo.clientId,
				localeId: userInfo.localeId,
				scenarioId: userInfo.selection.scenarioId,
				organizationId: userInfo.selection.organizationId,
				businessUnitId: userInfo.selection.businessUnitId,
				startPeriod: period[0].isBefore(period[1]) ? period[0].format("YYYY-MM") : period[1].format("YYYY-MM"),
				endPeriod: period[1].isAfter(period[0]) ? period[1].format("YYYY-MM") : period[0].format("YYYY-MM"),
				revenueViewType: selectedViewType.value,
				fields
			}
		}, handleFlexFieldTree.bind(this, isFirstLoad), (err: ErrorRequest) => {
			handleErrorRequest(err);
			if (isLoading) handleIsLoading(false);
			if (isFetching) handleIsFetching(false);
		});
	};

	function handleLoadCards(data: any) {
		let revenueHeaderInformations: RevenueHeaderInformations = {
			planned: data.planned,
			accomplished: data.accomplished
		};

		setCardHeaderInformation(revenueHeaderInformations);
	}

	function updateTreeGrid(closeFetching: boolean) {
		doLoadTree(false);
		doLoadCards();

		if (isLoading) {
			handleIsLoading(false);
		};

		if (closeFetching) {
			handleIsFetching(false);
		}
	}

	function doLoadCards() {
		const startPeriod: string = period[0].isBefore(period[1]) ? period[0].format("YYYY-MM") : period[1].format("YYYY-MM");
		const endPeriod: string = period[1].isAfter(period[0]) ? period[1].format("YYYY-MM") : period[0].format("YYYY-MM");
		const groupIds = userInfo?.groupIds ? userInfo.groupIds : [];

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/revenue/load-cards?client={client}&scenario={scenario}&organization={organization}&businessUnit={businessUnit}&startPeriod=${startPeriod}&endPeriod=${endPeriod}&user={user}&groupId=${groupIds}`
		}, handleLoadCards.bind(this), (err: ErrorRequest) => {
			handleErrorRequest(err);
			if (isLoading) handleIsLoading(false);
			if (isFetching) handleIsFetching(false);
		});
	}

	const rowSelection: TableRowSelection<RevenueItemTable> = {
		onChange: (_, selectedRows) => {
			const selectedList = selectedRows.map(row => {
				if (row?.key) {
					return row.key
				}
			});

			setKeysSelected(selectedList);
			setListRowsSelected(selectedRows);
		},
		onSelect: (_, __, selectedRows) => {
			const selectedList = selectedRows.map(row => {
				if (row?.key) {
					return row.key
				}
			});

			setKeysSelected(selectedList);
			setListRowsSelected(selectedRows);
		},
		onSelectAll: (_, selectedRows, __) => {
			const listToCheck = selectedRows.filter((value) => {
				if (value?.measuringUnitName && value?.hasCheckbox) {
					return value;
				}
			});

			setListRowsSelected(listToCheck);

			const listKeysToCheck = selectedRows.map((value) => {
				if (value?.measuringUnitName && value?.hasCheckbox && value?.key) {
					return value.key;
				}
			});

			setKeysSelected(listKeysToCheck);
		},
		selectedRowKeys: keysSelected,
		columnWidth: 25,
		getCheckboxProps: (record) => {
			if (record.key.includes('lineBeforeCoin') || !record.measuringUnitName) {
				return {
					className: 'disable-checkbox-revenue',
					disabled: false
				};
			}
		}
	};
	function calculateCellClassName(record: RevenueItemTable, col, selectedRow) {
		const isAccomplished = verifyIfIsAccomplished(record, col.dataIndex, periodAccomplished);
		const isCoinRow = record.key.includes('lineBeforeCoin');

		if (isAccomplished && !isCoinRow) {
			return 'revenue-icon-replicate-values-accomplished';
		}

		return verifyIfIsCoinRow(selectedRow, record.key);
	}

	function verifyIfIsCoinRow(selectedRow, rowKey): string {
		if (rowKey.includes('lineBeforeCoin') && selectedRow === rowKey) {
			return 'revenue-hidden-row-coin';
		} else if (rowKey.includes('lineBeforeCoin') && selectedRow !== rowKey) {
			return 'revenue-hidden-row-border';
		} else {
			return null;
		}
	}

	const handleExpand = (expanded: boolean, record: RevenueItemTable) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.key]);
		} else {
			setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
		}
	};

	const handleCurrencyFlexField = (data: any) => {
		let listCurrency: CurrencyElement[] = [];
		data.forEach((element: any) => {
			const currency: CurrencyElement = {
				id: element.id,
				name: element.name
			}

			listCurrency.push(currency);
		});

		setCurrencyList(listCurrency);
	}

	const handleFlexFieldTree = (isFirstLoad: boolean, data: RevenueGridRequest[]) => {
		let flexFieldList: RevenueItemTable[] = [];

		if (data) {
			data.forEach((element: RevenueGridRequest, index: number) => {
				let flex: RevenueItemTable = {
					key: element.flexFieldValue.name + "-" + element.flexFieldValue.id + "-" + index,
					fieldValueId: element.flexFieldValue.id,
					name: element.flexFieldValue.name,
					children: [],
					dates: element.dates,
					total: element.total,
					isoCode: element.currency ? element.currency.isoCode : null,
					hasCheckbox: true,
					flexFieldValueIds: element.flexFieldValueIds
				}

				flex.children = element.childrens ? buildChildrenGrid(flex, element.childrens, period[0], period[1], [index]) : null;
				flexFieldList.push(flex);
			});

			if (isFirstLoad) {
				setExpandedRowKeys(getAllTreeRowKeys(flexFieldList));
				handleIsFetching(false);
			}

			setFlexFieldTree(flexFieldList);

			if (updateFiltersFlexField) {
				handleIsFetching(false);
				handleUpdateFiltersFlexField(false);
			}

			if (isFilterChanged) {
				handleIsFetching(false);
				setIsFilterChanged(false);
			}

			if (isOrdenationChanged) {
				handleIsFetching(false);
				handleIsOrdenationChanged(false);
			}

			if (isViewTypeChanged) {
				handleIsFetching(false);
				handleIsViewTypeChanged(false);
			}
		}

		handleIsLoading(false);
	}

	const getAllTreeRowKeys = (rows: RevenueItemTable[]) => {
		let keys: Key[] = [];

		rows.forEach(({ key, children }) => {
			if (children) {
				keys = keys.concat(getAllTreeRowKeys(children));
			}

			keys.push(key);
		});

		handleIsFetching(false);
		return keys;
	};

	return <>
		<div style={{ border: '0.2px solid #D7D7D7' }}>
			<HeaderBarGrid
				itensOrdened={itensOrdened} setItensOrdened={setItensOrdened}
				flexFieldTree={flexFieldTree} setFlexFieldTree={setFlexFieldTree}
				hasSavedValue={hasSavedValue}
				listRowsSelected={listRowsSelected}
				updateTreeGrid={updateTreeGrid}
				columnsGrid={columnsGrid}
				lastMonthAccomplished={lastMonthAccomplished}
				setExpandedRowKeys={setExpandedRowKeys}
				period={period}
			/>

			<div>
				<Table
					className='gs-table'
					columns={columnsGrid}
					onExpand={handleExpand}
					expandedRowKeys={expandedRowKeys}
					rowSelection={rowSelection}
					dataSource={flexFieldTree.length > 0 ? flexFieldTree : null}
					pagination={false}
					bordered={true}
					components={components}
					expandIcon={expandIcon}
					loading={{
						spinning: isFetching,
						tip: i18n.t<string>("loading")
					}}
					scroll={{ x: 'max-content', y: 500 }}
				/>
			</div>
		</div>
	</>
}
