import { createContext, useContext, useEffect, useState } from "react";
import { HiredTable, IAllocationData, IHumanResourcesContext } from "../IHumanResoucers";
import { Form } from "antd";
import moment from "moment";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ProfessionalCategoryOptions } from "hooks/useProfessionalCategoryOptions";
import { FlexFilter, Options } from "util/types/types";
import { CostCategory } from "../../collaborators/linkAccounts/ILinkAccounts";
import { CostCategoryOptions } from "hooks/useCostCategoryOptions";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { useUserContext } from "context/UserContext";

const HumanResourcesContext = createContext<IHumanResourcesContext>(null);

export function HumanResourcesProvider({ children }) {
	const { userInfo: { selection: { scenarioId, organizationId } } } = useUserContext();
	const [isOpen, setIsOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [resourcesList, setResourcesList] = useState<HiredTable[]>([]);
	const [resourceSelected, setResourceSelected] = useState<HiredTable>();
	const [allocationList, setAllocationList] = useState<IAllocationData[]>([]);
	const [allocationsToSave, setAllocationsToSave] = useState<IAllocationData[]>([]);
	const [isDisabledAllocatedButton, setIsDisabledAllocatedButton] = useState<boolean>(true);
	const [professionalCategoryOptions, setProfessionalCategoryOptions] = useState<ProfessionalCategoryOptions[]>([]);
	const [costCategoryOptions, setCostCategoryOptions] = useState<CostCategoryOptions[]>([]);
	const [businessUnitOptions, setBusinessUnitOptions] = useState<Options[]>([]);
	const [flexFieldValues, setFlexFieldValues] = useState<FlexFilter[]>([]);
	const [costCenterOptions, setCostCenterOptions] = useState<Options[]>([]);
	const [isFetchingOptions, setIsFetchingOptions] = useState<boolean>(true);
	const [isFetchingFlexFields, setIsFetchingFlexFields] = useState<boolean>(true);
	const [isEditing, setIsEditing] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchOptions();
	}, [])

	function closeModal() {
		setIsOpen(false);
		setResourcesList([]);
		setResourceSelected(null);
		setAllocationList([]);
		setAllocationsToSave([]);
		form.resetFields();
		setIsSaving(false);
		setIsEditing(false)
	}

	const chooseAllocationType = {
		"PART_TIME": 0,
		"FULL_TIME": 1
	}

	function onLoadEditAllocation(data: IAllocationData[]) {
		setAllocationList(data.map((item) => {
			return ({
				...item,
				startDate: moment(item.startDate),
				endDate: moment(item.endDate),
				type: chooseAllocationType[item.type],
			})
		}));
		setIsEditing(true);
		form.resetFields();
	}

	useEffect(() => {
		if (!(isEditing && resourceSelected)) return;
		const id = resourceSelected.id ? resourceSelected.id : resourceSelected.key;
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/allocation-employed-resource-link/find-by-employed-resource-ids?employedResourceIds=${id}`,
		}, onLoadEditAllocation)
	}, [isEditing, resourceSelected])

	function fetchOptions() {
		setIsFetchingOptions(true);

		const professionalCategoryRequest = new Promise<void>((resolve) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: '/human-resources/professional-category/findAllWithSecurity?locale={locale}&client={client}&user={user}&scenario={scenario}&organization={organization}',
				},
				(data) => {
					onLoadProfessionalCategory(data);
					resolve();
				},
				(error) => {
					resolve();
				}
			);
		});
	
		const businessUnitRequest = new Promise<void>((resolve) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: '/monolith/business-unit/findAllByOrganizationIdWithSecurity?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}',
				},
				(data) => {
					onLoadBusinessUnit(data);
					resolve();
				},
				(error) => {
					resolve();
				}
			);
		});

		const costCategoryRequest = new Promise<void>((resolve) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
            		url: "/human-resources/cost-category?scenario={scenario}&organization={organization}",
				},
				(data) => {
					onLoadCostCategory(data);
					resolve();
				},
				(error) => {
					resolve();
				}
			);
		});

		const costCenterRequest = new Promise<void>((resolve) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
            		url: `/budget-base/cost-center?locale={locale}&client={client}&bu={businessUnit}&user={user}&organization={organization}`,
				},
				(data) => {
					onLoadCostCenter(data);
					resolve();
				},
				(error) => {
					resolve();
				}
			);
		});

		Promise.all([professionalCategoryRequest, businessUnitRequest, costCategoryRequest, costCenterRequest])
			.finally(() => setIsFetchingOptions(false));
	}

	function onLoadProfessionalCategory(data) {
		const professionalCategories: ProfessionalCategoryOptions[] = data.map((item) => ({
            label: item.name,
            value: item.id,
            active: item.active,
            externalCode: item.externalCode,
			checked: false
        }))

		setProfessionalCategoryOptions(professionalCategories);
	}

	function onLoadBusinessUnit(data) {
		const businessUnits: Options[] = data.map((item) => ({
            label: item.name,
            value: item.id,
			checked: false
        }))

		setBusinessUnitOptions(businessUnits);
	}

	function onLoadCostCategory(data) {
		const costCategories: CostCategoryOptions[] = data.map((item) => ({
            label: item.name,
            value: item.id,
            active: item.active,
            externalCode: item.externalCode,
			checked: false
        }))

		setCostCategoryOptions(costCategories);
	}

	function onLoadCostCenter(data: Options[]) {
		const costCenterOptions: Options[] = data.map((item) => ({
			...item,
			checked: false
		}))

		setCostCenterOptions(costCenterOptions);
	}

	useEffect(() => {
		if (organizationId) {
			setIsFetchingFlexFields(true);
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/flex-field/find-all-by-module?module=HUMAN_RESOURCES`,
			}, handleAddFlexOptFilter.bind(this));
		}
	}, [organizationId])

	async function handleAddFlexOptFilter(data: any[]) {
		const flexField: FlexFilter[] = data
			.sort((a, b) => a.ordenation - b.ordenation)
			.map(item => {
				return {
					label: item.description,
					value: item.id,
					linkedFilters: item.filters ? item.filters : null,
					ordenation: item.ordenation,
					fieldCode: Number(item.fieldCode.split('_').pop()),
					selectedOption: [],
					children: []
				}
			});
		try {
			const promises = flexField.map(ff => {
				if (!ff.linkedFilters || ff.linkedFilters.includes('ORGANIZATION')) {
					return new Promise<void>((resolve, reject) => {
						ServiceCaller.doRequest({
							type: RequestType.GET,
							url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`
						}, (data: any[]) => {
							if (ff.linkedFilters?.includes('ORGANIZATION')) {
								ff.children = data
									.filter(item => item.organizationFilters ? item.organizationFilters.includes(organizationId) : true)
									.map(item => ({
										value: item.id,
										label: item.externalCode + ' - ' + item.description,
										flexFieldId: item.flexFieldId,
										checked: false
									}))
								return resolve();
							} else {
								ff.children = data.map(item => ({
									value: item.id,
									label: item.externalCode + ' - ' + item.description,
									flexFieldId: item.flexFieldId,
									checked: false
								}))
								return resolve();
							}
						}, (err) => {
							handleErrorRequest(err);
							return reject();
						})
					})
				}
				return Promise.resolve()
			})

			await Promise.all(promises);
		} catch (error) {
			handleErrorRequest(error);
		}

		setIsFetchingFlexFields(false);
		setFlexFieldValues(flexField);
	}

	return (
		<HumanResourcesContext.Provider
			value={{
				isOpen,
				setIsOpen,
				resourcesList,
				setResourcesList,
				resourceSelected,
				setResourceSelected,
				allocationList,
				setAllocationList,
				form,
				closeModal,
				allocationsToSave,
				setAllocationsToSave,
				isSaving,
				setIsSaving,
				isEditing,
				setIsEditing,
				isDisabledAllocatedButton,
				setIsDisabledAllocatedButton,
				isFetchingOptions,
				professionalCategoryOptions,
				setProfessionalCategoryOptions,
				businessUnitOptions,
				setBusinessUnitOptions,
				costCategoryOptions,
				setCostCategoryOptions,
				costCenterOptions,
				setCostCenterOptions,
				flexFieldValues,
				setFlexFieldValues,
				isFetchingFlexFields
			}}>
			{children}
		</HumanResourcesContext.Provider>
	)
}

export function useHumanResourcesContext() {
	return useContext(HumanResourcesContext);
};