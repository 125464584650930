import { FormulaFieldType, FormValues, TableData } from "../IProjectionModeling";
import moment from "moment";
import { IExpression, IFormula } from "../newProjection/stepTwo/IStepTwo";

export const formatProjectionConditios = (data: any[]) => {
	const formattedConditions = !data
		? []
		: data.map((item) => {
			return {
				id: item.id,
				levels: item.flexFieldId,
				levelsValue: item.flexFieldValueId,
				operation: item.condition,
			};
		});

	return formattedConditions;
}

export const handleEditProjection = (data: TableData): FormValues => {
	return ({
		description: data.description,
		event: data.event,
		origin: data.origin,
		calculationOrder: data.calculationOrder,
		initialDate: moment(data.startDate),
		endDate: moment(data.endDate),
		currency: data.currencyId,
		base: data.percentualBase,
		cashCreditAcc: data.cashCreditAccountingAccountId,
		cashDebitAcc: data.cashDebitAccountingAccountId,
		cashCreditCc: data.cashCreditCostCenterId,
		cashDebitCc: data.cashDebitCostCenterId,
		competenceCreditAcc: data.accrualCreditAccountingAccountId,
		competenceDebitAcc: data.accrualDebitAccountingAccountId,
		competenceCreditCc: data.accrualCreditCostCenterId,
		competenceDebitCc: data.accrualDebitCostCenterId,
		boxCronogramWhole: data.schedulePayment === "WHOLE",
		boxCronogramInstallment: data.schedulePayment === "INSTALLMENT",
		ledgerId: data.ledgerId,
		grouper: data.grouperId,
		projectionCopyType: data.projectionCopyType,
		indexerId: data.indexerId,
		indexerType: data.indexerType
	});
}

export const formatProjectionFormula = (formula: IFormula, event: string): IFormula => {
	const expressions: IExpression[] = [];
	formula?.expressions?.forEach((item, index) => {
		if (item.formulaExpression) {
			item.modularFieldId = event === "REVENUE" ? Number(item.formulaExpression.id) : null;
		}

		if (item.fieldType === FormulaFieldType.STATIC_FIELD) {
			if (event !== "REVENUE") {
				item.fieldType = FormulaFieldType.STATIC_FIELD;
				item.staticField = item.formulaExpression?.id || item.staticField;
			} else {
				item.fieldType = FormulaFieldType.MODULAR_FIELD;
				item.staticField = null;
			}
		}

		const expression = {
			position: index,
			fieldType:
				item.operator !== FormulaFieldType.RIGHT_PARENTHESIS &&
					item.operator !== FormulaFieldType.LEFT_PARENTHESIS
					? item.fieldType
					: FormulaFieldType.OPERATOR,
			operator: item.operator || null,
			staticField: item.staticField,
			modularFieldId: item.modularFieldId || null,
			projectionId: item?.projectionId || null,
			managementAccountingAccountId: item?.managementAccountingAccountId || null,
			managementCostCenterId: item?.managementCostCenterId || null,
			period: item?.period,
			rate: item?.rate || 100,
			value: item?.value || null,
			scenarioId: item.scenarioId || null,
			organizationId: item.organizationId || null,
		};
		expressions.push(expression);
	});
	const formulaBean = {
		expressions: expressions,
	};
	return formulaBean;
}