import { Icon } from "@iconify/react";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Notification } from "components/notification/Notification";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import { usePermissionContext } from "context/PermissionContext";
import { useUserContext } from "context/UserContext";
import { useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useProjectionModelingContext } from "../context/ProjectionModelingContext";
import { formatProjectionConditios, handleEditProjection } from "../functions/handleProjection";
import MultipleSearch from "./MultipleSearch";

export default function ProjectionTitle() {
    const { userInfo: { selection: { scenarioId, organizationId, }, clientId, localeId, id } } = useUserContext();
    const {
        tableData,
        selectedRows,
        selectedRowKeys,
        setSelectedRowKeys,
        setSelectedRows,
        setFilterdTableData,
        itemForEdit,
        setIsGridFetching,
        getTableData,
        setIsEditingProjection,
        setIsNewProjection,
        tableColumns,
        form,
        setFormula,
        setIsWhole,
        setIsInstallment,
        setStepOneListConditions,
        isNewProjection
    } = useProjectionModelingContext();
    const { functionalityPermissions } = usePermissionContext();
    const [isCalculatingBalance, setIsCalculatingBalance] = useState<boolean>(false);

    const modelingProcess: CustomButtons = {
        className: "icon-copy",
        icon: <Icon icon="material-symbols:settings" />,
        disabled: isCalculatingBalance,
        dropDownItens: [
            { title: i18n.t("perform_balance_calculation"), onClick: handleClickCalculateBalanceButton },
            { title: i18n.t("fetch_values"), onClick: handleClickFetchValuesButton },
            { title: i18n.t("copy"), onClick: handleClickFutureProjection },
        ],
    };
    function handleClickCalculateBalanceButton() {
        setIsCalculatingBalance(true);

        const data = {
            scenario: scenarioId,
            organization: organizationId,
        };

        Notification({
            type: "info",
            message: i18n.t("calculation.started"),
        });
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/planning/projection/create-planning-values?user={user}&client={client}&scenario={scenario}&organization={organization}",
                params: data,
            },
            (data: any) => {
                Notification({
                    type: data.type,
                    message: i18n.t(data.message),
                });

                setIsCalculatingBalance(false);
            }
        );
    }

    function handleClickFetchValuesButton() {
        setSelectedRowKeys([]);
        setSelectedRows([]);

        const data = {
            scenario: scenarioId,
            projections: JSON.stringify(selectedRowKeys),
        };

        Notification({
            type: "info",
            message: i18n.t("find_values.started"),
        });
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/planning/projection/create-values-by-projections?projections=${selectedRowKeys.toString()}&scenario={scenario}&client={client}&user={user}&organization={organization}`,
                params: data,
            },
            (data) => {
                if (data) {
                    Notification({
                        type: data.type,
                        message: i18n.t(data.message),
                    });
                }
            }
        );
    }

    function handleClickFutureProjection() {
        const data = {
            scenario: scenarioId,
            locale: localeId,
            client: clientId,
            user: id,
            organization: organizationId,
        };

        Notification({
            type: "info",
            message: i18n.t("copy_started"),
        });
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/planning/planning-balance/copy-values-long-projection",
                params: data,
            },
            (data: any) => {
                Notification({
                    type: data.type,
                    message: i18n.t(data.message),
                });
            }
        );
    }

    function onDeleteProjection(data) {
        Notification({
            type: data.type,
            message: data.type === "success" ? i18n.t("successfully_deleted") : i18n.t(data.message),
        });

        getTableData();
    }

    function handleDelete() {
        setIsGridFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.DELETE,
                url: `/planning/projection?scenario={scenario}&organization={organization}&ids=${selectedRowKeys.toString()}`,
            },
            onDeleteProjection.bind(this),
            handleErrorRequest
        );
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function handleNew() {
        setIsEditingProjection(false);
        setIsNewProjection(true);
    }

    function handleEdit() {
        if (itemForEdit) {
            form.setFieldsValue(handleEditProjection(itemForEdit));
            setIsWhole(itemForEdit.schedulePayment === "WHOLE");
            setIsInstallment(itemForEdit.schedulePayment === "INSTALLMENT");
            setFormula(itemForEdit.formula ? itemForEdit.formula : { expressions: [] });
            setStepOneListConditions(formatProjectionConditios(itemForEdit.flexFieldsConditions));

            setIsEditingProjection(true);
            setIsNewProjection(true);
        }
    }

    return (
        <>
            {!isNewProjection && (
                <div className="projection-title-content">
                    <div className="page-title-content">
                        <h1>{i18n.t("projection_modeling")}</h1>
                    </div>
                    <div className="top-buttons-projections">
                        <TopButtons
                            mainButtonTitle={i18n.t("new_projection")}
                            handleNew={handleNew}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            isEditable={selectedRows.length === 1 && !itemForEdit?.children?.length}
                            isDeletable={
                                selectedRows.length > 0 &&
                                !tableData.find(({ id }) => id === selectedRowKeys[0])?.children?.length
                            }
                            customButtons={[modelingProcess]}
                            hasCaptcha={true}
                        />
                        <div className="top-buttons-projection-export">
                            <MultipleSearch
                                tableData={tableData}
                                setTableData={setFilterdTableData}
                                options={[
                                    { i18nString: "description", description: "description" },
                                    { i18nString: "event", description: "event" },
                                ]}
                            />

                            {functionalityPermissions.export && (
                                <ImportExportMenu
                                    exportGridData={() =>
                                        handleExportGridData(tableData, tableColumns, i18n.t("collaborators_name"))
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}