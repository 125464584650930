import { Icon } from "@iconify/react";
import { Button, Checkbox, Col, DatePicker, Form, InputNumber, Modal, Row, Select } from "antd";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { useEffect, useState } from "react";
import i18n, { languages } from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFilter, Options } from "util/types/types";
import { Hired, IRegistrationModal, ExtendedOptions } from "../IHumanResoucers";
import FinalStepModal from "./FinalStepModal";
import TableCollaborators from "./TableCollaborators";
import { onCloseMultiStepModal } from "util/functions/modal";
import { updateFlexFieldFiltersValue } from "util/functions/updateFlexFieldFiltersValue";
import { onChangeFlexFieldValue } from "util/functions/onChangeFlexFieldValue";
import { useHumanResourcesContext } from "../context/HumanResourcesContext";

export function RegistrationModal({
	isVisible,
	setIsVisible,
	newHiredList,
	setNewHiredList,
	onSaved,
	setIsFetching,
	year,
	realizedPeriod,
	isNotRealized,
}: IRegistrationModal) {
	const [form] = Form.useForm()
	const [isDisabled, setIsDisabled] = useState(false);
	const [costCenterOptions, setCostCenterOptions] = useState<Options[]>([]);
	const [collaboratorOptions, setCollaboratorOptions] = useState<ExtendedOptions[]>([]);
	const [filteredCollaboratorOptions, setFilteredCollaboratorOptions] = useState<ExtendedOptions[]>([]);
	const [isFetchingCostCenter, setIsFetchingCostCenter] = useState(true);
	const [isFetchingCollaborators, setIsFetchingCollaborators] = useState(true);
	const [isOnFinalStep, setIsOnFinalStep] = useState(false);
	const [isPeriodDisabled, setisPeriodDisabled] = useState(false);

	const selectedCollaborator = Form.useWatch('collaborator', form);
	const isFixedIncome = Form.useWatch('variableSalary', form);
	const selectedCostCenter = Form.useWatch('costCenter', form)
	const selectedBusinessUnit = Form.useWatch('businessUnit', form)

	const { flexFieldValues, setFlexFieldValues, businessUnitOptions, professionalCategoryOptions, costCategoryOptions } = useHumanResourcesContext();
	const { userInfo: { selection: { businessUnitId, scenarioId, organizationId } } } = useUserContext();

	useEffect(() => {
		if (!isVisible) return
	}, [isVisible])

	async function handleOkButton() {
		if (isOnFinalStep) {
			const hiredListToSave = newHiredList.map(hired => {
				return ({
					id: null,
					scenarioId: scenarioId,
					businessUnitId: hired.businessUnit.value,
					costCenterId: hired.costCenter.value,
					costCategoryId: hired.costCategory.value,
					professionalCategoryId: hired.professionalCategory.value,
					resourceId: hired.collaborator.value,
					variableSalary: hired.variableSalary ? hired.variableSalary : false,
					period: moment(hired.period).format("YYYY-MM"),
					hours: hired.hourAmount,
					amount: hired.collaboratorAmount,
					entryValue: hired.variableSalary ? hired.hourPrice : hired.fixedSalary,
					originalBalance: null,
					justification: null,
					flexFieldFilter: generateFlexFieldSelectedString(hired.flexField),
				})
			})
			setIsVisible(false);
			setIsFetching(true);
			ServiceCaller.doRequest({
				type: RequestType.POST,
				url: "/human-resources/hiring?scenarioId={scenario}&organizationId={organization}",
				params: hiredListToSave
			}, onSaveHired);
		} else {
			if (!newHiredList.length) {
				Notification({ message: i18n.t("human_resources.warning"), type: "warning" });
				return
			}
			setIsDisabled(false);
			setIsOnFinalStep(true);
		}
	}

	function onSaveHired(status) {
		onSaved();
		setIsOnFinalStep(false);
	}

	function handleDeleteFromList(externalCode: string) {
		const index = newHiredList.findIndex(c => c.key === externalCode)
		const updatedHiredList = [...newHiredList]
		updatedHiredList.splice(index, 1)
		setNewHiredList(updatedHiredList)
	}

	function handleEditHired(collaborator: Hired) {
		setIsDisabled(true);
		form.setFieldsValue({
			collaborator: collaborator.collaborator.value,
			businessUnit: collaborator.businessUnit.value,
			hourPrice: collaborator.hourPrice,
			collaboratorAmount: collaborator.collaboratorAmount,
			period: collaborator.period,
			key: collaborator.collaborator,
			costCategory: collaborator.costCategory.value,
			costCenter: collaborator.costCenter.value,
			professionalCategory: collaborator.professionalCategory.value,
			hourAmount: collaborator.hourAmount,
			fixedSalary: collaborator.fixedSalary,
			flexFieldValues: collaborator.flexField,
			variableSalary: collaborator.variableSalary
		})
		const index = newHiredList.indexOf(collaborator);
		const updatedHiredList = [...newHiredList];
		updatedHiredList.splice(index, 1);
		setNewHiredList(updatedHiredList);
	}

	const selectProps = {
		showSearch: true,
		optionFilterProp: "children",
		filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
		filterSort: (optionA, optionB) =>
			(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
	}

	function closeModal() {
		form.resetFields();
		setIsVisible(false);
		setNewHiredList([]);
		setCollaboratorOptions([]);
		setCostCenterOptions([]);
	}

	useEffect(() => {
		const collaborators = newHiredList.map(({ collaborator }) => collaborator.value);
		const formValues = form.getFieldsValue();
		let newCollaboretorsFiltered = collaboratorOptions;

		newHiredList.forEach(hired => {
			if (hired.businessUnit.value === formValues.businessUnit &&
				hired.costCenter.value === formValues.costCenter &&
				hired.costCategory.value === formValues.costCategory &&
				hired.professionalCategory.value === formValues.professionalCategory) {
				newCollaboretorsFiltered = newCollaboretorsFiltered.filter(({ value }) => hired.collaborator.value !== value);
			}
		})
		setFilteredCollaboratorOptions(newCollaboretorsFiltered);

	}, [newHiredList])

	function handleFinishForm() {
		form.validateFields().then((formValue => {
			let newFlexField: FlexFilter[] = flexFieldValues;
			const flexValues = Object.keys(formValue)
				.filter(key => key.includes("ff"))
				.map(key => {
					const arrString = key.split("-");
					const id = arrString[1];

					return {
						idValue: formValue[key] ? Number(formValue[key]) : 0,
						idFlexField: Number(id),
					};
				});
			newFlexField.map(flexField => {
				flexField.selectedOption[0] = {
					value: flexValues.find(value => value.idFlexField === flexField.value).idValue,
					label: ""
				}
			})

			setFlexFieldValues(newFlexField);
			const newHired: Hired = {
				budgetStatus: false,
				hourPrice: formValue.hourPrice,
				collaboratorAmount: formValue.collaboratorAmount,
				period: formValue.period,
				variableSalary: formValue.variableSalary,
				key: formValue.collaborator,
				businessUnit: { value: formValue.businessUnit, label: getLabelOfOptionsById(businessUnitOptions, formValue.businessUnit) },
				collaborator: { value: formValue.collaborator, label: getLabelOfOptionsById(collaboratorOptions, formValue.collaborator) },
				costCategory: { value: formValue.costCategory, label: getLabelOfOptionsById(costCategoryOptions, formValue.costCategory) },
				costCenter: { value: formValue.costCenter, label: getLabelOfOptionsById(costCenterOptions, formValue.costCenter) },
				professionalCategory: { value: formValue.professionalCategory, label: getLabelOfOptionsById(professionalCategoryOptions, formValue.professionalCategory) },
				hourAmount: formValue.hourAmount,
				fixedSalary: formValue.fixedSalary,
				flexField: newFlexField,
			}
			form.resetFields();
			setNewHiredList(newHiredList.concat(newHired));
			setIsDisabled(false);
			setCollaboratorOptions([]);
			setCostCenterOptions([]);
		})).catch((err) => { console.log(err) })
	}

	function generateFlexFieldSelectedString(flexField: FlexFilter[]) {
		let flexFieldSelectedString = ':::::::::::::::::::::::::::::';

		flexField?.forEach(ff => {
			if (ff.selectedOption.length === 0) return
			const updatedData = flexFieldSelectedString.split(':');
			const position = ff.fieldCode - 1;
			const flexFilterValue = ff.selectedOption[0].value === 0 ? '' : ff.selectedOption[0].value;
			updatedData[position] = flexFilterValue.toString();
			const flexFieldString = updatedData.join(':');
			flexFieldSelectedString = flexFieldString;
		})
		return flexFieldSelectedString;
	}

	function getLabelOfOptionsById(options: Options[], id: number): string {
		return options.find(value => value.value === id).label;
	}

	function validateSelectDate(current) {
		if (!realizedPeriod) return false;
		if (current.year() === Number(year) && current.year() === realizedPeriod.year()) return (realizedPeriod ? current.month() <= realizedPeriod.month() : false)
		return true;
	}

	useEffect(() => {
		if (collaboratorOptions.find(item => item.value === selectedCollaborator)?.resourceType !== "EFETIVE") {
			setisPeriodDisabled(false);
		} else {
			setisPeriodDisabled(true);

			if (realizedPeriod == null) {
				const period = moment().set({ year: Number(year), month: 0 });
				form.setFieldsValue({
					period: period
				})
			} else if (realizedPeriod.month() < 11) {
				const period = moment(realizedPeriod);
				form.setFieldsValue({
					period: period.add(1, "month")
				})
			}
		}
	}, [selectedCollaborator])

	function validateKey() {
		const formValues = form.getFieldsValue();
		if (formValues.businessUnit && formValues.costCenter && formValues.costCategory && formValues.professionalCategory) {
			setIsFetchingCollaborators(true);
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/human-resources/resources/findNotEmployedByKey?scenarioId={scenario}&businessUnitId=${formValues.businessUnit}&costCenterId=${formValues.costCenter}&costCategoryId=${formValues.costCategory}&professionalCategoryId=${formValues.professionalCategory}&organizationId={organization}`,
			}, (data) => handleUpdateSelectOptions(data, 'collaborator'));
		}
	}

	function handleUpdateSelectOptions(options: any[], select) {
		const formatedOptions = options.map(option => {
			if (option.value) return option
			return {
				value: option.id,
				resourceType: option.resourceType,
				label: option.name ? option.name : option.description,
				externalCode: option.externalCode
			}
		})

		switch (select) {
			case 'costCenter':
				setCostCenterOptions(formatedOptions)
				setIsFetchingCostCenter(false);
				break;
			case 'collaborator':
				const formValues = form.getFieldsValue();
				let newCollaboretorsFiltered = formatedOptions;

				newHiredList.forEach(hired => {
					if (hired.businessUnit.value === formValues.businessUnit &&
						hired.costCenter.value === formValues.costCenter &&
						hired.costCategory.value === formValues.costCategory &&
						hired.professionalCategory.value === formValues.professionalCategory) {
						newCollaboretorsFiltered = newCollaboretorsFiltered.filter(({ value }) => hired.collaborator.value !== value);
					}
				})

				setCollaboratorOptions(formatedOptions);
				setFilteredCollaboratorOptions(newCollaboretorsFiltered);
				setIsFetchingCollaborators(false);
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		if (selectedBusinessUnit) {
			setIsFetchingCostCenter(true);
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/cost-center?locale={locale}&client={client}&user={user}&organization={organization}&bu=${selectedBusinessUnit}`,
			}, (data) => handleUpdateSelectOptions(data, 'costCenter'));
			validateKey();
		}
	}, [selectedBusinessUnit, selectedCostCenter]);

	const concatenatedCostCategoryOptions: ExtendedOptions[] = costCategoryOptions?.map(option => ({
		label: `${option.externalCode} - ${option.label}`,
		value: option.value,
		externalCode: option.externalCode, 
	})) || [];
	
	const concatenatedProfessionalCategoryOptions: ExtendedOptions[] = professionalCategoryOptions?.map(option => ({
		label: `${option.externalCode} - ${option.label}`,
		value: option.value,
		externalCode: option.externalCode, 
	})) || [];
	
	const concatenatedFilteredCollaboratorOptions: ExtendedOptions[] = filteredCollaboratorOptions?.map(option => ({
		label: `${option.externalCode} - ${option.label}`,
		value: option.value,
		externalCode: option.externalCode, 
	})) || [];
	
	return (
		<Modal
			width={'70%'}
			title={isOnFinalStep ? i18n.t("human_resources.hiring_summary") : i18n.t("human_resources.new_hiring")}
			visible={isVisible}
			className="gs-modal"
			cancelText={isOnFinalStep ? i18n.t<string>("return") : i18n.t<string>("cancel")}
			okText={isOnFinalStep ? i18n.t<string>("save") : i18n.t<string>("next")}
			onOk={handleOkButton}
			onCancel={(e) => onCloseMultiStepModal(e, !isOnFinalStep, closeModal, () => setIsOnFinalStep(false))}
			centered
		>
			<Form
				form={form}
				layout="vertical"
				requiredMark={false}
				className="collaborators-registration-form"
				scrollToFirstError={true}
				onFinish={handleFinishForm}
			>
				{
					isOnFinalStep &&
					<FinalStepModal
						hiredList={newHiredList}
					/>
				}
				{
					!isOnFinalStep &&
					<>
						<Row className="first-container-form" gutter={16}>
							<Col span={6}>
								<Form.Item
									label={i18n.t("period")}
									name="period"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<DatePicker
										style={{ width: '100%' }}
										picker="month"
										defaultPickerValue={moment(year, "YYYY")}
										allowClear={false}
										locale={languages[i18n.language]}
										disabledDate={validateSelectDate}
										format="MMM/YYYY"
										placeholder=""
										className="gs-date-picker"
										disabled={isPeriodDisabled}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={i18n.t("business_unit")}
									name="businessUnit"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select {...selectProps}
										options={businessUnitOptions}
										onChange={() => updateFlexFieldFiltersValue('BUSINESS_UNIT', form, flexFieldValues, setFlexFieldValues, organizationId)}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={i18n.t("cost_center")}
									name="costCenter"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select {...selectProps}
										options={costCenterOptions}
										disabled={isFetchingCostCenter ? true : costCenterOptions.length === 0}
										onChange={() => updateFlexFieldFiltersValue('COST_CENTER', form, flexFieldValues, setFlexFieldValues, organizationId)}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={i18n.t("cost_category")}
									name="costCategory"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select {...selectProps} options={concatenatedCostCategoryOptions} onChange={validateKey} />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={i18n.t("professional_category.professional_category_name")}
									name="professionalCategory"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select {...selectProps} options={concatenatedProfessionalCategoryOptions} onChange={validateKey} />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={i18n.t("collaborator")}
									name="collaborator"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select {...selectProps} options={concatenatedFilteredCollaboratorOptions} disabled={isFetchingCollaborators ? true : collaboratorOptions.length === 0} />
								</Form.Item>
							</Col>
							{flexFieldValues.length > 0 &&
								flexFieldValues.map(ff =>
									<Col span={6} key={ff.value}>
										<Form.Item
											label={ff.label}
											name={`ff-${ff.value}`}
										>
											<Select
												placeholder={i18n.t("select")}
												options={ff.children}
												onChange={(value) => onChangeFlexFieldValue(value, ff.value, setFlexFieldValues, form,
													{
														flexFieldOptions: flexFieldValues,
														selectedCostCenter: selectedCostCenter,
														selectedBusinessUnit: selectedBusinessUnit
													})}
												{...selectProps}
											/>
										</Form.Item>
									</Col>
								)
							}
						</Row>
						<Row style={{ marginBottom: 12 }}>
							<Col className="flex-center">
								<Form.Item
									name={"variableSalary"}
									className="checkbox-form-item"
									valuePropName="checked"
								>
									<Checkbox>
										{i18n.t("human_resources.variable_salary")}
									</Checkbox>
								</Form.Item>
							</Col>
						</Row>
						<Row >
							<Col span={6}>
								<Form.Item
									label={i18n.t("amount")}
									name="collaboratorAmount"
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
									initialValue={1}
								>
									<InputNumber
										min={1}
									/>
								</Form.Item>
							</Col>
							{!isFixedIncome ?
								<Col span={6}>
									<Form.Item name="fixedSalary"
										label={i18n.t("collaborators.additional_fields.SALARY")}
										rules={[{ required: true, message: i18n.t<string>("required_field") }]}
									>
										<InputNumber
											onChange={value => {
												return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
													notation: "compact",
													currency: "BRL"
												}).format(Number(value)))
											}}
											controls={false}
											decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
											width={"100%"}
											precision={2}
											prefix="R$"
										/>
									</Form.Item>
								</Col>
								:
								<>
									<Col span={6}>
										<Form.Item
											label={i18n.t("human_resources.hourly_value")}
											name="hourPrice"
											rules={[{ required: true, message: i18n.t<string>("required_field") }]}
										>
											<InputNumber
												onChange={value => {
													return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
														notation: "compact",
														currency: "BRL"
													}).format(Number(value)))
												}}
												controls={false}
												decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
												width={"100%"}
												precision={2}
												prefix="R$"
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label={i18n.t("amount_of_hours")}
											name="hourAmount"
											rules={[{ required: true, message: i18n.t<string>("required_field") }]}
										>
											<InputNumber
												controls={false}
												min={0}
											/>
										</Form.Item>
									</Col>
								</>
							}
							<Col className="gs-flex jc-end align-center" span={isFixedIncome ? 6 : 12}>
								<Button
									type="default"
									htmlType="submit"
									className="gs-secondary-button"
									icon={<Icon icon="akar-icons:circle-plus-fill" />}
								>
									{i18n.t<string>("addToList")}
								</Button>
							</Col>
						</Row>
					</>
				}
			</Form>
			{
				(!isOnFinalStep) &&
				<TableCollaborators
					deleteFunction={handleDeleteFromList}
					editFunction={handleEditHired}
					hiredList={newHiredList}
					isEditButtonDisabled={isDisabled}
					isOnFinalStep={isOnFinalStep}
				/>
			}
		</Modal >
	)
}