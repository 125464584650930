import { Icon } from '@iconify/react';
import { Card, Divider, Layout, Space, Tooltip, Typography, notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { FlexFieldData } from '../../flexField/IFlexField';

import { useUserContext } from 'context/UserContext';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import { ROOT_PATH } from 'util/rootPath';
import { ErrorRequest, ModuleType } from 'util/types/types';
import { RevenueContext } from './RevenueContext';

import RevenueTable from './grid/RevenueTable';

import i18n from 'util/base/i18n';
import {
	CurrencyUnitDefault,
	FiltersIdsAndOrder,
	FlexFieldFilter,
	MeasuringUnitConversion,
	MeasuringUnitDefault,
	RevenueFields,
	RevenueFilterOrder,
	RevenueFlexField,
	RevenueFlexFieldItem,
	RevenueHeaderInformations,
	RevenueStandard
} from './IRevenue';
import './Revenue.sass';

import FilterContainer from './components/FilterContainer';
import { BudgetPeriodDates } from '../../fixedExpense/IFixedExpense';
import { Loading } from 'components/loading/Loading';
import { ImageBox } from 'components/imageBox/ImageBox';
import { useBudgetDates } from 'hooks/useBudgetDates';
import { useNavigate } from 'react-router-dom';

export default function Revenue() {

	const [flexFieldListOrdened, setFlexFieldListOrdened] = useState<RevenueFlexField[]>([]);
	const [itensOrdened, setItensOrdened] = useState<RevenueFilterOrder[]>([]);
	const [hasSavedValue, setHasSavedValue] = useState<boolean>(null);
	const [fieldsRevenue, setFieldRevenue] = useState<FiltersIdsAndOrder[]>([]);
	const [currencyDefaultUnit, setCurrencyDefaultUnit] = useState<CurrencyUnitDefault>({ id: 0, image: "", isoCode: "BRL", name: "" });
	const [measuringUnitDefault, setMeasuringUnitDefault] = useState<MeasuringUnitDefault>({ description: "", id: 0, symbol: "" });
	const [cardHeaderInformation, setCardHeaderInformation] = useState<RevenueHeaderInformations>({ planned: 0, accomplished: 0 });
	const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
	const [hasFilterSelected, setHasFilterSelected] = useState<boolean>(true);
	const [fieldsDefault, setFieldsDefault] = useState([]);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [hasFilterFlexField, setHasFilterFlexField] = useState<boolean>(true);
	const navigate = useNavigate();
	const { isLoading: isLoadingBudgetPeriodDates } = useBudgetDates(ModuleType.REVENUE)
	const { userInfo } = useUserContext();
	const { hasBudgetYear, handleHasChangeOnGrid, hasChangeOnGrid, handleIsAllFilterChecked,
		period, filtersFatherActive, handleFiltersFatherFactive, updateFiltersFlexField, handleUpdateFiltersFlexField, flexFieldsSelected } = useContext(RevenueContext);

	function updateFlexFields() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flex-field/find-all-by-module?module=REVENUE`
		}, loadFlexField.bind(this), (err: ErrorRequest) => {
			handleErrorRequest(err);
		});
	}

	const loadFlexField = (returnFlex: FlexFieldData[]) => {
		if (!returnFlex.length) {
			setHasFilterFlexField(false);
			return;
		}
		if (updateFiltersFlexField) {
			handleUpdateFiltersFlexField(false);
		}

		returnFlex.forEach(element => {
			if (element.filters) {
				const fieldSelect = flexFieldsSelected.find((f) => element.filters.includes(f.flexFieldId.toString()));
				const organizationFilter: number = element.filters.includes("ORGANIZATION") ? userInfo.selection.organizationId : null;

				if (!fieldSelect && !organizationFilter) return;

				const flexFieldQuery: string = fieldSelect ? `&flexFieldId=${fieldSelect.flexFieldId}&flexFieldValueId=${fieldSelect.flexFieldValuesId}` : "";
				const organizationQuery: string = organizationFilter ? `&organization=${organizationFilter}` : "";

				ServiceCaller.doRequest({
					type: RequestType.GET,
					url: `/budget-base/flex-field-value/get-all-by-filters-with-security-revenue?user={user}${flexFieldQuery}${organizationQuery}&groupIds=${userInfo.groupIds}`
				}, (data: FlexFieldFilter[]) => {
					data.forEach((flex) => {
						handleConstructFlexFieldFilters(flex, data);
					});
				}, (err: ErrorRequest) => {
					handleErrorRequest(err);
				});
			} else {
				ServiceCaller.doRequest({
					type: RequestType.GET,
					url: `/budget-base/flex-field-value/find-flex-field-values-with-security-revenue?user={user}&flexFieldId=${element.id}&group=${userInfo.groupIds}`,
				}, (data: FlexFieldFilter[]) => {
					data.forEach(flex => {
						handleConstructFlexFieldFilters(flex, data);
					});
				}, (err: ErrorRequest) => {
					handleErrorRequest(err);
				});
			}
		});

		constructFlexFieldsFilterOrdenation(returnFlex);
	};

	const handleConstructFlexFieldFilters = (flex: FlexFieldFilter, data: FlexFieldFilter[]) => {
		let flexFieldFilterContainer: RevenueFlexField = {
			key: flex.flexFieldId,
			title: flex.flexFieldDescription,
			itens: [],
			description: [],
			listKeys: [],
			listActives: [],
			order: flex.flexFieldOrdenation,
		};

		data.forEach((elementItem: any) => {
			if (elementItem.flexFieldDescription === flex.flexFieldDescription) {
				let flexFieldItem: RevenueFlexFieldItem = {
					id: elementItem.id,
					checked: false,
					label: elementItem.externalCode + " - " + elementItem.description,
					value: elementItem.description
				};

				flexFieldsSelected.forEach((field) => {
					if (field.flexFieldId === flex.flexFieldId) {
						field.flexFieldValuesId.forEach((fieldValue) => {
							if (fieldValue === elementItem.id) {
								flexFieldItem.checked = true;
								flexFieldFilterContainer.listActives.push(elementItem.description);
							}
						})
					}
				});

				flexFieldFilterContainer.itens.push(flexFieldItem);
			}
		});

		const field = flexFieldListOrdened.find((field) => field.key === flexFieldFilterContainer.key);
		if (!field) {
			flexFieldListOrdened.push(flexFieldFilterContainer);

			let listFlexFieldAux = [...flexFieldListOrdened];
			setFlexFieldListOrdened(listFlexFieldAux.sort(sortByOrder));
		} else if (field && field.itens.length !== flexFieldFilterContainer.itens.length) {
			flexFieldListOrdened[flexFieldListOrdened.indexOf(field)].itens = flexFieldFilterContainer.itens;

			let listFlexFieldAux = [...flexFieldListOrdened];
			setFlexFieldListOrdened(listFlexFieldAux.sort(sortByOrder));
		}
	}

	const constructFlexFieldsFilterOrdenation = (flexFields: FlexFieldData[]) => {
		if (itensOrdened.length) {
			return;
		}

		const items: RevenueFilterOrder[] = flexFields.sort(sortByOrder).map((flexField, index) => {
			return {
				id: flexField.id,
				text: flexField.description,
				checked: true,
				order: index,
				originalOrder: index
			}
		});

		const checkedFiltersCount = items.filter(({checked}) => checked).length;
		const totalFiltersCount = items.length;

		if (checkedFiltersCount === totalFiltersCount) {
			handleIsAllFilterChecked(true);
		}

		setItensOrdened(items.sort(sortByOrder));
	};

	const handleMeasuringUnitConversion = (data: MeasuringUnitConversion) => {
		const measuring: MeasuringUnitDefault = {
			id: data.id,
			description: data.description,
			symbol: data.symbol
		};

		setMeasuringUnitDefault(measuring);
	}

	const handleCurrencyUnitConversion = (data) => {
		const currency: CurrencyUnitDefault = {
			id: data.id,
			image: data.image,
			isoCode: data.isoCode ? data.isoCode : null,
			name: data.name
		};

		setCurrencyDefaultUnit(currency);
	}

	const sortByOrder = (itemA, itemB) => {
		const orderA = itemA.order || itemA.ordenation;
		const orderB = itemB.order || itemB.ordenation;

		let comparatorValue: number = 0;

		if (orderA < orderB) {
			comparatorValue = -1;
		} else if (orderA > orderB) {
			comparatorValue = 1;
		}

		return comparatorValue;
	};

	const loadRevenueTable = (data: RevenueStandard) => {
		setHasFilterSelected(true);
	};

	useEffect(() => {
		if (userInfo.selection.organizationId) {
			updateFlexFields();

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/control-panel/get-unit-conversion?scenario={scenario}&organization={organization}`
			}, handleMeasuringUnitConversion.bind(this), () => {
				notification.error({
					message: i18n.t<string>("error_on_loading_measuring_unit"),
					description: i18n.t<string>("create_a_measuring_unit"),
					placement: "topRight",
					duration: 3,
				});
			});

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/monolith/organization/get-currency?organization={organization}&client={client}&locale={locale}`,
			}, handleCurrencyUnitConversion.bind(this), (err: ErrorRequest) => {
				handleErrorRequest(err);
			});
		}
	}, [userInfo]);

	useEffect(() => {
		if (updateFiltersFlexField) {
			updateFlexFields();
			return;
		}

		if (filtersFatherActive.length && isFirstLoad) {
			updateFlexFields();
			return;
		}

		if (!hasFilterSelected && !filtersFatherActive.length && !flexFieldListOrdened.length) {
			updateFlexFields();
			return;
		}

		if (!hasFilterSelected && !flexFieldListOrdened) {
			updateFlexFields();
		}
	}, [filtersFatherActive, hasFilterSelected, updateFiltersFlexField]);

    const handleClick = () => {
        navigate('/budget/flex-field');
    };
	const handleClickControlPanel = () => {
		navigate('/budget/control-panel');
	}

	return (
		<Layout id="revenue-container" className="light">
			<Layout.Header>
				<div id="revenue-header-data-container">
					<Space align="center">
						<Typography.Title level={3} className="gs-font-color-primary">{i18n.t<string>("revenues")}</Typography.Title>
					</Space>

					<Space id="revenue-header-cards-container">
						<Card size="small" style={{ whiteSpace: 'nowrap' }}>
							<Card.Meta avatar={<Icon icon="mdi:chart-donut" />} title={i18n.t<string>("gross_revenue_planned")} description={
								<FormattedNumber
									value={Number(cardHeaderInformation.planned.toFixed(2))}
									style={`currency`}
									currencyDisplay="symbol"
									currency={currencyDefaultUnit.isoCode}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							} />
						</Card>
						<Card size="small" style={{ whiteSpace: 'nowrap' }}>
							<Card.Meta avatar={<Icon icon="system-uicons:graph-increase" />} title={i18n.t<string>("gross_revenue_accomplished")} description={
								<FormattedNumber
									value={Number(cardHeaderInformation.accomplished.toFixed(2))}
									style={`currency`}
									currencyDisplay="symbol"
									currency={currencyDefaultUnit.isoCode}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							} />
						</Card>
						<Card id="revenue-header-company-info-card" size="small">
							<img
								src={`${ROOT_PATH}/renderer/image/${currencyDefaultUnit?.image}`}
								alt={currencyDefaultUnit?.isoCode}
								style={{
									marginRight: "5px",
									borderRadius: "50%",
									objectFit: "cover",
									width: 15,
									height: 15
								}}
							/>
							<Tooltip title={currencyDefaultUnit.name}>
								<Typography.Text className="revenue-currency-name-info">{currencyDefaultUnit.isoCode}</Typography.Text>
							</Tooltip>
							<Tooltip title={measuringUnitDefault.description}>
								<Typography.Text className="revenue-currency-unit-info">{measuringUnitDefault.symbol}</Typography.Text>
							</Tooltip>
						</Card>
					</Space>
				</div>

				<Divider style={{
					margin: 0,
					height: 1
				}}
				/>
				{hasBudgetYear && <Space id="revenue-header-filter-container" align="center" style={{ width: "100%" }}>
					<div style={{ display: 'flex', flexDirection: 'row', gap: 7, width: "100%" }}>
						<FilterContainer
							itensOrdened={itensOrdened}
							flexFields={flexFieldListOrdened}
							setFlexFields={setFlexFieldListOrdened}
							isFilterChanged={isFilterChanged}
							setIsFilterChanged={setIsFilterChanged}
							setItensOrdened={setItensOrdened}
							currencyId={currencyDefaultUnit.id}
							measuringUnitId={measuringUnitDefault.id}
						/>
					</div>
				</Space>}
			</Layout.Header>

			<Layout.Content>
				{isLoadingBudgetPeriodDates ?
					<Loading /> :
					hasBudgetYear && hasFilterFlexField ?
						itensOrdened.length ? <div id="revenue-table-container">
							<RevenueTable
								itensOrdened={itensOrdened}
								setItensOrdened={setItensOrdened}
								hasSavedValue={hasSavedValue}
								setHasSavedValue={setHasSavedValue}
								setCardHeaderInformation={setCardHeaderInformation}
								fieldsDefault={fieldsDefault}
								isFilterChanged={isFilterChanged} setIsFilterChanged={setIsFilterChanged}
							/>
						</div> : <Loading />
						:
						<ImageBox
						imgName='image_bw_01'
						title={!hasFilterFlexField ? i18n.t<string>("no_commercial_structure") : i18n.t<string>("no_budget_year")}
						message={
							!hasFilterFlexField ? (
								<>
									{i18n.t<string>("company_without_commercial_structure")}{' '}
									<span
										style={{ color: 'blue', cursor: 'pointer', textDecoration: 'none' }}
										onClick={handleClick}
									>
										{i18n.t<string>("click_here")}
									</span>
								</>
							) : (
								<>
									{i18n.t<string>("start_budgeting_control_panel")}{' '}
									<span
										style={{ color: 'blue', cursor: 'pointer', textDecoration: 'none' }}
										onClick={handleClickControlPanel}
									>
										{i18n.t<string>("click_here")}
									</span>
								</>
							)
						}
					/>
				}
			</Layout.Content>
		</Layout >
	);
}
