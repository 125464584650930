import { Col, Layout, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Filter } from "components/filter/Filter";
import { ImageBox } from "components/imageBox/ImageBox";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Loading } from "components/loading/Loading";
import { usePermissionContext } from "context/PermissionContext";
import { useBudgetDates } from "hooks/useBudgetDates";
import { SplitComponent } from "module/budget/components/SplitComponents/SplitComponent";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ModuleType, Options } from "util/types/types";
import { AccomplishedValue, FlexFieldValue, ITableData, Ledger, LevelItem } from "../IAccountingBalances";
import { useAccountingBalancesContext } from "../context/AccountingBalancesContext";
import SidebarContent from "./SidebarContent";
import TableContainer from "./TableContainer";

export default function Content() {
    const [isLastTreeNode, setIsLastTreeNode] = useState<boolean>(false);
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    const {
        treeData,
        checkedKeys,
        setCheckedKeys,
        expandedKeys,
        setExpandedKeys,
        isFetchingTreeData,
        nodeSelected,
        setNodeSelected,
        costCentersList,
        period,
        handleChangePeriod,
        costCenterId,
        setCostCenterId,
        openedCalendarPeriod,
    } = useAccountingBalancesContext();

    const [accomplishedValue] = useState<Options[]>([{ value: 1, label: i18n.t<string>("budgeted") }]);
    const [isAccomplished, setIsAccomplished] = useState<number>(1);
    const [ledger, setLedger] = useState<Options[]>([]);
    const [ledgers, setLedgers] = useState<Options[]>([]);
    const [ledgerId, setLedgerId] = useState<number>();
    const [companyFlex, setCompanyFlex] = useState<Options[]>([]);
    const [companyFlexList, setCompanyFlexList] = useState<Options[]>([]);
    const [companyFlexId, setCompanyFlexId] = useState<number>();
    const [tableColumns, setTableColumns] = useState<ColumnsType<ITableData>>([]);
    const [tableData, setTableData] = useState<ITableData[]>([]);

    const { refetch } = useBudgetDates(ModuleType.PLANNING);

    const { functionalityPermissions } = usePermissionContext();
    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/planning-balance/import/initial-balance-budget",
            templateUrl: "/planning/planning-balance/import/initial-balance-budget/template?locale={locale}",
            type: "excel",
            title: i18n.t("import_opening_balance"),
        },
        {
            importUrl: "/planning/planning-balance/accomplished/transaction/value/import",
            templateUrl: "/planning/planning-balance/accomplished/transaction/value/template?locale={locale}",
            type: "excel",
            title: i18n.t("import_accomplished_balance"),
        },
    ];

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/monolith/ledger?client={client}",
            },
            (response: Ledger[]) => {
                const options: Options[] = response.map((option) => ({
                    value: option.id,
                    label: `${option.externalCode} - ${option.name}`,
                }));
                setLedgers(options);
                setLedgerId(options[0]?.value);
                setLedger([{ value: options[0]?.value, label: options[0]?.label }]);
            }
        );
    }, []);

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-base/flex-field-value/find-all-organization-flex-fields",
            },
            (response: FlexFieldValue[]) => {
                const options: Options[] = response.map((option) => ({
                    value: option.id,
                    label: `${option.externalCode} - ${option.description}`,
                }));
                options.unshift({ label: i18n.t("no_option"), value: 0 });

                setCompanyFlexList(options);
                setCompanyFlexId(options[0].value);
                setCompanyFlex([{ value: options[0]?.value, label: options[0]?.label }]);
            }
        );
    }, []);

    useEffect(() => {
        if (checkedKeys.length === 0) return;
        if (checkedKeys.length > 1) return;

        const nodeSelected: LevelItem = findNodeTree(treeData, checkedKeys[0]);
        setNodeSelected(nodeSelected);

        if (nodeSelected.children.length === 0) {
            setCostCenterId(nodeSelected.externalCode);
        }
    }, [checkedKeys]);

    useEffect(() => {
        const nodeSelected: LevelItem = findNodeTree(treeData, expandedKeys[expandedKeys.length - 1]);
        setNodeSelected(nodeSelected);

        let isLastNode: boolean = false;
        if (nodeSelected?.children.length === costCentersList.length) {
            if (nodeSelected?.children.length === costCentersList.length) {
                isLastNode = !!costCentersList.find((costCenter) =>
                    nodeSelected.children.find(
                        (costCentersListitem) => costCentersListitem.externalCode === costCenter.externalCode
                    )
                );
            }
        } else {
            nodeSelected?.children.forEach((node) => {
                if (node?.children.length === costCentersList.length) {
                    isLastNode = !!costCentersList.find((costCenter) =>
                        node.children.find(
                            (costCentersListitem) => costCentersListitem.externalCode === costCenter.externalCode
                        )
                    );
                }
            });
        }

        setIsLastTreeNode(isLastNode);
    }, [expandedKeys]);

    function findNodeTree(lines: LevelItem[], key: Key): LevelItem {
        let obj: LevelItem = null;

        for (let l = lines.length; l--; ) {
            const level = lines[l];

            if (level.key === key) {
                obj = level;
                break;
            }

            if (level.children.length > 0) {
                obj = findNodeTree(level.children, key);

                if (obj) {
                    break;
                }
            }
        }

        return obj;
    }

    function handleAccomplished(currency: AccomplishedValue[]) {
        if (currency.length > 0) {
            setIsAccomplished(currency[0].value);
        }
    }

    function handleLedger(ledgers: Options[]) {
        if (ledgers?.length > 0) {
            setLedgerId(ledgers[0].value);
        }
    }

    function handleCompanyFlex(companyFlex: Options[]) {
        if (companyFlex?.length > 0) {
            setCompanyFlexId(companyFlex[0].value);
        }
    }

    const handleSuccessImport = () => {
        refetch();
    };
    
    const modalitys: { value: number; label: string }[] = [
        {
            value: 1,
            label: i18n.t<string>("budgeted"),
        },
        {
            value: 2,
            label: i18n.t<string>("accomplished"),
        },
    ];

    const handleSetColumns = (columns) => {
        setTableColumns(columns)
    }

    const handleSetData = (data) => {
        setTableData(data);
    }

    return (
        <div id="accounting-balances-container">
            <div className="page-content">
                <Layout>
                    <SplitComponent>
                        <div className="sidebar-content">
                            <SidebarContent
                                treeData={treeData}
                                checkedKeys={checkedKeys}
                                isLastTreeNode={isLastTreeNode}
                                setCheckedKeys={setCheckedKeys}
                                expandedKeys={expandedKeys}
                                setExpandedKeys={setExpandedKeys}
                                isFetching={isFetchingTreeData}
                            />
                        </div>

                        <div className="content-container">
                            <div className="content-adjustment">
                                <Row wrap={false} align="middle" gutter={[0, 30]}>
                                    <div className="filter-container">
                                        <div style={{ display: "flex" }}>
                                            <Col className="period-filter-card">
                                                <Filter
                                                    id="period-filter"
                                                    value={period}
                                                    setValue={handleChangePeriod}
                                                    title={i18n.t("period")}
                                                    type="datepicker"
                                                    link="/"
                                                    data={[]}
                                                    datepicker={{
                                                        format: "MM/YYYY",
                                                        picker: "month",
                                                        period: openedCalendarPeriod,
                                                        rangePicker: true,
                                                        allowClear: false,
                                                    }}
                                                    buttonStyles={{ cursor: "default" }}
                                                />
                                            </Col>
                                            <Col className="accomplished-filter-card">
                                                <Filter
                                                    id="period-filter"
                                                    title={i18n.t<string>("modality")}
                                                    type="radio"
                                                    value={accomplishedValue}
                                                    setValue={handleAccomplished}
                                                    data={modalitys}
                                                />
                                            </Col>
                                            <Col className="ledger-filter-card">
                                                <Filter
                                                    id="ledger-filter"
                                                    title={i18n.t<string>("ledger_book")}
                                                    type="radio"
                                                    value={ledger}
                                                    setValue={handleLedger}
                                                    data={ledgers}
                                                />
                                            </Col>
                                            {companyFlexList?.length > 0 && (
                                                <Col className="company-flex-filter-card">
                                                    <Filter
                                                        id="company-flex-filter"
                                                        title={i18n.t<string>("company_flex")}
                                                        type="radio"
                                                        value={companyFlex}
                                                        setValue={handleCompanyFlex}
                                                        data={companyFlexList}
                                                    />
                                                </Col>
                                            )}
                                        </div>

                                        {functionalityPermissions.import && (
                                            <ImportExportMenu
                                                exportGridData={
                                                    tableData.length > 0
                                                        ? () =>
                                                              handleExportGridData(
                                                                  tableData,
                                                                  tableColumns,
                                                                  nodeSelected.title
                                                              )
                                                        : undefined
                                                }
                                                importProps={importProps}
                                                handleSuccessImport={handleSuccessImport}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Layout.Content>
                                    {isFetchingTreeData ? (
                                        <Loading />
                                    ) : checkedKeys.length === 1 ? (
                                        <TableContainer
                                            isLoadingTable={isLoadingTable}
                                            setIsLoadingTable={setIsLoadingTable}
                                            treeData={treeData}
                                            period={period}
                                            costCenterId={costCenterId}
                                            checkedKeys={checkedKeys}
                                            isAccomplished={isAccomplished}
                                            ledgerId={ledgerId}
                                            companyFlexId={companyFlexId}
                                            handleSetColumns={handleSetColumns}
                                            handleSetData={handleSetData}
                                        />
                                    ) : checkedKeys.length > 1 ? (
                                        <div className="tree-level-warning-container">
                                            <ImageBox
                                                imgStyles={{ width: 250 }}
                                                imgName="image_bw_01"
                                                message={i18n.t(
                                                    "groupings_texts.select_one_level_at_a_time_to_link_accounts"
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        <div className="tree-level-warning-container">
                                            <ImageBox
                                                imgStyles={{ width: 230 }}
                                                imgName="image_bw_02"
                                                message={i18n.t("groupings_texts.select_the_last_tree_level_to_link")}
                                            />
                                        </div>
                                    )}
                                </Layout.Content>
                            </div>
                        </div>
                    </SplitComponent>
                </Layout>
            </div>
        </div>
    );
}
