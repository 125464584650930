import { Button, Form, Modal } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { DataDistributionResultValue, DistributionFormData, DistributionValuesModalProps, OptionCascader } from "../../IHiredDashboard";
import { DistributionForm } from "./DistributionForm";
import { DistributionList } from "./DistributionList";
import moment from "moment";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import FinalStepModal from "./FinalStepModal";
import { Notification } from "components/notification/Notification";
import { onCloseMultiStepModal } from "util/functions/modal";
import { FlexFilter } from "util/types/types";
import { useHumanResourcesContext } from "../../../context/HumanResourcesContext";

export default function DistributionValuesModal({ 
	isModalVisible, 
	setIsModalVisible,
	year,
	addtionalFieldsOptions
}: DistributionValuesModalProps) {
	const [distributionList, setDistributionList] = useState<DistributionFormData[]>([])
	const [isFormSection, setIsFormSection] = useState(true)
	const [form] = Form.useForm();
	const [isDisabled, setIsDisabled] = useState(true);
	const [flexFieldValues, setFlexFieldValues] = useState<FlexFilter[]>([]);

	const { costCategoryOptions, businessUnitOptions, professionalCategoryOptions, costCenterOptions } = useHumanResourcesContext();

	const monthOptions: OptionCascader[] = [
		{
			value: moment(year).month(0).format("YYYY-MM"),
			label: moment(year).month(0).format("MMMM")
		},
		{
			value: moment(year).month(1).format("YYYY-MM"),
			label: moment(year).month(1).format("MMMM")
		},
		{
			value: moment(year).month(2).format("YYYY-MM"),
			label: moment(year).month(2).format("MMMM")
		},
		{
			value: moment(year).month(3).format("YYYY-MM"),
			label: moment(year).month(3).format("MMMM")
		},
		{
			value: moment(year).month(4).format("YYYY-MM"),
			label: moment(year).month(4).format("MMMM")
		},
		{
			value: moment(year).month(5).format("YYYY-MM"),
			label: moment(year).month(5).format("MMMM")
		},
		{
			value: moment(year).month(6).format("YYYY-MM"),
			label: moment(year).month(6).format("MMMM")
		},
		{
			value: moment(year).month(7).format("YYYY-MM"),
			label: moment(year).month(7).format("MMMM")
		},
		{
			value: moment(year).month(8).format("YYYY-MM"),
			label: moment(year).month(8).format("MMMM")
		},
		{
			value: moment(year).month(9).format("YYYY-MM"),
			label: moment(year).month(9).format("MMMM")
		},
		{
			value: moment(year).month(10).format("YYYY-MM"),
			label: moment(year).month(10).format("MMMM")
		},
		{
			value: moment(year).month(11).format("YYYY-MM"),
			label: moment(year).month(11).format("MMMM")
		},
	]

	const actionsOptions: OptionCascader[] = [
		{
			label: i18n.t("human_resources.override_value"),
			value: 0
		},
		{
			label: i18n.t("human_resources.add_value"),
			value: 1
		},
		{
			label:  `${i18n.t("human_resources.summation")} %`,
			value: 2
		},
	]

	function handleCollabDistribution(data: DistributionFormData) {
		setDistributionList([...distributionList, { ...data, key: Number(moment().milliseconds) }]);
		form.resetFields();
	}

	function handleOkButton() {
		if (isFormSection) {
			if(distributionList.length === 0) {
				Notification({
					type: 'warning',
					message: i18n.t("human_resources.add_some_item_first")
				})
			} else {
				setIsFormSection(false);
			}
			return
		} else {
			setIsModalVisible(false);
			Notification({
				message: i18n.t("human_resources.distribution_of_running_values"),
				type: "info"
			})
			setDistributionList([]);
			form.resetFields();
			setIsFormSection(true);
			ServiceCaller.doRequest({
				type: RequestType.POST,
				url: "/human-resources/resourceEntry/saveDistribition?scenarioId={scenario}&organizationId={organization}&clientId={client}&userId={user}&locale={locale}",
				params: buildDataToSave(distributionList)
			}, onSaveDistribution)
		}
		setIsFormSection(true);
		setIsModalVisible(false);
	}

	function onSaveDistribution(data: any[]) {
		Notification({
			message: i18n.t("human_resources.distribution_carried_out_successfully"),
			type: "success"
		});
	}

	function buildDataToSave(list: DistributionFormData[]) {
		return list.map(line => {
			return ({
				businessUnitIds: line.businessUnitIds?.map(({ value }) => value) || [],
				costCategoryIds: line.costCategoryIds?.map(({ value }) => value) || [],
				professionalCategoryIds: line.professionalCategoryIds?.map(({ value }) => value) || [],
				costCenterIds: line.costCenterIds?.map(({ value }) => value) || [],
				distributionPeriods: line.distributionPeriods?.map(({ value }) => value),
				action: line.action.value,
				distributionValue: line.distributionValue,
				fieldId: line.field.value,
				flexField: line.flexField?.map(ff => {
					const ffValues = ff.selectedOption.map(({ value }) => value)[0];
					const result = ffValues ? {
						flexFieldId: ff.value,
						flexFieldValues: ffValues
					} : null
					return result;
				}).filter(item => item) || [],
				resultValue: line.resultValue
			})
		})
	}

	function handleDelete(id: number) {
		const index = distributionList.findIndex(c => c.key === id);
		const updatedHiredList = [...distributionList];
		updatedHiredList.splice(index, 1);
		setDistributionList(updatedHiredList);
	}

	function handleEdit(data: DistributionFormData) {
		setIsDisabled(true);
		form.setFieldsValue({
			...data,
			action: data.action.value,
			businessUnitIds: data.businessUnitIds?.map(({ value }) => value),
			costCategoryIds: data.costCategoryIds?.map(({ value }) => value),
			costCenterIds: data.costCenterIds?.map(({ value }) => value),
			distributionPeriods: data.distributionPeriods?.map(({ value }) => value),
			professionalCategoryIds: data.professionalCategoryIds?.map(({ value }) => value),
			field: data.field.value,
			flexFieldValues: data.flexField,
		})
		const index = distributionList.indexOf(data);
		const updatedHiredList = [...distributionList];
		updatedHiredList.splice(index, 1);
		setDistributionList(updatedHiredList);
	}

	useEffect(() => {
		if (isFormSection) return;
		const dataToSend = distributionList.map(line => {
			return ({
				fieldId: line.field.value,
				distributionValue: line.distributionValue,
				action: line.action.value,
				businessUnitIds: returnOptionsFormated(line.businessUnitIds, businessUnitOptions),
				costCategoryIds: returnOptionsFormated(line.costCategoryIds, costCategoryOptions),
				costCenterIds: returnOptionsFormated(line.costCenterIds, costCenterOptions),
				professionalCategoryIds: returnOptionsFormated(line.professionalCategoryIds, professionalCategoryOptions),
				distributionPeriods: returnOptionsFormated(line.distributionPeriods, monthOptions),
				flexField: [],
				resultValue: 0,
			})
		})
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/human-resources/resourceEntry/calcDistribution?scenarioId={scenario}",
			params: dataToSend
		}, onload)
	}, [isFormSection])

	function returnOptionsFormated(data: OptionCascader[], allOptions: OptionCascader[]) {
		return data ? data.map(({ value }) => value) : allOptions.map(({ value }) => value);
	}

	function onload(data: DataDistributionResultValue[]) {
		setDistributionList(state => {
			let newData = [...state];
			return newData.map(line => {
				const resultValue = data.find(({ fieldId }) => fieldId === line.field.value)?.resultValue;
				return {
					...line,
					resultValue: resultValue ? resultValue : 0
				}
			});
		})
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flexfilter?module=1&userId={user}&localeId={locale}&clientId={client}`,
		}, handleAddFlexOptFilter.bind(this));
	}, [])

	function handleAddFlexOptFilter(data: any[]) {
		setFlexFieldValues(data.map(flexField => {
			return {
				value: flexField.value,
				label: flexField.label,
				fieldCode: flexField.fieldCode,
				selectedOption: [],
				children: flexField.children.map(ffValues => {
					return {
						value: ffValues.value,
						label: ffValues.label
					}
				})
			}
		}).sort((a, b) => {
			if (a.fieldCode < b.fieldCode) {
				return -1;
			} else if (a.fieldCode > b.fieldCode) {
				return 1;
			} else {
				return 0;
			}
		}));
	}

	function closeModal() {
		setIsModalVisible(false);
		form.resetFields();
		setDistributionList([]);
		setIsFormSection(true);
	}

	return (
		<Modal
			width={'90%'}
			title={i18n.t("human_resources.distribution_of_values")}
			visible={isModalVisible}
			className="gs-modal"
			cancelText={!isFormSection ? i18n.t<string>("return") : i18n.t<string>("cancel")}
			okText={!isFormSection ? i18n.t<string>("save") : i18n.t<string>("next")}
			onCancel={(e) => onCloseMultiStepModal(e, isFormSection, closeModal, () => setIsFormSection(true))}
			onOk={handleOkButton}
		>
			{isFormSection ?
				<>
					<DistributionForm
						form={form}
						handleSubmit={handleCollabDistribution}
						setDistributionList={setDistributionList}
						distributionList={distributionList}
						isFormSection={isFormSection}
						fieldOptions={addtionalFieldsOptions}
						actionsOptions={actionsOptions}
						monthOptions={monthOptions}
						flexFieldValues={flexFieldValues}
						setFlexFieldValues={setFlexFieldValues}
					/>
					<DistributionList
						form={form}
						setDistributionList={setDistributionList}
						distributionList={distributionList}
						isFormSection={isFormSection}
						editFunction={handleEdit}
						deleteFunction={handleDelete}
					/>
				</>
				:
				<FinalStepModal
					distributionList={distributionList}
					form={form}
					isFormSection={isFormSection}
					setDistributionList={setDistributionList}
				/>
			}
		</Modal>
	)
}