import { Icon } from "@iconify/react";
import { Button, Checkbox, List, Popover, Tooltip } from "antd";
import { useContext, useState } from "react";
import { IRevenueCardDetailList } from "../IRevenue";

import i18n from "util/base/i18n";
import { RevenueContext } from "../RevenueContext";
import { usePermissionContext } from "context/PermissionContext";

export default function CardFilterOrder({
    itensOrdened, setItensOrdened,
}: IRevenueCardDetailList) {

    const [draggingIndex, setDraggingIndex] = useState(null);
    const [cursor, setCursor] = useState('grab');
    const [popoverVisible, setPopoverVisible] = useState(false);
    const { functionalityPermissions } = usePermissionContext();

    const { handleIsFetching, hasChangeOnGrid, handleHasChangeOnGrid,
        isAllFilterChecked, flexFieldsSelected, handleFlexFieldsSelected, handleIsOrdenationChanged } = useContext(RevenueContext);

    function handleMouseGrab(isGrabbing: boolean) {
        isGrabbing ? setCursor('grabbing') : setCursor('grab');
    }

    const handleDragStart = (index: number) => {
        setDraggingIndex(index);
    };

    const handleDragEnd = () => {
        setDraggingIndex(null);
    };

    const handlePopoverClose = () => {
        setPopoverVisible(false);
    }

    const handleDragOver = (index: number) => {
        if (draggingIndex === null || index === draggingIndex) {
            return;
        }

        const newItems = [...itensOrdened];
        const draggingItem = newItems[draggingIndex];

        newItems.splice(draggingIndex, 1);
        newItems.splice(index, 0, draggingItem);

        setItensOrdened(newItems);
        setDraggingIndex(index);
    };

    const handleCheckboxChange = (id: number) => {
        const newItems = [...itensOrdened];
        const itemIndex = newItems.findIndex((item) => item.id === id);
        newItems[itemIndex].checked = !newItems[itemIndex].checked;
        setItensOrdened(newItems);
    };

    function handleSelectAllChange() {
        const allChecked = itensOrdened.every(item => item.checked);
        const updatedItems = itensOrdened.map(item => ({ ...item, checked: !allChecked }));
        setItensOrdened(updatedItems);
    };

    const itemCheckbox = (id: number, checked: boolean) => (
        <Checkbox
            checked={checked}
            onChange={() => handleCheckboxChange(id)}
        />
    );

    const saveFilters = () => {
        let listFlexField = flexFieldsSelected;
        listFlexField.forEach((flex) => {
            let f = itensOrdened.find((l) => l.id === flex.flexFieldId);
            if (f) {
                flex.originalOrder = flex.originalOrder;
                flex.order = itensOrdened.indexOf(f);
            }
        });

        let hasChanged = !hasChangeOnGrid;
        handleFlexFieldsSelected(listFlexField);
        handleIsFetching(true);
        handleIsOrdenationChanged(true);
        handleHasChangeOnGrid(hasChanged);
        handlePopoverClose();
    }

    return (
        <div>
            <Popover content={<>
                <div>
                    <div>
                        <div id="revenue-table-revenue-card-filter-header">
                            <p style={{ color: '#424242', fontWeight: 'bold', fontSize: 16, margin: 'none' }}>
                                {i18n.t<string>("revenue.detail_filter")}
                            </p>
                            <Icon onClick={handlePopoverClose} style={{ marginTop: 7, cursor: 'pointer' }} icon="material-symbols:close" />
                        </div>
                        {i18n.t<string>("revenue.select_and_order")}
                    </div>
                    <div id="scrollableDiv"
                        style={{
                            height: 400,
                            overflow: 'auto',
                            padding: '0 16px 0 0',
                        }}>
                        <List
                            dataSource={itensOrdened}
                            renderItem={(item, index) => (
                                <div
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={() => handleDragOver(index)}
                                    onDragEnd={handleDragEnd}>
                                    <List.Item className="revenue-item-row">
                                        <div id="revenue-card-detail-item"
                                            onMouseDown={() => handleMouseGrab(true)}
                                            onMouseUp={() => handleMouseGrab(false)}>
                                            {item.checked ? (
                                                <>
                                                    <div style={{ background: '#F1F2F3', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 7 }}>
                                                                {itemCheckbox(item.id, item.checked)}
                                                                <p style={{ fontSize: 14 }}>{item.text}</p>
                                                            </div>
                                                        </div>
                                                        <Icon style={{ cursor: cursor, justifyContent: 'flex-end' }} icon="radix-icons:drag-handle-dots-2" />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 7 }}>
                                                                {itemCheckbox(item.id, item.checked)}
                                                                <p style={{ fontSize: 14 }}>{item.text}</p>
                                                            </div>
                                                        </div>
                                                        <Icon style={{ cursor: cursor }} icon="radix-icons:drag-handle-dots-2" />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </List.Item>
                                </div>
                            )}
                            header={
                                <div id="revenue-filter-header">
                                    <Checkbox onChange={() => handleSelectAllChange()} checked={isAllFilterChecked} />
                                    {i18n.t<string>("select_all")}
                                </div>
                            }
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Button style={{ border: 'none', boxShadow: 'none' }}>
                                <div onClick={handlePopoverClose} style={{ color: '#0065B3', fontStyle: 'normal', fontWeight: 400, fontSize: 14 }}>
                                    {i18n.t<string>("close")}
                                </div>
                            </Button>
                            <Button className="revenue-filter-order-button-save" onClick={saveFilters}>
                                <div style={{ color: '#FFFFFF', fontStyle: 'normal', fontWeight: 400, fontSize: 14 }}>
                                    {i18n.t<string>("save")}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </>} trigger="click"
                visible={popoverVisible}
                onVisibleChange={setPopoverVisible}>
                {functionalityPermissions.accountgouping &&
                    <Tooltip placement="topLeft" title={i18n.t<string>("revenue.grouper_account")}>
                        <Button
                            style={{ border: 'none', boxShadow: 'none', }}
                            icon={<Icon icon="fa6-solid:chart-bar" style={{ border: 'none', color: '#A6A7A7' }} />}>
                        </Button>
                    </Tooltip>
                }
            </Popover>
        </div>
    )
}