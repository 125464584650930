import { Checkbox, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { tableProps } from "util/props/props";
import { IDistributionRuleData } from "../IDistributionRule";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import moment from "moment";
import i18n from "util/base/i18n";

export default function DistributionRuleGrid() {
	const {
		tableData,
		isTableDataLoading,
		rowSelection
	} = useDistributionRuleContext();

	const tableColumns: ColumnsType<IDistributionRuleData> = [
		{
			title: i18n.t("distribution_rules.rule"),
			dataIndex: "description",
			key: "description",
			width: 150
		},
		{
			title: i18n.t("order"),
			dataIndex: "order",
			key: "order",
		},
		{
			title: i18n.t("planned"),
			dataIndex: "planned",
			key: "planned",
			render: (value: boolean) => {
				return (
					<Checkbox disabled checked={value} style={{ color: '#B8B8B8' }} />
				)
			},
		},
		{
			title: i18n.t("realized"),
			dataIndex: "accomplished",
			key: "accomplished",
			render: (value: boolean) => {
				return (
					<Checkbox disabled checked={value} style={{ color: '#B8B8B8' }} />
				)
			},
		},
		{
			title: i18n.t("distribution_rules.beginning_term"),
			dataIndex: "startDate",
			key: "startDate",
			render: (value) => {
				return moment(value).format("MMM/YYYY")
			}
		},
		{
			title: i18n.t("distribution_rules.end_term"),
			dataIndex: "endDate",
			key: "endDate",
			render: (value) => {
				return moment(value).format("MMM/YYYY")
			}
		},
		{
			title: i18n.t("distribution_rules.changed_by"),
			dataIndex: "lastUserChangeName",
			key: "lastUserChangeName",
		},
		{
			title: i18n.t("distribution_rules.change_date"),
			dataIndex: "lastUserChangeDate",
			key: "lastUserChangeDate",
			render: (value) => {
				return moment(value).format("DD/MM/YYYY HH:mm:ss");
			}
		},
	]

	return (
		<Table
			{...tableProps}
			columns={tableColumns}
			dataSource={tableData}
			rowKey={"id"}
			loading={isTableDataLoading}
			rowSelection={rowSelection}
		/>
	)
}