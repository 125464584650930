import { createContext, useEffect, useState } from "react";
import { FieldSelected, RevenueContextProps, SelectedViewType } from './IRevenue';

import moment from 'moment';

import i18n from "util/base/i18n";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorFallbackProps } from "components/error/ErrorFallback";
import { useBudgetDates } from "hooks/useBudgetDates";
import { ModuleType } from "util/types/types";

export const RevenueContext = createContext<RevenueContextProps>(null);

export function RevenueProvider({ children }) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasBudgetYear, setHasBudgetYear] = useState<boolean>(false);
    const [isOrdenationChanged, setIsOrdenationChanged] = useState<boolean>(false);
    const [filtersFatherActive, setFiltersFatherActive] = useState<number[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [hasChangeOnGrid, setHasChangeOnGrid] = useState<boolean>(false);
    const [isAllFilterChecked, setIsAllFilterChecked] = useState<boolean>(false);
    const [updateFiltersFlexField, setUpdateFiltersFlexField] = useState<boolean>(false);
    const [flexFieldsSelected, setFlexFieldsSelected] = useState<FieldSelected[]>([]);
    const [isViewTypeChanged, setIsViewTypeChanged] = useState<boolean>(false);
    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>([null, null]);
    const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([[null, null]])
    const [monthColumns, setMonthColumns] = useState<string[]>([]);
    const { showBoundary } = useErrorBoundary()
    const [periodAccomplished, setPeriodAccomplished] = useState<moment.Moment>({} as moment.Moment);
    const [lastMonthAccomplished, setLastMonthAccomplished] = useState<string>("");
    const [selectedViewType, setSelectedViewType] = useState<SelectedViewType>({
        label: i18n.t<string>("planned"),
        value: "PLANNED"
    });
    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.REVENUE)

    useEffect(() => {
        if (!budgetPeriodDates || !budgetPeriodDates.period) {
            return;
        }

        let periodSelected = moment(period[0], 'YYYY').format('YYYY') === moment(period[1], 'YYYY').format('YYYY') ? moment(period[0], 'YYYY') : moment(period[0], 'YYYY');
        const realized = budgetPeriodDates.period.find(item => item.year === periodSelected.year());

        if (realized) {
            let dataMoment = moment(realized.revenueRealizedPeriod);
            let monthFormat: string = dataMoment.format("MMM YYYY");
            let monthFormatted: string = monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);
            setLastMonthAccomplished(monthFormatted);
            setPeriodAccomplished(dataMoment);
        }
    }, [budgetPeriodDates, period, setPeriodAccomplished, setLastMonthAccomplished]);

    useEffect(() => {
        if (!budgetPeriodDates || !budgetPeriodDates.period) {
            return;
        }

        const periods = budgetPeriodDates.period;

        const currentPeriod = periods.length > 0 ? periods[periods.length - 1] : null;

        if (periods.length === 0) {
            return showBoundary({
                code: 400,
                title: 'Não há exercício aberto',
                message: 'Adicione um exercício na tela de painel de controle antes de continuar',
                image: 'empty_list'
            } as ErrorFallbackProps['error'])
        }
        const momentYear = moment(periods[periods.length - 1].year, 'YYYY', true)
        const date = new Date(periods[periods.length - 1].year, 0, 1);

        setPeriod([moment(date), momentYear.endOf('year')])
        setMonthColumns(initializeMonthColumns(currentPeriod.year))

        const openedPeriods = periods.reduce((acc, val) => {
            const date = moment(val.year, 'YYYY')
            const period = [date.startOf('year'), date.endOf('year')]
            acc.push(period)
            return acc
        }, [])

        setOpenedCalendarPeriod(openedPeriods)
        setHasBudgetYear(true);

    }, [budgetPeriodDates])

    function initializeMonthColumns(year: number) {
        const months: string[] = [];
        let currentDate = moment(year, 'YYYY').startOf('year');
        let endDate = moment(year, 'YYYY').endOf('year');

        while (currentDate.isSameOrBefore(endDate)) {
            months.push(currentDate.format("YYYY-MM"));
            currentDate.add(1, "month");
        }

        return months;
    }

    function handleSelectViewType(viewType: SelectedViewType[]) {
        setSelectedViewType(viewType[0]);
    }

    function handleIsLoading(value: boolean) {
        setIsLoading(value);
    }

    function handleIsFetching(value: boolean) {
        setIsFetching(value);
    }

    function handleHasChangeOnGrid(value: boolean) {
        setHasChangeOnGrid(value);
    }

    function handleIsAllFilterChecked(value: boolean) {
        setIsAllFilterChecked(value);
    }

    function handleChangePeriod(value: [moment.Moment, moment.Moment]) {
        setPeriod(value)
        const months: string[] = [];
        let currentDate = value[0].clone();

        while (currentDate.isSameOrBefore(value[1], "month")) {
            months.push(currentDate.format("YYYY-MM"));
            currentDate.add(1, "month");
        }

        setMonthColumns(months);
    }

    function handleFiltersFatherFactive(value: number[]) {
        setFiltersFatherActive(value);
    }

    function handleUpdateFiltersFlexField(value: boolean) {
        setUpdateFiltersFlexField(value);
    }

    function handleFlexFieldsSelected(value: FieldSelected[]) {
        setFlexFieldsSelected(value);
    }

    function handleIsOrdenationChanged(value: boolean) {
        setIsOrdenationChanged(value);
    }

    function handleIsViewTypeChanged(value: boolean) {
        setIsViewTypeChanged(value);
    }

    function handleMonthColumns(value: string[]) {
        setMonthColumns(value);
    }

    return (
        <RevenueContext.Provider value={{
            selectedViewType,
            handleSelectViewType,
            handleIsLoading,
            isLoading,
            isFetching,
            handleIsFetching,
            hasChangeOnGrid,
            handleHasChangeOnGrid,
            isAllFilterChecked,
            handleIsAllFilterChecked,
            period,
            handleChangePeriod,
            filtersFatherActive,
            handleFiltersFatherFactive,
            updateFiltersFlexField,
            handleUpdateFiltersFlexField,
            flexFieldsSelected,
            handleFlexFieldsSelected,
            isOrdenationChanged,
            handleIsOrdenationChanged,
            isViewTypeChanged,
            handleIsViewTypeChanged,
            monthColumns,
            handleMonthColumns,
            hasBudgetYear,
            openedCalendarPeriod,
            periodAccomplished,
            lastMonthAccomplished,
        }} >
            {children}
        </RevenueContext.Provider>
    );
}
