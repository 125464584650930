import { Button, Collapse, Form, FormInstance, InputRef, Table, Tooltip } from "antd";
import { tableProps } from "util/props/props";
import { EditableCellProps, IColumnsProps, IValuePeriodTableData, IValuePeriodTableProps, PeriodicityTypeValues } from "../IRecurringContract";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import moment from "moment";
import StringUtil from "util/StringUtil";
import { EditableRowProps } from "module/budget/pages/detailLayout/IDetailLayout";
import { NumericFormat } from "react-number-format";
import { Icon } from "@iconify/react";
import { cloneDeep } from "lodash";

export default function ValuePeriodTable({
	period = ([moment(), moment()]),
	value,
	isNotRecurring,
	periodicityTypeSelected,
	setMonthsValues,
	monthsForEdit,
	periodFrozen,
	isDisable,
	basePeriod
}: IValuePeriodTableProps) {
	const [cols, setCols] = useState<IColumnsProps[]>([]);
	const [tableData, setTableData] = useState<IValuePeriodTableData[]>([]);
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};
	const defaultColumnValuePeriod: IColumnsProps[] = [{
		title: i18n.t("months"),
		dataIndex: "months",
		key: "months"
	}]
	const defaultDataValuePeriod: IValuePeriodTableData = {
		key: "months",
		months: i18n.t("value"),
	}
	const totalColumn: IColumnsProps = {
		title: i18n.t("total"),
		dataIndex: "total",
		key: "total",
		render: (_, record) => {
			let total = 0;
			Object.keys(record).forEach((key) => {
				if (key.includes("/")) {
					total += Number(record[key]);
				}
			})
			return (
				<p className={total < 0 && `format-negative-number-col`}>
					{
						new Intl.NumberFormat(i18n.language.replace("_", "-"), {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
							currency: "BRL",
							style: "currency",
						}).format(total)
					}
				</p>
			)
		},
		align: "center",
	}

	function doReplicateValues(table, cellIndex) {
		const cellPeriod = cellIndex.replace("/", "-");
		const cellMoment = moment(cellPeriod);

		let startPeriod = period[0].clone();
		let endPeriod = period[1].clone();

		while (startPeriod.isSameOrBefore(endPeriod)) {
			const key = startPeriod.format("YYYY/MM");
			if (startPeriod.isAfter(cellMoment)) {
				table[key] = table[cellIndex];
			}

			startPeriod.add(1, 'month');
		}

		setTableData([table]);
	}

	const EditableCell: React.FC<EditableCellProps> = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef<InputRef>(null);
		const form = useContext(EditableContext)!;
		let isDisabledEditCell: boolean = false;
		if (periodFrozen && dataIndex?.toString()?.includes("/")) {
			const dataIndexDate: moment.Moment = moment(dataIndex);
			if (periodFrozen.isSameOrAfter(dataIndexDate)) isDisabledEditCell = true;
		}
		if (basePeriod && dataIndex?.toString()?.includes("/")) {
			const dataIndexDate: moment.Moment = moment(dataIndex);
			if (dataIndexDate.isSameOrBefore(moment(basePeriod))) isDisabledEditCell = true;
		  }

		useEffect(() => {
			if (editing) {
				inputRef.current!.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			if (isDisabledEditCell) return;
			setEditing(!editing);
			form.setFieldsValue({ [dataIndex]: record[dataIndex] });
		};

		const save = async () => {
			try {
				const values = await form.validateFields();
				const keyIndex: string = Object.keys(values)[0];
				values[keyIndex] = Number(values[keyIndex].replace(",", "."));

				toggleEdit();
				handleSave({ ...record, ...values }, keyIndex);
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = (editing && !isDisabledEditCell) ? (
				<Form.Item
					style={{ margin: 0 }}
					name={dataIndex}
				>
					<NumericFormat
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
								save()
								return
							}
						}}
						className="gs-input-numeric-format"
						decimalSeparator=","
						getInputRef={inputRef}
						onBlur={save}
					/>
				</Form.Item>
			) : (
				<div className="editable-cell-value-contract-modal" >
					<div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
						{children}
					</div>
					{!isDisabledEditCell &&
						<Tooltip placement="topLeft" title={i18n.t<string>("replicate_to_next_months")}>
							<Button style={{ border: 'none', boxShadow: 'none', color: '#0065B3', background: 'none' }}
								icon={<Icon
									style={{ boxShadow: 'none', color: '#A6A7A7' }} icon={"material-symbols:content-copy"} />}
								onClick={() => doReplicateValues(record, dataIndex)} />
						</Tooltip>
					}
				</div>
			);
		}

		return <td  {...restProps} className={` ${isDisabledEditCell && "gs-tag gray disabled-cell"}`}> {childNode}</td >;
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const handleSave = (row: IValuePeriodTableData, key: string) => {
		const newData = [...tableData];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setTableData(newData);
	};

	const columns = cols.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IValuePeriodTableData) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	useEffect(() => {
		const initialDate = period[0];
		const finalDate = period[1];

		setCols(() => {
			let currentDate = initialDate.clone();
			let newColumns: IColumnsProps[] = [];

			while (currentDate.isSameOrBefore(finalDate)) {
				const columnIndex: string = currentDate.format("YYYY/MM")
				newColumns.push({
					title: StringUtil.capitalizeFirstLetter(currentDate.format("MMM YYYY")),
					dataIndex: columnIndex,
					key: columnIndex,
					render: (value = 0) => {
						return (
							<p className={value < 0 && `format-negative-number-col`}>
								{
									new Intl.NumberFormat(i18n.language.replace("_", "-"), {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										currency: "BRL",
										style: "currency",
									}).format(value)
								}
							</p>
						)
					},
					align: "center",
					editable: isNotRecurring && !isDisable
				});
				currentDate.add(1, 'month');
			}

			return [...defaultColumnValuePeriod, ...newColumns, ...[totalColumn]];
		});
	}, [period, isNotRecurring]);

	useEffect(() => {
		let newMonthsValues: any = {};
		const periodInitial = period[0].clone();
		const periodFinal = period[1].clone();
		const baseDate = moment(basePeriod);
	
		setTableData(state => {
			const line: IValuePeriodTableData = cloneDeep(state[0]);
			cols.forEach(({ key }, index) => {
				if (!index) return;

				let newValue = (value && periodicityTypeSelected) ? value / PeriodicityTypeValues[periodicityTypeSelected] : 0;
				if (monthsForEdit && Object.keys(monthsForEdit).length ) {
					const monthToEdit = monthsForEdit[moment(key).format("YYYY-MM")];
					if (monthToEdit) newValue = monthToEdit;
				}
	
				if (moment(key).isSameOrBefore(baseDate)) {
					line[key] = 0;
				} else if (moment(key).isSameOrAfter(periodInitial) && moment(key).isSameOrBefore(periodFinal)) {
					line[key] = newValue;
				} else {
					line[key] = 0;
				}

				newMonthsValues[moment(key).format("YYYY-MM")] = newValue;
			})
			return [{ ...defaultDataValuePeriod, ...line }];
		});
	}, [value, cols, periodicityTypeSelected, monthsForEdit, periodFrozen, period, basePeriod]);
	

	useEffect(() => {
		if (!tableData.length) return;
		let newMonthsValues = {};
		const allMonths = tableData[0];
		Object.keys(allMonths).forEach((key) => {
			if (key.includes("/")) {
				newMonthsValues[moment(key).format("YYYY-MM")] = Number(allMonths[key]);
			}
		});
		setMonthsValues(newMonthsValues);
	}, [tableData])

	return (
		<Collapse ghost>
			<Collapse.Panel header={i18n.t("value_for_period")} key={1}>
				<Table
					dataSource={tableData}
					columns={columns as ColumnsType}
					scroll={{
						x: "max-content"
					}}
					components={components}
					{...tableProps}
				/>
			</Collapse.Panel>
		</Collapse>
	)
}