import { Icon } from "@iconify/react";
import { Col, Row, Table, Tooltip } from "antd";
import { ColumnTypes } from "module/budget/pages/detailLayout/IDetailLayout";
import i18n from "util/base/i18n";
import { FormulaType, IFiltersResponse, ILevelStyle, ITableSideBarProps, LevelFilterPlanning, LevelFormula, LevelReport } from "../../../IRegistrationSalesReports";
import { useEffect, useRef, useState } from "react";
import { FormulaItem, ICondition, IConditionGrouperType, ILevel, ILevelsValue } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useNewReportContext } from "../../NewReportContext";

export default function TableSideBar({
	report,
	selectedRowKey,
	setSelectedRowKey,
	setSelectedRowFormula,
	options,
	setSelectedRowFormulaFormated,
	setPendingLevelSelected,
	setSelectedRowFilters,
	isChangedFormula,
	isFilterValid,
	setSelectedRowPlanningFilters
}: ITableSideBarProps) {
	const [tableData, setTableData] = useState<LevelReport[]>(report.levels);
	const [levelFilters, _] = useState<Map<number, ICondition[]>>(new Map());
	const [levelPlanningFilters, __] = useState<Map<number, LevelFilterPlanning[]>>(new Map());
	const { isPlanningReport } = useNewReportContext();

	const columns: ColumnTypes[number][] = [
		{
			title: i18n.t<string>("code_row"),
			dataIndex: "externalCode",
			key: "externalCode",
			align: "left",
			width: "40%",
		},
		{
			title: i18n.t("description"),
			dataIndex: "description",
			key: "description",
			align: "left",
			render: (value, { children, levelFormula: { formulas } }: LevelReport) => {
				const hasChildren = !!children?.length;
				return (
					<Row className={`description-column`}>
						<Col>
							{value}
						</Col>
						{(!hasChildren && !formulas.length) &&
							<Tooltip title={i18n.t<string>("informative_row")}>
								<Icon icon="fa6-solid:circle-info" />
							</Tooltip>
						}
					</Row >
				)
			},
			width: "60%",
		}
	]

	const addStylesClass = ({ isBold, isItalic, isScratched, isUnderlined }: ILevelStyle) => {
		let classStyled = "";
		classStyled += isBold ? "bold" : "";
		classStyled += isItalic ? " italic" : "";
		classStyled += isScratched ? " scratched" : "";
		classStyled += isUnderlined ? " underlined" : "";
		return classStyled;
	}

	const RowRender = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}) => {
		const ref = useRef<HTMLTableRowElement>(null);
		const { children, levelStyle, id, title }: LevelReport = restProps.children[0]?.props.record;
		const backgroundColor = levelStyle?.color;
		const fontColor = levelStyle?.colorFont;
		const classStyled = levelStyle ? addStylesClass(levelStyle) : "";
		const fontSize = levelStyle?.fontSize;

		if (id === selectedRowKey) {
			restProps.children.push(
				<div className={"selected-row"}>
					<Icon icon="formkit:right" />
				</div >
			)
		}

		const isLineDisabled: string = (children?.length || title) ? "disabled-row" : "";

		return (
			<tr
				ref={ref}
				style={{ background: backgroundColor, color: fontColor, fontSize: fontSize }}
				className={`${className} ${classStyled} ${isLineDisabled} selectable-row ${id === selectedRowKey ? "is-selected" : ""}`}
				{...restProps} />
		);
	};

	const chooseType = {
		"+": "plus",
		"-": "minus",
		"*": "times",
		"/": "divide",
		"(": "left_parenthesis",
		")": "right_parenthesis",
		"value": "value"
	};

	function onClickRow(record: LevelReport) {
		const isFormulaInvalid: boolean = isChangedFormula(findLineById(tableData, selectedRowKey));
		const isFilterInvalid: boolean = !isFilterValid();
		if (isFormulaInvalid || isFilterInvalid) {
			if (isFormulaInvalid) {
				setPendingLevelSelected(record);
			}
		} else if (!(record.children?.length || record.title)) {
			setTableData(tableData);
			setSelectedRowKey(record.id);
			setSelectedRowFormulaFormated(buildFormatedFormula(record.levelFormula));
			setSelectedRowFormula(record.levelFormula?.formulas || []);
		}
	}

	const loadPlanningFilters = (lineId: number) => {
		if (levelPlanningFilters.has(lineId)) {
			setSelectedRowPlanningFilters(levelPlanningFilters.get(lineId));
		} else {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-report/report/find-level-filters?id=${lineId}`
			}, (filters: IFiltersResponse[]) => {
				const levelConditions: LevelFilterPlanning[] = filters.map(({
					fromAccounting: { id: fromAccountingExternalCode },
					fromCostCenter: { id: fromCostCenterExternalCode },
					toAccounting: { id: toAccountingExternalCode },
					toCostCenter: { id: toCostCenterExternalCode },
					signal,
					id
				}) => {
					return ({
						fromAccountingExternalCode,
						fromCostCenterExternalCode,
						toAccountingExternalCode,
						toCostCenterExternalCode,
						signal,
						id
					})
				})

				levelPlanningFilters.set(lineId, levelConditions);
				setSelectedRowPlanningFilters(levelConditions);
			})
		}
	}

	const loadFilters = ({ id }: LevelReport) => {
		if (isPlanningReport()) {
			loadPlanningFilters(id);
			return;
		}

		if (levelFilters.has(id)) {
			setSelectedRowFilters(levelFilters.get(id));
		} else {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-report/report/find-level-filters?id=${id}`
			}, (filters: IFiltersResponse[]) => {
				const levelConditions: ICondition[] = filters.map(filter => {
					let selectedLevel: ILevel = {
						description: filter.flexField?.name,
						id: filter.flexField?.id?.toString()
					};
					let selectedLevelValue: ILevelsValue = {
						description: filter.flexFieldValue?.name,
						id: filter.flexFieldValue?.id
					};

					if (!filter.flexField) {
						if (filter.managementAccounting) {
							selectedLevel = {
								description: i18n.t("new_sales_report.management_accounting_account"),
								id: "management_accounting_account",
							}
							selectedLevelValue = {
								description: filter.managementAccounting.name,
								id: filter.managementAccounting.id,
							}
						} else {
							selectedLevel = {
								description: i18n.t("new_sales_report.management_cost_center"),
								id: "management_cost_center",
							}
							selectedLevelValue = {
								description: filter.managementCostCenter.name,
								id: filter.managementCostCenter.id,
							}
						}
					}

					return ({
						id: filter.id,
						grouperType: IConditionGrouperType.REVENUE,
						selectedLevel,
						operations: filter.operation,
						selectedLevelValue
					})
				});

				levelFilters.set(id, levelConditions);
				setSelectedRowFilters(levelConditions);
			});
		}
	};

	function buildFormatedFormula(levelFormula: LevelFormula): FormulaItem[] {
		return (
			levelFormula?.formulas
				.sort(((a, b) => a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1))
				.map(({ type, attributeId, levelId, operator, value }) => {
					const keyItem = FormulaType.OPERATORS === type ? Math.random() : FormulaType.ATTRIBUTE === type ? attributeId : FormulaType.LEVEL === type ?
						levelId : "";
					const typeItem = FormulaType.OPERATORS === type ? "operator" : FormulaType.INFORMED_VALUE === type ? "value" : "attribute";

					return ({
						content: FormulaType.OPERATORS === type ? chooseType[operator] : { id: keyItem, name: getFormattedLabelLevelFormula(type, keyItem) },
						type: typeItem,
						key: `${FormulaType[type]}-${keyItem}`,
						value: value
					})
				}) || []
		);
	}
	function getFormattedLabelLevelFormula(type: FormulaType, keyItem: number | string): string {
		if (["OPENING_BALANCE", "FINAL_BALANCE", "MOVEMENT", "CREDIT", "DEBIT"].includes(type)) return i18n.t(`new_sales_report.options_formula.${type}`);
		const label = options.find(({ value }) => value.toString().includes(`${type}-${keyItem}`))?.label;
		return FormulaType.LEVEL === type ? `${i18n.t("report")}: ${label}` : label;
	}

	const updateLevelFilters = (levels: LevelReport[]) => {
		levels.forEach(level => {
			if (level.children?.length) {
				updateLevelFilters(level.children);
			} else {
				if (isPlanningReport()) {
					if (levelPlanningFilters.has(level.id)) {
						const planningFilter: LevelFilterPlanning[] = level?.levelFilter?.filters?.map(({ filterPlanning }) => filterPlanning);
						levelPlanningFilters.set(level.id, planningFilter);
					}
				} else {
					if (levelFilters.has(level.id)) {
						levelFilters.set(level.id, level.filters);
					}
				}
			}
		});
	};

	function findLineById(records: LevelReport[], key: number): LevelReport {
		let lineResult: LevelReport;
		for (let index = 0; index < records.length; index++) {
			const line = records[index];
			if (line.id === key) {
				lineResult = line;
				break;
			} else if (line.children) {
				lineResult = findLineById(line.children, key);
				if (lineResult) break;
			}
		}
		return lineResult;
	}

	useEffect(() => {
		let level: LevelReport = findLineById(tableData, selectedRowKey);
		let formulaToUpdate: LevelFormula = level.levelFormula;

		setSelectedRowFormulaFormated(buildFormatedFormula(formulaToUpdate));
		setSelectedRowFormula(formulaToUpdate?.formulas || []);
		loadFilters(level);
	}, [selectedRowKey]);

	useEffect(() => {
		updateLevelFilters(report.levels);
		setTableData(report.levels);
	}, [report]);

	return (
		<Table
			title={() => i18n.t<string>("select_level")}
			columns={columns as ColumnTypes}
			pagination={false}
			expandIcon={({ expanded, onExpand, record }) => {
				const level: LevelReport = record as LevelReport;

				if (level.children && level.children.length) {
					if (expanded) {
						return <div className="button-expandable-tree" onClick={e => onExpand(record, e)}><Icon icon="tabler:chevron-down" /></div>;
					} else {
						return <div className="button-expandable-tree" onClick={e => onExpand(record, e)}><Icon icon="tabler:chevron-right" /></div>;
					}
				}
			}}
			expandable={{
				defaultExpandAllRows: true,
			}}
			components={{
				body: {
					row: RowRender
				}
			}}
			dataSource={tableData}
			onRow={(record: LevelReport) => {
				return {
					onClick: () => onClickRow(record)
				};
			}}
			className="select-level-table"
			scroll={{ y: "max-content" }}
		/>
	)
}